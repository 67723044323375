/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressObject,
    AddressObjectFromJSON,
    AddressObjectFromJSONTyped,
    AddressObjectToJSON,
    PersonStatus,
    PersonStatusFromJSON,
    PersonStatusFromJSONTyped,
    PersonStatusToJSON,
    Phone,
    PhoneFromJSON,
    PhoneFromJSONTyped,
    PhoneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    email?: string;
    /**
     * 
     * @type {Phone}
     * @memberof Person
     */
    mobilePhone?: Phone;
    /**
     * 
     * @type {Phone}
     * @memberof Person
     */
    homePhone?: Phone;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    nationality?: string;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    birthDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    createdAt?: Date;
    /**
     * 
     * @type {AddressObject}
     * @memberof Person
     */
    primaryAddress?: AddressObject;
    /**
     * 
     * @type {AddressObject}
     * @memberof Person
     */
    mailingDdress?: AddressObject;
    /**
     * 
     * @type {AddressObject}
     * @memberof Person
     */
    billingDdress?: AddressObject;
    /**
     * 
     * @type {PersonStatus}
     * @memberof Person
     */
    status?: PersonStatus;
}

export function PersonFromJSON(json: any): Person {
    return PersonFromJSONTyped(json, false);
}

export function PersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Person {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobilePhone': !exists(json, 'mobile_phone') ? undefined : PhoneFromJSON(json['mobile_phone']),
        'homePhone': !exists(json, 'home_phone') ? undefined : PhoneFromJSON(json['home_phone']),
        'dateOfBirth': !exists(json, 'date_of_birth') ? undefined : (new Date(json['date_of_birth'])),
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'birthDate': !exists(json, 'birth_date') ? undefined : (new Date(json['birth_date'])),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'primaryAddress': !exists(json, 'primary_address') ? undefined : AddressObjectFromJSON(json['primary_address']),
        'mailingDdress': !exists(json, 'mailing_ddress') ? undefined : AddressObjectFromJSON(json['mailing_ddress']),
        'billingDdress': !exists(json, 'billing_ddress') ? undefined : AddressObjectFromJSON(json['billing_ddress']),
        'status': !exists(json, 'status') ? undefined : PersonStatusFromJSON(json['status']),
    };
}

export function PersonToJSON(value?: Person | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'mobile_phone': PhoneToJSON(value.mobilePhone),
        'home_phone': PhoneToJSON(value.homePhone),
        'date_of_birth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substr(0,10)),
        'nationality': value.nationality,
        'birth_date': value.birthDate === undefined ? undefined : (value.birthDate.toISOString().substr(0,10)),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'primary_address': AddressObjectToJSON(value.primaryAddress),
        'mailing_ddress': AddressObjectToJSON(value.mailingDdress),
        'billing_ddress': AddressObjectToJSON(value.billingDdress),
        'status': PersonStatusToJSON(value.status),
    };
}


