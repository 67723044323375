import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UsersPage } from './tabs/users/UsersPage';
import { RolesLogsPage } from 'src/domain/access-management/roles-and-permissions/tabs/rules-log/RolesLogsPage';

export const UserManagement = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path + '/users'}>
        <UsersPage />
      </Route>
      <Route path={match.path + '/user-approvals'} exact>
        <RolesLogsPage />
      </Route>
    </Switch>
  );
};
