import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import { CellLight, Column, CellBase } from 'src/components/table/Table';
import { TransactionItem } from './context';
import { TransactionStatusBadge } from './TransactionStatusBadge';
import { TransactionType, CardType, TransactionStatus } from '../transaction';

export const useTransactionsListColumns = ({
  transactionPreview,
}: {
  transactionPreview?: TransactionItem | null;
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    (): Column[] => [
      {
        id: 'settlementDate',
        value: (item: TransactionItem) => (
          <CellLight>
            {item.settlementDate &&
              moment(item.settlementDate).format('DD MMM YYYY')}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.settlementDate'),
        width: 90,
      },
      {
        id: 'transactionAmount',
        value: (item: TransactionItem) => (
          <CellBase>
            {item.transactionAmount &&
              numeral(item.transactionAmount).format('0.00')}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionAmount'),
        width: 120,
      },
      {
        id: 'settlementCurrencyCode',
        value: (item: TransactionItem) => (
          <CellBase>{item.settlementCurrencyCode}</CellBase>
        ),
        label: t(
          'transactionsManagement.transactions.list.settlementCurrencyCode'
        ),
        width: 120,
      },
      {
        id: 'transactionStatus',
        value: (item: TransactionItem) => (
          <CellLight>
            {item.transactionStatus && (
              <TransactionStatusBadge status={item.transactionStatus}>
                {Object.values(TransactionStatus).includes(
                  item.transactionStatus
                )
                  ? t(
                      `transactionsManagement.transactions.status.${item.transactionStatus}`
                    )
                  : item?.transactionStatus}
              </TransactionStatusBadge>
            )}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionStatus'),
        width: 120,
      },
      {
        id: 'transactionType',
        value: (item: TransactionItem) => (
          <CellBase>
            {item?.transactionType &&
            Object.values(TransactionType).includes(item.transactionType)
              ? t(
                  `transactionsManagement.transactions.transactionType.${item.transactionType}`
                )
              : item?.transactionType}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionType'),
        width: 90,
      },
      {
        id: 'transactionDate',
        value: (item: TransactionItem) => (
          <CellLight>
            {item.transactionDate &&
              moment(item.transactionDate).format('DD MMM YYYY')}
          </CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionDate'),
        width: 120,
      },
      {
        id: 'transactionTime',
        value: (item: TransactionItem) => (
          <CellLight>{item.transactionTime}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.transactionTime'),
        width: 90,
      },
      {
        id: 'matchType',
        value: (item: TransactionItem) => (
          <CellLight>{item.matchType}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.matchType'),
        width: 90,
      },
      {
        id: 'cardNumber',
        value: (item: TransactionItem) => (
          <CellBase>{item.cardNumber}</CellBase>
        ),
        label: t('transactionsManagement.transactions.list.cardNumber'),
        width: 140,
      },
      {
        id: 'cardType',
        value: (item: TransactionItem) => (
          <CellBase>
            {item?.cardType && Object.values(CardType).includes(item.cardType)
              ? t(
                  `transactionsManagement.transactions.cardType.${item.cardType}`
                )
              : item?.cardType}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.cardType'),
        width: 100,
      },
      {
        id: 'authorizationCode',
        value: (item: TransactionItem) => (
          <CellBase>{item.authorizationCode}</CellBase>
        ),
        label: t('transactionsManagement.transactions.list.authorizationCode'),
        width: 120,
      },
      {
        id: 'merchantName',
        value: (item: TransactionItem) => (
          <CellLight>{item.subMerchantName}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.subMerchantName'),
        width: 90,
      },
      {
        id: 'referenceNumber',
        value: (item: TransactionItem) => (
          <CellBase>{item.referenceNumber}</CellBase>
        ),
        label: t('transactionsManagement.transactions.list.referenceNumber'),
        width: 120,
      },
    ],
    [t]
  );

  return columns;
};
