import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FieldValues, useFormContext } from 'react-hook-form';
import { IconSearch } from 'src/components/icons/IconSearch';
import { IconCross } from 'src/components/icons/IconCross';
import { LoadingSwitch } from 'src/components/Loading';

export interface TabsHeaderSearchProps<
  TFieldValues extends FieldValues = FieldValues
> {
  name: string;
  fieldLabels: string[];
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const TabsHeaderSearch = ({
  name,
  loading = false,
  disabled = false,
  fieldLabels,
  placeholder,
}: TabsHeaderSearchProps) => {
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const value = watch(name);

  const handleClearValue = () => setValue(name, '');

  const [isFocused, setFocused] = useState(false);

  return (
    <div className="relative w-1/2 h-full border-l lg:w-1/3">
      <input
        name={name}
        ref={register}
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={
          'w-full h-full pt-px px-12 text-xs font-semibold focus:outline-none disabled:cursor-not-allowed focus:shadow-lg transition-shadow duration-300 appearance-none bg-white'
        }
      />
      <div className="absolute top-0 flex items-center h-full px-6 text-xs tracking-wider pointer-events-none">
        <div className="w-6 text-gray-700">
          <LoadingSwitch
            loading={loading}
            animationProps={{
              width: 12,
              height: 12,
              className: 'mr-2',
            }}
          >
            <IconSearch
              className={classNames('w-5 -mt-px', {
                'text-blue-500': isFocused,
              })}
            />
          </LoadingSwitch>
        </div>
        {!isFocused && !value && (
          <span className="hidden lg:inline-block">
            <span className="text-gray-500">
              {placeholder ? placeholder : t('kyc.searchHeader.searchBy')}
            </span>{' '}
            {!placeholder && fieldLabels.map((item, index) => (
              <span key={index} className="text-gray-700">
                <span className="font-semibold">{item}</span>{' '}
                {index !== fieldLabels.length - 1 && '/ '}
              </span>
            ))}
          </span>
        )}
      </div>
      <div className="absolute top-0 right-0 flex items-center h-full px-6">
        {value && (
          <button
            onClick={() => handleClearValue()}
            className="w-4 h-4 text-gray-700 transition duration-300 rounded focus:outline-none focus:shadow-outline"
          >
            <IconCross />
          </button>
        )}
      </div>
    </div>
  );
};
