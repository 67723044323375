import React, {
  createContext,
  FC,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { Sorting } from 'src/components/table/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { nameof, typeProxy } from 'src/utils/nameof';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { UsersListParams } from './UsersList';
import { accessChannelApi, usersApi } from 'src/api';
import {
  AccessChannel,
  CreateIamUserRequest,
  IamUser,
  UpdateIamUserRequest,
} from 'src/api/client';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { DateItemFilter } from 'src/components/list/filters/FilterDate';
import { RadioItemFilter } from 'src/components/list/filters/FilterRadio';
import {
  UPDATE_USER_GET_QUERY_KEY,
  UPDATE_USER_ROLES_QUERY_KEY,
  UPDATE_USER_COMPANIES_QUERY_KEY,
} from 'src/domain/access-management/user-management/tabs/users/add-user/useGetUserQueries';

export const LIST_QUERY_KEY = 'ACCESS_MANAGEMENT/USERS';
const ACCESS_CHANNEL_LIST_QUERY_KEY =
  'ACCESS_MANAGEMENT/USERS/ACCESS_CHANNEL_LIST_QUERY_KEY';

export type ListUserType = IamUser;
export type UserType = IamUser;

export const initialState = {
  dispatch: (_action: Actions) => {},
  columnSorting: undefined as Sorting | undefined,
  listParams: {} as UsersListParams,
  accessChannelList: [] as AccessChannel[],
  createUser: (_user: CreateIamUserRequest) => {},
  updateUser: (_user: UpdateIamUserRequest) => {},
  isLoadingSubmit: false,
  isLoadingChannels: false,
  clearListFilters: () => {},
};

export type Actions = {
  type: 'SET_COLUMN_SORTING';
  columnSorting: Sorting | undefined;
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_COLUMN_SORTING':
      return { ...state, columnSorting: action.columnSorting };
    default:
      throw new Error();
  }
};

export const UsersContext = createContext<typeof initialState>(initialState);

interface UserListFilters {
  names: ItemFilter[];
  status: ItemFilter[];
  dateCreated: DateItemFilter;
  testRadio: RadioItemFilter;
}

export interface UsersListFields {
  filters: UserListFilters;
  searchQuery: string;
  limit: { value: number; label: string };
  cursor: number;
  accessChannelFilter: AccessChannel;
}

export const usersFormType = typeProxy<UsersListFields>();

export const UsersContextProvider: FC = ({ children }) => {
  const methods = useForm<UsersListFields>({
    defaultValues: {
      cursor: 0,
    },
  });
  const queryClient = useQueryClient();
  const [state, dispatchAction] = useReducer(reducer, initialState);
  const { reset, getValues, setValue } = methods;
  const {
    searchQuery,
    limit,
    cursor,
    filters,
    accessChannelFilter,
  } = methods.watch([
    nameof(usersFormType.searchQuery),
    nameof(usersFormType.limit),
    nameof(usersFormType.cursor),
    nameof(usersFormType.filters),
    nameof(usersFormType.accessChannelFilter),
  ]);

  const debouncedSearchQuery = useDebouncedValue<string | undefined>(
    searchQuery,
    750
  );

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info('Dispatch Action', {
        ...action,
        context: LIST_QUERY_KEY,
      });
    }

    dispatchAction(action);
  }, []);

  const {
    data: accessChannelList,
    isLoading: isLoadingChannels,
  } = useQuery(ACCESS_CHANNEL_LIST_QUERY_KEY, () =>
    accessChannelApi.postAccessChannelList(),
    {
      retry: false
    }
  );

  const {
    data: listData,
    isLoading: initListLoading,
    isFetching: listLoading,
    hasNextPage: listHasNextPage,
    fetchNextPage: listFetchNextPage,
    isFetchingNextPage,
    refetch,
    error: listError,
    remove: listRemove,
  } = useInfiniteQuery(
    [
      LIST_QUERY_KEY,
      {
        searchQuery: debouncedSearchQuery,
        accessChannelFilter,
        filters,
      },
    ],
    (
      params: QueryFunctionContext<
        [
          string,
          {
            searchQuery: string | undefined;
            accessChannelFilter: AccessChannel | undefined;
            filters: UserListFilters;
          }
        ]
      >
    ) => {
      const { accessChannelFilter: _accessChannelFilter } = params.queryKey[1];
      return usersApi.postIamUsersList({
        listIamUsersRequest: {
          accessChannelId: _accessChannelFilter?.id,
          cursor,
          limit: limit?.value ? limit?.value : 20,
        },
      });
    },
    {
      enabled: !!limit && !!accessChannelFilter,
      retry: false,
      getNextPageParam: (lastPage) => lastPage?.count === limit?.value,
      onError: (err) => console.log(err),
    }
  );

  useEffect(() => {
    if (cursor === 0) {
      refetch();
    } else {
      setValue('cursor', 0);
    }
  }, [limit]); // eslint-disable-line

  useEffect(() => {
    listRemove();
    setValue('cursor', 0);
    refetch();
  }, [accessChannelFilter]); // eslint-disable-line

  useEffect(() => {
    if (cursor && cursor > 0) {
      listFetchNextPage();
    }
    if (cursor === 0) {
      listRemove();
      refetch();
    }
  }, [cursor, listFetchNextPage, listRemove]); // eslint-disable-line

  const clearListFilters = useCallback(() => {
    dispatch({
      type: 'SET_COLUMN_SORTING',
      columnSorting: undefined,
    });

    reset({ ...getValues(), [nameof(usersFormType.filters)]: undefined });
  }, [dispatch, reset, getValues]);

  const { mutateAsync: createUser, isLoading: isLoadingCreate } = useMutation(
    (user: CreateIamUserRequest) =>
      usersApi.postIamUsersCreate({
        createIamUserRequest: user,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(LIST_QUERY_KEY);
        queryClient.invalidateQueries(UPDATE_USER_GET_QUERY_KEY);
        queryClient.invalidateQueries(UPDATE_USER_COMPANIES_QUERY_KEY);
        queryClient.invalidateQueries(UPDATE_USER_ROLES_QUERY_KEY);
        clearListFilters();
      },
    }
  );

  const { mutateAsync: updateUser, isLoading: isLoadingUpdate } = useMutation(
    (user: UpdateIamUserRequest) =>
      usersApi.postIamUsersUpdate({
        updateIamUserRequest: user,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(LIST_QUERY_KEY);
        queryClient.invalidateQueries(UPDATE_USER_GET_QUERY_KEY);
        queryClient.invalidateQueries(UPDATE_USER_COMPANIES_QUERY_KEY);
        queryClient.invalidateQueries(UPDATE_USER_ROLES_QUERY_KEY);
        clearListFilters();
      },
    }
  );

  const items = useMemo(() => {
    return (
      listData?.pages
        .map((groupItem) =>
          groupItem?.iamUsers?.map((user) => ({
            ...user,
          }))
        )
        .flat() || []
    );
  }, [listData]);

  const handleFetchNextPage = useCallback(async () => {
    if (listData) {
      await listFetchNextPage({
        pageParam: { page: 1 },
      });
    }
  }, [listData, listFetchNextPage]);

  const listParams: UsersListParams = useMemo(
    () => ({
      items,
      initLoading: initListLoading,
      loading: listLoading,
      isFetchingNextPage,
      hasNextPage: listHasNextPage,
      fetchNextPage: handleFetchNextPage,
      refetch,
      listError,
    }),
    [
      items,
      listHasNextPage,
      handleFetchNextPage,
      initListLoading,
      listLoading,
      refetch,
      listError,
      isFetchingNextPage
    ]
  );

  return (
    <FormProvider {...methods}>
      <UsersContext.Provider
        value={{
          ...state,
          dispatch,
          listParams,
          accessChannelList: accessChannelList?.accessChannels || [],
          isLoadingChannels,
          createUser,
          updateUser,
          isLoadingSubmit: isLoadingCreate || isLoadingUpdate,
          clearListFilters,
        }}
      >
        {children}
      </UsersContext.Provider>
    </FormProvider>
  );
};
