/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateRange,
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListChargebacksRequest
 */
export interface ListChargebacksRequest {
    /**
     * 
     * @type {number}
     * @memberof ListChargebacksRequest
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ListChargebacksRequest
     */
    limit: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListChargebacksRequest
     */
    merchants?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListChargebacksRequest
     */
    matchType?: Array<string>;
    /**
     * 
     * @type {DateRange}
     * @memberof ListChargebacksRequest
     */
    transactionDate?: DateRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListChargebacksRequest
     */
    cardType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListChargebacksRequest
     */
    cardNumberPan?: string;
    /**
     * 
     * @type {string}
     * @memberof ListChargebacksRequest
     */
    authorizationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ListChargebacksRequest
     */
    stan?: string;
}

export function ListChargebacksRequestFromJSON(json: any): ListChargebacksRequest {
    return ListChargebacksRequestFromJSONTyped(json, false);
}

export function ListChargebacksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListChargebacksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': json['offset'],
        'limit': json['limit'],
        'merchants': !exists(json, 'merchants') ? undefined : json['merchants'],
        'matchType': !exists(json, 'matchType') ? undefined : json['matchType'],
        'transactionDate': !exists(json, 'transactionDate') ? undefined : DateRangeFromJSON(json['transactionDate']),
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumberPan': !exists(json, 'cardNumberPan') ? undefined : json['cardNumberPan'],
        'authorizationCode': !exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'stan': !exists(json, 'stan') ? undefined : json['stan'],
    };
}

export function ListChargebacksRequestToJSON(value?: ListChargebacksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'merchants': value.merchants,
        'matchType': value.matchType,
        'transactionDate': DateRangeToJSON(value.transactionDate),
        'cardType': value.cardType,
        'cardNumberPan': value.cardNumberPan,
        'authorizationCode': value.authorizationCode,
        'stan': value.stan,
    };
}


