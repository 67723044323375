import { IProcessEnv } from 'src/env';

export function assert(condition: any, message: string): asserts condition {
  if (!condition) throw new Error(message);
}

export function assertEnvVar(envVar: keyof IProcessEnv) {
  return assert(
    process.env[envVar] !== undefined,
    `Environment variable ${envVar} is required`
  );
}
