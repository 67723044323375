/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListCompaniesResponse
 */
export interface ListCompaniesResponse {
    /**
     * 
     * @type {Array<Company>}
     * @memberof ListCompaniesResponse
     */
    companies?: Array<Company>;
}

export function ListCompaniesResponseFromJSON(json: any): ListCompaniesResponse {
    return ListCompaniesResponseFromJSONTyped(json, false);
}

export function ListCompaniesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCompaniesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(CompanyFromJSON)),
    };
}

export function ListCompaniesResponseToJSON(value?: ListCompaniesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(CompanyToJSON)),
    };
}


