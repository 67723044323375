import React, { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { emailPattern } from 'src/validation/patterns';
import { useTranslation } from 'react-i18next';
import { forgotPassword } from 'src/auth/auth';

type ForgotPasswordSchema = {
  email: string;
};

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .email(t('validations.email'))
        .required(t('validations.required')),
    });
  }, [t]);
};

export const FormForgotPassword = ({
  onSubmit = () => {},
}: {
  onSubmit?: (data: ForgotPasswordSchema) => void;
}) => {
  const schema = useSchema();

  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
  } = useForm<ForgotPasswordSchema>({ resolver: yupResolver(schema) });

  const { t } = useTranslation();

  const handleSubmitCallback = async (data: ForgotPasswordSchema) => {
    try {
      await forgotPassword(data.email);

      onSubmit(data);
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: t('validations.somethingWentWrong'),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className="w-full">
      <div className="mb-6">
        <Input
          label={t('loginForm.email')}
          name="email"
          error={errors.email}
          inputRef={register({
            required: true,
            validate: {
              email: (value) => emailPattern.test(value),
            },
          })}
        />
      </div>
      <Button
        loading={formState.isSubmitting}
        disabled={formState.isSubmitting}
        type="submit"
        size="large"
        widthClass="w-full"
      >
        {t('forgotPasswordForm.next')}
      </Button>
    </form>
  );
};
