import React, {
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { get, toUpper, map, filter, forEach } from 'lodash';
import { Auth } from 'aws-amplify';

import { CellDateTime, CellLight, Column } from 'src/components/table/Table';
import { IconVerticalDots } from 'src/components/icons/IconVerticalDots';
import { Tooltip } from 'src/components/Tooltip';
import { HorizontalDivider } from 'src/components/HorizontalDivider';
import { IconPen } from 'src/components/icons/IconPen';
import { IconTrash } from 'src/components/icons/IconTrash';
import { ListUserType } from './UsersContext';
import { ReactComponent as IaecspIcon } from 'src/assets/icons/iaecsp.svg';
import { ReactComponent as EmailIcon } from 'src/assets/icons/email.svg';
import { ReactComponent as ActivateIcon } from 'src/assets/icons/activate.svg';
import { ReactComponent as BooIcon } from 'src/assets/icons/boo.svg';
import { ReactComponent as PowerIcon } from 'src/assets/icons/power.svg';
import { ReactComponent as AccessChannelOtherIcon } from 'src/assets/icons/accessChannelOther.svg';
import { StatusBadge, StatusBadgeType } from 'src/components/StatusBadge';
import { VerticalColItems } from 'src/components/list/VerticalColItems';
// import { FilterNames } from 'src/domain/access-management/user-management/tabs/users/filters/FilterNames';
// import { FilterStatuses } from 'src/domain/access-management/user-management/tabs/users/filters/FilterStatuses';
// import { FilterDateCreated } from 'src/domain/access-management/user-management/tabs/users/filters/FilterDateCreated';
// import { TestFilterRadio } from 'src/domain/access-management/user-management/tabs/users/filters/TestFilterRadio';
import { CreateIamUserRequest, IamUserStatus } from 'src/api/client';
import { UserStatus } from 'src/domain/access-management/roles-and-permissions/tabs/rules/role-detail/AssignedUsers';
import { Amplify } from 'aws-amplify';
import { notifications } from 'src/utils/notifications';
import { Loading } from 'src/components/Loading';
import { useUserChangeStatusModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserChangeStatusModal';
import { Button } from 'src/components/Button';
import { useMutation } from 'react-query';
import { usersApi } from 'src/api';
import checkStatus, { UserActions } from 'src/utils/statuses';

export const getUserStatusBadge = (status: IamUserStatus): StatusBadgeType => {
  switch (status) {
    case IamUserStatus.Active:
      return StatusBadgeType.SUCCESS;
    case IamUserStatus.Pending:
      return StatusBadgeType.SECONDARY;
    case IamUserStatus.Suspended:
      return StatusBadgeType.WARNING;
    case IamUserStatus.Deleted:
      return StatusBadgeType.DANGER;
    default:
      return StatusBadgeType.PRIMARY;
  }
};

interface UserAction {
  icon: ReactNode;
  text: string;
}

export const iconAccessChannelsMap = (name?: string) => {
  const icons = {
    'IAECSP Corporate Portal': <IaecspIcon className="mr-2" />,
    BOO: <BooIcon className="mr-2" />,
    Other: <AccessChannelOtherIcon className="mr-2" />,
  };

  return name ? get(icons, name, null) : null;
};

export const useUsersColumns = <Item extends ListUserType>({
  tableRef,
  onEdit,
  onDelete,
  onClickUserAction,
  openChangeStatusUserModal,
}: {
  tableRef: RefObject<HTMLDivElement>;
  onEdit?: (id: string) => void;
  onDelete?: (id: string, username: string) => void;
  onClickUserAction?: (id: string, status: IamUserStatus) => void;
  openChangeStatusUserModal: (
    status: IamUserStatus,
    id: string,
    username: string,
    item?: any
  ) => void;
}) => {
  const { t } = useTranslation();
  const [loadingResendUsername, setLoadingResendUsername] = useState('');

  const { mutateAsync: resendWelcomeEmail } = useMutation((iamUserId: string) =>
    usersApi.postIamUsersResendWelcomeEmail({
      resendWelcomeEmailRequest: {
        iamUserId,
      },
    })
  );

  const resendInvitation = useCallback(async (e, iamUserId) => {
    setLoadingResendUsername(iamUserId);

    e.stopPropagation();
    try {
      await resendWelcomeEmail(iamUserId);
      notifications.success();
    } catch (e) {} finally {
      setLoadingResendUsername('');
    }
  }, []);

  const getUserAction = useCallback(
    (status?: UserStatus): UserAction => {
      switch (status) {
        case UserStatus.CREATED:
          return {
            icon: <EmailIcon />,
            text: t('buttons.resendInvite'),
          };
        case UserStatus.ACTIVE:
          return {
            icon: <PowerIcon />,
            text: t('buttons.suspend'),
          };
        case UserStatus.SUSPENDED:
          return {
            icon: <PowerIcon />,
            text: t('buttons.activate'),
          };
        default:
          return {
            icon: <EmailIcon />,
            text: t('buttons.sendInvite'),
          };
      }
    },
    [t]
  );

  return useMemo(
    (): Column[] => [
      {
        id: 'id',
        value: (item: Item) => <CellLight>{item.id?.split('-')[0]}</CellLight>,
        label: t('accessManagement.userManagement.list.id'),
        width: 100,
        sortable: true,
        columnFilter: () => null,
      },
      {
        id: 'firstName',
        value: (item: Item) => (
          <>
            {item.firstName} {item.lastName}
          </>
        ),
        label: t('accessManagement.userManagement.list.fullName'),
        width: 'max-content',
        sortable: true,
        columnFilter: () => null,
        // columnFilter: () => <FilterNames tableRef={tableRef} />,
      },
      {
        id: 'email',
        value: (item: Item) => item.username,
        label: t('accessManagement.userManagement.list.email'),
        width: 'min-content',
        sortable: false,
        columnFilter: () => null,
      },
      {
        id: 'organization',
        value: (item: Item) => (
          <div>
            {map(
              filter(item.companiesList, ({ companyTopId }) => !companyTopId),
              ({ id, shortName }) => (
                <div key={id} className="flex items-center">
                  {shortName}

                  {filter(
                    item.companiesList,
                    ({ companyTopId }) => id === companyTopId
                  ).length > 0 && (
                    <Tooltip
                      minWidth={150}
                      placement="bottom-end"
                      withPadding={false}
                      content={() => (
                        <div className="py-1">
                          {map(
                            filter(
                              item.companiesList,
                              ({ companyTopId }) => id === companyTopId
                            ),
                            ({ id, shortName }) => (
                              <div key={id} className="px-3 py-1">
                                {shortName}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    >
                      {({ ref, setIsOpen, isOpen }) => (
                        <div
                          ref={ref}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(!isOpen);
                          }}
                        >
                          <div className="flex items-center justify-center ml-1 bg-blue-100 rounded-full w-5 h-5 text-xxs text-blue-500">
                            {
                              filter(
                                item.companiesList,
                                ({ companyTopId }) => id === companyTopId
                              ).length
                            }
                          </div>
                        </div>
                      )}
                    </Tooltip>
                  )}
                </div>
              )
            )}
          </div>
        ),
        label: t('accessManagement.userManagement.list.organization'),
        width: 'max-content',
        sortable: false,
        columnFilter: () => null,
      },
      {
        id: 'program',
        value: (item: Item) => (
          <VerticalColItems items={item.programsNameList} />
        ),
        label: t('accessManagement.userManagement.list.program'),
        width: 'max-content',
        sortable: false,
        columnFilter: () => null,
      },
      {
        id: 'role',
        value: (item: Item) => <VerticalColItems items={item.rolesNameList} />,
        label: t('accessManagement.userManagement.list.role'),
        width: 'max-content',
        sortable: false,
        columnFilter: () => null,
      },
      {
        id: 'typeName',
        value: (item: Item) => item.typeName,
        label: t('accessManagement.userManagement.list.type'),
        width: 'max-content',
        sortable: true,
        columnFilter: () => null,
        // columnFilter: () => <TestFilterRadio tableRef={tableRef} />,
      },
      {
        id: 'status',
        value: (item: Item) =>
          item.status && (
            <StatusBadge
              variant={getUserStatusBadge(
                toUpper(item.status) as IamUserStatus
              )}
            >
              {t(`accessManagement.userManagement.status.${item.status}`)}
            </StatusBadge>
          ),
        label: t('accessManagement.userManagement.list.status'),
        width: 100,
        sortable: true,
        columnFilter: () => null,
        // columnFilter: () => <FilterStatuses tableRef={tableRef} />,
      },
      {
        id: 'createdAt',
        value: (item: Item) => (
          <CellLight>
            {item.createdAt && <CellDateTime date={item.createdAt} />}
          </CellLight>
        ),
        label: t('accessManagement.userManagement.list.dateCreated'),
        width: 'max-content',
        sortable: true,
        columnFilter: () => null,
        // columnFilter: () => <FilterDateCreated tableRef={tableRef} />,
      },
      {
        id: 'actions',
        value: (item: Item) => {
          return (
            item.status !== IamUserStatus.Deleted && (
              <>
                <Tooltip
                  minWidth={50}
                  placement="bottom-end"
                  withPadding={false}
                  content={() => (
                    <div className="py-1">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          item.id && onEdit && onEdit(item.id);
                        }}
                        className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100 cursor-pointer"
                      >
                        <IconPen />
                        <span className="ml-2">{t('buttons.edit')}</span>
                      </div>

                      {checkStatus({
                        status: item.status,
                        action: UserActions.ACTIVATE,
                      }) && (
                        <div
                          className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            openChangeStatusUserModal(
                              IamUserStatus.Active,
                              item.id,
                              `${item?.firstName} ${item?.lastName}`,
                              item
                            );
                          }}
                        >
                          <ActivateIcon />
                          <span className="ml-2">{t('buttons.activate')}</span>
                        </div>
                      )}
                      {checkStatus({
                        status: item.status,
                        action: UserActions.SUSPEND,
                      }) && (
                        <div
                          className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            openChangeStatusUserModal(
                              IamUserStatus.Suspended,
                              item.id,
                              `${item?.firstName} ${item?.lastName}`,
                              item
                            );
                          }}
                        >
                          <ActivateIcon />
                          <span className="ml-2">{t('buttons.suspend')}</span>
                        </div>
                      )}

                      {checkStatus({
                        status: item.status,
                        action: UserActions.RESEND_INVITATION,
                      }) && (
                        <div
                          onClick={(e) => {
                            resendInvitation(e, item.id);
                          }}
                          className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100 cursor-pointer"
                        >
                          {loadingResendUsername === item.id && (
                            <Loading className="mr-2" />
                          )}
                          <EmailIcon />
                          <span className="ml-2">
                            {t('buttons.resendInvite')}
                          </span>
                        </div>
                      )}
                      <div className={'mx-4 py-1'}>
                        <HorizontalDivider />
                      </div>
                      {checkStatus({
                        status: item.status,
                        action: UserActions.DELETE,
                      }) &&
                        onDelete && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              item.id &&
                                onDelete(
                                  item.id,
                                  `${item.firstName} ${item.lastName}`
                                );
                            }}
                            className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100 cursor-pointer"
                          >
                            {<IconTrash className="text-red-500" />}
                            <span className="ml-2">{t('buttons.delete')}</span>
                          </div>
                        )}
                    </div>
                  )}
                >
                  {({ ref, setIsOpen, isOpen }) => (
                    <div
                      ref={ref}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(!isOpen);
                      }}
                      className="mr-5 cursor-pointer"
                    >
                      <IconVerticalDots className="text-gray-700" />
                    </div>
                  )}
                </Tooltip>
              </>
            )
          );
        },
        label: t('list.common.action'),
        width: 50,
        columnFilter: () => null,
      },
    ],
    [t, onEdit, onDelete, onClickUserAction, getUserAction, tableRef]
  );
};
