import React, { PropsWithoutRef, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const currencySigns = {
  USD: '$',
  EUR: '€',
};

export interface TotalBalanceProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
    balance: number,
    cardBalance?: boolean,
    simple?: boolean,
  }

const currency = 'USD';

export const TotalBalance = React.forwardRef<HTMLDivElement, TotalBalanceProps>(
  ({ balance, cardBalance, simple = false, ...props }, ref) => {
    const formatedAmount = useMemo(() => {
      const balanceNum = +balance;

      const rawAmount = balance ? balanceNum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0;
      const trailedAmount = rawAmount  ? rawAmount.slice(0, -3) : 0;
      const trail = rawAmount ? rawAmount.slice(-2) : 0;
      return {
        rawAmount,
        trailedAmount,
        trail,
      };
    }, [balance]);

    const { t } = useTranslation();

    return (
      <div ref={ref}>
        <span className={classNames("text-gray-800 font-din", { 'text-4xl font-medium leading-10 tracking-tighter': !simple })}>
          {currencySigns[currency]}
          {formatedAmount.trailedAmount}
          <span    className="text-gray-700">.{formatedAmount.trail}{`${formatedAmount.trail}`.length === 1 ? '0' : ''}</span>
        </span>
        {!simple && <div className="mt-1 font-sans text-xs font-medium leading-5 tracking-normal text-gray-700">
          {cardBalance ? t('balance.cardBalance') : t('balance.totalBalance') } {' '}
          <span className="text-cool-gray-600">USD dollars</span>
        </div>}
      </div>
    );
  }
);
