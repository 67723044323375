import React, { useContext, useEffect } from 'react';
import { MaskedNumberInput } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm, Controller } from 'react-hook-form';

export const FilterSettlementAmount = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { errors, control, watch, setValue } = useForm<{
    settlementAmount: string;
  }>();

  const { settlementAmount } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_SETTLEMENT_AMOUNT_FILTER',
      settlementAmount: settlementAmount,
    });
  }, [settlementAmount, dispatch]);

  useEffect(() => {
    if (filters.settlementAmount !== undefined) {
      setValue('settlementAmount', filters.settlementAmount);
    }
  }, [filters.settlementAmount, setValue]);

  return (
    <Controller
      as={
        <MaskedNumberInput
          label={t(
            'transactionsManagement.transactions.list.filters.settlementAmount'
          )}
          name="settlementAmount"
          labelFontClassName="text-xxs font-medium"
          labelColorClassName="text-gray-700"
          className="h-8 text-xs font-bold"
          maxLength={10}
          error={errors.settlementAmount}
        />
      }
      name="settlementAmount"
      control={control}
    />
  );
};
