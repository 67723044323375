import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RolesLogsContextProvider } from './RolesLogsContext';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { RolesLogsTabList } from './RolesLogsTabList';

const RolesLogsTab = () => {
  const { t } = useTranslation();
  const storeDispatch = useDispatch();
  const routeMatch = useRouteMatch();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('accessManagement.breadcrumb'),
          route: routeMatch.url,
        },
        {
          label: t('accessManagement.rolesAndPermissions.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  return <RolesLogsTabList />;
};

export const RolesLogsPage = () => (
  <RolesLogsContextProvider>
    <RolesLogsTab />
  </RolesLogsContextProvider>
);
