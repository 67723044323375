import { createContext } from 'react';

export const initialState = {
  searchQuery: '',
  ageSorting: '',
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_AGE_SORTING'; ageSorting: string }

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_AGE_SORTING':
      return { ...state, ageSorting: action.ageSorting };
    default:
      throw new Error();
  }
};


export const FrontContext = createContext<typeof initialState>(initialState);
