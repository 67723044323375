/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamPermission,
    IamPermissionFromJSON,
    IamPermissionFromJSONTyped,
    IamPermissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListIamPermissionsResponse
 */
export interface ListIamPermissionsResponse {
    /**
     * 
     * @type {Array<IamPermission>}
     * @memberof ListIamPermissionsResponse
     */
    iamPermissions?: Array<IamPermission>;
}

export function ListIamPermissionsResponseFromJSON(json: any): ListIamPermissionsResponse {
    return ListIamPermissionsResponseFromJSONTyped(json, false);
}

export function ListIamPermissionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamPermissionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamPermissions': !exists(json, 'iamPermissions') ? undefined : ((json['iamPermissions'] as Array<any>).map(IamPermissionFromJSON)),
    };
}

export function ListIamPermissionsResponseToJSON(value?: ListIamPermissionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iamPermissions': value.iamPermissions === undefined ? undefined : ((value.iamPermissions as Array<any>).map(IamPermissionToJSON)),
    };
}


