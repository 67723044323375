/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessChannel,
    AccessChannelFromJSON,
    AccessChannelFromJSONTyped,
    AccessChannelToJSON,
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    IamRole,
    IamRoleFromJSON,
    IamRoleFromJSONTyped,
    IamRoleToJSON,
    IamSecurityProfiles,
    IamSecurityProfilesFromJSON,
    IamSecurityProfilesFromJSONTyped,
    IamSecurityProfilesToJSON,
    IamUser,
    IamUserFromJSON,
    IamUserFromJSONTyped,
    IamUserToJSON,
    IamUserRole,
    IamUserRoleFromJSON,
    IamUserRoleFromJSONTyped,
    IamUserRoleToJSON,
    Program,
    ProgramFromJSON,
    ProgramFromJSONTyped,
    ProgramToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProfileResponse
 */
export interface GetProfileResponse {
    /**
     * 
     * @type {Array<Program>}
     * @memberof GetProfileResponse
     */
    programs?: Array<Program>;
    /**
     * 
     * @type {Array<Company>}
     * @memberof GetProfileResponse
     */
    companies?: Array<Company>;
    /**
     * 
     * @type {Array<IamRole>}
     * @memberof GetProfileResponse
     */
    roles?: Array<IamRole>;
    /**
     * 
     * @type {Array<IamUserRole>}
     * @memberof GetProfileResponse
     */
    userRoles?: Array<IamUserRole>;
    /**
     * 
     * @type {IamSecurityProfiles}
     * @memberof GetProfileResponse
     */
    securityProfile?: IamSecurityProfiles;
    /**
     * 
     * @type {Array<AccessChannel>}
     * @memberof GetProfileResponse
     */
    accessChannels?: Array<AccessChannel>;
    /**
     * 
     * @type {IamUser}
     * @memberof GetProfileResponse
     */
    iamUser?: IamUser;
}

export function GetProfileResponseFromJSON(json: any): GetProfileResponse {
    return GetProfileResponseFromJSONTyped(json, false);
}

export function GetProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProfileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programs': !exists(json, 'programs') ? undefined : ((json['programs'] as Array<any>).map(ProgramFromJSON)),
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(CompanyFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(IamRoleFromJSON)),
        'userRoles': !exists(json, 'user_roles') ? undefined : ((json['user_roles'] as Array<any>).map(IamUserRoleFromJSON)),
        'securityProfile': !exists(json, 'security_profile') ? undefined : IamSecurityProfilesFromJSON(json['security_profile']),
        'accessChannels': !exists(json, 'access_channels') ? undefined : ((json['access_channels'] as Array<any>).map(AccessChannelFromJSON)),
        'iamUser': !exists(json, 'iam_user') ? undefined : IamUserFromJSON(json['iam_user']),
    };
}

export function GetProfileResponseToJSON(value?: GetProfileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'programs': value.programs === undefined ? undefined : ((value.programs as Array<any>).map(ProgramToJSON)),
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(CompanyToJSON)),
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(IamRoleToJSON)),
        'user_roles': value.userRoles === undefined ? undefined : ((value.userRoles as Array<any>).map(IamUserRoleToJSON)),
        'security_profile': IamSecurityProfilesToJSON(value.securityProfile),
        'access_channels': value.accessChannels === undefined ? undefined : ((value.accessChannels as Array<any>).map(AccessChannelToJSON)),
        'iam_user': IamUserToJSON(value.iamUser),
    };
}


