import React, {
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  useRef, useState,
} from 'react';
import { TransactionsList } from './TransactionsList';
import {
  reducer,
  initialState,
  Actions,
  ListContext,
  ListFilters,
  TransactionItem,
} from './context';
import { useDispatch } from 'react-redux';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { transactionHistoryApi } from 'src/api';
import { Transaction } from 'src/api/client';
import { CardType, TransactionStatus, TransactionType } from '../transaction';
import { AuthorizationsList } from 'src/domain/transaction-management/authorizations/list/AuthorizationsList';

type LoadItemsOptions = {
  limit: number;
  offset?: number;
  page?: number;
  filters: ListFilters;
};

const filterValues = (items: ItemFilter[]) => items.map((item) => item.value);

const listRequestPayload = (options: LoadItemsOptions) => ({
  filters: {
    merchants:
      options.filters.merchants.length > 0
        ? filterValues(options.filters.merchants)
        : undefined,
    transactionType:
      options.filters.transactionTypes.length > 0
        ? filterValues(options.filters.transactionTypes)
        : undefined,
    transactionStatus: (options.filters.status as string) || undefined,
    transactionDate:
      options.filters.transactionDate.from && options.filters.transactionDate.to
        ? {
            from: options.filters.transactionDate.from.format('YYYY-MM-DD'),
            to: options.filters.transactionDate.to.format('YYYY-MM-DD'),
          }
        : undefined,
    settlementDate:
      options.filters.settlementDate.from && options.filters.settlementDate.to
        ? {
            from: options.filters.settlementDate.from.format('YYYY-MM-DD'),
            to: options.filters.settlementDate.to.format('YYYY-MM-DD'),
          }
        : undefined,
    cardNumberPan: options.filters.cardLastFour || undefined,
    authorizationCode: options.filters.authorizationCode || undefined,
    settlementAmount:
      ((options.filters.settlementAmount as unknown) as number) || undefined,
    arn: options.filters.arnNumber || undefined,
    rrn: options.filters.rrnNumber || undefined,
    stan: options.filters.stan || undefined,
    matchType:
      options.filters.matchTypes.length > 0
        ? filterValues(options.filters.matchTypes)
        : undefined,
    cardType:
      options.filters.cardTypes.length > 0
        ? filterValues(options.filters.cardTypes)
        : undefined,
  },
});

const loadItems = (options: LoadItemsOptions) => {
  return transactionHistoryApi
    .postTransactionsList({
      listTransactionsPageRequest: {
        offset: options.offset || 0,
        limit: options.limit,
        ...listRequestPayload(options),
      },
    })
};

const downloadItems = (options: LoadItemsOptions) => {
  return transactionHistoryApi.postTransactionsCsv({
    listTransactionsFilter: {
      ...listRequestPayload(options).filters,
    },
  });
};

const normalizeTransaction = (transaction: Transaction): TransactionItem => ({
  ...transaction,
  id: transaction.id!,
  subMerchantName: transaction.merchantName!,
  cardType: transaction.cardBrand as CardType,
  settlementDate: transaction.settlementDate?.toString()!,
  cardNumber: transaction.cardNumber || '',
  terminalId: transaction.terminalId || '',
  authorizationCode: transaction.authorizationCode || '',
  referenceNumber: transaction.referenceNumber || '',
  transactionDate: transaction.transactionDate?.toString() || '',
  transactionAmount: transaction.transactionAmount || 0,
  settlementCurrencyCode: transaction.settlementCurrencyCode || '',
  transactionStatus: transaction.transactionStatus as TransactionStatus,
  transactionType: transaction.transactionType as TransactionType,
  transactionTime: transaction.transactionTime || '',
  subMerchantId: transaction.subMerchantId || '',
  matchType: transaction.matchType || '',
});

export const Container = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<Response | null>(null);
  const [state, dispatchAction] = useReducer(reducer, initialState);

  const storeDispatch = useDispatch();

  const routeMatch = useRouteMatch();

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info({ ...action, context: 'TRANSACTIONS/FRONT' });
    }

    dispatchAction(action);
  }, []);

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('transactionsManagement.breadcrumb'),
          route: routeMatch.url,
        },
        {
          label: t('transactionsManagement.transactions.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  const rowsPerPageRef = useRef(state.rowsPerPage);

  const fetchedItemsRef = useRef(state.items.length);

  useEffect(() => {
    rowsPerPageRef.current = state.rowsPerPage;

    fetchedItemsRef.current = state.items.length;
  }, [state.rowsPerPage, state.items.length]);

  useEffect(() => {
    let canceled = false;

    dispatch({
      type: 'INCREMENT_ITEMS_LOADING',
    });

    dispatch({
      type: 'SET_PAGE',
      page: 1,
    });

    dispatch({
      type: 'SET_END_REACHED',
      endReached: false,
    });

    const requestPayload = {
      limit: rowsPerPageRef.current,
      offset: 0,
      filters: {
        ...state.appliedFilters,
      },
    };

    loadItems(requestPayload)
      .then((response) => {
        if (!canceled) {
          const data = (response.data || []).map(normalizeTransaction);

          dispatch({ type: 'SET_ITEMS', items: data });

          if (data.length === 0) {
            dispatch({
              type: 'SET_END_REACHED',
              endReached: true,
            });
          }
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        if (!canceled) {
          dispatch({
            type: 'DECREMENT_ITEMS_LOADING',
          });
        }
      });

    return () => {
      dispatch({
        type: 'DECREMENT_ITEMS_LOADING',
      });

      canceled = true;
    };
  }, [dispatch, state.appliedFilters]);

  const appliedFiltersRef = useRef(state.appliedFilters);

  useEffect(() => {
    appliedFiltersRef.current = state.appliedFilters;
  }, [state.appliedFilters]);

  useEffect(() => {
    let canceled = false;

    if (state.page === 1) {
      return;
    }

    const setPaginationLoading = (loading: boolean) =>
      dispatch({
        type: 'SET_PAGINATION_LOADING',
        loading,
      });

    setPaginationLoading(true);

    dispatch({
      type: 'SET_END_REACHED',
      endReached: false,
    });

    loadItems({
      page: state.page,
      limit: rowsPerPageRef.current,
      offset: fetchedItemsRef.current,
      filters: {
        ...appliedFiltersRef.current,
      },
    })
      .then((response) => {
        if (!canceled) {
          const data = (response.data || []).map(normalizeTransaction);

          dispatch({ type: 'APPEND_ITEMS', items: data });

          if (data.length === 0) {
            dispatch({
              type: 'SET_END_REACHED',
              endReached: true,
            });
          }
        }
      })
      .finally(() => {
        if (!canceled) {
          setPaginationLoading(false);
        }
      });

    return () => {
      canceled = true;
    };
  }, [state.page, dispatch]);

  const download = useCallback(async () => {
    dispatch({ type: 'SET_DOWNLOAD_LOADING', loading: true });

    downloadItems({
      limit: 1000,
      filters: state.filters,
    })
      .then(async (csv: any) => {
        // for some reason, the original type doesn't match the real prototype. @TODO
        const downloadLink = document.createElement('a');

        const data = ((await csv.text()) as string).replace(/"/g, '');

        downloadLink.href = 'data:text/csv;charset=utf-8,' + atob(data);

        downloadLink.target = '_blank';

        downloadLink.download = 'transactions.csv';

        downloadLink.click();

        setTimeout(() => downloadLink.remove());
      })
      .finally(() =>
        dispatch({ type: 'SET_DOWNLOAD_LOADING', loading: false })
      );
  }, [dispatch, state.filters]);

  const itemsLoadingComputed = useMemo(() => state.itemsLoading > 0, [
    state.itemsLoading,
  ]);

  return (
    <ListContext.Provider
      value={{
        ...state,
        itemsLoadingComputed,
        download,
        dispatch,
      }}
    >
      <TransactionsList error={error} />
    </ListContext.Provider>
  );
};
