import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { nameof } from 'src/utils/nameof';
import { addUserFormType } from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserModal';
import { EditUserData } from './useGetUserQueries';

export const initialState = {
  userData: {} as EditUserData,
  firstOrgnValue: {} as { [key: number]: string | undefined },
  setFirstOrgnValue: (_value: string, _fieldIndex: number) => {},
  setDefaultValues: (_firstOrg: {}, _autoAffiliated: {}) => {},
  autoAssignAffiliated: {} as { [key: number]: boolean },
  setAutoAssignAffiliated: (_value: boolean, _fieldIndex: number) => {},
  setClearSecondEnable: (_value: boolean) => {},
};

export const AddUsersContext = createContext<typeof initialState>(initialState);

interface Props {
  editUserData: EditUserData;
}

export const AddUsersContextProvider: FC<Props> = ({
  editUserData,
  children,
}) => {
  const { getValues, setValue } = useFormContext();
  const [clearSecondEnable, setClearSecondEnable] = useState(false);
  const [firstOrgnValue, setFirstOrgnValue] = useState<{
    [key: number]: string | undefined;
  }>({});
  const [fieldsIndex, setFieldsIndex] = useState();
  const [userData, setUserData] = useState({});
  const [autoAssignAffiliated, setAutoAssignAffiliated] = useState({});

  useEffect(() => setUserData(editUserData), [editUserData]);

  useEffect(() => {
    if (!clearSecondEnable || fieldsIndex === undefined) {
      return;
    }

    const formValues = getValues();

    if (!formValues.companyAssignments) {
      return;
    }

    const currentFieldValues = {
      ...formValues.companyAssignments[fieldsIndex],
    };

    if (!currentFieldValues.secondOrganization) {
      return;
    }

    setValue(
      nameof(addUserFormType.companyAssignments) +
        `[${fieldsIndex}].secondOrganization`,
      null
    );
  }, [
    clearSecondEnable,
    fieldsIndex,
    setValue,
    getValues,
    firstOrgnValue,
    autoAssignAffiliated,
  ]);

  const toggleClearSecondEnable = useCallback((value: boolean) => {
    setClearSecondEnable(value);
  }, []);

  const handleSetFirstOrgnValue = useCallback(
    (value: string, fieldIndex: number) => {
      if (!clearSecondEnable) {
        return;
      }
      setFirstOrgnValue((prevState) => ({ ...prevState, [fieldIndex]: value }));
      setFieldsIndex(fieldIndex);
    },
    [clearSecondEnable]
  );

  const setDefaultValues = useCallback((firstOrg: {}, autoAffiliated: {}) => {
    setFirstOrgnValue(firstOrg);
    setAutoAssignAffiliated(autoAffiliated);
  }, []);

  const handleSetAutoAssignAffiliated = useCallback(
    (value: boolean, fieldIndex: number) => {
      if (!clearSecondEnable) {
        return;
      }
      setAutoAssignAffiliated((prevState) => ({
        ...prevState,
        [fieldIndex]: value,
      }));
      setFieldsIndex(fieldIndex);
    },
    [clearSecondEnable]
  );

  return (
    <AddUsersContext.Provider
      value={{
        userData,
        firstOrgnValue,
        setFirstOrgnValue: handleSetFirstOrgnValue,
        setDefaultValues,
        autoAssignAffiliated,
        setAutoAssignAffiliated: handleSetAutoAssignAffiliated,
        setClearSecondEnable: toggleClearSecondEnable,
      }}
    >
      {children}
    </AddUsersContext.Provider>
  );
};
