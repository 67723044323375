import React, { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { toLower } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  AddUserFields,
  addUserFormType,
  AssignmentsItemFields,
} from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserModal';
import { Button } from 'src/components/Button';
import { CheckboxForm } from 'src/components/form/CheckboxForm';
import { nameof } from 'src/utils/nameof';
import { IconPlus } from 'src/components/icons/IconPlus';
import { HorizontalDivider } from 'src/components/HorizontalDivider';
import { FormViewItem } from 'src/domain/access-management/user-management/tabs/users/add-user/FormViewItem';
import { StatusBadge } from 'src/components/StatusBadge';
import { getUserStatusBadge } from 'src/domain/access-management/user-management/tabs/users/useUsersColumns';
import { UsersContext } from 'src/domain/access-management/user-management/tabs/users/UsersContext';
import { IamUserStatus } from 'src/api/client';
import { isEmptyAssign } from './FormTwo/FormTwo';

const DEFAULT_SEND_INVITATION = true;

type AddUserItemByKey = AssignmentsItemFields & { key: string };

interface Props {
  toPreviousStep: () => void;
  isEdit?: boolean;
}

export const FormReview = memo(({ toPreviousStep, isEdit }: Props) => {
  const { t } = useTranslation();
  const { isLoadingSubmit } = useContext(UsersContext);
  const { control, getValues } = useFormContext<AddUserFields>();
  const user = useMemo(() => getValues(), [getValues]);

  const userAssigmentItems: AddUserItemByKey[] = useMemo(
    () => [
      ...user.companyAssignments.map((item) => ({ key: uuidv4(), ...item })),
    ],
    [user.companyAssignments]
  );

  return (
    <div className={'break-words'}>
      <div className={'mb-4'}>
        <div className={'flex items-center'}>
          <div className={'mr-2 font-bold text-2xl min-w-0'}>
            {user.firstName + ' ' + user.lastName}
          </div>
          {user.status && (
            <StatusBadge
              variant={getUserStatusBadge(
                toLower(user.status) as IamUserStatus
              )}
            >
              {t(`accessManagement.userManagement.status.${user.status}`)}
            </StatusBadge>
          )}
        </div>
      </div>
      {!isEdit && (
        <div className={'grid grid-cols-2 gap-4 w-full mb-4'}>
          <FormViewItem label={t('accessManagement.userManagement.form.email')}>
            {user.email}
          </FormViewItem>
          <FormViewItem
            label={t('accessManagement.userManagement.form.phoneNumber')}
          >
            {user.phoneNumber}
          </FormViewItem>
        </div>
      )}
      <div className={'grid grid-cols-2 gap-4 w-full mb-4'}>
        {user.title && (
          <FormViewItem label={t('accessManagement.userManagement.form.title')}>
            {user.title}
          </FormViewItem>
        )}
        {user.notes && (
          <FormViewItem
            className={'row-span-3'}
            label={t('accessManagement.userManagement.form.notes')}
          >
            {user.notes}
          </FormViewItem>
        )}
        {user.company && (
          <FormViewItem
            label={t('accessManagement.userManagement.form.company')}
          >
            {user.company}
          </FormViewItem>
        )}
        {user.orgUnit && (
          <FormViewItem
            label={t('accessManagement.userManagement.form.organizationUnit')}
          >
            {user.orgUnit}
          </FormViewItem>
        )}
        {user.directSupervisor && (
          <FormViewItem
            label={t('accessManagement.userManagement.form.directSupervisor')}
          >
            {user.directSupervisor}
          </FormViewItem>
        )}
      </div>
      <HorizontalDivider className={'my-4'} />
      {userAssigmentItems.map(
        (item) =>
          !isEmptyAssign(item) && (
            <div key={item.key}>
              <div className={'grid grid-cols-2 gap-4 w-full mb-4'}>
                {item?.organizations && (
                  <FormViewItem
                    label={t(
                      'accessManagement.userManagement.form.organization.label'
                    )}
                  >
                    {item?.organizations?.label}
                  </FormViewItem>
                )}
                {item?.firstOrganization && (
                  <FormViewItem
                    label={t(
                      'accessManagement.userManagement.form.firstOrganization'
                    )}
                  >
                    {item?.firstOrganization?.label}
                  </FormViewItem>
                )}
                {item?.secondOrganization && (
                  <FormViewItem
                    label={t(
                      'accessManagement.userManagement.form.secondOrganization'
                    )}
                  >
                    {item?.secondOrganization?.label}
                  </FormViewItem>
                )}
                {item?.program &&
                  Array.isArray(item?.program) &&
                  !!item?.program.length && (
                    <FormViewItem
                      label={t(
                        'accessManagement.userManagement.form.program.label'
                      )}
                    >
                      {item?.program?.map((item) => (
                        <span key={item.value}>{item.label}</span>
                      ))}
                    </FormViewItem>
                  )}
                {item?.roles &&
                  Array.isArray(item?.roles) &&
                  !!item?.roles.length && (
                    <FormViewItem
                      label={t('accessManagement.userManagement.form.roles')}
                    >
                      {item?.roles?.map((item) => (
                        <span key={item.value}>{item.label}</span>
                      ))}
                    </FormViewItem>
                  )}
              </div>
              <HorizontalDivider className={'my-4'} />
            </div>
          )
      )}

      <div className={'grid grid-cols-2 gap-4 w-full mb-4'}>
        <FormViewItem
          label={t('accessManagement.userManagement.form.securityProfile')}
        >
          {user.securityProfile.label}
        </FormViewItem>
        {user.id && (
          <FormViewItem
            label={t('accessManagement.userManagement.form.review.userID')}
          >
            {user.id}
          </FormViewItem>
        )}
      </div>
      <HorizontalDivider className={'my-4'} />
      <div className={'flex justify-between items-center mt-6'}>
        {!isEdit && (
          <CheckboxForm
            label={t('accessManagement.userManagement.form.emailInvitation')}
            control={control}
            name={nameof(addUserFormType.sendInvitation)}
            labelClassName={'w-full'}
            labelTextClassName={'font-semibold text-xs text-gray-900'}
            disabled={isLoadingSubmit}
            defaultValue={DEFAULT_SEND_INVITATION}
          />
        )}
        <div className={'flex justify-end ml-2 w-full'}>
          <Button
            widthClass="w-20"
            size={'medium-large'}
            type={'button'}
            onClick={toPreviousStep}
            variant={'text'}
            className={'mr-2'}
            disabled={isLoadingSubmit}
          >
            {t('buttons.back')}
          </Button>
          <Button
            widthClass="w-40"
            size={'medium-large'}
            type={'submit'}
            loading={isLoadingSubmit}
          >
            <span className={'flex justify-between items-center w-full px-4'}>
              <span>
                {isEdit
                  ? t('accessManagement.userManagement.form.review.updateUser')
                  : t('accessManagement.userManagement.form.review.createUser')}
              </span>
              {!isEdit && <IconPlus />}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
});
