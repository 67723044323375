/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProgramChannelProvider,
    ProgramChannelProviderFromJSON,
    ProgramChannelProviderFromJSONTyped,
    ProgramChannelProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListProgramChannelProvidersResponse
 */
export interface ListProgramChannelProvidersResponse {
    /**
     * 
     * @type {Array<ProgramChannelProvider>}
     * @memberof ListProgramChannelProvidersResponse
     */
    programChannelProviders?: Array<ProgramChannelProvider>;
}

export function ListProgramChannelProvidersResponseFromJSON(json: any): ListProgramChannelProvidersResponse {
    return ListProgramChannelProvidersResponseFromJSONTyped(json, false);
}

export function ListProgramChannelProvidersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProgramChannelProvidersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programChannelProviders': !exists(json, 'program_channel_providers') ? undefined : ((json['program_channel_providers'] as Array<any>).map(ProgramChannelProviderFromJSON)),
    };
}

export function ListProgramChannelProvidersResponseToJSON(value?: ListProgramChannelProvidersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'program_channel_providers': value.programChannelProviders === undefined ? undefined : ((value.programChannelProviders as Array<any>).map(ProgramChannelProviderToJSON)),
    };
}


