import React, { FC } from 'react';
import classNames from 'classnames';
import { NavLink, Route } from 'react-router-dom';

interface Props {
  to: string;
}

export const TabLink: FC<Props> = ({ to, children }) => (
  <Route
    path={to}
    exact
    children={({ match }) => (
      <NavLink
        to={to}
        className={classNames(
          'flex items-center justify-center h-full text-sm relative font-bold mr-10',
          {
            'text-gray-700': !match,
          }
        )}
      >
        {!!match && (
          <div className="absolute bottom-0 w-full h-1 bg-blue-500" />
        )}
        {children}
      </NavLink>
    )}
  />
);
