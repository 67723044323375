import React, { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { RectLoader } from 'src/components/RectLoader';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { Checkbox } from 'src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { ListItem } from './ListItem';
import { IconEmpty } from 'src/components/icons/IconEmpty';
import { InfiniteScroll } from 'src/components/InfiniteScroll';

const Loading = () => (
  <>
    {[...Array(7)].map((item, key) => (
      <RectLoader key={key} width={250} height={14} className="w-full mb-2" />
    ))}
  </>
);

const Item = ({
  item,
  filters,
  onChange,
  listDisabled,
}: {
  item: ItemFilter;
  filters: ItemFilter[];
  onChange: ChangeEventHandler<HTMLInputElement>;
  listDisabled: boolean;
}) => {
  const checked =
    filters.findIndex((filter) => filter.value === item.value) > -1;

  const disabled = listDisabled && !checked;

  return (
    <ListItem label={item.label} disabled={disabled}>
      <Checkbox disabled={disabled} checked={checked} onChange={onChange} />
    </ListItem>
  );
};

const List = ({
  items,
  filters,
  onItemToggle,
  disabled,
}: {
  items: ItemFilter[];
  filters: ItemFilter[];
  onItemToggle: ItemToggle;
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  if (items.length) {
    return (
      <>
        {items.map((item, key) => (
          <Item
            key={key}
            listDisabled={disabled}
            filters={filters}
            item={item}
            onChange={(event) =>
              onItemToggle({ checked: event.target.checked, item })
            }
          />
        ))}
      </>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="mb-5 text-center">
        <IconEmpty />
      </div>
      <span className="text-xs font-medium text-gray-700">
        {t('kyc.filters.noDataFound')}
      </span>
    </div>
  );
};

type ItemToggle = (options: { checked: boolean; item: ItemFilter }) => any;

export const CheckboxList = ({
  items,
  loading = false,
  filters,
  onItemToggle,
  disabled = false,
  searchOffset = 0,
  setSearchOffset,
  limit,
  totalItems = 0,
  heightClassName = 'h-40',
}: {
  items: ItemFilter[];
  loading?: boolean;
  filters: ItemFilter[];
  onItemToggle: ItemToggle;
  disabled?: boolean;
  searchOffset?: number;
  setSearchOffset?: Dispatch<SetStateAction<number>>;
  limit?: number;
  totalItems?: number | null;
  heightClassName?: string;
}) => {
  const renderList = () => (
    <List
      disabled={disabled}
      items={items}
      onItemToggle={onItemToggle}
      filters={filters}
    />
  );

  return (
    <div
      id="checkboxList"
      className={classNames('flex flex-col overflow-y-auto', heightClassName)}
    >
      {loading && !(setSearchOffset && items.length) ? (
        <Loading />
      ) : !(setSearchOffset && limit && totalItems) ? (
        renderList()
      ) : (
        <InfiniteScroll
          dataLength={items.length}
          hasMore={totalItems > items.length}
          next={() => setSearchOffset(searchOffset + limit)}
          scrollableTarget="checkboxList"
        >
          {renderList()}
        </InfiniteScroll>
      )}
    </div>
  );
};
