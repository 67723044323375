import React, { useContext, useEffect } from 'react';
import {
  Input,
  onKeyPressDisallowSpecialCharacters,
} from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm } from 'react-hook-form';

export const FilterAuthorizationCode = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { register, watch, setValue } = useForm<{
    authorizationCode: string;
  }>();

  const { authorizationCode } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_AUTHORIZATION_CODE_FILTER',
      authorizationCode: authorizationCode,
    });
  }, [authorizationCode, dispatch]);

  useEffect(() => {
    setValue('authorizationCode', filters.authorizationCode);
  }, [filters.authorizationCode, setValue]);

  return (
    <Input
      label={t(
        'transactionsManagement.transactions.list.filters.authorizationCode'
      )}
      name="authorizationCode"
      labelFontClassName="text-xxs font-medium"
      labelColorClassName="text-gray-700"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressDisallowSpecialCharacters}
      inputRef={register}
    />
  );
};
