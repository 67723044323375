import React from 'react';
import { useTranslation } from 'react-i18next';
// import { nameof } from 'src/utils/nameof';
import { RolesList } from 'src/domain/access-management/roles-and-permissions/tabs/rules/RolesList';
import { Tab } from '../../../tabs/Tab';
import { TabsHeader } from '../../../tabs/TabsHeader';
// import { rolesFormType } from 'src/domain/access-management/roles-and-permissions/tabs/rules/RolesContext';

export const RolesTabList = () => {
  const { t } = useTranslation();

  // const searchFieldLabels = useMemo(
  //   () => [
  //     t('accessManagement.rolesAndPermissions.searchColumns.name'),
  //     t('accessManagement.rolesAndPermissions.searchColumns.channel'),
  //     t('accessManagement.rolesAndPermissions.searchColumns.description'),
  //   ],
  //   [t]
  // );

  return (
    <Tab
      header={
        <TabsHeader
          tabs={[
            {
              url: '/roles',
              label: t('accessManagement.rolesAndPermissions.breadcrumb')
            },
            {
              url: '/roles-logs',
              label: t('accessManagement.rolesAndPermissions.logs.breadcrumb')
            },
          ]}
          // searching={{
          //   name: nameof(rolesFormType.searchQuery),
          //   fieldLabels: searchFieldLabels,
          // }}
        />
      }
      content={<RolesList />}
    />
  );
};
