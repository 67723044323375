/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListIamPermissionsRequest
 */
export interface ListIamPermissionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ListIamPermissionsRequest
     */
    accessChannelId: string;
}

export function ListIamPermissionsRequestFromJSON(json: any): ListIamPermissionsRequest {
    return ListIamPermissionsRequestFromJSONTyped(json, false);
}

export function ListIamPermissionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamPermissionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessChannelId': json['access_channel_id'],
    };
}

export function ListIamPermissionsRequestToJSON(value?: ListIamPermissionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_channel_id': value.accessChannelId,
    };
}


