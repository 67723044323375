export default function ccBrandById(cur_val?: string) {
  if (cur_val) {
    // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
    // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also

    //JCB
    const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
    // American Express
    const amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
    // Diners Club
    const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
    // Visa
    const visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    const mastercard_regex = new RegExp(
      '^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'
    ); //2221-2720, 51-55
    const maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    //Discover
    const discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    ////6011, 622126-622925, 644-649, 65

    const upi = new RegExp('^62');

    // get rid of anything but numbers
    // cur_val = cur_val.replace(/\D/g, '');

    if (cur_val.length !== 16) {
      return null;
    }

    // checks per each, as their could be multiple hits
    //fix: ordering matter in detection, otherwise can give false results in rare cases
    var sel_brand = null;
    if (cur_val.match(jcb_regex)) {
      sel_brand = 'jcb';
    } else if (cur_val.match(amex_regex)) {
      sel_brand = 'amex';
    } else if (cur_val.match(diners_regex)) {
      sel_brand = 'diners club';
    } else if (cur_val.match(visa_regex)) {
      sel_brand = 'visa';
    } else if (cur_val.match(mastercard_regex)) {
      sel_brand = 'mastercard';
    } else if (cur_val.match(discover_regex)) {
      console.log(cur_val, cur_val.match(discover_regex));
      sel_brand = 'discover';
    } else if (cur_val.match(upi)) {
      sel_brand = 'upi';
    } else if (cur_val.match(maestro_regex)) {
      if (cur_val[0] == '5') {
        //started 5 must be mastercard
        sel_brand = 'mastercard';
      } else {
        sel_brand = 'maestro'; //maestro is all 60-69 which is not something else, thats why this condition in the end
      }
    }

    return sel_brand;
  }
}
