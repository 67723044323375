import React, {
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ApiLogsList } from './ApiLogsList';
import {
  reducer,
  initialState,
  Actions,
  ListContext,
  LogItem,
  Filters,
} from './context';
import { useDispatch } from 'react-redux';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { acquiringApi } from 'src/api';
import { Card } from 'src/components/Card';

type LoadItemsOptions = {
  limit: number;
  offset?: number;
  page?: number;
  filters: Filters;
};

const filterValues = (items: ItemFilter[]) => items.map((item) => item.value);

const listRequestPayload = (options: LoadItemsOptions) => ({
  filters: {
    merchants:
      options.filters.merchants.length > 0
        ? filterValues(options.filters.merchants)
        : undefined,
    terminalId: options.filters.terminalId || undefined,
    referenceNumber: options.filters.referenceNumber || undefined,
  },
});

const loadItems = (options: LoadItemsOptions) => {
  return acquiringApi.postLogList({
    acquiringAuditLogsListRequest: {
      offset: options.offset || 0,
      ...options,
    },
  });
};

const downloadItems = (options: LoadItemsOptions) => {
  return new Promise((resolve) => resolve(new Blob()));
};

const normalizeItem = (item: any): LogItem => ({
  ...item,
});

export const Container = () => {
  const { t } = useTranslation();

  const [state, dispatchAction] = useReducer(reducer, initialState);
  const [error, setError] = useState();

  const storeDispatch = useDispatch();

  const routeMatch = useRouteMatch();

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info({ ...action, context: 'LOGS/LIST' });
    }

    dispatchAction(action);
  }, []);

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('logs.breadcrumb'),
          route: routeMatch.url,
        },
        {
          label: t('logs.api.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  const rowsPerPageRef = useRef(state.rowsPerPage);

  const fetchedItemsRef = useRef(state.items.length);

  useEffect(() => {
    rowsPerPageRef.current = state.rowsPerPage;

    fetchedItemsRef.current = state.items.length;
  }, [state.rowsPerPage, state.items.length]);

  useEffect(() => {
    let canceled = false;

    dispatch({
      type: 'INCREMENT_ITEMS_LOADING',
    });

    dispatch({
      type: 'SET_PAGE',
      page: 1,
    });

    dispatch({
      type: 'SET_END_REACHED',
      endReached: false,
    });

    const requestPayload = {
      limit: rowsPerPageRef.current,
      offset: 0,
      filters: {
        ...state.appliedFilters,
      },
    };

    loadItems(requestPayload)
      .then((response) => {
        if (!canceled) {
          const data = (response.logList || []).map(normalizeItem);

          dispatch({ type: 'SET_ITEMS', items: data });

          if (data.length === 0) {
            dispatch({
              type: 'SET_END_REACHED',
              endReached: true,
            });
          }
        }
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        if (!canceled) {
          dispatch({
            type: 'DECREMENT_ITEMS_LOADING',
          });
        }
      });

    return () => {
      dispatch({
        type: 'DECREMENT_ITEMS_LOADING',
      });

      canceled = true;
    };
  }, [dispatch, state.appliedFilters]);

  const appliedFiltersRef = useRef(state.appliedFilters);

  useEffect(() => {
    appliedFiltersRef.current = state.appliedFilters;
  }, [state.appliedFilters]);

  useEffect(() => {
    let canceled = false;

    if (state.page === 1) {
      return;
    }

    const setPaginationLoading = (loading: boolean) =>
      dispatch({
        type: 'SET_PAGINATION_LOADING',
        loading,
      });

    setPaginationLoading(true);

    dispatch({
      type: 'SET_END_REACHED',
      endReached: false,
    });

    loadItems({
      page: state.page,
      limit: rowsPerPageRef.current,
      offset: fetchedItemsRef.current,
      filters: {
        ...appliedFiltersRef.current,
      },
    })
      .then((response) => {
        if (!canceled) {
          const data = (response.logList || []).map(normalizeItem);

          dispatch({ type: 'APPEND_ITEMS', items: data });

          if (data.length === 0) {
            dispatch({
              type: 'SET_END_REACHED',
              endReached: true,
            });
          }
        }
      })
      .finally(() => {
        if (!canceled) {
          setPaginationLoading(false);
        }
      });

    return () => {
      canceled = true;
    };
  }, [state.page, dispatch]);

  const download = useCallback(async () => {
    dispatch({ type: 'SET_DOWNLOAD_LOADING', loading: true });

    downloadItems({
      limit: 1000,
      filters: state.filters,
    })
      .then(async (csv: any) => {
        // for some reason, the original type doesn't match the real prototype. @TODO
        const downloadLink = document.createElement('a');

        const data = ((await csv.text()) as string).replace(/"/g, '');

        downloadLink.href = 'data:text/csv;charset=utf-8,' + atob(data);

        downloadLink.target = '_blank';

        downloadLink.download = 'logs.csv';

        downloadLink.click();

        setTimeout(() => downloadLink.remove());
      })
      .finally(() =>
        dispatch({ type: 'SET_DOWNLOAD_LOADING', loading: false })
      );
  }, [dispatch, state.filters]);

  const itemsLoadingComputed = useMemo(() => state.itemsLoading > 0, [
    state.itemsLoading,
  ]);

  return (
    <ListContext.Provider
      value={{
        ...state,
        itemsLoadingComputed,
        download,
        dispatch,
      }}
    >
      {error?.status === 403 ? (
        <div className="p-2">
          <Card>
            <div className="px-5 py-3 text-sm text-gray-900 bg-red-100 rounded-sm">
              {t('permissions.permissionDenied')}
            </div>
          </Card>
        </div>
      ) : (
        <ApiLogsList />
      )}
    </ListContext.Provider>
  );
};
