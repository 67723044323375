/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListIamRolesRequest
 */
export interface ListIamRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof ListIamRolesRequest
     */
    accessChannelId?: string;
}

export function ListIamRolesRequestFromJSON(json: any): ListIamRolesRequest {
    return ListIamRolesRequestFromJSONTyped(json, false);
}

export function ListIamRolesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamRolesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessChannelId': !exists(json, 'access_channel_id') ? undefined : json['access_channel_id'],
    };
}

export function ListIamRolesRequestToJSON(value?: ListIamRolesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_channel_id': value.accessChannelId,
    };
}


