/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ListIamUserTypesRequest,
    ListIamUserTypesRequestFromJSON,
    ListIamUserTypesRequestToJSON,
    ListIamUserTypesResponse,
    ListIamUserTypesResponseFromJSON,
    ListIamUserTypesResponseToJSON,
} from '../models';

export interface PostIamTypesListRequest {
    listIamUserTypesRequest?: ListIamUserTypesRequest;
}

/**
 * 
 */
export class UserTypesApi extends runtime.BaseAPI {

    /**
     */
    async postIamTypesListRaw(requestParameters: PostIamTypesListRequest): Promise<runtime.ApiResponse<ListIamUserTypesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-user-types/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListIamUserTypesRequestToJSON(requestParameters.listIamUserTypesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamUserTypesResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamTypesList(requestParameters: PostIamTypesListRequest): Promise<ListIamUserTypesResponse> {
        const response = await this.postIamTypesListRaw(requestParameters);
        return await response.value();
    }

}
