import { ItemFilter } from 'src/components/dropdown-filter/filter';

type StateFilters = Record<string | number | symbol, ItemFilter[]>;

export const addFilterToState = <T extends StateFilters>({
  filters,
  filterKey,
  payload,
}: {
  filters: T;
  filterKey: keyof T;
  payload: ItemFilter;
}) => ({
  ...filters,
  [filterKey]: [...filters[filterKey], payload],
});

export const removeFilterFromState = <T extends StateFilters>({
  filters,
  filterKey,
  payload,
}: {
  filters: T;
  filterKey: keyof T;
  payload: ItemFilter;
}) => ({
  ...filters,
  [filterKey]: filters[filterKey].filter(
    (item) => item.value !== payload.value
  ),
});

export const resetFilterInState = <T extends StateFilters>({
  filters,
  filterKey,
}: {
  filters: T;
  filterKey: keyof T;
}) => {
  if (!filters[filterKey].length) {
    return filters;
  }

  return {
    ...filters,
    [filterKey]: [],
  };
};
