import React, { useContext, useState } from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { iconAccessChannelsMap } from 'src/domain/access-management/user-management/tabs/users/useUsersColumns';
import { Button } from 'src/components/Button';
import { IconPlus } from 'src/components/icons/IconPlus';
import { IconChevronLeft } from 'src/components/icons/IconChevronLeft';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AccessChannel } from 'src/api/client';
import { UsersContext } from 'src/domain/access-management/user-management/tabs/users/UsersContext';
import { useUserModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserModal';

export const AddUser = () => {
  const { t } = useTranslation();
  const { accessChannelList } = useContext(UsersContext);

  const [selectedChannel, setSelectedChannel] = useState<null | AccessChannel>(
    null
  );

  const { openModal, modal } = useUserModal({
    selectedChannel,
    isOpen: false,
  });

  return (
    <>
      <Tooltip
        minWidth={150}
        placement="bottom-end"
        withPadding={false}
        content={() => (
          <div className="py-1">
            <div className="uppercase text-blue-500 text-xxxs pt-3 pb-1 px-4">
              {t('accessManagement.userManagement.filters.accessChannel')}
            </div>
            {accessChannelList.map((item, id) => (
              <div
                onClick={() => {
                  openModal();
                  setSelectedChannel(item);
                }}
                key={id}
                className="cursor-pointer flex items-center w-full h-5 px-4 py-4 mr-5 font-semibold hover:bg-gray-100"
              >
                {item.name && iconAccessChannelsMap(item.name)}
                <span className="ml-2">{item.name}</span>
              </div>
            ))}
            <div
              onClick={() => {
                openModal();
                setSelectedChannel(null);
              }}
              className="cursor-pointer flex items-center w-full h-5 px-4 py-4 mr-5 font-semibold hover:bg-gray-100"
            >
              {iconAccessChannelsMap('Other')}
              <span className="ml-2">{t('buttons.other')}</span>
            </div>
          </div>
        )}
      >
        {({ ref, setIsOpen, isOpen }) => (
          <div
            ref={ref}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            <Button
              loading={false}
              widthClass="w-full"
              className="pr-4"
              prefix={<IconPlus />}
            >
              {t('buttons.addNew')}

              <IconChevronLeft
                className={classNames('transform ml-2', {
                  '-rotate-90': !isOpen,
                  'rotate-90': isOpen,
                })}
              />
            </Button>
          </div>
        )}
      </Tooltip>
      {modal}
    </>
  );
};
