import React, { useCallback, useContext, useMemo } from 'react';
import { FilterTerminalId } from './FilterTerminalId';
import { Button } from 'src/components/Button';
import { ListContext, defaultFilters } from '../context';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { FilterReferenceNumber } from './FilterReferenceNumber';
import { FilterMerchants } from './FilterMerchants';
import { DateRange } from 'src/types/dateRange';
import { FilterGenericDate } from './FilterGenericDate';
import { FilterApi } from './FilterApi';

export const Filters = () => {
  const { dispatch, filters, appliedFilters } = useContext(ListContext);

  const { t } = useTranslation();

  const filtersApplied = useMemo(() => {
    return isEqual(filters, appliedFilters);
  }, [filters, appliedFilters]);

  const filtersInDefaultState = useMemo(() => {
    return isEqual(filters, defaultFilters);
  }, [filters]);

  const setProcessDateFilter = useCallback(
    (dateRange: DateRange) =>
      dispatch({ type: 'SET_PROCESS_DATE_FILTER', dateRange }),
    [dispatch]
  );

  const resetProcessDateFilter = useCallback(
    () => dispatch({ type: 'RESET_PROCESS_DATE_FILTER' }),
    [dispatch]
  );

  return (
    <div className="grid grid-cols-6 gap-5 row-gap-2">
      <div>
        <FilterMerchants />
      </div>
      <div>
        <FilterTerminalId />
      </div>
      <div>
        <FilterApi />
      </div>
      <div>
        <FilterGenericDate
          filterKey="processDate"
          title={t('logs.api.filters.processDate')}
          setFilterAction={setProcessDateFilter}
          resetFilterAction={resetProcessDateFilter}
        />
      </div>
      <div>
        <FilterReferenceNumber />
      </div>
      <div className="flex items-end">
        <div className="w-px h-8 mr-3 bg-gray-300" />
        <Button
          className="w-1/2 mr-3"
          onClick={() => dispatch({ type: 'APPLY_FILTERS' })}
          disabled
          // disabled={filtersApplied}
          variant={filtersApplied ? 'secondary' : 'primary'}
        >
          {t('transactionsManagement.transactions.list.filters.apply')}
        </Button>
        <Button
          className="w-1/2"
          variant="secondary"
          onClick={() => dispatch({ type: 'RESET_ALL_FILTERS' })}
          disabled
          // disabled={filtersInDefaultState}
        >
          {t('transactionsManagement.transactions.list.filters.clearAll')}
        </Button>
      </div>
    </div>
  );
};
