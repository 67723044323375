import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
// import { authResource } from 'src/api/auth';
// import { Button } from 'src/components/Button';
import { FormForgotPassword } from './FormForgotPassword';
import { IconLogo } from 'src/components/icons/IconLogo';

const SubmittedIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    className="text-green-500"
  >
    <circle cx="30" cy="30" r="30" fill="currentColor" />
    <path
      d="M43 21L27.6154 38L22 31.9051"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Container = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [submitted] = useState(false);

  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      {!submitted && (
        <div className="absolute top-0 right-0 p-8">
          <span className="mr-5 text-sm font-medium">
            {t('forgotPasswordForm.rememberedPassword')}
          </span>
          <Link
            to="/login"
            className="px-6 py-3 text-sm font-semibold border border-gray-300 rounded"
          >
            {t('loginForm.logIn')}
          </Link>
        </div>
      )}
      <div className="flex flex-col items-center justify-center w-full max-w-sm">
        {submitted ? (
          <div className="mb-8">
            <SubmittedIcon />
          </div>
        ) : (
          <IconLogo className="mb-5" />
        )}

        <h3 className="mb-2 text-2xl font-semibold text-gray-900">
          {submitted
            ? t('forgotPasswordForm.submittedTitle')
            : t('forgotPasswordForm.passwordReset')}
        </h3>

        {!submitted && (
          <p className="mb-8 text-base font-medium text-center text-gray-700">
            {t('forgotPasswordForm.passwordResetDescription')}
          </p>
        )}

        {!submitted && (
          <React.Fragment>
            <FormForgotPassword
              onSubmit={(data: any) => {
                history.push(`/reset-password?email=${data.email}`);
              }}
            />
            {/*<Link to="/login" className="mt-5 text-sm font-semibold">
              <IconChevronLeft className="inline mr-1 text-blue-500" />
              {t('forgotPasswordForm.backToLogin')}
            </Link>*/}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
