import React, { ReactNode } from 'react';
import { TransactionStatus } from '../transaction';
import { StatusBadge, StatusBadgeType } from 'src/components/StatusBadge';

const statusBadge = (status: TransactionStatus): StatusBadgeType => {
  switch (status) {
    case TransactionStatus.APPROVED:
      return StatusBadgeType.SUCCESS;
    case TransactionStatus.REJECTED:
      return StatusBadgeType.DANGER;
    default:
      return StatusBadgeType.PRIMARY;
  }
};

export const TransactionStatusBadge = ({
  status,
  children,
}: {
  status: TransactionStatus;
  children: ReactNode;
}) => <StatusBadge variant={statusBadge(status)}>{children}</StatusBadge>;
