import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormViewItem } from 'src/domain/access-management/user-management/tabs/users/add-user/FormViewItem';
import { ButtonLink } from 'src/components/ButtonLink';
import { AssignmentsItemFields } from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserModal';

interface Props {
  fieldsValues: AssignmentsItemFields;
  handleEdit: () => void;
}

export const HiddenPreviewFields = memo(
  ({ fieldsValues, handleEdit }: Props) => {
    const { t } = useTranslation();

    return (
      <div className={'flex'}>
        <div className={'grid grid-cols-2 gap-4 w-full'}>
          {fieldsValues?.organizations && (
            <FormViewItem
              label={t(
                'accessManagement.userManagement.form.organization.label'
              )}
            >
              {fieldsValues?.organizations?.label}
            </FormViewItem>
          )}
          {fieldsValues?.firstOrganization && (
            <FormViewItem
              label={t(
                'accessManagement.userManagement.form.firstOrganization'
              )}
            >
              {fieldsValues?.firstOrganization?.label}
            </FormViewItem>
          )}
          {fieldsValues?.secondOrganization && (
            <FormViewItem
              label={t(
                'accessManagement.userManagement.form.secondOrganization'
              )}
            >
              {fieldsValues?.secondOrganization?.label}
            </FormViewItem>
          )}
          {fieldsValues?.roles &&
            Array.isArray(fieldsValues?.roles) &&
            !!fieldsValues?.roles.length && (
              <FormViewItem
                label={t('accessManagement.userManagement.form.roles')}
              >
                {fieldsValues?.roles?.map((item) => (
                  <span key={item.value}>{item.label}</span>
                ))}
              </FormViewItem>
            )}
          {fieldsValues?.program &&
            Array.isArray(fieldsValues?.program) &&
            !!fieldsValues?.program.length && (
              <FormViewItem
                label={t('accessManagement.userManagement.form.program.label')}
              >
                {fieldsValues?.program?.map((item) => (
                  <span key={item.value}>{item.label}</span>
                ))}
              </FormViewItem>
            )}
        </div>
        <ButtonLink
          className={'font-semibold text-blue-500 text-xxs ml-2'}
          onClick={handleEdit}
        >
          {t('buttons.edit')}
        </ButtonLink>
      </div>
    );
  }
);
