import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckMarkIcon } from 'src/assets/icons/checkMark.svg';
import { useQuery } from 'react-query';
import { permissionsApi } from 'src/api';
import { AccessChannel, IamPermission } from 'src/api/client';
import { RectLoader } from 'src/components/RectLoader';

const Permission = ({ title }: { title: string }) => (
  <span className="inline-flex items-center mr-3 py-1 px-2 bg-gray-150 rounded-sm capitalize font-semibold">
    <CheckMarkIcon className="mr-1" /> {title}
  </span>
);

const permissionsData = [
  {
    name: 'Overview',
    items: [
      {
        name: 'Transaction History',
        permissions: { view: true },
      },
    ],
  },
  {
    name: 'Load card',
    items: [
      {
        name: 'Cards List',
        permissions: { view: true },
      },
      {
        name: 'Card Details',
        permissions: { view: true, lock: true, block: true },
      },
    ],
  },
  {
    name: 'Assign card',
    items: [
      {
        name: '',
        permissions: { assign: true },
      },
    ],
  },
  {
    name: 'Payees',
    items: [
      {
        name: 'Payees List',
        permissions: { view: true },
      },
      {
        name: 'Payee Details',
        permissions: { view: true, viewCards: true },
      },
    ],
  },
];

export const AssignedPermissions = ({
  channelId,
  iamRolePermissions,
}: {
  channelId: string;
  iamRolePermissions: { [key: string]: AccessChannel };
}) => {
  const { t } = useTranslation();

  const {
    data: permissionsList,
    isLoading: isLoadingPermissionsList,
  } = useQuery(
    ['postIamPermissionsList', channelId],
    () =>
      permissionsApi.postIamPermissionsList({
        listIamPermissionsRequest: {
          accessChannelId: channelId,
        },
      }),
    {
      enabled: !!channelId,
    }
  );

  return (
    <div>
      <h2 className="text-base font-bold leading-none text-gray-900">
        {t(
          'accessManagement.rolesAndPermissions.roleDetails.assignedPermissions'
        )}
      </h2>

      {!(
        !isLoadingPermissionsList &&
        permissionsList?.iamPermissions.length === 0
      ) && (
        <table
          className="collapse my-6 w-full text-gray-900 font-medium"
          style={{
            boxShadow:
              '0px -4px 12px rgba(47, 50, 58, 0.1), 0px 4px 20px rgba(47, 50, 58, 0.1)',
          }}
        >
          <thead className="bg-gray-100 text-gray-700 text-xxs">
            <tr>
              <td className="py-3 px-5 w-1/5">Module</td>
              {/*<td className="py-3 px-5 w-1/5">Submodule</td>*/}
              <td className="py-3 px-5">Permission</td>
            </tr>
          </thead>
          <tbody className="table__tbody">
            {(isLoadingPermissionsList
              ? [...Array(5)]
              : permissionsList?.iamPermissions
            ).map((permission: IamPermission, index: number) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="py-3 px-5 text-xs font-semibold">
                    {isLoadingPermissionsList ? (
                      <RectLoader
                        className={'w-full'}
                        width={100}
                        height={20}
                      />
                    ) : (
                      <div>{permission.shortName}</div>
                    )}
                  </td>
                  <td className="px-5">
                    {isLoadingPermissionsList ? (
                      <RectLoader
                        className={'w-full'}
                        width={100}
                        height={20}
                      />
                    ) : (
                      // @ts-ignore
                      iamRolePermissions[permission.id] && (
                        <CheckMarkIcon className="mr-1" />
                      )
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}

      {!isLoadingPermissionsList &&
        permissionsList?.iamPermissions.length === 0 && (
          <div className="text-xs text-gray-900 mt-4">{t('noResults')}</div>
        )}
    </div>
  );
};
