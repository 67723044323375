import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import get from 'lodash/get';
import map from 'lodash/map';

import { IamUserStatus } from 'src/api/client';
import { notifications } from 'src/utils/notifications';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { useTranslation } from 'react-i18next';
import { usersApi } from 'src/api';

import { LIST_QUERY_KEY } from '../UsersContext';

interface Params {
  isOpen?: boolean;
  onSubmit?: () => void;
  isInvalidateCache?: boolean;
}

export const useUserChangeStatusModal = ({
  isOpen = false,
  onSubmit,
  isInvalidateCache = false,
}: Params) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(isOpen);
  const [item, setItem] = useState();
  const [userId, setUserId] = useState<string>();
  const [userStatus, setUserStatus] = useState<IamUserStatus>();
  const [username, setUsername] = useState<string>();
  const [error, setError] = useState();

  const { mutateAsync: userStatusUpdateMutation, isLoading } = useMutation(
    ({ userId, status }: { userId: string; status: IamUserStatus }) =>
      usersApi.postIamUsersUpdate({
        updateIamUserRequest: { id: userId, status },
      }),
    {
      onError: async (error) => {
        // @ts-ignore
        const err = await error.text();
        setError(JSON.parse(err));
      },
    }
  );

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleOpen = useCallback(
    (status: IamUserStatus, userId?: string, username?: string, item?: any) => {
      setItem(item);
      userId && setUserId(userId);
      setUserStatus(status);
      username && setUsername(username);
      setVisible(true);
    },
    [setUserId, userStatus]
  );

  const handleSubmit = useCallback(async () => {
    setError(null);
    try {
      await userStatusUpdateMutation({ userId: userId!, status: userStatus! });
      notifications.success();
      if (isInvalidateCache) {
        queryClient.invalidateQueries(LIST_QUERY_KEY);
      }
      if (item) {
        item.status = userStatus!;
      }
      onSubmit && onSubmit();
    } catch (err) {}
  }, [handleClose, userId, userStatus]);

  const changeStatusUserModal = useMemo(
    () =>
      visible && (
        <Modal
          isOpen={visible}
          closeModal={() => {
            handleClose();
          }}
          maxWidth="auto"
          width="500px"
        >
          <div>
            <div className={'font-bold mb-2 text-center text-2xl'}>
              {userStatus === IamUserStatus.Suspended &&
                t('accessManagement.userManagement.suspendUser.title')}
              {userStatus === IamUserStatus.Active &&
                t('accessManagement.userManagement.activateUser.title')}
            </div>

            <p className="text-center font-medium text-sm text-gray-900 pb-3">
              {username}
            </p>

            <p className="text-center text-sm text-gray-700">
              {userStatus === IamUserStatus.Suspended &&
                t(
                  'accessManagement.userManagement.description.suspendUser.description',
                  { username }
                )}
              {userStatus === IamUserStatus.Active &&
                t(
                  'accessManagement.userManagement.description.activateUser.description',
                  { username }
                )}
            </p>

            {error && (
              <div className="py-3 px-5 bg-red-100 my-3 text-sm text-gray-900 rounded-sm">
                {Array.isArray(get(error, 'error'))
                  ? map(get(error, 'error'), ({ message, details }) => (
                      <p>
                        {message}: {details}
                      </p>
                    ))
                  : error}
              </div>
            )}

            <div className="flex justify-between mt-8">
              <Button
                disabled={isLoading}
                variant="secondary"
                size="medium-large"
                widthClass="w-1/2"
                className="mr-2"
                onClick={() => {
                  handleClose();
                }}
              >
                {userStatus === IamUserStatus.Suspended &&
                  t('accessManagement.userManagement.suspendUser.cancel')}
                {userStatus === IamUserStatus.Active &&
                  t('accessManagement.userManagement.activateUser.cancel')}
              </Button>
              <Button
                loading={isLoading}
                variant={
                  userStatus === IamUserStatus.Suspended
                    ? 'red-pale'
                    : 'primary'
                }
                size="medium-large"
                widthClass="w-1/2"
                className="ml-2"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {userStatus === IamUserStatus.Suspended &&
                  t('accessManagement.userManagement.suspendUser.confirm')}
                {userStatus === IamUserStatus.Active &&
                  t('accessManagement.userManagement.activateUser.confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      ),
    [visible, handleSubmit, handleClose, isLoading, username]
  );

  return {
    openChangeStatusUserModal: handleOpen,
    closeChangeStatusUserModal: handleClose,
    changeStatusUserModal,
    isChangeStatusUserModalOpen: visible,
  };
};
