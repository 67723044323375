/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditLogType,
    AuditLogTypeFromJSON,
    AuditLogTypeFromJSONTyped,
    AuditLogTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AcquiringAuditLogResponse
 */
export interface AcquiringAuditLogResponse {
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    id: string;
    /**
     * 
     * @type {AuditLogType}
     * @memberof AcquiringAuditLogResponse
     */
    type?: AuditLogType;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    transactionType?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    programId?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    response?: string;
    /**
     * 
     * @type {string}
     * @memberof AcquiringAuditLogResponse
     */
    request?: string;
    /**
     * 
     * @type {Date}
     * @memberof AcquiringAuditLogResponse
     */
    createdTime: Date;
}

export function AcquiringAuditLogResponseFromJSON(json: any): AcquiringAuditLogResponse {
    return AcquiringAuditLogResponseFromJSONTyped(json, false);
}

export function AcquiringAuditLogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcquiringAuditLogResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': !exists(json, 'type') ? undefined : AuditLogTypeFromJSON(json['type']),
        'transactionType': !exists(json, 'transaction_type') ? undefined : json['transaction_type'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'programId': !exists(json, 'program_id') ? undefined : json['program_id'],
        'merchantId': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'transactionId': !exists(json, 'transaction_id') ? undefined : json['transaction_id'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'request': !exists(json, 'request') ? undefined : json['request'],
        'createdTime': (new Date(json['created_time'])),
    };
}

export function AcquiringAuditLogResponseToJSON(value?: AcquiringAuditLogResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': AuditLogTypeToJSON(value.type),
        'transaction_type': value.transactionType,
        'client_id': value.clientId,
        'program_id': value.programId,
        'merchant_id': value.merchantId,
        'transaction_id': value.transactionId,
        'reference': value.reference,
        'body': value.body,
        'response': value.response,
        'request': value.request,
        'created_time': (value.createdTime.toISOString()),
    };
}


