import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container as ListContainer } from './card-list/Container';
import { CardDetails } from './CardDetails';

export const Cards = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/list`}>
        <ListContainer />
      </Route>
      <Route path={`${match.path}/card-details/:id`}>
        <CardDetails />
      </Route>
    </Switch>
  );
};
