import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Overview } from './overview/Overview';
import { Security } from './security/Security';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const UserProfile = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const storeDispatch = useDispatch();
  const routeMatch = useRouteMatch();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('userProfile.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  return (
    <Switch>
      <Route path={match.path + '/overview'}>
        <Overview />
      </Route>
      <Route path={match.path + '/security'} exact>
        <Security />
      </Route>
    </Switch>
  );
};
