import React, { useContext } from 'react';
import faker from 'faker';
import moment from 'moment';
import { DetailsContext } from './context';
import { Loading } from 'src/components/Loading';

export const Cell = ({
  children,
  label,
}: JSX.IntrinsicElements['div'] & { label?: string }) => (
  <div className="flex items-center p-3">
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-700">{label}</p>
    </div>
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-900 break-all">{children}</p>
    </div>
  </div>
);

export const Details = () => {
  const { item, itemLoading, itemNotFound } = useContext(DetailsContext);

  if (itemNotFound) {
    return <div>Not found</div>;
  }

  if (itemLoading || !item) {
    return (
      <div className="flex items-center justify-center p-10">
        <Loading />
      </div>
    );
  }

  // created_time: "2021-02-22T13:38:39.402982Z"
  // id: "8434117e-90fe-4df6-a5d0-794ccb6df900"
  // merchant_id: "00000000-0000-0000-0000-000000001002"
  // program_id: "e3801872-3f95-41aa-ba2c-30947483950e"
  // reference: "EXT-REF-ID1"
  // transaction_id: "00000000-0000-0000-0000-000000001002"
  // transaction_type: "PURCHASE"
  // type: "REQUEST"

  return (
    <div className="p-3">
      <div className="mb-4 bg-white border rounded shadow">
        <div className="p-3 text-xs font-medium bg-gray-100 border-b rounded-t">
          Details
        </div>
        <div className="divide-y">
          <div className="grid grid-cols-4 divide-x">
            <Cell label="ID">{item.id}</Cell>
            <Cell label="Merchant ID">{item.merchantId}</Cell>
            <Cell label="Program ID">{item.programId}</Cell>
            <Cell label="Reference">{item.reference}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label="Transaction ID">{item.transactionId}</Cell>
            <Cell label="Transaction Type">{item.transactionType}</Cell>
            <Cell label="Type">{item.type}</Cell>
            <Cell label="Created At">
              {moment(item.createdTime).format('DD MMM YYYY HH:mm')}
            </Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label="Reference">{item.reference}</Cell>
          </div>
        </div>
      </div>
      <div>
        {/* <div className="bg-white border rounded shadow">
          <div className="p-3 text-xs font-medium bg-gray-100 border-b rounded-t">
            Request
          </div>
          <pre className="p-3 overflow-auto text-xs text-green-500 bg-gray-900 rounded-b h-90">
            {JSON.stringify(
              { id: '017665e5-2ac7-492f-bdad-8b905c809cf7' },
              null,
              2
            )}
          </pre>
        </div> */}
        <div className="bg-white border rounded shadow">
          <div className="p-3 text-xs font-medium bg-gray-100 border-b rounded-t">
            Response
          </div>
          <pre className="p-3 overflow-auto text-xs text-green-500 bg-gray-900 rounded-b h-90">
            {JSON.stringify(JSON.parse(item.body || ''), null, 2)}
          </pre>
        </div>
      </div>
    </div>
  );
};
