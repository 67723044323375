/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetIamUserRoleRequest
 */
export interface GetIamUserRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof GetIamUserRoleRequest
     */
    iamUserRoleId: string;
}

export function GetIamUserRoleRequestFromJSON(json: any): GetIamUserRoleRequest {
    return GetIamUserRoleRequestFromJSONTyped(json, false);
}

export function GetIamUserRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIamUserRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamUserRoleId': json['iam_user_role_id'],
    };
}

export function GetIamUserRoleRequestToJSON(value?: GetIamUserRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iam_user_role_id': value.iamUserRoleId,
    };
}


