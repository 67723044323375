import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from '../tabs/Tab';
import { TabsHeader } from '../tabs/TabsHeader';
import { Card } from 'src/components/Card';
import { VerifyPhone } from './VerifyPhone';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { QueryFunctionContext, useQuery } from 'react-query';

import {
  useGetUserQueries,
} from 'src/domain/access-management/user-management/tabs/users/add-user/useGetUserQueries';
import { RectLoader } from 'src/components/RectLoader';
import { FormEditUser } from './FormEditUser';
import { useOptionsQueries } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/useOptionsQueries';

import { ReactComponent as OrganizationIcon } from '../../../assets/icons/organization.svg';
import { GetIamUserResponse } from 'src/api/client';
import { profileApi } from 'src/api';

export const Overview = () => {
  const { t } = useTranslation();

  return (
    <Tab
      header={
        <TabsHeader
          tabs={[
            {
              url: '/overview',
              label: t('userProfile.tabs.overview'),
            },
            {
              url: '/security',
              label: t('userProfile.tabs.security'),
            },
          ]}
        />
      }
      content={<OverviewTab />}
    />
  );
};

const PROFILE_GET = 'PROFILE_GET';

const OverviewTab = () => {
  const { t } = useTranslation();
  const [isVerifyPhone, setIsVerifyPhone] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userAttributes, setUserAttributes] = useState<{
    id?: string;
    sub?: string;
    phone_number?: string;
    phone_number_verified?: boolean;
    email?: string;
  }>({});

  useEffect(() => {
    getAttr();
  }, []);

  const {
    data: userResponse,
    isLoading: isLoadingUserData,
    isFetching: isFetchingUserData,
    refetch,
  } = useQuery(
    [PROFILE_GET],
    () => {
      return profileApi.postProfileGet();
    },
    { retry: 1 }
  );

  const { iamUser: userData, companies, securityProfile: securityProfileOptions, userRoles, roles } = userResponse || {};

  const getAttr = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const data = await Auth.userAttributes(cognitoUser);
      setUserAttributes({
        id: get(
          find(data, (item) => item.Name === 'custom:iamUserId'),
          'Value'
        ),
        sub: get(
          find(data, (item) => item.Name === 'sub'),
          'Value'
        ),
        phone_number: get(
          find(data, (item) => item.Name === 'phone_number'),
          'Value'
        ),
        phone_number_verified:
          get(
            find(data, (item) => item.Name === 'phone_number_verified'),
            'Value'
          ) === 'true',
        email: get(
          find(data, (item) => item.Name === 'email'),
          'Value'
        ),
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const rolesObj = useMemo(
    () =>
      roles && roles.reduce((acc: any, curr: any) => { acc[curr.id] = curr.roleName; return acc; }, {}),
    [roles]
  );

  return (
    <div className="relative flex flex-col bg-gray-200">
      <Card className="flex h-full">
        <div className="w-1/3 -my-6 py-8 pl-2 pr-8 border-r border-gray-300">
          <div className="font-bold">
            {t('userProfile.personalInformation')}
          </div>

          {(!userData || isFetchingUserData) && (
            <div className="mt-6">
              <RectLoader className={'w-full'} width={150} height={25} />
              <RectLoader className={'w-full mt-2'} width={250} height={25} />
            </div>
          )}

          {userData && !isFetchingUserData && (
            <div>
              <div className="flex mt-8 items-center">
                <div>
                  <div className="flex items-center justify-center w-32 h-32 mr-4 text-5xl font-semibold tracking-wider text-blue-500 bg-blue-100 rounded-full uppercase">
                    {userData?.firstName && userData?.lastName ? (
                      <>
                        {userData?.firstName[0]}
                        {userData?.lastName[0]}
                      </>
                    ) : (
                      <>{userData?.username![0]}</>
                    )}
                  </div>
                </div>

                <div className="text-ssm">
                  <div className="font-medium text-gray-700">Email</div>
                  <div className="font-medium text-gray-900 mb-3">
                    {userAttributes?.email}
                  </div>
                  <div className="font-medium text-gray-700">
                    {t('userProfile.phoneNumber')}
                  </div>
                  <div className="flex items-center font-medium text-gray-900">
                    {userAttributes?.phone_number}

                    {!userAttributes.phone_number_verified && (
                      <button
                        className="ml-2 bg-white border-none text-blue-500 font-semibold focus:outline-none"
                        onClick={() => {
                          setIsVerifyPhone(true);
                        }}
                      >
                        {t('phoneVerification.verify')}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <div className="text-ssm uppercase text-gray-700">
                  {userData?.id}
                </div>
                <div className="text-2xl text-gray-900 font-semibold w-full truncate">
                  {userData?.firstName && userData?.lastName ? (
                    <>
                      {userData?.firstName} {userData?.lastName}
                    </>
                  ) : (
                    <>{userData?.username}</>
                  )}
                </div>

                <div className="mt-1">
                  {!isEdit && (
                    <div className="relative">
                      <button
                        className="absolute top-0 right-0 mt-3 text-xs ml-2 bg-white border-none text-blue-500 font-semibold focus:outline-none"
                        onClick={() => {
                          setIsEdit(true);
                        }}
                      >
                        {t('userProfile.edit')}
                      </button>

                      <p className="text-xs font-medium text-gray-700 pt-3">
                        {t('userProfile.title')}
                      </p>
                      <p className="flex items-center text-xs font-medium text-gray-900">
                        {userData?.title ? userData?.title : '-'}
                      </p>
                      <p className="text-xs font-medium text-gray-700 pt-3">
                        {t('userProfile.company')}
                      </p>
                      <p className="flex items-center text-xs font-medium text-gray-900">
                        {userData?.company ? userData?.company : '-'}
                      </p>
                      <p className="text-xs font-medium text-gray-700 pt-3">
                        {t('userProfile.orgUnit')}
                      </p>
                      <p className="flex items-center text-xs font-medium text-gray-900">
                        {userData?.orgUnit ? userData?.orgUnit : '-'}
                      </p>
                      <p className="text-xs font-medium text-gray-700 pt-3">
                        {t('userProfile.directSupervisor')}
                      </p>
                      <p className="flex items-center text-xs font-medium text-gray-900">
                        {userData?.directSupervisor
                          ? userData?.directSupervisor
                          : '-'}
                      </p>
                    </div>
                  )}

                  {isEdit && (
                    <FormEditUser
                      onCancel={() => {
                        setIsEdit(false);
                      }}
                      userData={userData}
                      onSubmit={() => {
                        refetch && refetch();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-2/3 py-2 px-8">
          <div className="flex items-center">
            <div className="w-1/3 text-base text-gray-900 font-bold">
              {t('userProfile.assignedTo')}
            </div>
            <div className="w-2/3 flex items-center text-ssm font-medium">
              <span className="text-gray-700">Security Profile</span>
              &nbsp;&nbsp;
              {!securityProfileOptions ? (
                <RectLoader width={80} height={20} />
              ) : (
                <span className="text-gray-900">
                  {get(securityProfileOptions, 'name')}
                </span>
              )}
            </div>
          </div>

          <div>
            {userData &&
            companies &&
              map(
                filter(
                  companies,
                  ({ companyTopId }) => !companyTopId
                ),
                ({ id, companyTopId, shortName }, i) => (
                  <div key={i} className="mt-10">
                    <div className="mb-2">
                      <div className="flex items-center">
                        <OrganizationIcon className="mr-3" />

                        <div>
                          <p className="text-xs font-medium text-gray-700">
                            Organization
                          </p>
                          <p className="text-2xl text-gray-900 font-semibold">
                            {shortName}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="w-1/3 py-2">
                        <p className="text-xs font-medium text-gray-700 border-b border-gray-300 pb-2 mb-2">
                          Roles
                        </p>

                        <div>
                          <p className="text-xs font-medium text-gray-900">
                            {!(userData && rolesObj) && (
                              <RectLoader width={80} height={20} />
                            )}
                          </p>
                          {map(
                            filter(userRoles, (item) => item.orgId === id),
                            (role, i) => (
                              <p
                                key={i}
                                className="text-xs font-medium text-gray-900"
                              >
                                {role.roleId &&
                                  rolesObj &&
                                  rolesObj[role.roleId]}
                              </p>
                            )
                          )}

                          {map(
                            filter(
                              companies,
                              ({ companyTopId }) => companyTopId === id
                            ),
                            ({ id }) =>
                              map(
                                filter(userRoles, (item) => item.orgId === id),
                                (role, i) => (
                                  <p
                                    key={i}
                                    className="text-xs font-medium text-gray-900"
                                  >
                                    {role.roleId &&
                                      rolesObj &&
                                      rolesObj[role.roleId]}
                                  </p>
                                )
                              )
                          )}
                        </div>
                      </div>
                      <div className="w-2/3 pl-4 py-2">
                        <p className="text-xs font-medium text-gray-700 border-b border-gray-300 pb-2 mb-2">
                          Programs
                        </p>
                        <div className="flex flex-wrap">
                          {map(
                            filter(
                              companies,
                              (company) => company.id === id
                            ),
                            ({ programsNameList }, i) =>
                              map(programsNameList, (program) => (
                                <p
                                  key={i}
                                  className="w-1/2 text-xs font-medium text-gray-900"
                                >
                                  {program}
                                </p>
                              ))
                          )}
                          {map(
                            filter(
                              companies,
                              ({ companyTopId }) => companyTopId === id
                            ),
                            ({ id }) =>
                              map(
                                filter(
                                  companies,
                                  (company) => company.id === id
                                ),
                                ({ programsNameList }, i) =>
                                  map(programsNameList, (program) => (
                                    <p
                                      key={i}
                                      className="w-1/2 text-xs font-medium text-gray-900"
                                    >
                                      {program}
                                    </p>
                                  ))
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </Card>

      {isVerifyPhone && (
        <VerifyPhone
          onCancel={() => {
            setIsVerifyPhone(false);
          }}
          onSubmit={() => {
            getAttr();
            refetch && refetch();
          }}
        />
      )}
    </div>
  );
};
