import axios from 'axios';
import Cookies from 'js-cookie';
import { createClient } from 'r3shaper';
import { store } from 'src/store/store';
import { accountSlice } from 'src/store/account/accountSlice';
import { ErrorCodes } from 'src/enums/errorCodes';
import { ACCESS_TOKEN_HEADER_KEY } from 'src/constants/accessTokenHeaderKey';

const getAuthHeaders = () => {
  const authCookie = Cookies.get(ACCESS_TOKEN_HEADER_KEY);

  if (authCookie) {
    return {
      [ACCESS_TOKEN_HEADER_KEY]: authCookie,
    };
  }

  return {};
};

export const apiClient = createClient({
  basePath: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  },
  apiProvider: ({ body, path, headers, method, meta }, onSuccess, onError) =>
    axios({
      url: path,
      data: body,
      headers: {
        ...headers,
        ...getAuthHeaders(),
      },
      method,
      ...meta?.config,
    })
      .then(({ data, headers }) => {
        if (
          ['application/json', 'text/csv'].includes(headers['content-type'])
        ) {
          onSuccess(data);
        } else {
          onSuccess({
            blob: new Blob([data], { type: headers['content-type'] }),
            fileName: headers['content-disposition']
              ? headers['content-disposition'].split('filename=')[1]
              : null,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (
            [ErrorCodes.UNAUTHORIZED, ErrorCodes.CREDENTIALS_INVALID].includes(
              error.response?.data?.error?.code
            )
          ) {
            store.dispatch(accountSlice.actions.logout());
          }

          onError(error.response?.data || error.response);
        } else {
          onError(error);
        }

        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      }),
});
