import React, { useContext } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { DetailsContext } from './context';
import { Loading } from 'src/components/Loading';

export const Cell = ({
  children,
  label,
}: JSX.IntrinsicElements['div'] & { label: string }) => (
  <div className="flex items-center p-3">
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-700">{label}</p>
    </div>
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-900 break-all">{children}</p>
    </div>
  </div>
);

export const Details = () => {
  const { adjustment, adjustmentLoading } = useContext(DetailsContext);

  return (
    <div className="p-3">
      <div className="mb-4 bg-white border rounded shadow">
        <div className="p-3 text-xs font-medium bg-gray-100 border-b rounded-t">
          Details
        </div>
        {adjustmentLoading || !adjustment ? (
          <div className="flex items-center justify-center p-10">
            <Loading className="text-blue-500" width={32} height={32} />
          </div>
        ) : (
          <div className="border-t divide-y">
            {lodash.chunk(Object.keys(adjustment!), 4).map((chunk, index) => (
              <div className="grid grid-cols-4 divide-x" key={index}>
                {chunk.map((item, itemIndex) => {
                  const itemValue = adjustment[item as keyof typeof adjustment];

                  const getValue = (value: any) => {
                    if (value instanceof Date) {
                      return moment(value).format('D MMM YYYY HH:mm');
                    }

                    if (value instanceof Object) {
                      return JSON.stringify(value);
                    }

                    return value;
                  };

                  return (
                    <Cell key={itemIndex} label={lodash.startCase(item)}>
                      {getValue(itemValue)}
                    </Cell>
                  );
                })}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
