import React, { memo } from 'react';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
  closeMainModal: () => void;
}

export const CloseConfirmModal = memo(
  ({ isOpen, toggleModal, closeMainModal }: Props) => {
    const { t } = useTranslation();

    return (
      <Modal
        isOpen={isOpen}
        closeModal={() => {
          toggleModal(false);
        }}
        isOverlayDisabled
        maxWidth="auto"
        width="430px"
      >
        <div>
          <div className={'font-bold mb-2 text-center text-2xl'}>
            {t('accessManagement.userManagement.cancelTitle')}
          </div>

          <p className="text-center text-sm text-gray-700">
            {t('accessManagement.userManagement.cancelDescription')}
            <br />
            {t('accessManagement.userManagement.cancelDescriptionSure')}
          </p>

          <div className="flex justify-between mt-8">
            <Button
              variant="primary"
              size="medium-large"
              widthClass="w-1/2"
              className="mr-2"
              onClick={() => {
                toggleModal(false);
              }}
            >
              {t('accessManagement.confirmResume')}
            </Button>
            <Button
              variant="red-pale"
              size="medium-large"
              widthClass="w-1/2"
              className="ml-2"
              onClick={() => {
                closeMainModal();
                toggleModal(false);
              }}
            >
              {t('accessManagement.confirmCancel')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
);
