import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';

export const FormDocument = ({ onSubmit }: { onSubmit: () => void }) => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        document: yup.mixed().test('document', 'Invalid', (value) => {
          return value.length > 0;
        }),
      })
      .required();
  }, []);

  const { register, handleSubmit, errors, formState, watch } = useForm<
    yup.InferType<typeof schema>
  >({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (data: yup.InferType<typeof schema>) => {
    onSubmit();
  };

  const formValues = watch();

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="w-full">
      <div className="my-8">
        <label className="flex flex-col items-center w-full px-4 py-6 text-blue-500 border-4 border-dashed rounded-lg cursor-pointer bg-blue-50">
          <svg className="w-8 h-8" viewBox="0 0 24 24">
            <line
              x1="12"
              y1="18"
              x2="12"
              y2="2"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="2"
              strokeLinecap="butt"
              strokeLinejoin="miter"
            ></line>
            <polyline
              points="8 6 12 2 16 6"
              fill="none"
              stroke="currentColor"
              strokeLinecap="square"
              strokeMiterlimit="10"
              strokeWidth="2"
              strokeLinejoin="miter"
            ></polyline>
            <polyline
              points="7 11 5 11 2 22 22 22 19 11 17 11"
              fill="none"
              stroke="currentColor"
              strokeLinecap="square"
              strokeMiterlimit="10"
              strokeWidth="2"
              strokeLinejoin="miter"
            ></polyline>
          </svg>
          <span className="mt-2 text-sm font-semibold">
            {formValues.document && (formValues.document as any)[0]
              ? ((formValues.document as any)[0] as any).name
              : 'Select a file'}
          </span>
          <input
            name="document"
            type="file"
            className="hidden"
            ref={register()}
          />
        </label>
        {errors.document && (
          <span className="absolute right-0 mt-1 text-xs font-medium text-red-500">
            {t('validations.required')}
          </span>
        )}
      </div>
      <Button
        loading={formState.isSubmitting}
        disabled={formState.isSubmitting}
        type="submit"
        size="large"
        widthClass="w-full"
      >
        {t('disputeResolution.chargebacks.details.documents.addDocument')}
      </Button>
    </form>
  );
};
