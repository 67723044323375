/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Program,
    ProgramFromJSON,
    ProgramFromJSONTyped,
    ProgramToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateProgramRequest
 */
export interface CreateProgramRequest {
    /**
     * 
     * @type {Program}
     * @memberof CreateProgramRequest
     */
    program: Program;
}

export function CreateProgramRequestFromJSON(json: any): CreateProgramRequest {
    return CreateProgramRequestFromJSONTyped(json, false);
}

export function CreateProgramRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProgramRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'program': ProgramFromJSON(json['program']),
    };
}

export function CreateProgramRequestToJSON(value?: CreateProgramRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'program': ProgramToJSON(value.program),
    };
}


