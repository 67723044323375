import React, { useCallback, useEffect, useState } from 'react';
import { IconLogo } from 'src/components/icons/IconLogo';
import { Button } from 'src/components/Button';
import { Link, useHistory } from 'react-router-dom';
import { useCurrentUser } from 'src/auth';

export const Container = () => {
  const { isAuthenticated, logout: logoutFn } = useCurrentUser();
  const history = useHistory();

  const logout = useCallback(() => {
    logoutFn();
  }, [logoutFn]);

  const [logoutPerformed, setLogoutPerformed] = useState(false);

  useEffect(() => {
    if (logoutPerformed) {
      return;
    }

    if (isAuthenticated) {
      logout();

      setLogoutPerformed(true);
    } else {
      history.push('/login');
    }
  }, [history, logout, isAuthenticated, logoutPerformed, setLogoutPerformed]);

  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      <div className="flex flex-col items-center justify-center w-full max-w-sm">
        <Link to="/login">
          <Button
            type="button"
            size="medium"
            widthClass="px-3 absolute top-0 right-0 mt-6 mr-6"
          >
            Log In
          </Button>
        </Link>

        <div className="flex flex-col items-center justify-center text-center">
          <IconLogo className="mb-5" />

          <h3 className="mb-3 text-2xl font-semibold text-gray-900">
            You've been Logged Out
            <br />
            of your session.
          </h3>

          <p className="mb-8 text-base font-semibold text-gray-700">
            Thank you for doing an amazing job!
          </p>
        </div>
      </div>
    </div>
  );
};
