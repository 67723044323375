import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { Input } from 'src/components/Input';
import { ListContext } from '../context';

export const FilterMerchantExternalReference = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { register, watch, setValue } = useForm<{
    merchantExternalReference: string;
  }>();

  const { merchantExternalReference } = watch();

  const setFilter = useRef(
    debounce(
      (filter: string) =>
        dispatch({
          type: 'SET_MERCHANT_EXTERNAL_REFERENCE_FILTER',
          merchantExternalReference: filter,
        }),
      300
    )
  );

  useEffect(() => {
    setFilter.current(merchantExternalReference);
  }, [setFilter, merchantExternalReference]);

  useEffect(() => {
    setValue('merchantExternalReference', filters.merchantExternalReference);
  }, [filters.merchantExternalReference, setValue]);

  return (
    <Input
      label={t(
        'transactionsManagement.authorizations.list.filters.merchantExternalReference'
      )}
      name="merchantExternalReference"
      labelFontClassName="text-xxs font-medium"
      labelColorClassName="text-gray-700"
      className="h-8 text-xs font-bold"
      inputRef={register}
    />
  );
};
