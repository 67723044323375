/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivateIamUserRequest,
    ActivateIamUserRequestFromJSON,
    ActivateIamUserRequestToJSON,
    CreateIamUserRequest,
    CreateIamUserRequestFromJSON,
    CreateIamUserRequestToJSON,
    CreateIamUserResponse,
    CreateIamUserResponseFromJSON,
    CreateIamUserResponseToJSON,
    DeleteIamUserRequest,
    DeleteIamUserRequestFromJSON,
    DeleteIamUserRequestToJSON,
    GetIamUserRequest,
    GetIamUserRequestFromJSON,
    GetIamUserRequestToJSON,
    GetIamUserResponse,
    GetIamUserResponseFromJSON,
    GetIamUserResponseToJSON,
    ListIamUserProgramsResponse,
    ListIamUserProgramsResponseFromJSON,
    ListIamUserProgramsResponseToJSON,
    ListIamUsersRequest,
    ListIamUsersRequestFromJSON,
    ListIamUsersRequestToJSON,
    ListIamUsersResponse,
    ListIamUsersResponseFromJSON,
    ListIamUsersResponseToJSON,
    PageRequest,
    PageRequestFromJSON,
    PageRequestToJSON,
    ResendWelcomeEmailRequest,
    ResendWelcomeEmailRequestFromJSON,
    ResendWelcomeEmailRequestToJSON,
    UpdateIamUserRequest,
    UpdateIamUserRequestFromJSON,
    UpdateIamUserRequestToJSON,
} from '../models';

export interface PostIamUsersActivateRequest {
    activateIamUserRequest: ActivateIamUserRequest;
}

export interface PostIamUsersCreateRequest {
    createIamUserRequest: CreateIamUserRequest;
}

export interface PostIamUsersDeleteRequest {
    deleteIamUserRequest: DeleteIamUserRequest;
}

export interface PostIamUsersDisabledListRequest {
    pageRequest?: PageRequest;
}

export interface PostIamUsersGetRequest {
    getIamUserRequest: GetIamUserRequest;
}

export interface PostIamUsersListRequest {
    listIamUsersRequest?: ListIamUsersRequest;
}

export interface PostIamUsersProgramsRequest {
    body?: any | null;
}

export interface PostIamUsersResendWelcomeEmailRequest {
    resendWelcomeEmailRequest: ResendWelcomeEmailRequest;
}

export interface PostIamUsersUpdateRequest {
    updateIamUserRequest: UpdateIamUserRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async postIamUsersActivateRaw(requestParameters: PostIamUsersActivateRequest): Promise<runtime.ApiResponse<GetIamUserResponse>> {
        if (requestParameters.activateIamUserRequest === null || requestParameters.activateIamUserRequest === undefined) {
            throw new runtime.RequiredError('activateIamUserRequest','Required parameter requestParameters.activateIamUserRequest was null or undefined when calling postIamUsersActivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivateIamUserRequestToJSON(requestParameters.activateIamUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIamUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUsersActivate(requestParameters: PostIamUsersActivateRequest): Promise<GetIamUserResponse> {
        const response = await this.postIamUsersActivateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUsersCreateRaw(requestParameters: PostIamUsersCreateRequest): Promise<runtime.ApiResponse<CreateIamUserResponse>> {
        if (requestParameters.createIamUserRequest === null || requestParameters.createIamUserRequest === undefined) {
            throw new runtime.RequiredError('createIamUserRequest','Required parameter requestParameters.createIamUserRequest was null or undefined when calling postIamUsersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIamUserRequestToJSON(requestParameters.createIamUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIamUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUsersCreate(requestParameters: PostIamUsersCreateRequest): Promise<CreateIamUserResponse> {
        const response = await this.postIamUsersCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUsersDeleteRaw(requestParameters: PostIamUsersDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteIamUserRequest === null || requestParameters.deleteIamUserRequest === undefined) {
            throw new runtime.RequiredError('deleteIamUserRequest','Required parameter requestParameters.deleteIamUserRequest was null or undefined when calling postIamUsersDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteIamUserRequestToJSON(requestParameters.deleteIamUserRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postIamUsersDelete(requestParameters: PostIamUsersDeleteRequest): Promise<void> {
        await this.postIamUsersDeleteRaw(requestParameters);
    }

    /**
     */
    async postIamUsersDisabledListRaw(requestParameters: PostIamUsersDisabledListRequest): Promise<runtime.ApiResponse<ListIamUsersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users-disabled/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageRequestToJSON(requestParameters.pageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUsersDisabledList(requestParameters: PostIamUsersDisabledListRequest): Promise<ListIamUsersResponse> {
        const response = await this.postIamUsersDisabledListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUsersGetRaw(requestParameters: PostIamUsersGetRequest): Promise<runtime.ApiResponse<GetIamUserResponse>> {
        if (requestParameters.getIamUserRequest === null || requestParameters.getIamUserRequest === undefined) {
            throw new runtime.RequiredError('getIamUserRequest','Required parameter requestParameters.getIamUserRequest was null or undefined when calling postIamUsersGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetIamUserRequestToJSON(requestParameters.getIamUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIamUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUsersGet(requestParameters: PostIamUsersGetRequest): Promise<GetIamUserResponse> {
        const response = await this.postIamUsersGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUsersListRaw(requestParameters: PostIamUsersListRequest): Promise<runtime.ApiResponse<ListIamUsersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListIamUsersRequestToJSON(requestParameters.listIamUsersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUsersList(requestParameters: PostIamUsersListRequest): Promise<ListIamUsersResponse> {
        const response = await this.postIamUsersListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUsersProgramsRaw(requestParameters: PostIamUsersProgramsRequest): Promise<runtime.ApiResponse<ListIamUserProgramsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/programs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamUserProgramsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUsersPrograms(requestParameters: PostIamUsersProgramsRequest): Promise<ListIamUserProgramsResponse> {
        const response = await this.postIamUsersProgramsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUsersResendWelcomeEmailRaw(requestParameters: PostIamUsersResendWelcomeEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resendWelcomeEmailRequest === null || requestParameters.resendWelcomeEmailRequest === undefined) {
            throw new runtime.RequiredError('resendWelcomeEmailRequest','Required parameter requestParameters.resendWelcomeEmailRequest was null or undefined when calling postIamUsersResendWelcomeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/resend-welcome-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendWelcomeEmailRequestToJSON(requestParameters.resendWelcomeEmailRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postIamUsersResendWelcomeEmail(requestParameters: PostIamUsersResendWelcomeEmailRequest): Promise<void> {
        await this.postIamUsersResendWelcomeEmailRaw(requestParameters);
    }

    /**
     */
    async postIamUsersUpdateRaw(requestParameters: PostIamUsersUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateIamUserRequest === null || requestParameters.updateIamUserRequest === undefined) {
            throw new runtime.RequiredError('updateIamUserRequest','Required parameter requestParameters.updateIamUserRequest was null or undefined when calling postIamUsersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-users/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIamUserRequestToJSON(requestParameters.updateIamUserRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postIamUsersUpdate(requestParameters: PostIamUsersUpdateRequest): Promise<void> {
        await this.postIamUsersUpdateRaw(requestParameters);
    }

}
