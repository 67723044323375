/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChannelProvider,
    ChannelProviderFromJSON,
    ChannelProviderFromJSONTyped,
    ChannelProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateChannelProviderResponse
 */
export interface CreateChannelProviderResponse {
    /**
     * 
     * @type {ChannelProvider}
     * @memberof CreateChannelProviderResponse
     */
    channelProvider: ChannelProvider;
}

export function CreateChannelProviderResponseFromJSON(json: any): CreateChannelProviderResponse {
    return CreateChannelProviderResponseFromJSONTyped(json, false);
}

export function CreateChannelProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateChannelProviderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelProvider': ChannelProviderFromJSON(json['channel_provider']),
    };
}

export function CreateChannelProviderResponseToJSON(value?: CreateChannelProviderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_provider': ChannelProviderToJSON(value.channelProvider),
    };
}


