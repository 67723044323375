import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ApiLogs } from './api/ApiLogs';

export const Logs = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/api`}>
        <ApiLogs />
      </Route>
    </Switch>
  );
};
