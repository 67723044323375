import React from 'react';
import faker from 'faker';

export const Cell = ({
  children,
  label,
}: JSX.IntrinsicElements['div'] & { label?: string }) => (
  <div className="flex items-center p-3">
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-700">{label}</p>
    </div>
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-900 break-all">{children}</p>
    </div>
  </div>
);

export const Details = () => {
  return (
    <div className="p-3">
      <div className="mb-4 bg-white border rounded shadow">
        <div className="p-3 text-xs font-medium bg-gray-100 border-b rounded-t">
          Details
        </div>
        <div className="divide-y">
          <div className="grid grid-cols-4 divide-x">
            <Cell label="User ID">{faker.random.words(1)}</Cell>
            <Cell label="User Email">{faker.random.words(1)}</Cell>
            <Cell label="Last login">{faker.random.words(1)}</Cell>
            <Cell label="Disabled date">{faker.random.words(1)}</Cell>
          </div>
        </div>
      </div>
    </div>
  );
};
