import React, { PropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQuery } from 'react-query';

// import { TransactionsTable } from './TransactionsTable';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { NavLink, useParams } from 'react-router-dom';
// import { useQuery } from 'react-query';
// import { personsApi } from '../../api';
import { Loading } from './components/loading/Loading';
// import { Status } from './components/status/Status';
import { CardsTable } from './card-list/CardsTable';
import { Card } from 'src/components/Card';
import { personApi } from 'src/api';

import { StatusBadge } from 'src/components/StatusBadge';
import { statusBadge } from './payees-list/PayeesTable';

export interface AccountholderDetailsProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {}

export const AccountholderDetails = React.forwardRef<
  HTMLDivElement,
  AccountholderDetailsProps
>(({ children, ...props }, ref) => {
  const { t } = useTranslation();
  const params: { id?: string } = useParams();

  const { data, isLoading, error } = useQuery(
    `getPerson-${params.id}`,
    () =>
      personApi.postGetPersons({
        getPersonRequest: {
          personId: params.id!,
        },
      }),
    { retry: 1 }
  );

  return (
    <div ref={ref} {...props} className="bg-gray-200">
      <div className="w-full">
        <div className="flex items-center justify-between w-full h-16 bg-white border-b">
          <div className="px-5 w-full">
            <div className="mb-0 text-lg font-bold leading-none text-black">
              <div className="flex justify-between">
                {t('headings.accountholderDetails')}

                <NavLink
                  to={'/payees/list'}
                  tabIndex={0}
                  className="flex items-center text-sm font-medium text-blue-500 transition-colors duration-200 ease-in-out outline-none font-din hover:text-orange-500"
                >
                  <ArrowLeftIcon className="mr-3" />
                  {t('accountholdersList.backToAccountholdersList')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2">
        <Card>
          {error?.status === 403 ? (
            <div className="px-5 py-3 text-sm text-gray-900 bg-red-100 rounded-sm">
              {t('permissions.permissionDenied')}
            </div>
          ) : (
            <div>
              {isLoading && <Loading />}

              {!isLoading && (
                <div>
                  <div className="flex items-center mb-1 pt-5">
                    <div className="pr-5 text-2xl font-semibold leading-5 text-gray-800 font-din tracking-2px">
                      {data?.person?.firstName} {data?.person?.lastName}
                    </div>

                    <StatusBadge variant={statusBadge(data?.person?.status)}>
                      {t(
                        `cards.table.status.${data?.person?.status?.toLowerCase()}`
                      )}
                    </StatusBadge>
                  </div>

                  {/*{data?.person?.createdAt && (
                <div className="text-gray-700 text-xxs">
                  {moment(data?.person?.createdAt).format('DD MMM, YYYY')}
                </div>
              )}*/}

                  <div className="flex mt-2 max-w-3xl">
                    <div className="flex w-1/2 border-r pb-6 border-gray-300">
                      <div className="w-2/3">
                        <div className="mt-6">
                          <div className="text-gray-700 text-xs font-medium">
                            Email
                          </div>
                          <div className="text-gray-900 text-xs font-semibold">
                            {data?.person?.email}
                          </div>
                        </div>
                        <div className="mt-6">
                          <div className="text-gray-700 text-xs font-medium">
                            Phone Number
                          </div>
                          <div className="text-gray-900 text-xs font-semibold">
                            {data?.person?.mobilePhone?.areaCode}{' '}
                            {data?.person?.mobilePhone?.number}
                          </div>
                        </div>
                      </div>

                      <div className="w-1/3">
                        {data?.person?.birthDate && (
                          <div className="mt-6">
                            <div className="text-gray-700 text-xs font-medium">
                              Date of Birth
                            </div>
                            <div className="text-gray-900 text-xs font-semibold">
                              {moment(data?.person?.birthDate!).format(
                                'MMM DD, YYYY'
                              )}
                            </div>
                          </div>
                        )}
                        <div className="mt-6">
                          <div className="text-gray-700 text-xs font-medium">
                            Nationality
                          </div>
                          <div className="text-gray-900 text-xs font-semibold">
                            {data?.person?.nationality}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex w-1/2 pl-12 pb-6">
                      <div className="w-1/2">
                        <div className="mt-6">
                          <div className="text-gray-700 text-xs font-medium">
                            Street (Line 1)
                          </div>
                          <div className="text-gray-900 text-xs font-semibold">
                            {data?.person?.primaryAddress?.addressLine1} &nbsp;
                          </div>
                        </div>

                        <div className="mt-6">
                          <div className="text-gray-700 text-xs font-medium">
                            Street (Line 2)
                          </div>
                          <div className="text-gray-900 text-xs font-semibold">
                            {data?.person?.primaryAddress?.addressLine2}
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 pl-3">
                        <div className="mt-6">
                          <div className="text-gray-700 text-xs font-medium">
                            City
                          </div>
                          <div className="text-gray-900 text-xs font-semibold">
                            {data?.person?.primaryAddress?.city} &nbsp;
                          </div>
                        </div>
                        <div className="flex mt-6">
                          <div>
                            <div className="text-gray-700 text-xs font-medium">
                              Country Code
                            </div>
                            <div className="text-gray-900 text-xs font-semibold">
                              {data?.person?.primaryAddress?.countryCode}
                            </div>
                          </div>
                          <div className="pl-6">
                            <div className="text-gray-700 text-xs font-medium">
                              Zip Code
                            </div>
                            <div className="text-gray-900 text-xs font-semibold">
                              {data?.person?.primaryAddress?.postalCode}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Card>
      </div>

      {!isLoading && error?.status !== 403 && (
        <div className="pt-0 p-2">
          <div className="bg-white border rounded-sm">
            <div className="text-lg font-bold leading-none text-black px-4 pt-8 pb-4">
              {t('headings.payeesCards')}
            </div>
            <CardsTable personId={params.id} hasStatusFilter={false} />
          </div>
        </div>
      )}
    </div>
  );
});

export default AccountholderDetails;
