import React, { useContext, useEffect } from 'react';
import { MaskedNumberInput } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm, Controller } from 'react-hook-form';

export const FilterStan = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { errors, control, watch, setValue } = useForm<{
    stan: string;
  }>();

  const { stan } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_STAN_FILTER',
      stan: stan,
    });
  }, [stan, dispatch]);

  useEffect(() => {
    if (filters.stan !== undefined) {
      setValue('stan', filters.stan);
    }
  }, [filters.stan, setValue]);

  return (
    <Controller
      as={
        <MaskedNumberInput
          format="################################"
          label={t('transactionsManagement.transactions.list.filters.stan')}
          name="stan"
          labelFontClassName="text-xxs font-medium"
          labelColorClassName="text-gray-700"
          className="h-8 text-xs font-bold"
          error={errors.stan}
        />
      }
      name="stan"
      control={control}
    />
  );
};
