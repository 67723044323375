import { createContext } from 'react';
import { GetAdjustmentDetailsResponse } from 'src/api/client';

export interface Adjustment extends GetAdjustmentDetailsResponse {}

export const initialState = {
  adjustment: undefined as Adjustment | undefined,
  adjustmentLoading: false,
  adjustmentNotFound: false,
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_ADJUSTMENT'; adjustment: Adjustment }
  | { type: 'SET_ADJUSTMENT_LOADING'; loading: boolean }
  | { type: 'SET_ADJUSTMENT_NOT_FOUND'; notFound: boolean };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_ADJUSTMENT':
      return { ...state, adjustment: action.adjustment };
    case 'SET_ADJUSTMENT_NOT_FOUND':
      return {
        ...state,
        adjustmentNotFound: action.notFound,
      };
    case 'SET_ADJUSTMENT_LOADING':
      return { ...state, adjustmentLoading: action.loading };
    default:
      throw new Error();
  }
};

export const DetailsContext = createContext<typeof initialState>(initialState);
