import React, { ReactNode } from 'react';

interface Props {
  header: ReactNode;
  content: ReactNode;
}

export const Tab = ({ header, content }: Props) => {
  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="absolute w-full">{header}</div>
      <div className="h-full p-3 pt-19">{content}</div>
    </div>
  );
};
