import React, { useReducer, useCallback, useEffect } from 'react';
import { Details } from './Details';
import {
  reducer,
  initialState,
  Actions,
  DetailsContext,
  Chargeback,
} from './context';
import { useRouteMatch, useParams } from 'react-router-dom';
import { NotFound } from './NotFound';
import { useDispatch } from 'react-redux';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import faker from 'faker';

const fetchChargeback = ({ id }: { id: string | undefined }) => {
  return new Promise<{ data: Chargeback }>((resolve) =>
    resolve({
      data: {
        id: '1',
        comments: [
          {
            createdAt: '2020',
            createdBy: 'test',
            comment: faker.random.words(50),
          },
        ],
        documents: [
          {
            createdAt: '2020',
            createdBy: 'test',
            document: 'Some document',
          },
        ],
      },
    })
  );
};

export const Container = () => {
  const [state, dispatchAction] = useReducer(reducer, initialState);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info({ ...action, context: 'CHARGEBACKS/DETAILS' });
    }

    dispatchAction(action);
  }, []);

  const routeMatch = useRouteMatch();

  const { id } = useParams<{ id: string }>();

  const storeDispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('disputeResolution.breadcrumb'),
          route: '/dispute-resolution/chargebacks',
        },
        {
          label: t('disputeResolution.chargebacks.breadcrumb'),
          route: '/dispute-resolution/chargebacks',
        },
        {
          label: t('disputeResolution.chargebacks.details.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  useEffect(() => {
    let canceled = false;

    dispatch({
      type: 'SET_CHARGEBACK_LOADING',
      loading: true,
    });

    fetchChargeback({ id })
      .then(({ data }) => {
        if (!canceled) {
          dispatch({ type: 'SET_CHARGEBACK', chargeback: data });
        }
      })
      .catch(() => {
        if (!canceled) {
          dispatch({
            type: 'SET_CHARGEBACK_NOT_FOUND',
            notFound: true,
          });
        }
      })
      .finally(() => {
        if (!canceled) {
          dispatch({
            type: 'SET_CHARGEBACK_LOADING',
            loading: false,
          });
        }
      });

    return () => {
      canceled = true;
    };
  }, [dispatch, routeMatch.url, routeMatch.isExact, id]);

  return (
    <DetailsContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {state.chargebackNotFound ? <NotFound /> : <Details />}
    </DetailsContext.Provider>
  );
};
