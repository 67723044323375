import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { get, map } from 'lodash';
import { Modal } from 'src/components/Modal';
import { OptionType } from 'src/components/form/Select';
import { nameof, typeProxy } from 'src/utils/nameof';
import { Button } from 'src/components/Button';
import { AsyncSelect } from 'src/components/form/AsyncSelect';
import { usersApi } from 'src/api';
import { UserType } from 'src/domain/access-management/user-management/tabs/users/UsersContext';

type UserOptions = OptionType & { avatar: string };

const _toUserOptions = (users: UserType[]): UserOptions[] =>
  map(users, (user) => ({
    value: user.id || '',
    label: user.firstName + ' ' + user.lastName || '',
    avatar: '',
  }));

interface FormFields {
  searchUser: OptionType;
}

const formFieldsType = typeProxy<FormFields>();

interface Props {
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
  copyAssigment: () => void;
}

export const CopyAssigmentModal = memo(
  ({ isOpen, toggleModal, copyAssigment }: Props) => {
    const { t } = useTranslation();
    const { watch, control, errors } = useForm<FormFields>({
      mode: 'onBlur',
    });
    const {
      mutateAsync,
    } = useMutation(({ searchValue }: { searchValue: string }) =>
      usersApi.postIamUsersList({ listIamUsersRequest: {} })
    );
    const selectedUser = watch(nameof(formFieldsType.searchUser));

    const handleSearch = useCallback(
      async (
        searchValue: string,
        callback: (options: UserOptions[]) => void
      ) => {
        const userData = await mutateAsync({ searchValue });

        if (!userData?.iamUsers) {
          return callback([]);
        }

        const partUsers = [...(userData.iamUsers || [])].slice(0, 5);
        const options = userData?.iamUsers && _toUserOptions(partUsers);
        callback(options);
      },
      [mutateAsync]
    );

    const handleCopyAssigment = useCallback(() => {
      if (selectedUser) {
        console.log(selectedUser);
        copyAssigment();
      }
    }, [selectedUser, copyAssigment]);

    return (
      <Modal
        isOpen={isOpen}
        closeModal={() => toggleModal(false)}
        isOverlayDisabled
        maxWidth="auto"
        width="500px"
      >
        <div>
          <div className={'font-bold mb-2 text-center text-2xl'}>
            {t('accessManagement.userManagement.form.copyAssigment.title')}
          </div>

          <p className="text-center text-sm text-gray-700">
            {t(
              'accessManagement.userManagement.form.copyAssigment.description'
            )}
          </p>
          <div className="my-8">
            <AsyncSelect
              label={t(
                'accessManagement.userManagement.form.copyAssigment.formItem.label'
              )}
              placeholder={t(
                'accessManagement.userManagement.form.copyAssigment.formItem.placeholder'
              )}
              name={nameof(formFieldsType.searchUser)}
              error={get(errors, nameof(formFieldsType.searchUser))}
              rules={{ required: true }}
              control={control}
              loadOptions={handleSearch}
              defaultValue={null}
            />
          </div>
          <div className="flex justify-between mt-8">
            <Button
              variant="text"
              size="medium-large"
              widthClass="w-1/2"
              className="mr-2"
              onClick={() => toggleModal(false)}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              variant="primary"
              size="medium-large"
              widthClass="w-1/2"
              className="ml-2"
              onClick={handleCopyAssigment}
            >
              {t('accessManagement.userManagement.form.copyAssigment.copy')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
);
