/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateProductRequest,
    CreateProductRequestFromJSON,
    CreateProductRequestToJSON,
    CreateProductResponse,
    CreateProductResponseFromJSON,
    CreateProductResponseToJSON,
    GetProductRequest,
    GetProductRequestFromJSON,
    GetProductRequestToJSON,
    GetProductResponse,
    GetProductResponseFromJSON,
    GetProductResponseToJSON,
    ListProductsRequest,
    ListProductsRequestFromJSON,
    ListProductsRequestToJSON,
    ListProductsResponse,
    ListProductsResponseFromJSON,
    ListProductsResponseToJSON,
} from '../models';

export interface PostProductsCreateRequest {
    createProductRequest: CreateProductRequest;
}

export interface PostProductsGetRequest {
    getProductRequest: GetProductRequest;
}

export interface PostProductsListRequest {
    listProductsRequest?: ListProductsRequest;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     */
    async postProductsCreateRaw(requestParameters: PostProductsCreateRequest): Promise<runtime.ApiResponse<CreateProductResponse>> {
        if (requestParameters.createProductRequest === null || requestParameters.createProductRequest === undefined) {
            throw new runtime.RequiredError('createProductRequest','Required parameter requestParameters.createProductRequest was null or undefined when calling postProductsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/products/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductRequestToJSON(requestParameters.createProductRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProductResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProductsCreate(requestParameters: PostProductsCreateRequest): Promise<CreateProductResponse> {
        const response = await this.postProductsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProductsGetRaw(requestParameters: PostProductsGetRequest): Promise<runtime.ApiResponse<GetProductResponse>> {
        if (requestParameters.getProductRequest === null || requestParameters.getProductRequest === undefined) {
            throw new runtime.RequiredError('getProductRequest','Required parameter requestParameters.getProductRequest was null or undefined when calling postProductsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/products/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProductRequestToJSON(requestParameters.getProductRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProductsGet(requestParameters: PostProductsGetRequest): Promise<GetProductResponse> {
        const response = await this.postProductsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProductsListRaw(requestParameters: PostProductsListRequest): Promise<runtime.ApiResponse<ListProductsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/products/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListProductsRequestToJSON(requestParameters.listProductsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProductsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProductsList(requestParameters: PostProductsListRequest): Promise<ListProductsResponse> {
        const response = await this.postProductsListRaw(requestParameters);
        return await response.value();
    }

}
