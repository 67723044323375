/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChannelProvider,
    ChannelProviderFromJSON,
    ChannelProviderFromJSONTyped,
    ChannelProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListChannelProvidersResponse
 */
export interface ListChannelProvidersResponse {
    /**
     * 
     * @type {Array<ChannelProvider>}
     * @memberof ListChannelProvidersResponse
     */
    channelProviders?: Array<ChannelProvider>;
}

export function ListChannelProvidersResponseFromJSON(json: any): ListChannelProvidersResponse {
    return ListChannelProvidersResponseFromJSONTyped(json, false);
}

export function ListChannelProvidersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListChannelProvidersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelProviders': !exists(json, 'channel_providers') ? undefined : ((json['channel_providers'] as Array<any>).map(ChannelProviderFromJSON)),
    };
}

export function ListChannelProvidersResponseToJSON(value?: ListChannelProvidersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_providers': value.channelProviders === undefined ? undefined : ((value.channelProviders as Array<any>).map(ChannelProviderToJSON)),
    };
}


