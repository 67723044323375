import React, { memo, useContext, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { differenceBy, filter, map } from 'lodash';
import { OptionType, Select } from 'src/components/form/Select';
import { CheckboxForm } from 'src/components/form/CheckboxForm';
import {
  addUserFormType,
  addUserItemType,
  BackOfficeAssignmentsItem,
} from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserModal';
import { transformToOptions } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/FormTwo';
import { Company } from 'src/api/client';
import { nameof, typeProxy } from 'src/utils/nameof';
import { AddUsersContext } from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserContext';

type DefaultValues = Partial<
  Omit<BackOfficeAssignmentsItem, 'program' | 'autoAssignProgram'>
>;

interface Props {
  defaultValues: DefaultValues;
  companies: Array<Company>;
  fieldIndex: number;
  fieldValues: BackOfficeAssignmentsItem;
  isLoadingCompanies: boolean;
  isLoadingRoles: boolean;
  rolesOptions: OptionType[];
}

export const BackofficeAssignmentsFields = memo(
  ({
    defaultValues,
    companies,
    fieldIndex,
    fieldValues,
    isLoadingCompanies,
    isLoadingRoles,
    rolesOptions,
  }: Props) => {
    const { t } = useTranslation();
    const { control, errors, watch } = useFormContext();
    const {
      firstOrgnValue,
      setFirstOrgnValue,
      autoAssignAffiliated,
      setAutoAssignAffiliated,
    } = useContext(AddUsersContext);
    const fieldsValue: BackOfficeAssignmentsItem[] = watch(
      nameof(addUserFormType.companyAssignments)
    );

    const firstOrganizationValue = watch(
      nameof(addUserFormType.companyAssignments) +
        `[${fieldIndex}].firstOrganization.value`
    );

    useEffect(() => {
      if (
        !firstOrganizationValue ||
        firstOrgnValue[fieldIndex] === firstOrganizationValue
      ) {
        return;
      }

      setFirstOrgnValue(firstOrganizationValue, fieldIndex);
    }, [firstOrgnValue, setFirstOrgnValue, fieldIndex, firstOrganizationValue]);

    const autoAssignAffiliatedValue = watch(
      nameof(addUserFormType.companyAssignments) +
        `[${fieldIndex}].autoAssignAffiliated`
    );

    useEffect(() => {
      if (autoAssignAffiliated[fieldIndex] === autoAssignAffiliatedValue) {
        return;
      }
      setAutoAssignAffiliated(autoAssignAffiliatedValue, fieldIndex);
    }, [
      fieldIndex,
      autoAssignAffiliated,
      autoAssignAffiliatedValue,
      setAutoAssignAffiliated,
    ]);

    const firstOrgnOptions = useMemo(() => {
      const filteredOrganizations = filter(
        companies,
        (company) => !company.companyTopId
      );

      return transformToOptions<Company>(filteredOrganizations, 'shortName');
    }, [companies]);

    const secondOrgnOptions = useMemo(() => {
      if (!fieldValues.firstOrganization) {
        return [];
      }

      const secondLevelId = fieldValues.firstOrganization.value;
      const filteredOrganizations = companies.filter(
        ({ companyTopId }) => secondLevelId === companyTopId
      );

      const transformedOptions = transformToOptions<Company>(
        filteredOrganizations,
        'shortName'
      );
      const selectedSecondOrgn = filter(
        map(fieldsValue, nameof(addUserItemType.secondOrganization))
      );

      if (!selectedSecondOrgn.length) {
        return transformedOptions;
      }

      return differenceBy(
        transformedOptions,
        selectedSecondOrgn,
        nameof(typeProxy<OptionType>().value)
      );
    }, [fieldsValue, fieldValues, companies]);

    return (
      <>
        <div className="flex mb-3">
          <div className="w-full mr-1">
            <Select
              label={t(
                'accessManagement.userManagement.form.firstOrganization'
              )}
              placeholder={t(
                'accessManagement.userManagement.form.organization.placeholder'
              )}
              error={get(
                errors,
                `companyAssignments[${fieldIndex}].firstOrganization`
              )}
              name={`companyAssignments[${fieldIndex}].firstOrganization`}
              control={control}
              defaultValue={defaultValues.firstOrganization}
              options={firstOrgnOptions}
              isLoading={isLoadingCompanies}
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="w-full mr-1">
            <Select
              label={t('accessManagement.userManagement.form.roles')}
              error={get(errors, `companyAssignments[${fieldIndex}].roles`)}
              name={`companyAssignments[${fieldIndex}].roles`}
              control={control}
              defaultValue={defaultValues.roles}
              options={rolesOptions}
              isLoading={isLoadingRoles}
              isMulti
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="w-full mr-1">
            <Select
              label={t(
                'accessManagement.userManagement.form.secondOrganization'
              )}
              placeholder={t(
                'accessManagement.userManagement.form.organization.placeholder'
              )}
              error={get(
                errors,
                `companyAssignments[${fieldIndex}].secondOrganization`
              )}
              name={`companyAssignments[${fieldIndex}].secondOrganization`}
              control={control}
              defaultValue={defaultValues.secondOrganization}
              options={secondOrgnOptions}
              isLoading={isLoadingCompanies}
              disabled={fieldValues.autoAssignAffiliated}
              rules={{ required: fieldValues.firstOrganization && !fieldValues.autoAssignAffiliated }}
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="w-full mr-1">
            <CheckboxForm
              label={t(
                'accessManagement.userManagement.form.autoAssignAffiliated'
              )}
              control={control}
              name={`companyAssignments[${fieldIndex}].autoAssignAffiliated`}
              labelClassName={'w-full'}
              labelTextClassName={'font-semibold text-xs text-gray-900'}
              defaultValue={defaultValues.autoAssignAffiliated}
              disabled={!fieldValues.firstOrganization}
            />
          </div>
        </div>
      </>
    );
  }
);
