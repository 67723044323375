/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ListTransactionsFilter,
    ListTransactionsFilterFromJSON,
    ListTransactionsFilterFromJSONTyped,
    ListTransactionsFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListTransactionsPageRequest
 */
export interface ListTransactionsPageRequest {
    /**
     * 
     * @type {number}
     * @memberof ListTransactionsPageRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof ListTransactionsPageRequest
     */
    limit?: number;
    /**
     * 
     * @type {ListTransactionsFilter}
     * @memberof ListTransactionsPageRequest
     */
    filters?: ListTransactionsFilter;
}

export function ListTransactionsPageRequestFromJSON(json: any): ListTransactionsPageRequest {
    return ListTransactionsPageRequestFromJSONTyped(json, false);
}

export function ListTransactionsPageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTransactionsPageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'filters': !exists(json, 'filters') ? undefined : ListTransactionsFilterFromJSON(json['filters']),
    };
}

export function ListTransactionsPageRequestToJSON(value?: ListTransactionsPageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'filters': ListTransactionsFilterToJSON(value.filters),
    };
}


