import React, { ReactNode } from 'react';
import classNames from 'classnames';

export const ListItem = ({
  children,
  label,
  disabled = false,
}: {
  children: ReactNode;
  label: string | ReactNode;
  disabled?: boolean;
}) => (
  <div className="mb-2">
    <label
      className={classNames('flex items-center w-full', {
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
      })}
    >
      <div className="flex items-center">{children}</div>
      <span
        className={classNames(
          'ml-2 text-xs font-semibold leading-tight text-gray-900',
          {
            'opacity-50': disabled,
          }
        )}
      >
        {label}
      </span>
    </label>
  </div>
);
