import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { BreadcrumbItem } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { RootState } from 'src/store/store';
import { IconLogout } from 'src/components/icons/IconLogout';
import { IconNotifications } from 'src/components/icons/IconNotifications';
import { versionResource } from 'src/api/version';
import { IconCog } from 'src/components/icons/IconCog';
import { Tooltip } from 'src/components/Tooltip';
const { version } = require('../../../package.json');

export const Topbar = () => {
  const breadcrumbs: BreadcrumbItem[] = useSelector(
    (state: RootState) => state.breadcrumbs.items
  );

  const [beVersion, setBeVersion] = useState('');

  const fetchVersion = useCallback(() => {
    return versionResource.getVersion().then((response) => {
      setBeVersion(response.data.version);
    });
  }, []);

  useEffect(() => {
    fetchVersion();
  }, [fetchVersion]);

  return (
    <div className="fixed z-20 flex items-center justify-between w-full h-16 px-6 bg-white border-b lg:pl-64">
      <div className="pl-5">
        <ul className="flex items-center text-xs font-medium text-gray-700">
          {breadcrumbs.map((item, index) => (
            <Fragment key={index}>
              <li>
                <NavLink exact to={item.route}>
                  {item.label}
                </NavLink>
              </li>
              {index !== breadcrumbs.length - 1 && <li className="mx-2">›</li>}
            </Fragment>
          ))}
        </ul>
      </div>
      <div className="flex items-center text-gray-700">
        {process.env.NODE_ENV !== 'production' && (
          <React.Fragment>
            <span className="mr-5 text-xs font-semibold">BE v{beVersion}</span>
            <span className="mr-5 text-xs font-semibold">FE v{version}</span>

            <a href="/" className="w-5 h-5 mr-5">
              <IconNotifications />
            </a>
          </React.Fragment>
        )}

        <Tooltip
          minWidth={200}
          placement="bottom-end"
          withPadding={false}
          content={() => (
            <div className="py-1">
              <Link
                to="/logout"
                className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100"
              >
                <IconLogout className="text-red-500" />
                <span className="ml-2">Log Out</span>
              </Link>
            </div>
          )}
        >
          {({ ref, setIsOpen, isOpen }) => (
            <div
              ref={ref}
              onClick={() => setIsOpen(!isOpen)}
              className="mr-5 cursor-pointer"
            >
              <IconCog className={classNames({ 'text-blue-500': isOpen })} />
            </div>
          )}
        </Tooltip>
      </div>
    </div>
  );
};
