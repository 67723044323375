import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import { Button } from 'src/components/Button';
import { AddRoleFields } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/AddRoleModal';
import { FormWrapper } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/FormWrapper';
import { FormOnePreview } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/FormOnePreview';
// import { PermissionWrapper } from './PermissionWrapper';
import { CheckboxForm } from 'src/components/form/CheckboxForm';
import { useQuery } from 'react-query';
import { permissionsApi } from 'src/api';
import { Loading } from 'src/domain/cards/components/loading/Loading';

interface Props {
  roleId?: string | null;
  currentStep: number;
  isDefaultValues?: boolean;
  isLoading: boolean;
  error?: any;
  rolePermissionsList: { [key: string]: boolean };
}

export const FormTwo = memo(
  ({
    roleId,
    currentStep,
    isDefaultValues,
    isLoading,
    rolePermissionsList,
    error,
  }: Props) => {
    const { t } = useTranslation();
    const {
      getValues,
      setValue,
      watch,
      control,
    } = useFormContext<AddRoleFields>();

    const { name, description, channel } = getValues([
      'name',
      'description',
      'channel',
    ]);

    const permissions = watch('permissions');

    const {
      data: permissionsList,
      isLoading: isLoadingPermissionsList,
    } = useQuery(
      ['postIamPermissionsList', get(channel, 'value', '')],
      () =>
        permissionsApi.postIamPermissionsList({
          listIamPermissionsRequest: {
            accessChannelId: channel.value!,
          },
        }),
      {
        enabled: !!channel?.value,
      }
    );

    useEffect(() => {
      if (Object.keys(rolePermissionsList).length > 0 && permissionsList) {
        setValue('permissions', { ...rolePermissionsList });
      }
    }, [rolePermissionsList, permissionsList]);

    return (
      <FormWrapper
        title={
          roleId
            ? t('accessManagement.rolesAndPermissions.role.editPermissions')
            : t('accessManagement.rolesAndPermissions.role.assignPermissions')
        }
        currentStep={currentStep}
        className={classNames({ hidden: currentStep !== 1 })}
      >
        <FormOnePreview
          name={name}
          description={description}
          channel={channel}
        />
        <div>
          <div>
            <div className="mb-6">
              <p className={'font-bold mb-2'}>
                {t(
                  'accessManagement.rolesAndPermissions.role.permissions.title'
                )}
              </p>
              <p className={'text-gray-700 text-ssm'}>
                {t(
                  'accessManagement.rolesAndPermissions.role.permissions.subtitle'
                )}
              </p>
            </div>

            <div className="mb-6">
              {isLoadingPermissionsList ? (
                <div className="flex justify-center">
                  <Loading />
                </div>
              ) : (
                <div>
                  {permissionsList?.iamPermissions &&
                    permissionsList.iamPermissions.map(
                      (permission: { id: string; shortName: string }) => (
                        <div key={permission.id}>
                          <CheckboxForm
                            name={`permissions.${permission.id}`}
                            control={control}
                            color="text-green-500"
                            label={permission.shortName}
                          />
                        </div>
                      )
                    )}

                  {permissionsList?.iamPermissions.length === 0 && (
                    <div className="text-sm text-gray-900 font-semibold text-center">
                      {t(
                        'accessManagement.rolesAndPermissions.role.permissions.noData'
                      )}
                    </div>
                  )}

                  {/* <PermissionWrapper
                  title={t(
                    'accessManagement.rolesAndPermissions.role.permissions.titles.loadCard'
                  )}
                  selectAllFields={
                    [
                      'permissions.loadCard.cardsList.view',
                      'permissions.loadCard.cardsDetails.view',
                      'permissions.loadCard.cardsDetails.lock',
                      'permissions.loadCard.cardsDetails.block',
                    ]
                  }
                  setValue={setValue}
                >
                  <div className="mx-4">
                    <div className="mb-2">
                      <div className="text-gray-900 text-xs mb-2 font-medium">
                        {t(
                          'accessManagement.rolesAndPermissions.role.permissions.titles.cardsList'
                        )}
                      </div>
                      <CheckboxForm
                        error={errors?.permissions?.loadCard?.cardsList?.view}
                        name="permissions.loadCard.cardsList.view"
                        control={control}
                        color='text-green-500'
                        label={t(
                          'accessManagement.rolesAndPermissions.role.permissions.values.view'
                        )}
                      />
                    </div>

                    <div>
                      <div className="text-gray-900 text-xs mb-2 font-medium">
                        {t(
                          'accessManagement.rolesAndPermissions.role.permissions.titles.cardsDetails'
                        )}
                      </div>
                      <div className="flex">
                        <CheckboxForm
                          error={errors?.permissions?.loadCard?.cardsDetails?.view}
                          name="permissions.loadCard.cardsDetails.view"
                          control={control}
                          color='text-green-500'
                          label={t(
                            'accessManagement.rolesAndPermissions.role.permissions.values.view'
                          )}
                        />
                        <CheckboxForm
                          error={errors?.permissions?.loadCard?.cardsDetails?.lock}
                          name="permissions.loadCard.cardsDetails.lock"
                          control={control}
                          color='text-green-500'
                          label={t(
                            'accessManagement.rolesAndPermissions.role.permissions.values.lock'
                          )}
                        />
                        <CheckboxForm
                          error={errors?.permissions?.loadCard?.cardsDetails?.block}
                          name="permissions.loadCard.cardsDetails.block"
                          control={control}
                          color='text-green-500'
                          label={t(
                            'accessManagement.rolesAndPermissions.role.permissions.values.block'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </PermissionWrapper>*/}
                </div>
              )}
            </div>
          </div>
        </div>

        {error && (
          <div className="py-3 px-5 bg-red-100 mb-4 text-sm text-gray-900 rounded-sm">
            {Array.isArray(get(error, 'error'))
              ? map(get(error, 'error'), ({ message, details }) => (
                  <p>
                    {message}: {details}
                  </p>
                ))
              : error}
          </div>
        )}

        <Button
          size={'medium-large'}
          className={'w-full'}
          type={'submit'}
          disabled={
            isLoadingPermissionsList ||
            !Object.keys(permissions || {}).some((key) => permissions[key])
          }
          loading={isLoading}
        >
          {isDefaultValues
            ? t('accessManagement.rolesAndPermissions.role.saveRole')
            : t('accessManagement.rolesAndPermissions.role.addRole')}
        </Button>
      </FormWrapper>
    );
  }
);
