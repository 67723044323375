import { useEffect } from 'react';

export const ESC_KEY = 27;

export const useOnKeyDown = (
  { keyCode }: { keyCode: number },
  callback: (event: KeyboardEvent) => any
) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === keyCode) {
        callback(event);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [keyCode, callback]);
};
