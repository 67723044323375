/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamRole,
    IamRoleFromJSON,
    IamRoleFromJSONTyped,
    IamRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetIamRoleResponse
 */
export interface GetIamRoleResponse {
    /**
     * 
     * @type {IamRole}
     * @memberof GetIamRoleResponse
     */
    iamRole?: IamRole;
}

export function GetIamRoleResponseFromJSON(json: any): GetIamRoleResponse {
    return GetIamRoleResponseFromJSONTyped(json, false);
}

export function GetIamRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIamRoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamRole': !exists(json, 'iam_role') ? undefined : IamRoleFromJSON(json['iam_role']),
    };
}

export function GetIamRoleResponseToJSON(value?: GetIamRoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iam_role': IamRoleToJSON(value.iamRole),
    };
}


