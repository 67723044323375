import React, { ReactNode, useState, useCallback, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import classNames from 'classnames';
import { NavLink, Route } from 'react-router-dom';
// import { IconBox } from 'src/components/icons/IconBox';
import { IconPocket } from 'src/components/icons/IconPocket';
import { IconCaretDown } from 'src/components/icons/IconCaretDown';
import { IconRepeat } from 'src/components/icons/IconRepeat';
import { IconFileText } from 'src/components/icons/IconFileText';
// import { IconDatabase } from 'src/components/icons/IconDatabase';
// import { IconCodePen } from 'src/components/icons/IconCodePen';
// import { IconUserPlus } from 'src/components/icons/IconUserPlus';
// import { IconUsers } from 'src/components/icons/IconUsers';
// import { IconSliders } from 'src/components/icons/IconSliders';
// import { IconDollar } from 'src/components/icons/IconDollar';
// import { IconQuestionCircle } from 'src/components/icons/IconQuestionCircle';
// import { IconPenLine } from 'src/components/icons/IconPenLine';
// import { IconShield } from 'src/components/icons/IconShield';
// import { IconCard } from 'src/components/icons/IconCard';
// import { Profile } from 'src/store/account/accountSlice';
// import { authResource } from 'src/api/auth';
import { RectLoader } from 'src/components/RectLoader';
import { useCurrentUser } from 'src/auth';
import { IconUsers } from 'src/components/icons/IconUsers';

export enum MenuItemIds {
  DUE_DILIGENCE = 'DUE_DILIGENCE',
  KYC = 'KYC',
  ACCESS_MANAGEMENT = 'ACCESS_MANAGEMENT',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  ROLES_AND_PERMISSIONS = 'ROLES_AND_PERMISSIONS',
  TRANSACTION_MANAGEMENT = 'TRANSACTION_MANAGEMENT',
  AUTHORIZATIONS = 'AUTHORIZATIONS',
  TRANSACTIONS = 'TRANSACTIONS',
  REPORTS = 'REPORTS',
  ACQUIRING = 'ACQUIRING',
  CARDS = 'CARDS',
  CARDS_LIST = 'CARDS_LIST',
  PAYEES = 'PAYEES',
  PAYEES_LIST = 'PAYEES_LIST',
  DISPUTE_RESOLUTION = 'DISPUTE_RESOLUTION',
  CHARGEBACKS = 'CHARGEBACKS',
  ADJUSTMENTS = 'ADJUSTMENTS',
  LOGS = 'LOGS',
  API_LOGS = 'API_LOGS',
  INACTIVE_USERS = 'INACTIVE_USERS',
}

const menuItemsMap = {
  [MenuItemIds.DUE_DILIGENCE]: {
    label: 'Due Diligence',
    icon: <IconPocket />,
    route: '/due-diligence',
  },
  [MenuItemIds.ACCESS_MANAGEMENT]: {
    label: 'Access Management',
    icon: <IconUsers />,
    route: '/access-management',
  },
  [MenuItemIds.TRANSACTION_MANAGEMENT]: {
    label: 'Transaction Management',
    icon: <IconRepeat />,
    route: '/transaction-management',
  },
  [MenuItemIds.REPORTS]: {
    label: 'Reports',
    icon: <IconFileText />,
    route: '/reports',
  },
  [MenuItemIds.CARDS]: {
    label: 'Cards',
    icon: <IconFileText />,
    route: '/cards',
  },
  [MenuItemIds.PAYEES]: {
    label: 'Payees',
    icon: <IconFileText />,
    route: '/payees',
  },
  [MenuItemIds.DISPUTE_RESOLUTION]: {
    label: 'Dispute Resolution',
    icon: <IconFileText />,
    route: '/dispute-resolution',
  },
  [MenuItemIds.LOGS]: {
    label: 'Logs',
    icon: <IconFileText />,
    route: '/logs',
  },
};

const menuSubItemsMap = {
  [MenuItemIds.KYC]: {
    label: 'KYC',
    route: '/due-diligence/kyc',
  },
  [MenuItemIds.USER_MANAGEMENT]: {
    label: 'User Management',
    route: '/access-management/user-management/users',
  },
  [MenuItemIds.ROLES_AND_PERMISSIONS]: {
    label: 'Roles and Permissions',
    route: '/access-management/roles-and-permissions/roles',
  },
  [MenuItemIds.AUTHORIZATIONS]: {
    label: 'Authorizations',
    route: '/transaction-management/authorizations',
  },
  [MenuItemIds.TRANSACTIONS]: {
    label: 'Transactions',
    route: '/transaction-management/transactions',
  },
  [MenuItemIds.ACQUIRING]: {
    label: 'Acquiring',
    route: '/reports/acquiring',
  },
  [MenuItemIds.CARDS_LIST]: {
    label: 'Cards list',
    route: '/cards/list',
  },
  [MenuItemIds.PAYEES_LIST]: {
    label: 'Payees list',
    route: '/payees/list',
  },
  [MenuItemIds.PAYEES_LIST]: {
    label: 'Payees list',
    route: '/payees/list',
  },
  [MenuItemIds.CHARGEBACKS]: {
    label: 'Chargebacks',
    route: '/dispute-resolution/chargebacks',
  },
  [MenuItemIds.ADJUSTMENTS]: {
    label: 'Adjustments',
    route: '/transaction-management/adjustments',
  },
  [MenuItemIds.API_LOGS]: {
    label: 'API Logs',
    route: '/logs/api',
  },
  [MenuItemIds.INACTIVE_USERS]: {
    label: 'Disabled Inactive Users',
    route: '/reports/inactive-users',
  },
};

type MenuItem = {
  label: string;
  icon: ReactNode;
  route: string;
  subitems?: MenuSubItem[];
};

type MenuSubItem = {
  label: string;
  route: string;
};

const SidebarSubitem = ({ item }: { item: MenuSubItem }) => (
  <li className="text-black">
    <NavLink
      to={item.route}
      activeClassName="text-blue-500"
      className={classNames(
        { 'pointer-events-none': item.route === '/404' },
        'block w-full py-1 pl-12 text-xs text-left transition-colors duration-300 focus:outline-none hover:bg-gray-100'
      )}
    >
      <span className={classNames('text-xs font-medium')}>{item.label}</span>
    </NavLink>
  </li>
);

const SidebarItem = ({ item, active }: { item: MenuItem; active: boolean }) => {
  const [open, setOpen] = useState(active);

  const subitemsCollapse = useSpring({
    config: { duration: 150 },
    opacity: open ? 1 : 0,
    height: open ? (item.subitems?.length || 0) * 26 : 0,
  });

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return (
    <li>
      <button
        onClick={toggleOpen}
        className="relative flex items-center justify-between w-full px-5 py-3 transition-colors duration-300 hover:bg-gray-100 focus:outline-none"
      >
        {active && <div className="absolute left-0 w-1 h-full bg-blue-500" />}
        <div className="flex">
          <span
            className={classNames('mr-3', {
              'text-blue-500': active,
              'text-gray-700': !active,
            })}
          >
            {item.icon}
          </span>
          <span
            className={classNames('text-xs font-bold', {
              'text-blue-500': active,
              'text-black': !active,
            })}
          >
            {item.label}
          </span>
        </div>
        {item.subitems && (
          <IconCaretDown
            className={classNames('transition-transform duration-300', {
              'text-gray-700': !active,
              'text-blue-500': active,
              'transform rotate-180': open,
            })}
          />
        )}
      </button>
      {item.subitems && (
        <animated.ul
          style={subitemsCollapse}
          className={classNames(!open && 'pointer-events-none')}
        >
          {item.subitems.map((item, index) => (
            <SidebarSubitem item={item} key={index} />
          ))}
        </animated.ul>
      )}
    </li>
  );
};

export const Sidebar = () => {
  const [loading, setLoading] = useState(false);
  const [sidebarItems, setSidebarItems] = useState<MenuItem[]>();
  const { user } = useCurrentUser();

  useEffect(() => {
    const items = [
      {
        name: 'ACCESS_MANAGEMENT',
        items: [
          { name: 'USER_MANAGEMENT' },
          { name: 'ROLES_AND_PERMISSIONS' },
        ],
      },
      {
        name: 'TRANSACTION_MANAGEMENT',
        items: [
          { name: 'AUTHORIZATIONS' },
          { name: 'TRANSACTIONS' },
          { name: MenuItemIds.ADJUSTMENTS },
        ],
      },
      { name: MenuItemIds.CARDS, items: [{ name: MenuItemIds.CARDS_LIST }] },
      { name: MenuItemIds.PAYEES, items: [{ name: MenuItemIds.PAYEES_LIST }] },
      {
        name: MenuItemIds.REPORTS,
        items: [{ name: MenuItemIds.INACTIVE_USERS }],
      },
      {
        name: MenuItemIds.DISPUTE_RESOLUTION,
        items: [{ name: MenuItemIds.CHARGEBACKS }],
      },
      {
        name: MenuItemIds.LOGS,
        items: [{ name: MenuItemIds.API_LOGS }],
      },
    ]
      .map((item) =>
        item.name !== MenuItemIds.DUE_DILIGENCE ||
        process.env.REACT_APP_HAS_KYC === 'true'
          ? {
              ...menuItemsMap[item.name as keyof typeof menuItemsMap],
              subitems: item.items.map((subItem) => ({
                ...menuSubItemsMap[
                  subItem.name as keyof typeof menuSubItemsMap
                ],
              })),
            }
          : null
      )
      .filter(Boolean);

    setSidebarItems(items as MenuItem[]);
  }, [setLoading]);

  console.log(sidebarItems);

  return (
    <div className="fixed z-30 w-64 h-full px-5 py-4 overflow-y-auto bg-white border-r">
      <NavLink to='/user-profile/overview' className="flex items-center mb-4">
        <div className="flex items-center justify-center w-8 h-8 mr-4 text-xs tracking-wider text-blue-500 bg-blue-100 rounded-full">
          {user?.email[0]}
        </div>
        <div className="flex flex-col justify-between h-full leading-snug">
          <span className="w-40 text-sm font-bold tracking-wide text-black truncate">
            {user?.email}
          </span>
          <span className="font-semibold tracking-wider text-blue-500 text-xxs">
            {/* Development */}
          </span>
        </div>
      </NavLink>
      <ul className="-mx-5">
        {loading || !sidebarItems ? (
          <div className="pt-px">
            {[...Array(7)].map((item, key) => (
              <div className="px-5" key={key}>
                <RectLoader width={250} height={16} className="w-full mt-4" />
              </div>
            ))}
          </div>
        ) : (
          sidebarItems.map((item, index) => (
            <Route
              key={index}
              path={item.route}
              children={({ match }) => (
                <SidebarItem item={item} active={!!match} />
              )}
            />
          ))
        )}
      </ul>
    </div>
  );
};
