import { useState, useRef, useCallback, Dispatch, SetStateAction } from 'react';
import { debounce } from 'lodash';
import { ItemFilter } from 'src/components/dropdown-filter/filter';

export const useSearchableList = ({
  offset = 0,
  limit = 10,
  resource,
  setSearchOffset,
  setItemsLoaded,
}: {
  offset?: number;
  limit?: number;
  resource: (options: {
    limit: number;
    offset: number;
    searchTerm?: string;
  }) => Promise<{
    data: ItemFilter[];
    meta: { totalItems: number };
  }>;
  setSearchOffset?: Dispatch<SetStateAction<number>>;
  setItemsLoaded?: Dispatch<SetStateAction<any[]>>;
}) => {
  const searchQueryRef = useRef('');

  const [itemsLoading, setItemsLoading] = useState(true);

  const [items, setItems] = useState<ItemFilter[]>([]);

  const [totalItems, setTotalItems] = useState<number | null>(null);

  const handleHide = useCallback(() => {
    setItemsLoading(true);
  }, [setItemsLoading]);

  const fetchItems = useCallback(
    debounce((searchQuery?: string) => {
      let offsetOverride = false;

      if (searchQueryRef.current !== searchQuery) {
        setItemsLoaded && setItemsLoaded([]);
        setSearchOffset && setSearchOffset(0);
        offsetOverride = true;
      }

      setItemsLoading(true);

      resource({
        limit,
        offset: offsetOverride ? 0 : offset,
        searchTerm: searchQuery,
      })
        .then(({ data, meta }) => {
          setItems(data);

          setTotalItems(meta.totalItems);
        })
        .finally(() => setItemsLoading(false));

      searchQueryRef.current = searchQuery || '';
    }, 400),
    [resource, limit, offset]
  );

  return {
    fetchItems,
    handleHide,
    totalItems,
    items,
    itemsLoading,
    setItemsLoading,
  };
};
