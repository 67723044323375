import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import get from 'lodash/get';
import { notifications } from 'src/utils/notifications';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { useTranslation } from 'react-i18next';
import { usersApi } from 'src/api';
import map from 'lodash/map';

interface Params {
  isOpen?: boolean;
  onSubmit?: () => void;
}

export const useUserDeleteModal = ({ isOpen = false, onSubmit }: Params) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(isOpen);
  const [deleteUserId, setDeleteUserId] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [error, setError] = useState();

  const {
    mutateAsync: deleteUserMutation,
    isLoading,
  } = useMutation(({ iamUserId }: { iamUserId: string }) =>
    usersApi.postIamUsersDelete({ deleteIamUserRequest: { iamUserId } }),
    {
      onError: async (error) => {
        // @ts-ignore
        const err = await error.text();
        setError(JSON.parse(err));
      },
    }
  );

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleOpen = useCallback((userId?: string, username?: string) => {
    userId && setDeleteUserId(userId);
    username && setUsername(username);
    setVisible(true);
  }, [setDeleteUserId]);

  const handleSubmit = useCallback(async () => {
    setError(null);
    try {
      await deleteUserMutation({ iamUserId: deleteUserId! });
      notifications.success();
      onSubmit && onSubmit();
    } catch (err) {
    }
  }, [handleClose, deleteUserId]);

  const deleteUserModal = useMemo(
    () =>
      visible && (
        <Modal
          isOpen={visible}
          closeModal={() => {
            handleClose();
          }}
          maxWidth="auto"
          width="500px"
        >
          <div>
            <div className={'font-bold mb-2 text-center text-2xl'}>
              {t('accessManagement.userManagement.deleteUser.title')}
            </div>

            <p className='text-center font-medium text-sm text-gray-900 pb-3'>{username}</p>

            <p className="text-center text-sm text-gray-700">
              {t('accessManagement.userManagement.deleteUser.description', { username })}
            </p>

            {error && (
              <div className="py-3 px-5 bg-red-100 my-3 text-sm text-gray-900 rounded-sm">
                {Array.isArray(get(error, 'error'))
                  ? map(get(error, 'error'), ({ message, details }) => (
                    <p>
                      {message}: {details}
                    </p>
                  ))
                  : error}
              </div>
            )}

            <div className="flex justify-between mt-8">
              <Button
                disabled={isLoading}
                variant="secondary"
                size="medium-large"
                widthClass="w-1/2"
                className="mr-2"
                onClick={() => {
                  handleClose();
                }}
              >
                {t('accessManagement.userManagement.deleteUser.cancel')}
              </Button>
              <Button
                loading={isLoading}
                variant="red"
                size="medium-large"
                widthClass="w-1/2"
                className="ml-2"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t('accessManagement.userManagement.deleteUser.confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      ),
    [visible, handleSubmit, handleClose, isLoading, username]
  );

  return { openDeleteUserModal: handleOpen, closeDeleteUserModal: handleClose, deleteUserModal };
};
