import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconLogo } from 'src/components/icons/IconLogo';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Button';

export const InvalidCredentials = ({ error } : { error?: string }) => {
  const { t } = useTranslation();
  console.log(error);
  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      <div className="flex flex-col items-center justify-center w-full max-w-sm">
        <IconLogo className="mb-5" />

        <h3 className="mb-2 text-2xl font-semibold text-gray-900 text-center">
          Link Expired
        </h3>

        <p className="mb-8 text-base font-semibold text-gray-700 text-center">
          Looks like the page you're trying to access isn't there anymore.
        </p>

        <Link to="/login" className="w-full mb-6">
          <Button type="button" size="large" widthClass="w-full">
            Log Into an Existing Account
          </Button>
        </Link>

        {/*{error && (
          <div className="py-3 px-5 bg-red-100 my-4 text-sm text-gray-900 rounded-sm">
            {error}
          </div>
        )}*/}

        <a
          href="mailto:support@agilepartners.eu"
          target="_blank"
          className="text-xs font-semibold text-blue-500"
        >
          Contact Support
        </a>
      </div>
    </div>
  );
};
