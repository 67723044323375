import { IamUserStatus } from 'src/api/client';

export enum UserActions {
  EDIT,
  RESEND_INVITATION,
  DELETE,
  SUSPEND,
  RESET_PASSWORD,
  RESET_MFA,
  ACTIVATE,
}

export default ({
  status,
  action,
}: {
  status?: IamUserStatus;
  action: UserActions;
}) => {
  switch (status) {
    case IamUserStatus.Created:
      return [
        UserActions.EDIT,
        UserActions.RESEND_INVITATION,
        UserActions.DELETE,
      ].includes(action);
    case IamUserStatus.Active:
      return [
        UserActions.EDIT,
        UserActions.RESET_PASSWORD,
        UserActions.RESET_MFA,
        UserActions.SUSPEND,
        UserActions.DELETE,
      ].includes(action);
    case IamUserStatus.Suspended:
      return [
        UserActions.EDIT,
        UserActions.RESET_PASSWORD,
        UserActions.RESET_MFA,
        UserActions.ACTIVATE,
        UserActions.DELETE,
      ].includes(action);
    case IamUserStatus.Deleted:
      return false;
    default:
      return false;
  }
};
