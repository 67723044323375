import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LayoutApp } from 'src/layouts/app/LayoutApp';
import { AuthProvider } from 'src/auth/context';
// import { DueDiligence } from 'src/domain/due-diligence/DueDiligence';
import { Container as LoginContainer } from 'src/domain/auth/login/Container';
import { Onboarding } from 'src/domain/auth/onboarding/Onboarding';
import { Container as ForgotPassword } from 'src/domain/auth/forgot-password/Container';
import { Container as ResetPassword } from 'src/domain/auth/reset-password/Container';
import { Container as LogoutContainer } from 'src/domain/auth/logout/Container';
import { Container as SuspendedContainer } from 'src/domain/auth/suspended/Container';
import { PrivateRoute } from '../auth/PrivateRoute';
import { PublicRoute } from '../auth/PublicRoute';
import { AuthContainer } from './AuthContainer';
import { TransactionManagement } from 'src/domain/transaction-management/TransactionManagement';
import { AppErrorBoundary } from './AppErrorBoundary';
import { Cards } from 'src/domain/cards/Cards';
import { Payees } from 'src/domain/cards/Payees';
import { AccessManagement } from 'src/domain/access-management/AccessManagement';
import { UserProfile } from 'src/domain/user-profile/UserProfile';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DisputeResolution } from 'src/domain/dispute-resolution/DisputeResolution';
import { Logs } from 'src/domain/logs/Logs';
import { Reports } from 'src/domain/reports/Reports';
import { ResetMFA } from 'src/domain/user-profile/security/ResetMFA';
import { Consent } from './Consent';
import { PrivacyPolicy } from './PrivacyPolicy';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

moment.locale('en', {
  relativeTime: {
    m: '1 minute',
    h: '1 hour',
  },
});

Modal.setAppElement('#root');

export const Routes = () => {
  return (
    <Switch>
      <PublicRoute path="/login">
        <QueryClientProvider client={queryClient}>
          <LoginContainer />
        </QueryClientProvider>
      </PublicRoute>
      <PublicRoute path="/reset-password">
        <ResetPassword />
      </PublicRoute>
      <PublicRoute path="/onboarding">
        <QueryClientProvider client={queryClient}>
          <Onboarding />
        </QueryClientProvider>
      </PublicRoute>
      <PublicRoute path="/forgot-password">
        <ForgotPassword />
      </PublicRoute>

      <Route path="/logout">
        <LogoutContainer />
      </Route>

      <Route path="/no-access">
        <SuspendedContainer />
      </Route>

      <Route path="/privacy">
        <PrivacyPolicy />
      </Route>

      <PrivateRoute path="/">
        <AuthContainer>
          <LayoutApp>
            <div className="h-full">
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <AppErrorBoundary>
                  <Switch>
                    <Route path="/access-management">
                      <AccessManagement />
                    </Route>
                    <Route path="/transaction-management">
                      <TransactionManagement />
                    </Route>
                    <Route path="/dispute-resolution">
                      <DisputeResolution />
                    </Route>
                    <Route path="/cards">
                      <Cards />
                    </Route>
                    <Route path="/payees">
                      <Payees />
                    </Route>
                    <Route path="/logs">
                      <Logs />
                    </Route>
                    <Route path="/reports">
                      <Reports />
                    </Route>
                    <Route path="/user-profile">
                      <UserProfile />
                    </Route>
                    <Route path="/">Dashboard</Route>
                  </Switch>
                </AppErrorBoundary>
              </QueryClientProvider>
            </div>
          </LayoutApp>

          <ResetMFA isCheckFinished />

        </AuthContainer>
      </PrivateRoute>
    </Switch>
  );
};

export const App = () => {
  return (
    <AuthProvider
      onAuth={(args) => {
        import('src/api').then(({ configureApi }) => {
          configureApi(args);
        });
      }}
      onLogout={() => {
        import('src/api').then(({ destroyApi }) => {
          destroyApi();
        });
      }}
    >
      <Router>
        <Routes />
      </Router>
      <Consent />
    </AuthProvider>
  );
};
