import React from 'react';

export const IconPen = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.95341 12.8819L2 14L3.11814 10.0466L10.7454 2.4193C11.3045 1.86023 12.183 1.86023 12.7022 2.4193L13.5807 3.2579C14.1398 3.81697 14.1398 4.69551 13.5807 5.21464L5.95341 12.8819Z"
      stroke="#157BFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4625 6.37273L9.62726 3.57739"
      stroke="#157BFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
