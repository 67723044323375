/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamUserStatus,
    IamUserStatusFromJSON,
    IamUserStatusFromJSONTyped,
    IamUserStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListIamUsersRequest
 */
export interface ListIamUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof ListIamUsersRequest
     */
    cursor?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIamUsersRequest
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof ListIamUsersRequest
     */
    accessChannelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIamUsersRequest
     */
    iamRoleId?: string;
    /**
     * 
     * @type {IamUserStatus}
     * @memberof ListIamUsersRequest
     */
    status?: IamUserStatus;
}

export function ListIamUsersRequestFromJSON(json: any): ListIamUsersRequest {
    return ListIamUsersRequestFromJSONTyped(json, false);
}

export function ListIamUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'accessChannelId': !exists(json, 'access_channel_id') ? undefined : json['access_channel_id'],
        'iamRoleId': !exists(json, 'iam_role_id') ? undefined : json['iam_role_id'],
        'status': !exists(json, 'status') ? undefined : IamUserStatusFromJSON(json['status']),
    };
}

export function ListIamUsersRequestToJSON(value?: ListIamUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor': value.cursor,
        'limit': value.limit,
        'access_channel_id': value.accessChannelId,
        'iam_role_id': value.iamRoleId,
        'status': IamUserStatusToJSON(value.status),
    };
}


