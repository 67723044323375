import { QueryFunctionContext, useQuery } from 'react-query';
import {
  companiesApi,
  programsApi,
  rolesApi,
  securityProfilesApi,
} from 'src/api';
import {
  Company,
  IamRole,
  IamSecurityProfiles,
  ListCompaniesResponse,
  Program,
} from 'src/api/client';
import { useMemo } from 'react';
import { transformToOptions } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/FormTwo';

export const useOptionsQueries = ({
  accessChannelId,
  id = '',
}: {
  accessChannelId?: string;
  id?: string;
}) => {
  const { data: programsResponse, isLoading: isLoadingPrograms } = useQuery(
    ['ACCESS_MANAGEMENT/USERS/PROGRAMS', id],
    () =>
      programsApi.postProgramsList({
        listProgramsRequest: {},
      }),
    { retry: 1, enabled: false }
  );

  const {
    data: companiesResponse,
    isLoading: isLoadingCompanies,
  } = useQuery<ListCompaniesResponse>(
    ['ACCESS_MANAGEMENT/USERS/COMPANIES', id],
    () =>
      companiesApi.postCompaniesList({
        listCompaniesRequest: {},
      }),
    { retry: 1 }
  );

  const { data: rolesResponse, isLoading: isLoadingRoles } = useQuery(
    ['ACCESS_MANAGEMENT/USERS/ROLES', { accessChannelId, id }],
    ({
      queryKey: [, { accessChannelId: _accessChannelId }],
    }: QueryFunctionContext<[string, { accessChannelId?: string }]>) => {
      if (!_accessChannelId) {
        return;
      }
      return rolesApi.postIamRolesList({
        listIamRolesRequest: {
          accessChannelId: _accessChannelId,
        },
      });
    },
    { retry: 1 }
  );

  const {
    data: securityProfileResponse,
    isLoading: isLoadingSecurityProfile,
  } = useQuery(
    ['ACCESS_MANAGEMENT/USERS/SECURITY_PROFILE', { accessChannelId, id }],
    ({
      queryKey: [, { accessChannelId: _accessChannelId }],
    }: QueryFunctionContext<[string, { accessChannelId?: string }]>) => {
      if (!_accessChannelId) {
        return;
      }
      return securityProfilesApi.postIamSecurityProfiles({
        listIamSecurityProfilesRequest: {
          accessChannelId: _accessChannelId,
        },
      });
    },
    { retry: 1 }
  );

  const organizationsOptions = useMemo(
    () =>
      companiesResponse?.companies
        ? transformToOptions<Company>(companiesResponse?.companies, 'shortName')
        : [],
    [companiesResponse]
  );

  const rolesOptions = useMemo(
    () =>
      rolesResponse &&
      transformToOptions<IamRole>(rolesResponse?.iamRoles, 'roleName'),
    [rolesResponse]
  );

  const rolesObj = useMemo(
    () =>
      rolesOptions && rolesOptions.reduce<{ [key: string]: string }>((acc, curr) => { acc[curr.value] = curr.label; return acc; }, {}),
    [rolesOptions]
  );

  const programsOptions = useMemo(
    () =>
      programsResponse &&
      transformToOptions<Program>(programsResponse?.programs, 'programName'),
    [programsResponse]
  );

  const securityProfileOptions = useMemo(
    () =>
      securityProfileResponse &&
      transformToOptions<IamSecurityProfiles>(
        securityProfileResponse?.iamSecurityProfiles,
        'name'
      ),
    [securityProfileResponse]
  );

  return {
    organizationsOptions,
    rolesOptions,
    programsOptions,
    securityProfileOptions,
    isLoadingCompanies,
    isLoadingRoles,
    isLoadingPrograms,
    isLoadingSecurityProfile,
    companiesResponse,
    securityProfileResponse,
    rolesObj,
  };
};
