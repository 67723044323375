import React, { useContext, useEffect } from 'react';
import { Input, onKeyPressAllowPattern } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm } from 'react-hook-form';

export const FilterReferenceNumber = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { register, watch, setValue } = useForm<{
    referenceNumber: string;
  }>();

  const { referenceNumber } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_REFERENCE_NUMBER_FILTER',
      referenceNumber,
    });
  }, [referenceNumber, dispatch]);

  useEffect(() => {
    setValue('referenceNumber', filters.referenceNumber);
  }, [filters.referenceNumber, setValue]);

  return (
    <Input
      disabled
      label={t('disputeResolution.chargebacks.filters.referenceNumber')}
      name="referenceNumber"
      labelFontClassName="text-xxs font-medium"
      labelColorClassName="text-gray-700"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressAllowPattern(/[a-zA-Z0-9-_]/g)}
      inputRef={register}
    />
  );
};
