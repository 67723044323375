/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IamRoleAssignPermissionRequest
 */
export interface IamRoleAssignPermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof IamRoleAssignPermissionRequest
     */
    iamRoleId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IamRoleAssignPermissionRequest
     */
    iamPermissionIds: Array<string>;
}

export function IamRoleAssignPermissionRequestFromJSON(json: any): IamRoleAssignPermissionRequest {
    return IamRoleAssignPermissionRequestFromJSONTyped(json, false);
}

export function IamRoleAssignPermissionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IamRoleAssignPermissionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamRoleId': json['iam_role_id'],
        'iamPermissionIds': json['iam_permission_ids'],
    };
}

export function IamRoleAssignPermissionRequestToJSON(value?: IamRoleAssignPermissionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iam_role_id': value.iamRoleId,
        'iam_permission_ids': value.iamPermissionIds,
    };
}


