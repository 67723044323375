import { QueryFunctionContext } from 'react-query';
import { Sorting } from 'src/components/table/Table';

export interface LogsItems {
  id: number;
  roleName: string;
  user: {
    avatar: string;
    name: string;
  };
  date: string;
  action: string;
}

export type RowsPerPage = 20 | 50 | 100;

export type LogsQueryOptions = {
  limit: RowsPerPage;
  offset?: number;
  page?: number;
};

export const INITIAL_LOGS_OPTIONS: Pick<LogsQueryOptions, 'page'> = {
  page: 1,
};

interface Params extends QueryFunctionContext {
  queryKey: [
    string,
    { searchQuery: string; limit: RowsPerPage; sorting?: Sorting }
  ];
}

export const getLogs = async (params: Params) => {
  const { queryKey, pageParam } = params;
  const { limit = 20, searchQuery, sorting } = queryKey[1];

  console.log({ pageParam, limit, searchQuery, sorting });

  const result = await fetch('https://api.mocki.io/v1/625e9170', {
    method: 'GET',
  });

  return await result.json();
};
