/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetChargebackDetailsResponse
 */
export interface GetChargebackDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    recordType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    hierarchyId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    hierarchyLevelNo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    locationDBAName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    storeNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    disputeType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    requestType?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    statusDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    statusDescription?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    requestDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    chargebackCurrencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof GetChargebackDetailsResponse
     */
    disputeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    reasonCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    reasonCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    idsCaseNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    acquirerReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    chargebackCycle?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    firstChargebackDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    secondChargebackDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    cardType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    cardNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    expDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    adjustmentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    transactionDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    batchDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    batchNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    processedTransactionCurrencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof GetChargebackDetailsResponse
     */
    processedTransactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    authorizationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    airlineTicketNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    sicCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    receiveDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetChargebackDetailsResponse
     */
    adjustmentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    subMerchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    softDescriptor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    specialReference2?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    submittedTransactionCurrencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof GetChargebackDetailsResponse
     */
    submittedTransactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    transactionServiceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    transactionPOSEntryMode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    workType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    loanNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    workOrderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    mobileIndicator?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    userData2?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    specialReference1?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    cbResponseReceived?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    currentStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChargebackDetailsResponse
     */
    respondedOnDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    debitNetworkId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    debitNetworkDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    customTracking?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChargebackDetailsResponse
     */
    filler?: string;
}

export function GetChargebackDetailsResponseFromJSON(json: any): GetChargebackDetailsResponse {
    return GetChargebackDetailsResponseFromJSONTyped(json, false);
}

export function GetChargebackDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChargebackDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordType': !exists(json, 'recordType') ? undefined : json['recordType'],
        'hierarchyId': !exists(json, 'hierarchyId') ? undefined : json['hierarchyId'],
        'hierarchyLevelNo': !exists(json, 'hierarchyLevelNo') ? undefined : json['hierarchyLevelNo'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'locationDBAName': !exists(json, 'locationDBAName') ? undefined : json['locationDBAName'],
        'storeNumber': !exists(json, 'storeNumber') ? undefined : json['storeNumber'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'disputeType': !exists(json, 'disputeType') ? undefined : json['disputeType'],
        'requestType': !exists(json, 'requestType') ? undefined : json['requestType'],
        'statusDate': !exists(json, 'statusDate') ? undefined : (new Date(json['statusDate'])),
        'statusDescription': !exists(json, 'statusDescription') ? undefined : json['statusDescription'],
        'requestDate': !exists(json, 'requestDate') ? undefined : (new Date(json['requestDate'])),
        'chargebackCurrencyCode': !exists(json, 'chargebackCurrencyCode') ? undefined : json['chargebackCurrencyCode'],
        'disputeAmount': !exists(json, 'disputeAmount') ? undefined : json['disputeAmount'],
        'reasonCode': !exists(json, 'reasonCode') ? undefined : json['reasonCode'],
        'reasonCodeDescription': !exists(json, 'reasonCodeDescription') ? undefined : json['reasonCodeDescription'],
        'idsCaseNumber': !exists(json, 'idsCaseNumber') ? undefined : json['idsCaseNumber'],
        'dueDate': !exists(json, 'dueDate') ? undefined : (new Date(json['dueDate'])),
        'acquirerReferenceNumber': !exists(json, 'acquirerReferenceNumber') ? undefined : json['acquirerReferenceNumber'],
        'chargebackCycle': !exists(json, 'chargebackCycle') ? undefined : json['chargebackCycle'],
        'firstChargebackDate': !exists(json, 'firstChargebackDate') ? undefined : (new Date(json['firstChargebackDate'])),
        'secondChargebackDate': !exists(json, 'secondChargebackDate') ? undefined : (new Date(json['secondChargebackDate'])),
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'expDate': !exists(json, 'expDate') ? undefined : (new Date(json['expDate'])),
        'adjustmentDate': !exists(json, 'adjustmentDate') ? undefined : (new Date(json['adjustmentDate'])),
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'batchDate': !exists(json, 'batchDate') ? undefined : (new Date(json['batchDate'])),
        'batchNumber': !exists(json, 'batchNumber') ? undefined : json['batchNumber'],
        'processedTransactionCurrencyCode': !exists(json, 'processedTransactionCurrencyCode') ? undefined : json['processedTransactionCurrencyCode'],
        'processedTransactionAmount': !exists(json, 'processedTransactionAmount') ? undefined : json['processedTransactionAmount'],
        'authorizationCode': !exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'invoiceNumber': !exists(json, 'invoiceNumber') ? undefined : json['invoiceNumber'],
        'airlineTicketNumber': !exists(json, 'airlineTicketNumber') ? undefined : json['airlineTicketNumber'],
        'sicCode': !exists(json, 'sicCode') ? undefined : json['sicCode'],
        'receiveDate': !exists(json, 'receiveDate') ? undefined : (new Date(json['receiveDate'])),
        'adjustmentAmount': !exists(json, 'adjustmentAmount') ? undefined : json['adjustmentAmount'],
        'subMerchantId': !exists(json, 'subMerchantId') ? undefined : json['subMerchantId'],
        'softDescriptor': !exists(json, 'softDescriptor') ? undefined : json['softDescriptor'],
        'specialReference2': !exists(json, 'specialReference2') ? undefined : json['specialReference2'],
        'submittedTransactionCurrencyCode': !exists(json, 'submittedTransactionCurrencyCode') ? undefined : json['submittedTransactionCurrencyCode'],
        'submittedTransactionAmount': !exists(json, 'submittedTransactionAmount') ? undefined : json['submittedTransactionAmount'],
        'transactionServiceCode': !exists(json, 'transactionServiceCode') ? undefined : json['transactionServiceCode'],
        'transactionPOSEntryMode': !exists(json, 'transactionPOSEntryMode') ? undefined : json['transactionPOSEntryMode'],
        'workType': !exists(json, 'workType') ? undefined : json['workType'],
        'loanNumber': !exists(json, 'loanNumber') ? undefined : json['loanNumber'],
        'workOrderNumber': !exists(json, 'workOrderNumber') ? undefined : json['workOrderNumber'],
        'mobileIndicator': !exists(json, 'mobileIndicator') ? undefined : json['mobileIndicator'],
        'userData2': !exists(json, 'userData2') ? undefined : json['userData2'],
        'specialReference1': !exists(json, 'specialReference1') ? undefined : json['specialReference1'],
        'cbResponseReceived': !exists(json, 'cbResponseReceived') ? undefined : json['cbResponseReceived'],
        'currentStatus': !exists(json, 'currentStatus') ? undefined : json['currentStatus'],
        'respondedOnDate': !exists(json, 'respondedOnDate') ? undefined : (new Date(json['respondedOnDate'])),
        'debitNetworkId': !exists(json, 'debitNetworkId') ? undefined : json['debitNetworkId'],
        'debitNetworkDescription': !exists(json, 'debitNetworkDescription') ? undefined : json['debitNetworkDescription'],
        'customTracking': !exists(json, 'customTracking') ? undefined : json['customTracking'],
        'filler': !exists(json, 'filler') ? undefined : json['filler'],
    };
}

export function GetChargebackDetailsResponseToJSON(value?: GetChargebackDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordType': value.recordType,
        'hierarchyId': value.hierarchyId,
        'hierarchyLevelNo': value.hierarchyLevelNo,
        'locationId': value.locationId,
        'locationDBAName': value.locationDBAName,
        'storeNumber': value.storeNumber,
        'externalId': value.externalId,
        'disputeType': value.disputeType,
        'requestType': value.requestType,
        'statusDate': value.statusDate === undefined ? undefined : (value.statusDate.toISOString().substr(0,10)),
        'statusDescription': value.statusDescription,
        'requestDate': value.requestDate === undefined ? undefined : (value.requestDate.toISOString().substr(0,10)),
        'chargebackCurrencyCode': value.chargebackCurrencyCode,
        'disputeAmount': value.disputeAmount,
        'reasonCode': value.reasonCode,
        'reasonCodeDescription': value.reasonCodeDescription,
        'idsCaseNumber': value.idsCaseNumber,
        'dueDate': value.dueDate === undefined ? undefined : (value.dueDate.toISOString().substr(0,10)),
        'acquirerReferenceNumber': value.acquirerReferenceNumber,
        'chargebackCycle': value.chargebackCycle,
        'firstChargebackDate': value.firstChargebackDate === undefined ? undefined : (value.firstChargebackDate.toISOString().substr(0,10)),
        'secondChargebackDate': value.secondChargebackDate === undefined ? undefined : (value.secondChargebackDate.toISOString().substr(0,10)),
        'cardType': value.cardType,
        'cardNumber': value.cardNumber,
        'expDate': value.expDate === undefined ? undefined : (value.expDate.toISOString().substr(0,10)),
        'adjustmentDate': value.adjustmentDate === undefined ? undefined : (value.adjustmentDate.toISOString().substr(0,10)),
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString().substr(0,10)),
        'batchDate': value.batchDate === undefined ? undefined : (value.batchDate.toISOString().substr(0,10)),
        'batchNumber': value.batchNumber,
        'processedTransactionCurrencyCode': value.processedTransactionCurrencyCode,
        'processedTransactionAmount': value.processedTransactionAmount,
        'authorizationCode': value.authorizationCode,
        'invoiceNumber': value.invoiceNumber,
        'airlineTicketNumber': value.airlineTicketNumber,
        'sicCode': value.sicCode,
        'receiveDate': value.receiveDate === undefined ? undefined : (value.receiveDate.toISOString().substr(0,10)),
        'adjustmentAmount': value.adjustmentAmount,
        'subMerchantId': value.subMerchantId,
        'softDescriptor': value.softDescriptor,
        'specialReference2': value.specialReference2,
        'submittedTransactionCurrencyCode': value.submittedTransactionCurrencyCode,
        'submittedTransactionAmount': value.submittedTransactionAmount,
        'transactionServiceCode': value.transactionServiceCode,
        'transactionPOSEntryMode': value.transactionPOSEntryMode,
        'workType': value.workType,
        'loanNumber': value.loanNumber,
        'workOrderNumber': value.workOrderNumber,
        'mobileIndicator': value.mobileIndicator,
        'userData2': value.userData2,
        'specialReference1': value.specialReference1,
        'cbResponseReceived': value.cbResponseReceived,
        'currentStatus': value.currentStatus,
        'respondedOnDate': value.respondedOnDate === undefined ? undefined : (value.respondedOnDate.toISOString().substr(0,10)),
        'debitNetworkId': value.debitNetworkId,
        'debitNetworkDescription': value.debitNetworkDescription,
        'customTracking': value.customTracking,
        'filler': value.filler,
    };
}


