import React, { useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { store } from 'react-notifications-component';

import { FormTwo } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/FormTwo';
import { FormOne } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/FormOne';
import { LinkBack } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/LinkBack';
import { FormModal } from 'src/components/FormModal';
import { typeProxy } from 'src/utils/nameof';

import { rolePermissionsApi, rolesApi } from 'src/api';
import {
  PostIamRoleEditRequest,
  PostIamRolesAssignPermissionsRequest,
  PostIamRolesCreateRequest,
} from 'src/api/client';
import forEach from 'lodash/forEach';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { useTranslation } from 'react-i18next';

export const COUNT_ROLE_STEPS = 2;

export interface AddRoleFields {
  name: string;
  description: string;
  channel: { value?: string; label?: any };
  reason: string;
  permissions: any;
}

export const addRoleFormType = typeProxy<AddRoleFields>();

interface Props {
  roleId?: string | null;
  visible: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  defaultValues?: any;
}

export const AddRoleModal = ({
  roleId,
  visible,
  defaultValues,
  closeModal,
  onSubmit,
}: Props) => {
  const methods = useForm<AddRoleFields>({
    mode: 'onBlur',
    defaultValues,
  });

  const { t } = useTranslation();

  const { handleSubmit } = methods;
  const [activeStep, setActiveStep] = React.useState(0);
  const [isVisibleCancelModal, setVisibleCancelModal] = useState(false);
  const [error, setError] = useState();

  const onCloseCancelModal = useCallback(() => {
    setVisibleCancelModal(false);
    setActiveStep(0);
    closeModal();
  }, [closeModal]);

  const { data: rolePermissions } = useQuery(
    ['postIamRolePermissionsList', roleId],
    () =>
      rolePermissionsApi.postIamRolePermissionsList({
        listIamRolePermissionsRequest: {
          iamRoleId: roleId || '',
        },
      }),
    {
      enabled: !!roleId,
      retry: false,
    }
  );

  const rolePermissionsList = useMemo(() => {
    let permissions = {};

    forEach(rolePermissions?.iamRolePermissions, (item) => {
      // @ts-ignore
      permissions[item.permissionId] = true;
    });

    return permissions;
  }, [rolePermissions]);

  const {
    mutateAsync: roleCreateMutateAsync,
    isLoading: roleCreateIsLoading,
  } = useMutation(
    (args: PostIamRolesCreateRequest) => rolesApi.postIamRolesCreate(args),
    {
      onError: async (error) => {
        // @ts-ignore
        const err = await error.text();
        setError(JSON.parse(err));
      },
    }
  );
  const {
    mutateAsync: roleUpdateMutateAsync,
    isLoading: roleUpdateIsLoading,
  } = useMutation(
    (args: PostIamRoleEditRequest) => rolesApi.postIamRoleEdit(args),
    {
      onError: async (error) => {
        // @ts-ignore
        const err = await error.text();
        setError(JSON.parse(err));
      },
    }
  );

  const {
    mutateAsync: rolesAssignPermissionsMutateAsync,
    isLoading: rolesAssignPermissionsIsLoading,
  } = useMutation(
    (args: PostIamRolesAssignPermissionsRequest) =>
      rolesApi.postIamRolesAssignPermissions(args),
    {
      onError: async (error) => {
        // @ts-ignore
        const err = await error.text();
        setError(JSON.parse(err));
      },
    }
  );

  const handleFormFinish = useCallback(
    async (values: AddRoleFields) => {
      let roleIdCreated: string = '';
      if (!roleId) {
        const result = await roleCreateMutateAsync({
          createIamRoleRequest: {
            name: values.name,
            description: values.description,
            accessChannelId: values.channel.value!,
          },
        });

        roleIdCreated = result.iamRoleId!;
      } else {
        await roleUpdateMutateAsync({
          updateIamRoleRequest: {
            id: roleId,
            roleName: values.name,
            roleDescription: values.description,
          },
        });
      }

      await rolesAssignPermissionsMutateAsync({
        // @ts-ignore
        iamRoleAssignPermissionRequest: {
          iamRoleId: (roleIdCreated || roleId)!,
          iamPermissionIds: Object.keys(values.permissions).filter(
            (permission) => values.permissions[permission]
          ),
        },
      });

      store.addNotification({
        message: 'Success',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });

      onSubmit();
      setActiveStep(0);
    },
    [roleCreateMutateAsync, onSubmit, roleId, roleUpdateMutateAsync, rolesAssignPermissionsMutateAsync]
  );

  const handleNext = useCallback(async () => {
    if (activeStep < COUNT_ROLE_STEPS - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [activeStep]);

  const handleBack = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }, [activeStep]);

  return (
    <FormProvider {...methods}>
      <div>
        <FormModal
          isOpen={visible}
          closeModal={() => {
            setVisibleCancelModal(true);
          }}
          maxWidth="auto"
          width="500px"
          title={activeStep > 0 && <LinkBack onClick={handleBack} />}
        >
          <form onSubmit={handleSubmit(handleFormFinish)}>
            <FormOne
              roleId={roleId}
              currentStep={activeStep}
              buttonClick={handleNext}
              isDefaultValues={!!defaultValues}
            />
            <FormTwo
              roleId={roleId}
              error={error}
              currentStep={activeStep}
              isDefaultValues={!!defaultValues}
              rolePermissionsList={rolePermissionsList}
              isLoading={
                roleCreateIsLoading ||
                roleUpdateIsLoading ||
                rolesAssignPermissionsIsLoading
              }
            />
          </form>
        </FormModal>

        <Modal
          isOpen={isVisibleCancelModal}
          closeModal={onCloseCancelModal}
          isOverlayDisabled
          maxWidth="auto"
          width="430px"
        >
          <div>
            <div className={'font-bold mb-2 text-center text-2xl'}>
              {t('accessManagement.cancel')}
            </div>

            <p className="text-sm text-center text-gray-700">
              {roleId
                ? t('accessManagement.cancelEditDescription')
                : t('accessManagement.cancelDescription')}
            </p>

            <div className="flex justify-between mt-8">
              <Button
                variant="primary"
                size="medium-large"
                widthClass="w-1/2"
                className="mr-2"
                onClick={() => {
                  setVisibleCancelModal(false);
                }}
              >
                {t('accessManagement.confirmProceed')}
              </Button>
              <Button
                variant="secondary"
                size="medium-large"
                widthClass="w-1/2"
                className="ml-2"
                onClick={onCloseCancelModal}
              >
                {t('accessManagement.confirmCancel')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </FormProvider>
  );
};
