/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IamRole
 */
export interface IamRole {
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamRole
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    createdByName?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamRole
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    updatedByName?: string;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    roleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    accessChannelId?: string;
    /**
     * 
     * @type {string}
     * @memberof IamRole
     */
    status?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamRole
     */
    statusDate?: Date;
}

export function IamRoleFromJSON(json: any): IamRole {
    return IamRoleFromJSONTyped(json, false);
}

export function IamRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): IamRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'createdByName': !exists(json, 'created_by_name') ? undefined : json['created_by_name'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'updatedByName': !exists(json, 'updated_by_name') ? undefined : json['updated_by_name'],
        'updatedBy': !exists(json, 'updated_by') ? undefined : json['updated_by'],
        'roleName': !exists(json, 'role_name') ? undefined : json['role_name'],
        'roleDescription': !exists(json, 'role_description') ? undefined : json['role_description'],
        'accessChannelId': !exists(json, 'access_channel_id') ? undefined : json['access_channel_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDate': !exists(json, 'status_date') ? undefined : (new Date(json['status_date'])),
    };
}

export function IamRoleToJSON(value?: IamRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'created_by_name': value.createdByName,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updated_by_name': value.updatedByName,
        'updated_by': value.updatedBy,
        'role_name': value.roleName,
        'role_description': value.roleDescription,
        'access_channel_id': value.accessChannelId,
        'status': value.status,
        'status_date': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
    };
}


