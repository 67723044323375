/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTransactionDetailsResponse
 */
export interface GetTransactionDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    hierarchyId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    bdaName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    storeNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    terminalId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    subMerchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    cardBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetTransactionDetailsResponse
     */
    settlementDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    batchNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetTransactionDetailsResponse
     */
    batchDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    authorizationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    stan?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    arn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    rrn?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetTransactionDetailsResponse
     */
    transactionDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionDetailsResponse
     */
    settlementAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    settlementCurrencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionDetailsResponse
     */
    billingAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    billingCurrencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionDetailsResponse
     */
    currencyConversionRate?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    transactionStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    transactionType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    transactionMode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    transactionSource?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    transactionTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    matchType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    entryMode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    pcTerminalCapability?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    posEntryMode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    partialAuthIndicator?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionDetailsResponse
     */
    referenceNumber?: string;
}

export function GetTransactionDetailsResponseFromJSON(json: any): GetTransactionDetailsResponse {
    return GetTransactionDetailsResponseFromJSONTyped(json, false);
}

export function GetTransactionDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTransactionDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hierarchyId': !exists(json, 'hierarchyId') ? undefined : json['hierarchyId'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'bdaName': !exists(json, 'bdaName') ? undefined : json['bdaName'],
        'storeNumber': !exists(json, 'storeNumber') ? undefined : json['storeNumber'],
        'terminalId': !exists(json, 'terminalId') ? undefined : json['terminalId'],
        'subMerchantId': !exists(json, 'subMerchantId') ? undefined : json['subMerchantId'],
        'cardBrand': !exists(json, 'cardBrand') ? undefined : json['cardBrand'],
        'maskedCardNumber': !exists(json, 'maskedCardNumber') ? undefined : json['maskedCardNumber'],
        'settlementDate': !exists(json, 'settlementDate') ? undefined : (new Date(json['settlementDate'])),
        'batchNumber': !exists(json, 'batchNumber') ? undefined : json['batchNumber'],
        'batchDate': !exists(json, 'batchDate') ? undefined : (new Date(json['batchDate'])),
        'authorizationCode': !exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'stan': !exists(json, 'stan') ? undefined : json['stan'],
        'arn': !exists(json, 'arn') ? undefined : json['arn'],
        'rrn': !exists(json, 'rrn') ? undefined : json['rrn'],
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'settlementAmount': !exists(json, 'settlementAmount') ? undefined : json['settlementAmount'],
        'settlementCurrencyCode': !exists(json, 'settlementCurrencyCode') ? undefined : json['settlementCurrencyCode'],
        'billingAmount': !exists(json, 'billingAmount') ? undefined : json['billingAmount'],
        'billingCurrencyCode': !exists(json, 'billingCurrencyCode') ? undefined : json['billingCurrencyCode'],
        'currencyConversionRate': !exists(json, 'currencyConversionRate') ? undefined : json['currencyConversionRate'],
        'transactionStatus': !exists(json, 'transactionStatus') ? undefined : json['transactionStatus'],
        'transactionType': !exists(json, 'transactionType') ? undefined : json['transactionType'],
        'transactionMode': !exists(json, 'transactionMode') ? undefined : json['transactionMode'],
        'transactionSource': !exists(json, 'transactionSource') ? undefined : json['transactionSource'],
        'transactionTime': !exists(json, 'transactionTime') ? undefined : json['transactionTime'],
        'matchType': !exists(json, 'matchType') ? undefined : json['matchType'],
        'entryMode': !exists(json, 'entryMode') ? undefined : json['entryMode'],
        'pcTerminalCapability': !exists(json, 'pcTerminalCapability') ? undefined : json['pcTerminalCapability'],
        'posEntryMode': !exists(json, 'posEntryMode') ? undefined : json['posEntryMode'],
        'partialAuthIndicator': !exists(json, 'partialAuthIndicator') ? undefined : json['partialAuthIndicator'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'referenceNumber': !exists(json, 'referenceNumber') ? undefined : json['referenceNumber'],
    };
}

export function GetTransactionDetailsResponseToJSON(value?: GetTransactionDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hierarchyId': value.hierarchyId,
        'locationId': value.locationId,
        'bdaName': value.bdaName,
        'storeNumber': value.storeNumber,
        'terminalId': value.terminalId,
        'subMerchantId': value.subMerchantId,
        'cardBrand': value.cardBrand,
        'maskedCardNumber': value.maskedCardNumber,
        'settlementDate': value.settlementDate === undefined ? undefined : (value.settlementDate.toISOString().substr(0,10)),
        'batchNumber': value.batchNumber,
        'batchDate': value.batchDate === undefined ? undefined : (value.batchDate.toISOString().substr(0,10)),
        'authorizationCode': value.authorizationCode,
        'stan': value.stan,
        'arn': value.arn,
        'rrn': value.rrn,
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString().substr(0,10)),
        'settlementAmount': value.settlementAmount,
        'settlementCurrencyCode': value.settlementCurrencyCode,
        'billingAmount': value.billingAmount,
        'billingCurrencyCode': value.billingCurrencyCode,
        'currencyConversionRate': value.currencyConversionRate,
        'transactionStatus': value.transactionStatus,
        'transactionType': value.transactionType,
        'transactionMode': value.transactionMode,
        'transactionSource': value.transactionSource,
        'transactionTime': value.transactionTime,
        'matchType': value.matchType,
        'entryMode': value.entryMode,
        'pcTerminalCapability': value.pcTerminalCapability,
        'posEntryMode': value.posEntryMode,
        'partialAuthIndicator': value.partialAuthIndicator,
        'orderNumber': value.orderNumber,
        'referenceNumber': value.referenceNumber,
    };
}


