import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  UsersContext,
  usersFormType,
} from 'src/domain/access-management/user-management/tabs/users/UsersContext';
import { AccessChannel } from 'src/api/client';
import { Tooltip } from 'src/components/Tooltip';
import { IconChevronLeft } from 'src/components/icons/IconChevronLeft';
import { iconAccessChannelsMap } from 'src/domain/access-management/user-management/tabs/users/useUsersColumns';
import {
  ControllerRenderProps,
  useController,
  useFormContext,
} from 'react-hook-form';
import { namesof } from 'src/utils/nameof';

interface ControllerField extends ControllerRenderProps {
  value: AccessChannel | undefined;
}

export const AccessChannelFilter = () => {
  const { t } = useTranslation();
  const { accessChannelList } = useContext(UsersContext);
  const { control, setValue } = useFormContext();

  const {
    field: { value: filter, onChange: onChangeFilter },
  }: { field: ControllerField } = useController({
    control,
    defaultValue: accessChannelList.find((item) => item.name === 'BOO'),
    name: namesof(usersFormType.accessChannelFilter)?.join('.'),
  });

  const channelList: AccessChannel[] = useMemo(
    () => [{ id: 'all', name: t('buttons.ALL') }, ...accessChannelList],
    [t, accessChannelList]
  );

  const handleSelectChannel = (item: AccessChannel) => {
    setValue('cursor', 0);
    if (item.name === filter?.name) {
      return null;
    }

    if (item.id === 'all') {
      onChangeFilter('');
    } else {
      onChangeFilter(item);
    }
  };

  return (
    <Tooltip
      minWidth={250}
      placement="bottom-end"
      withPadding={false}
      content={() => (
        <div className="py-1">
          <div className="uppercase text-blue-500 text-xxxs pt-3 pb-1 px-4">
            {t('accessManagement.userManagement.filters.accessChannel')}
          </div>
          {channelList.map((item, id) => {
            const icon = item.name && iconAccessChannelsMap(item.name);
            return (
              <div
                onClick={() => {
                  handleSelectChannel(item);
                }}
                key={id}
                className="cursor-pointer flex items-center w-full h-5 px-4 py-4 mr-5 font-semibold hover:bg-gray-100"
              >
                {icon}
                <span>{item.name}</span>
              </div>
            );
          })}
        </div>
      )}
    >
      {({ ref, setIsOpen, isOpen }) => (
        <div
          className="w-52 px-2 min-w-min cursor-pointer"
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <span className={'text-gray-700 text-xxs'}>
            {t('accessManagement.userManagement.filters.accessChannel')}
          </span>
          <div className="flex w-full justify-between items-center text-xs text-gray-900 font-semibold">
            {filter ? (
              <span className={'flex items-center w-full'}>
                {filter.name && iconAccessChannelsMap(filter.name)}
                <span className={'truncate'}>{filter.name}</span>
              </span>
            ) : (
              <span className={'truncate'}>{channelList[0].name}</span>
            )}

            <IconChevronLeft
              className={classNames('transform ml-2', {
                '-rotate-90': !isOpen,
                'rotate-90': isOpen,
              })}
            />
          </div>
        </div>
      )}
    </Tooltip>
  );
};
