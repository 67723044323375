/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcquiringAuditLogRequest,
    AcquiringAuditLogRequestFromJSON,
    AcquiringAuditLogRequestToJSON,
    AcquiringAuditLogResponse,
    AcquiringAuditLogResponseFromJSON,
    AcquiringAuditLogResponseToJSON,
    AcquiringAuditLogsListRequest,
    AcquiringAuditLogsListRequestFromJSON,
    AcquiringAuditLogsListRequestToJSON,
    AcquiringAuditLogsListResponse,
    AcquiringAuditLogsListResponseFromJSON,
    AcquiringAuditLogsListResponseToJSON,
} from '../models';

export interface PostLogGetRequest {
    acquiringAuditLogRequest?: AcquiringAuditLogRequest;
}

export interface PostLogListRequest {
    acquiringAuditLogsListRequest?: AcquiringAuditLogsListRequest;
}

/**
 * 
 */
export class AcquiringApi extends runtime.BaseAPI {

    /**
     */
    async postLogGetRaw(requestParameters: PostLogGetRequest): Promise<runtime.ApiResponse<AcquiringAuditLogResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/audit/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcquiringAuditLogRequestToJSON(requestParameters.acquiringAuditLogRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AcquiringAuditLogResponseFromJSON(jsonValue));
    }

    /**
     */
    async postLogGet(requestParameters: PostLogGetRequest): Promise<AcquiringAuditLogResponse> {
        const response = await this.postLogGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postLogListRaw(requestParameters: PostLogListRequest): Promise<runtime.ApiResponse<AcquiringAuditLogsListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/audit/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcquiringAuditLogsListRequestToJSON(requestParameters.acquiringAuditLogsListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AcquiringAuditLogsListResponseFromJSON(jsonValue));
    }

    /**
     */
    async postLogList(requestParameters: PostLogListRequest): Promise<AcquiringAuditLogsListResponse> {
        const response = await this.postLogListRaw(requestParameters);
        return await response.value();
    }

}
