import React, { ReactNode, useCallback, Fragment } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RectLoader } from '../RectLoader';
import { Tooltip } from '../Tooltip';
import { IconEmpty } from '../icons/IconEmpty';
import { IconSortArrow } from 'src/components/icons/IconSortArrow';

export type Column = {
  value: (item: any) => ReactNode;
  headerContent?: () => ReactNode;
  columnFilter?: (item: any) => ReactNode;
  label?: string;
  id: string;
  minWidth?: number;
  width?: number | string;
  tooltip?: string;
  sortable?: boolean;
};

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Sorting = {
  column: Column;
  direction: SortingDirection;
};

const SortButton = ({
  children,
  sorted,
  onClick,
}: {
  children: ReactNode;
  sorted?: boolean;
  onClick: () => any;
}) => (
  <button
    type="button"
    className={classNames('focus:outline-none', { 'text-gray-700': sorted })}
    onClick={onClick}
  >
    {children}
  </button>
);

const Column = ({
  item,
  sorting,
  onSort,
  onColumnHeaderClick,
  withFilters,
  isHeaderFixed,
}: {
  item: Column;
  sorting?: Sorting;
  onSort: (sorting: Sorting) => any;
  onColumnHeaderClick: (column: Column) => any;
  withFilters: boolean;
  isHeaderFixed: boolean;
}) => (
  <th
    className={classNames('sticky z-10 py-2 pl-4 bg-gray-100 border-t-0', [
      isHeaderFixed ? 'top-4' : 'top-0',
    ])}
    style={{ width: item.width }}
  >
    {item.headerContent ? (
      item.headerContent()
    ) : (
      <div style={{ minWidth: item.minWidth }}>
        <div className="flex items-center mb-1">
          <Tooltip content={item.tooltip}>
            {({ ref, setIsOpen }) => (
              <button
                ref={ref}
                onClick={() => onColumnHeaderClick(item)}
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                className="font-light text-gray-700 cursor-pointer text-xxs focus:outline-none"
              >
                {item.label || ' '}
              </button>
            )}
          </Tooltip>
          {item.sortable && (
            <div className="flex flex-col ml-1 text-gray-500">
              <SortButton
                sorted={
                  sorting && sorting.direction === SortingDirection.ASC
                    ? true
                    : false
                }
                onClick={() =>
                  onSort({ column: item, direction: SortingDirection.ASC })
                }
              >
                <IconSortArrow className="w-2" />
              </SortButton>
              <SortButton
                sorted={
                  sorting && sorting.direction === SortingDirection.DESC
                    ? true
                    : false
                }
                onClick={() =>
                  onSort({ column: item, direction: SortingDirection.DESC })
                }
              >
                <IconSortArrow className="w-2 mt-1 transform rotate-180" />
              </SortButton>
            </div>
          )}
        </div>
        {withFilters && (
          <div>
            {item.columnFilter ? (
              item.columnFilter(item)
            ) : (
              <div className="h-4 text-xs text-gray-500">
                {item.label && '--'}
              </div>
            )}
          </div>
        )}
      </div>
    )}
  </th>
);

export const CellBase = ({ children }: { children: ReactNode }) => (
  <span className="block overflow-x-auto text-xs font-medium leading-normal text-gray-900 break-words whitespace-pre-line">
    {children}
  </span>
);

export const CellDateTime = ({ date }: { date: Date }) => {
  const parsedDate = moment(date);

  return (
    <CellLight>
      <div>{parsedDate.format('D MMM YYYY')}</div>
      <div>at {parsedDate.format('HH:mm')}</div>
    </CellLight>
  );
};

export const CellLight = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={classNames('text-gray-700', className)}>{children}</div>;

interface Props {
  items: any[];
  itemExpand?: any;
  itemExpandContent?: ReactNode;
  columns: Column[];
  sorting?: Sorting;
  loading?: boolean;
  withFilters?: boolean;
  onSort?: (sorting?: Sorting) => any;
  onRowClick?: (row: any, index: number) => any;
  isHeaderFixed?: boolean;
}

export const Table = ({
  items,
  itemExpand,
  itemExpandContent,
  columns,
  onSort = () => {},
  sorting,
  loading = false,
  withFilters = true,
  onRowClick = () => {},
  isHeaderFixed = false,
}: Props) => {
  const onColumnSorting = useCallback(
    (columnSorting: Sorting) => {
      onSort(
        columnSorting.column === sorting?.column &&
          columnSorting.direction === sorting?.direction
          ? undefined
          : columnSorting
      );
    },
    [sorting, onSort]
  );
  const { t } = useTranslation();

  const onColumnHeaderClick = useCallback(
    (column: Column) => {
      if (!column.sortable) {
        return;
      }

      let nextSorting: Sorting | undefined = {
        column,
        direction:
          sorting?.direction === SortingDirection.ASC
            ? SortingDirection.DESC
            : SortingDirection.ASC,
      };

      if (
        sorting?.column === column &&
        sorting.direction === SortingDirection.DESC
      ) {
        nextSorting = undefined;
      }

      onSort(nextSorting);
    },
    [sorting, onSort]
  );

  const rowClickHandle = useCallback(
    (item: any, index: number) => {
      if (!loading) {
        onRowClick(item, index);
      }
    },
    [loading, onRowClick]
  );

  return (
    <table className="relative w-full table-auto">
      <thead className="w-full">
        <tr className="text-left">
          {columns.map((item, index) => (
            <Column
              key={index}
              item={item}
              sorting={sorting?.column.id === item.id ? sorting : undefined}
              onSort={onColumnSorting}
              onColumnHeaderClick={onColumnHeaderClick}
              withFilters={withFilters}
              isHeaderFixed={isHeaderFixed}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {!loading && !items.length && (
          <tr>
            <td colSpan={20} className="h-64">
              <div className="flex flex-col items-center justify-center w-full h-full pt-20">
                <div className="mb-5 text-center">
                  <IconEmpty />
                </div>
                <span className="flex flex-col items-center text-xs font-medium text-gray-700">
                  <div>{t('kyc.table.noResults')}</div>
                  <div>{t('kyc.table.tryAgain')}</div>
                </span>
              </div>
            </td>
          </tr>
        )}
        {(loading ? [...Array(10)] : items).map((item, index) => (
          <Fragment key={index}>
            <tr
              className={classNames(
                'transition-colors duration-300 cursor-pointer hover:bg-gray-100',
                {
                  'bg-gray-100': isEqual(item, itemExpand),
                }
              )}
              onClick={() => rowClickHandle(item, index)}
            >
              {columns.map((column, columnIndex) => (
                <td
                  key={`${index}-${columnIndex}`}
                  className={classNames('p-0 relative', {
                    'border-b': !isEqual(item, itemExpand),
                  })}
                >
                  <div
                    className="py-4 pl-4"
                  >
                    {isEqual(item, itemExpand) &&
                      columnIndex === 0 &&
                      !loading && (
                        <div className="absolute top-0 left-0 w-1 h-full bg-blue-500" />
                      )}

                    {loading ? (
                      <RectLoader
                        className={'w-full'}
                        width={100}
                        height={20}
                      />
                    ) : (
                      <CellBase>{column.value(item)}</CellBase>
                    )}
                  </div>
                </td>
              ))}
            </tr>
            {isEqual(item, itemExpand) && (
              <tr className="bg-gray-100">
                <td colSpan={columns.length} className="relative p-0 border-b">
                  <div className="absolute top-0 left-0 w-1 h-full bg-blue-500" />
                  <div className="border-l-4 border-blue-500">
                    {itemExpandContent}
                  </div>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
