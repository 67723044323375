export enum TransactionStatus {
  'APPROVED' = 'A',
  'REJECTED' = 'R',
}

export enum TransactionType {
  'SALE' = '5',
  'CREDIT' = '6',
  'CASH_ADVANCE' = '7',
  'CONDITIONAL_DEPOSIT_SALE' = '8',
  'CONDITIONAL_DEPOSIT_CASH_ADVANCE' = '9',
  'SALE_AUTH_REQUEST' = 'A',
  'CASH_ADVANCE_AUTH_REQUEST' = 'B',
  'AMERICAN_EXPRESS_AUTH_REQUEST' = 'C',
  'CARTE_BLANCHE_AUTH_REQUEST' = 'D',
  'DINERS_CLUB_AUTH_REQUEST' = 'E',
  'JBS_AUTH_REQUEST' = 'F',
  'PRIVATE_LABEL_AUTH_REQUEST' = 'G',
  'ACCOUNT_VERIFY_REQUEST' = 'H',
  'REGULAR_VOID' = 'X',
  'VOID_UNDELIVERED' = 'Y',
  'VOID_REVERSAL' = 'Z',
}

export enum CardType {
  'MASTERCARD' = 'MasterCard',
  'VISA' = 'Visa',
  'AMEX' = 'Amex',
  'JCB' = 'JCB',
  'DISCOVER' = 'Discover',
  'DINERS' = 'Diners',
}

export enum MatchType {
  'PENDING' = 'PENDING',
  'SUCCESS' = 'SUCCESS',
  'DUP' = 'DUP',
  'NEQSTAN' = 'NEQSTAN',
  'NEQAMT' = 'NEQAMT',
  'NEQNWID' = 'NEQNWID',
  'NOAUTH' = 'NOAUTH',
  'NOSTL' = 'NOSTL',
}
