/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListCompaniesRequest
 */
export interface ListCompaniesRequest {
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRequest
     */
    iamUserId?: string;
}

export function ListCompaniesRequestFromJSON(json: any): ListCompaniesRequest {
    return ListCompaniesRequestFromJSONTyped(json, false);
}

export function ListCompaniesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCompaniesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamUserId': !exists(json, 'iam_user_id') ? undefined : json['iam_user_id'],
    };
}

export function ListCompaniesRequestToJSON(value?: ListCompaniesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iam_user_id': value.iamUserId,
    };
}


