import React, { useMemo } from 'react';
import moment from 'moment';
import { CellLight, Column } from 'src/components/table/Table';
import { LogItem } from './context';

export const useColumns = () => {
  const columns = useMemo(
    (): Column[] => [
      {
        id: 'id',
        value: (item: LogItem) => <CellLight>{item.id}</CellLight>,
        label: 'ID',
      },
      {
        id: 'reference',
        value: (item: LogItem) => <CellLight>{item.reference}</CellLight>,
        label: 'Reference',
      },
      {
        id: 'type',
        value: (item: LogItem) => <CellLight>{item.type}</CellLight>,
        label: 'Type',
      },
      {
        id: 'createdAt',
        value: (item: LogItem) => (
          <CellLight>
            {moment(item.createdTime).format('DD MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Created At',
      },
    ],
    []
  );

  return columns;
};
