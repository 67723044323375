import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <section className="pt-20 bg-gray-100 pb-30 text-content">
      <div className="container px-6 mx-auto lg:px-20">
        <div className="pb-24 mb-24 border-b border-gray-800">
          <a href="/">
            <img src="/logo-dark.svg" className="w-42" alt="Prysym" />
          </a>
        </div>{' '}
        <div className="flex flex-col justify-end lg:flex-row">
          <div className="w-2/3 mb-16 font-sans text-lg font-thin">
            <h1 className="text-gray-800 font-bold text-5xl md:text-6.5xl mb-24">
              Privacy Policy
            </h1>
            The protection of your personal data is of particular concern to us.
            We therefore process your data exclusively on the basis of the
            statutory provisions (DSGVO, TKG 2003). In this data protection
            information we inform you about the most important aspects of data
            processing within the framework of our website.
          </div>
        </div>{' '}
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="w-1/3 mb-5 text-lg font-bold tracking-wider text-gray-700 uppercase lg:mb-0">
            Contact with us
          </div>{' '}
          <div className="w-2/3 mb-16 font-sans text-lg font-thin">
            If you contact us via e-mail, your data will be stored for six
            months for the purpose of processing the request and in the event of
            follow-up questions. These data will not be passed on without your
            consent.
          </div>
        </div>{' '}
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="w-1/3 mb-5 text-lg font-bold tracking-wider text-gray-700 uppercase lg:mb-0">
            Cookies
          </div>{' '}
          <div className="w-2/3 mb-16 font-sans text-lg font-thin">
            Our website uses so-called Cookies. These are small text files that
            are stored on your end device with the help of the browser. They do
            not cause any damage.
            <br />
            <br />
            We use Cookies to make our website user-friendly. Some Cookies
            remain stored on your end device until you delete them. They enable
            us to recognise your browser the next time you visit our website.
            <br />
            <br />
            If you do not wish Cookies to be used, you can adjust your browser
            so that it informs you when Cookies are enabled. You can then decide
            on the exceptional activation of Cookies in individual cases.
            <br />
            <br />
            Please note that if you deactivate Cookies, the functionality of our
            website may be restricted.
          </div>
        </div>{' '}
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="w-1/3 mb-5 text-lg font-bold tracking-wider text-gray-700 uppercase lg:mb-0">
            Web-Analysis
          </div>{' '}
          <div className="w-2/3 mb-16 font-sans text-lg font-thin">
            This website uses Google Analytics, a web analytics service provided
            by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
            United States of America ("Google"). Google Analytics uses Cookies,
            which are text files placed on your computer, to help the website
            analyse how users use our website. The information generated by the
            Cookie about your use of the website will generally be transmitted
            to and stored by Google on servers in the United States of America.
            On behalf of the operator of this website, Google will use this
            information to evaluate your use of the website, to compile reports
            on website activity, to provide the website operator with other
            services relating to website activity and internet usage, and to
            compile reports on performance based on demographic characteristics
            and interests.
            <br />
            <br />
            The IP address transmitted by your browser as part of Google
            Analytics is not combined with other data from Google. You may
            refuse the use of Cookies by selecting the appropriate settings on
            your browser. Please note, however, that in this case you may not be
            able to use all functions of our website to their full extent. You
            can also prevent Google from collecting the data generated by the
            Cookie and related to your use of the website (including your IP
            address) and from processing this data by downloading and installing
            the browser plugin available under the following link:
            <br />
            <br />
            <a
              href="http://tools.google.com/dlpage/gaoptout?hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className="pb-px text-blue-500 border-b border-transparent hover:border-blue-500"
            >
              http://tools.google.com/dlpage/gaoptout?hl=en
            </a>
          </div>
        </div>{' '}
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="w-1/3 mb-5 text-lg font-bold tracking-wider text-gray-700 uppercase lg:mb-0">
            Newsletter
          </div>{' '}
          <div className="w-2/3 mb-16 font-sans text-lg font-thin">
            You have the possibility to subscribe to our newsletter via our
            website. To do this, we need your e-mail address and your
            declaration that you agree to receive the newsletter.
            <br />
            <br />
            In order to provide you with targeted information, we also collect
            and process voluntarily provided information on areas of interest,
            birthdays, postal codes, etc.
          </div>
        </div>{' '}
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="w-1/3 mb-5 text-lg font-bold tracking-wider text-gray-700 uppercase lg:mb-0">
            Your rights
          </div>{' '}
          <div className="w-2/3 mb-16 font-sans text-lg font-thin">
            You have the right to information, correction, deletion, limitation,
            data transferability, revocation and objection regarding your data
            stored with us. If you believe that the processing of your data
            violates the data protection law or your data protection claims have
            otherwise been violated in any way, you can contact our data
            protection officer at{' '}
            <a
              href="mailto:privacy@prysym.com"
              target="_blank"
              rel="noopener noreferrer"
              className="pb-px text-blue-500 border-b border-transparent hover:border-blue-500"
            >
              privacy@prysym.com
            </a>{' '}
            or the Austrian Data Protection Authority.
          </div>
        </div>
      </div>
    </section>
  );
};
