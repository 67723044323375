import React from 'react';
import {
  Controller,
  Control,
  UseControllerOptions,
  FieldErrors,
} from 'react-hook-form';
import BaseSelect, {
  OptionTypeBase,
  StylesConfig,
  components as SelectComponents,
} from 'react-select';
import { IconCaretDown } from 'src/components/icons/IconCaretDown';
import { Label, LabelProps } from 'src/components/form/FormLabel';

export interface OptionType {
  value: string;
  label: string;
}

export const DropdownIndicator = (props: any) => (
  <SelectComponents.DropdownIndicator {...props}>
    <IconCaretDown className="mr-1" />
  </SelectComponents.DropdownIndicator>
);

export interface CustomSelectProps extends LabelProps {
  name: string;
  options: OptionTypeBase;
  control: Control;
  rules?: UseControllerOptions['rules'];
  error?: FieldErrors;
  defaultFirst?: boolean;
  defaultValue?: unknown;
  disabled?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  isLoading?: boolean;
}

export const getCustomSelectStyles = (
  error: CustomSelectProps['error']
): StylesConfig<OptionType, boolean> => {
  return {
    container: (base) => ({
      ...base,
      marginTop: '0.25rem',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '0.75rem',
      fontSize: '0.75rem',
      fontWeight: 'bold',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#C8CACE',
      fontWeight: 'bold',
    }),
    control: (base, state) => ({
      ...base,
      // boxShadow: 'none',
      borderRadius: '0.125rem',
      transition: 'border-color 0.3s',
      borderColor: error
        ? '#FC5067 !important'
        : state.selectProps.menuIsOpen
        ? '#157BFA !important'
        : '#EBEAED !important',
      outline: 'none',
      boxShadow: error
        ? '0 0 0 3px rgb(252 80 103 / 30%)'
        : state.selectProps.menuIsOpen
        ? '0 0 0 3px rgba(21, 123, 250, 0.4)!important'
        : 'none',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#2F323A',
    }),
    multiValue: (base) => ({
      ...base,
      background: '#EBEAED',
      fontWeight: 'bold',
      color: '#2F323A',
      fontSize: '.75rem',
    }),
    multiValueLabel: (base) => ({
      ...base,
      position: 'relative',
      top: '1px',
    }),
    multiValueRemove: (base) => ({
      ...base,
      opacity: '0.8',
    }),
    menu: (base) => ({
      ...base,
      border: 'none',
      boxShadow: '0px 8px 20px rgba(47, 50, 58, 0.1)',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: '0.75rem',
      color: state.isSelected ? '#2F323A' : '#87949E',
      background: 'transparent',
      fontWeight: 'bold',
      '&:hover': {
        background: '#F7F9FA',
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
      transition: 'transform 0.3s',
      transformOrigin: '43%',
      color: state.selectProps.menuIsOpen
        ? '#157BFA !important'
        : '#87949E !important',
    }),
  };
};

export const Select = ({
  control,
  name,
  disabled,
  label,
  labelColorClassName,
  labelFontClassName,
  options,
  rules,
  error,
  defaultFirst,
  defaultValue,
  isMulti = false,
  placeholder,
  isLoading,
}: CustomSelectProps) => {
  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={!disabled ? error : undefined}
    >
      <Controller
        isLoading={isLoading}
        placeholder={placeholder}
        error={!disabled ? error : null}
        rules={rules}
        name={name}
        isMulti={isMulti}
        isDisabled={disabled}
        control={control}
        options={options}
        isSearchable={false}
        isClearable={false}
        menuPlacement={'auto'}
        defaultValue={defaultValue || (defaultFirst && options[0]) || ''}
        styles={getCustomSelectStyles(!disabled ? error : undefined)}
        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
        as={BaseSelect}
      />
    </Label>
  );
};
