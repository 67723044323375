export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const oneUpperCase = /(?=.*[A-Z])/;

export const oneLowerCase = /(?=.*[a-z])/;

export const oneDigit = /(?=.*\d)/;

export const oneSpecial = /\W|_/g;

export const oneDigitOrSpecial = /(?=.*\d)|\W|_/g;
