import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IconEye } from '../../../components/icons/IconEye';
import { ReactComponent as IconCopy } from '../../../assets/icons/iconCopy.svg';

import { Input, PasswordInput, AuthCodeInput } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { MfaInfo } from '../mfa-info/MfaInfo';

import { emailPattern } from 'src/validation/patterns';
import { useTranslation } from 'react-i18next';

import { configureApi } from 'src/api';

import {
  getAccountParams,
  getToken as getUserToken,
  login,
  MFARequired,
  NewPasswordRequired,
} from 'src/auth/auth';
import { AuthContext } from 'src/auth/context';
import { LoginStep } from './Container';
import { useMutation } from 'react-query';
import { IamUserStatus } from 'src/api/client';
import { profileApi } from 'src/api';
import find from 'lodash/find';

type LoginFormSchema = {
  email: string;
  password: string;
  totpToken?: string;
  setupTotpToken?: string;
  captchaToken?: string;
  onSetNewPasswordError?: ({ user }: { user: CognitoUser }) => void;
};

export const FormLogin = ({
  onSetNewPasswordError,
  setCodeRequested,
  setIsSetupMFAStep,
  setLoginStep,
  email,
  p,
}: {
  onSetNewPasswordError: ({ user }: { user: CognitoUser }) => void;
  setCodeRequested: (val: boolean) => void;
  setIsSetupMFAStep: (val: boolean) => void;
  setLoginStep: (val: LoginStep) => void;
  email?: string | null;
  p?: string | null;
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    control,
  } = useForm<LoginFormSchema>();

  const state = useContext(AuthContext);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isShowSecretKey, setIsShowSecretKey] = useState(false);
  const [isSetupMFA, setIsSetupMFA] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [qr, setQR] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | any>(null);

  useEffect(() => {
    if (email && p) {
      onSubmit({ email, password: p });
    }
  }, []);

  const { mutateAsync: userStatusUpdateMutation } = useMutation(() =>
    profileApi.postProfileUpdate({
      updateProfileRequest: { iamUser: { status: IamUserStatus.Active } },
    })
  );

  const onSubmit = async (data: LoginFormSchema) => {
    setLoading(true);

    if (data.setupTotpToken) {
      Auth.verifyTotpToken(cognitoUser, data.setupTotpToken)
        .then(async () => {
          await Auth.setPreferredMFA(cognitoUser, 'TOTP');
          const token = await getUserToken();

          try {
            configureApi({ token });
            await userStatusUpdateMutation();
          } catch (e) {}
          await processLogin();
        })
        .catch((e) => {
          setError('setupTotpToken', {
            type: 'manual',
            message: t('validations.invalidCode'),
          });
          setLoading(false);
        });

      return;
    }

    if (data.totpToken) {
      Auth.confirmSignIn(cognitoUser, data.totpToken, 'SOFTWARE_TOKEN_MFA')
        .then(async () => {
          await processLogin();
        })
        .catch((e) => {
          setError('totpToken', {
            type: 'manual',
            message: t('validations.invalidCode'),
          });

          setLoading(false);
        });

      return;
    }

    if (data.email && data.password) {
      try {
        const user = await login({
          username: data.email,
          password: data.password,
        });

        setCognitoUser(user);

        if (get(user, 'preferredMFA') === 'NOMFA') {
          setIsSetupMFA(true);
          setCodeRequested(true);
          await getToken();
          setIsSetupMFAStep(true);
        } else {
          processLogin();
        }
      } catch (error) {
        if (error instanceof MFARequired) {
          setShowMFA(true);
          setCodeRequested(true);
          setCognitoUser(error.user);
        }
        if (error instanceof NewPasswordRequired) {
          onSetNewPasswordError(error);
        } else if (error?.code === 'NotAuthorizedException') {
          setError('email', {
            type: 'manual',
            message: get(error, 'message'),
          });
        } else {
          console.log(error);
          setError('email', {
            type: 'manual',
            message: t('validations.somethingWentWrong'),
          });
        }
      }

      setValue('password', '');
      setLoading(false);
    }
  };

  const getToken = useCallback(async function () {
    const user = await Auth.currentAuthenticatedUser();
    const data = await Auth.setupTOTP(user);

    const otpauth = `otpauth://totp/AWSCognito:${user.username}?secret=${data}&issuer=BackOffice`;
    setQR(otpauth);
    setVerificationCode(data);
  }, []);

  const processLogin = useCallback(async () => {
    const cognitoUserVerified = await Auth.currentAuthenticatedUser();

    const { user, token, roles } = getAccountParams(cognitoUserVerified);

    state.dispatch({ type: 'LOGIN', user, token, roles });

    // LOL. Kill it with fire.
    document.body.innerHTML = '';
    // TODO: Find time to refactor this.
    window.location.reload();
  }, [state]);

  useEffect(() => {
    if (showMFA) {
      setLoginStep(LoginStep.OTP);
    }
  }, [showMFA]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      {!isSetupMFA && (
        <>
          {!showMFA && (
            <>
              <div className="mb-5">
                <Input
                  label={t('loginForm.email')}
                  name="email"
                  error={errors.email}
                  inputRef={register({
                    required: true,
                    validate: {
                      email: (value) => emailPattern.test(value),
                    },
                  })}
                />
              </div>
              <div className="mb-8">
                <PasswordInput
                  label={t('loginForm.password')}
                  name="password"
                  error={errors.password}
                  inputRef={register({ required: true })}
                />
              </div>
            </>
          )}
          {showMFA && (
            <div className="mt-2 mb-16">
              <AuthCodeInput
                label={t('loginForm.totpToken')}
                name="totpToken"
                error={errors.totpToken}
                control={control}
                rules={{ required: true }}
              />
            </div>
          )}
        </>
      )}

      {isSetupMFA && qr && (
        <div>
          <div className="flex justify-center mb-8">
            <MfaInfo />
          </div>
          <div className="flex justify-between">
            <div className="flex justify-center w-1/2 py-6">
              <div className="w-full" style={{ maxWidth: '400px' }}>
                <div className="text-center mb-4">
                  <div className="text-xxs font-medium text-blue-500 uppercase">
                    STEP 1
                  </div>
                  <div className="text-gray-900 text-lg font-semibold">
                    Scan this QR Code
                  </div>
                </div>

                <div className="flex justify-center">
                  <span className="border border-gray-300 rounded p-2">
                    <QRCode value={qr} size={128} />
                  </span>
                </div>

                <p className="text-gray-700 text-sm text-center pt-8">
                  Don’t have acces to your mobile right now? Paste the security
                  key in your app.
                </p>

                <div className="">
                  {!isShowSecretKey ? (
                    <div
                      className="mt-6 flex justify-center items-center text-blue-500 font-semibold text-xs cursor-pointer"
                      onClick={() => {
                        setIsShowSecretKey(true);
                      }}
                    >
                      Display Shared Secret Key
                      <IconEye className="-mt-px ml-2" />
                    </div>
                  ) : (
                    <div className="mt-6">
                      <div className="text-xs text-gray-900 font-semibold pb-1">
                        Shared Secret Key
                      </div>
                      <div className="relative text-xxs text-gray-900 font-medium border border-gray-300 rounded-sm p-3 w-full">
                        {verificationCode}

                        <div
                          className="absolute"
                          style={{ top: '6px', right: '6px' }}
                        >
                          <CopyToClipboard
                            text={verificationCode}
                            onCopy={() => {
                              setIsCopied(true);
                              setTimeout(() => {
                                setIsCopied(false);
                              }, 3000);
                            }}
                          >
                            <div className="text-blue-500 text-xs font-semibold cursor-pointer">
                              {!isCopied && <IconCopy />}
                              {isCopied && (
                                <div
                                  style={{
                                    paddingTop: '3px',
                                    paddingRight: '6px',
                                  }}
                                >
                                  copied
                                </div>
                              )}
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="text-gray-700 text-xs pt-2">
                        Paste this in key your authentificatior app
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-center w-1/2 border-l border-gray-300 border-solid py-8">
              <div className="w-full" style={{ maxWidth: '400px' }}>
                <div className="text-center mb-8">
                  <div className="text-xxs font-medium text-blue-500 uppercase">
                    STEP 2
                  </div>
                  <div className="text-gray-900 text-lg font-semibold">
                    Enter Your Code
                  </div>
                </div>

                <div className="mb-16">
                  <AuthCodeInput
                    label={t('loginForm.verificationCode')}
                    name="setupTotpToken"
                    error={errors.setupTotpToken}
                    control={control}
                    rules={{ required: true }}
                  />
                </div>

                <Button
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  size="large"
                  widthClass="w-full"
                >
                  {t(showMFA ? 'loginForm.confirm' : 'loginForm.logIn')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!(isSetupMFA && qr) && (
        <Button
          loading={loading}
          disabled={loading}
          type="submit"
          size="large"
          widthClass="w-full"
        >
          {t(showMFA ? 'loginForm.confirm' : 'loginForm.logIn')}
        </Button>
      )}
    </form>
  );
};
