import { QueryFunctionContext } from 'react-query';
import { baseFetch } from 'src/api/baseFetch';
import { Sorting } from 'src/components/table/Table';

export interface RulePermission {
  id: string;
  roleName: string;
  createdAt: string;
  roleDescription: string;
  accessChannelId: string;
  channelName?: string;
}

export type RowsPerPage = 20 | 50 | 100;

export type RulesQueryOptions = {
  limit: RowsPerPage;
  offset?: number;
  page?: number;
};

export const INITIAL_RULES_OPTIONS: Pick<RulesQueryOptions, 'page'> = {
  page: 1,
};

interface Params extends QueryFunctionContext {
  queryKey: [
    string,
    { searchQuery: string; limit: RowsPerPage; sorting?: Sorting }
  ];
}

export const getRolesAndPermissions = async (params: Params) => {
  const { queryKey, pageParam } = params;
  const { limit = 20, searchQuery, sorting } = queryKey[1];

  console.log({ pageParam, limit, searchQuery, sorting });

  return await baseFetch<Array<RulePermission>>({ url: '/roles' });
};
