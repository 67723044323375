/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListCardsRequest
 */
export interface ListCardsRequest {
    /**
     * 
     * @type {number}
     * @memberof ListCardsRequest
     */
    cursor?: number;
    /**
     * 
     * @type {number}
     * @memberof ListCardsRequest
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCardsRequest
     */
    personId?: string;
}

export function ListCardsRequestFromJSON(json: any): ListCardsRequest {
    return ListCardsRequestFromJSONTyped(json, false);
}

export function ListCardsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCardsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'personId': !exists(json, 'person_id') ? undefined : json['person_id'],
    };
}

export function ListCardsRequestToJSON(value?: ListCardsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor': value.cursor,
        'limit': value.limit,
        'person_id': value.personId,
    };
}


