import React from 'react';

export const IconEye = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.666992 7.99984C0.666992 7.99984 3.33366 2.6665 8.00033 2.6665C12.667 2.6665 15.3337 7.99984 15.3337 7.99984C15.3337 7.99984 12.667 13.3332 8.00033 13.3332C3.33366 13.3332 0.666992 7.99984 0.666992 7.99984Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
