import React, { useState, Ref, ReactNode, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { useTransition, animated } from 'react-spring';
import { Manager, Reference, Popper } from 'react-popper';
import { PopoverPortal } from './PopoverPortal';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { ESC_KEY, useOnKeyDown } from 'src/hooks/useOnKeyDown';

export type TooltipPositions =
  | 'top-start'
  | 'bottom-start'
  | 'bottom-end'
  | 'left-start';

export const Tooltip = ({
  children,
  content,
  offsetHorizonal = -5,
  offsetVertical = 4,
  placement = 'top-start',
  minWidth = 'auto',
  escapeWithReference = true,
  withPadding = true,
  persist = false,
  disabled = false,
}: {
  content:
    | ReactNode
    | string
    | ((options: { setIsOpen: (state: boolean) => any }) => ReactNode);
  placement?: TooltipPositions;
  offsetHorizonal?: number;
  offsetVertical?: number;
  minWidth?: number | 'auto';
  escapeWithReference?: boolean;
  withPadding?: boolean;
  persist?: boolean;
  disabled?: boolean;
  children: (options: {
    ref: Ref<any>;
    setIsOpen: (state: boolean) => any;
    isOpen?: boolean;
  }) => ReactNode;
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const transitions = useTransition(tooltipIsOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 },
  });

  const setTooltipIsOpenDebounced = useCallback(
    debounce((state: boolean) => {
      if (!disabled) {
        setTooltipIsOpen(state);
      }
    }, 150),
    [tooltipIsOpen]
  );

  const tooltipRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    {
      ref: tooltipRef,
    },
    () => setTooltipIsOpenDebounced(false)
  );

  useOnKeyDown({ keyCode: ESC_KEY }, () => setTooltipIsOpenDebounced(false));

  return (
    <div ref={tooltipRef}>
      <Manager>
        <Reference>
          {({ ref }) =>
            children({
              ref,
              setIsOpen: setTooltipIsOpenDebounced,
              isOpen: tooltipIsOpen,
            })
          }
        </Reference>
        {content && (
          <PopoverPortal>
            <Popper
              placement={placement}
              eventsEnabled={false}
              modifiers={{
                preventOverflow: {
                  escapeWithReference,
                },
                offset: {
                  offset: `${offsetHorizonal},${offsetVertical}`,
                },
              }}
            >
              {({ ref, style, placement }) =>
                transitions.map(
                  ({ item: transitionItem, key, props }) =>
                    transitionItem && (
                      <animated.div
                        ref={ref}
                        key={key}
                        style={{
                          ...style,
                          ...props,
                          zIndex: 999999,
                        }}
                        className="relative"
                        data-placement={placement}
                        onMouseEnter={() => setTooltipIsOpenDebounced(true)}
                        onMouseLeave={() => {
                          if (!persist) {
                            setTooltipIsOpenDebounced(false);
                          }
                        }}
                      >
                        <div
                          style={{
                            boxShadow: '0px 8px 20px rgba(47, 50, 58, 0.1)',
                            minWidth,
                          }}
                          className={classNames(
                            'relative flex items-center bg-white rounded-sm',
                            {
                              'px-4 py-2': withPadding,
                            }
                          )}
                        >
                          <div className="w-full text-xs font-semibold leading-tight text-gray-900">
                            {typeof content === 'function'
                              ? content({ setIsOpen: setTooltipIsOpenDebounced })
                              : content}
                          </div>
                        </div>
                        {/* <svg
                          className={classNames('absolute w-4 h-2 text-white', {
                            'left-0 bottom-0 -mb-2 ml-1':
                              placement === 'top-start',
                            'left-0 top-0 -mt-2 ml-1 transform rotate-180':
                              placement === 'bottom-start',
                          })}
                          x="0px"
                          y="0px"
                          viewBox="0 0 255 255"
                          xmlSpace="preserve"
                        >
                          <polygon
                            className="fill-current"
                            points="0,0 127.5,127.5 255,0"
                          />
                        </svg> */}
                      </animated.div>
                    )
                )
              }
            </Popper>
          </PopoverPortal>
        )}
      </Manager>
    </div>
  );
};
