import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  AddUserFields,
  addUserFormType,
  AssignmentsItemFields,
} from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserModal';
import { nameof } from 'src/utils/nameof';
import { Button } from 'src/components/Button';
import { HorizontalDivider } from 'src/components/HorizontalDivider';
import { OptionType, Select } from 'src/components/form/Select';
import { CheckboxForm } from 'src/components/form/CheckboxForm';
import { ArrayField } from 'react-hook-form/dist/types';
import {
  Company,
  AccessChannel,
  ListIamUserTypesResponse,
} from 'src/api/client';
import { BackofficeAssignmentsFields } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/DynamicFileds/BackofficeAssignmentsFields';
import { HiddenPreviewFields } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/DynamicFileds/HiddenPreviewFields';
import { AssignmentsFields } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/DynamicFileds/AssignmentsFields';

export interface DynamicFieldsOptions {
  organizationsOptions: OptionType[];
  programsOptions: OptionType[];
  rolesOptions: OptionType[];
}

interface Props {
  field: Partial<ArrayField<AssignmentsItemFields, string>>;
  fieldIndex: number;
  fieldsLength: number;
  removeFields: (fieldsIndex: number) => void;
  toggleHiddenFields: (value: boolean[] | number) => void;
  isHidden?: boolean;
  isLoadingCompanies: boolean;
  isLoadingPrograms: boolean;
  isLoadingRoles: boolean;
  fieldsOptions: DynamicFieldsOptions;
  companies: Array<Company>;
  userTypesResponse?: ListIamUserTypesResponse;
}

export const DynamicFields = memo(
  ({
    field,
    fieldIndex,
    fieldsLength,
    removeFields,
    toggleHiddenFields,
    isHidden,
    isLoadingCompanies,
    isLoadingPrograms,
    isLoadingRoles,
    fieldsOptions,
    companies,
    userTypesResponse,
  }: Props) => {
    const { t } = useTranslation();
    const { errors, control, watch, trigger } = useFormContext<AddUserFields>();

    const isBackoffice = useMemo(
      () => get(userTypesResponse, 'types[0].name') === 'backoffice',
      [userTypesResponse]
    );

    const watchItems: AssignmentsItemFields[] | undefined = watch(
      nameof(addUserFormType.companyAssignments)
    );

    const fieldValues: AssignmentsItemFields = useMemo(
      () =>
        watchItems?.length && watchItems[fieldIndex]
          ? watchItems[fieldIndex]
          : ({} as AssignmentsItemFields),
      [watchItems, fieldIndex]
    );

    const handleEdit = () => {
      toggleHiddenFields(fieldIndex);
    };

    const handleSave = async () => {
      const organizationFieldsNames = isBackoffice
        ? [
            `companyAssignments[${fieldIndex}].firstOrganization`,
            `companyAssignments[${fieldIndex}].secondOrganization`,
          ]
        : [`companyAssignments[${fieldIndex}].organizations`];

      const isValid = await trigger([
        ...organizationFieldsNames,
        `companyAssignments[${fieldIndex}].roles`,
        `companyAssignments[${fieldIndex}].program`,
      ]);

      if (isValid) {
        toggleHiddenFields(fieldIndex);
      }
    };

    const handleDelete = () => {
      if (fieldsLength !== 1) {
        removeFields(fieldIndex);
      }
    };

    return (
      <div>
        <div
          className={classNames({
            hidden: isHidden,
          })}
        >
          {isBackoffice ? (
            <BackofficeAssignmentsFields
              defaultValues={{
                firstOrganization: field.firstOrganization,
                secondOrganization: field.secondOrganization,
                roles: field.roles,
                autoAssignAffiliated: field.autoAssignAffiliated,
              }}
              companies={companies}
              fieldIndex={fieldIndex}
              fieldValues={fieldValues}
              isLoadingCompanies={isLoadingCompanies}
              isLoadingRoles={isLoadingRoles}
              rolesOptions={fieldsOptions.rolesOptions}
            />
          ) : (
            <AssignmentsFields
              defaultValues={{
                roles: field.roles,
                organizations: field.organizations,
              }}
              fieldIndex={fieldIndex}
              fieldValues={fieldValues}
              organizationsOptions={fieldsOptions.organizationsOptions}
              rolesOptions={fieldsOptions.rolesOptions}
              isLoadingCompanies={isLoadingCompanies}
              isLoadingRoles={isLoadingRoles}
            />
          )}
          <div className="flex mb-3">
            <div className="w-full mr-1">
              <Select
                isMulti
                label={t('accessManagement.userManagement.form.program.label')}
                placeholder={t(
                  'accessManagement.userManagement.form.program.placeholder'
                )}
                error={get(errors, `companyAssignments[${fieldIndex}].program`)}
                name={`companyAssignments[${fieldIndex}].program`}
                defaultValue={field.program}
                control={control}
                options={fieldsOptions.programsOptions}
                isLoading={isLoadingPrograms}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex mb-3">
            <div className="w-full mr-1">
              <CheckboxForm
                label={t(
                  'accessManagement.userManagement.form.autoAssignProgram'
                )}
                control={control}
                name={`companyAssignments[${fieldIndex}].autoAssignProgram`}
                labelClassName={'w-full'}
                labelTextClassName={'font-semibold text-xs text-gray-900'}
                defaultValue={true}
                disabled={true}
              />
            </div>
          </div>
          {fieldsLength !== 1 && fieldIndex !== fieldsLength - 1 && (
            <div className="flex justify-end mb-3">
              <Button
                widthClass="w-16"
                size={'small'}
                type={'button'}
                onClick={handleDelete}
                variant={'text'}
                className={'mr-2'}
              >
                {t('buttons.delete')}
              </Button>
              <Button
                widthClass="w-16"
                size={'small'}
                type={'button'}
                onClick={handleSave}
              >
                {t('buttons.save')}
              </Button>
            </div>
          )}
        </div>
        {isHidden && (
          <HiddenPreviewFields
            fieldsValues={fieldValues}
            handleEdit={handleEdit}
          />
        )}
        {fieldsLength > 1 && fieldIndex !== fieldsLength - 1 && (
          <HorizontalDivider className={'mt-4 mb-4'} />
        )}
      </div>
    );
  }
);
