import React, { useContext, useEffect } from 'react';
import {
  Input,
  onKeyPressDisallowSpecialCharacters,
} from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm } from 'react-hook-form';

export const FilterRrnNumber = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { register, watch, setValue } = useForm<{
    rrnNumber: string;
  }>();

  const { rrnNumber } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_RRN_NUMBER_FILTER',
      rrnNumber: rrnNumber,
    });
  }, [rrnNumber, dispatch]);

  useEffect(() => {
    setValue('rrnNumber', filters.rrnNumber);
  }, [filters.rrnNumber, setValue]);

  return (
    <Input
      label={t('transactionsManagement.transactions.list.filters.rrnNumber')}
      name="rrnNumber"
      labelFontClassName="text-xxs font-medium"
      labelColorClassName="text-gray-700"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressDisallowSpecialCharacters}
      inputRef={register}
    />
  );
};
