import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container as ListContainer } from './list/Container';
import { Container as DetailsContainer } from './details/Container';

export const Chargebacks = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <DetailsContainer />
      </Route>
      <Route path={match.path}>
        <ListContainer />
      </Route>
    </Switch>
  );
};
