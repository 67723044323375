/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateRange,
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListTransactionsFilter
 */
export interface ListTransactionsFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTransactionsFilter
     */
    merchants?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTransactionsFilter
     */
    transactionType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListTransactionsFilter
     */
    transactionStatus?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTransactionsFilter
     */
    matchType?: Array<string>;
    /**
     * 
     * @type {DateRange}
     * @memberof ListTransactionsFilter
     */
    transactionDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof ListTransactionsFilter
     */
    settlementDate?: DateRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTransactionsFilter
     */
    cardType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListTransactionsFilter
     */
    cardNumberPan?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactionsFilter
     */
    authorizationCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTransactionsFilter
     */
    settlementAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTransactionsFilter
     */
    arn?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactionsFilter
     */
    rrn?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactionsFilter
     */
    stan?: string;
}

export function ListTransactionsFilterFromJSON(json: any): ListTransactionsFilter {
    return ListTransactionsFilterFromJSONTyped(json, false);
}

export function ListTransactionsFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTransactionsFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merchants': !exists(json, 'merchants') ? undefined : json['merchants'],
        'transactionType': !exists(json, 'transactionType') ? undefined : json['transactionType'],
        'transactionStatus': !exists(json, 'transactionStatus') ? undefined : json['transactionStatus'],
        'matchType': !exists(json, 'matchType') ? undefined : json['matchType'],
        'transactionDate': !exists(json, 'transactionDate') ? undefined : DateRangeFromJSON(json['transactionDate']),
        'settlementDate': !exists(json, 'settlementDate') ? undefined : DateRangeFromJSON(json['settlementDate']),
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumberPan': !exists(json, 'cardNumberPan') ? undefined : json['cardNumberPan'],
        'authorizationCode': !exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'settlementAmount': !exists(json, 'settlementAmount') ? undefined : json['settlementAmount'],
        'arn': !exists(json, 'arn') ? undefined : json['arn'],
        'rrn': !exists(json, 'rrn') ? undefined : json['rrn'],
        'stan': !exists(json, 'stan') ? undefined : json['stan'],
    };
}

export function ListTransactionsFilterToJSON(value?: ListTransactionsFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merchants': value.merchants,
        'transactionType': value.transactionType,
        'transactionStatus': value.transactionStatus,
        'matchType': value.matchType,
        'transactionDate': DateRangeToJSON(value.transactionDate),
        'settlementDate': DateRangeToJSON(value.settlementDate),
        'cardType': value.cardType,
        'cardNumberPan': value.cardNumberPan,
        'authorizationCode': value.authorizationCode,
        'settlementAmount': value.settlementAmount,
        'arn': value.arn,
        'rrn': value.rrn,
        'stan': value.stan,
    };
}


