/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProductResponse
 */
export interface GetProductResponse {
    /**
     * 
     * @type {Product}
     * @memberof GetProductResponse
     */
    product?: Product;
}

export function GetProductResponseFromJSON(json: any): GetProductResponse {
    return GetProductResponseFromJSONTyped(json, false);
}

export function GetProductResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
    };
}

export function GetProductResponseToJSON(value?: GetProductResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': ProductToJSON(value.product),
    };
}


