import React, { useEffect, ReactNode, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { fetchProfile } from 'src/store/account/actions';
import { i18n } from 'src/i18n';
import {
  isAuthenticatedSelector,
  accountSlice,
} from '../store/account/accountSlice';

const IDLE_TIMEOUT = 60; // minutes

export const AuthContainer = ({ children }: { children: ReactNode }) => {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const storeDispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      storeDispatch(fetchProfile());
    }
  }, [isAuthenticated, storeDispatch]);

  useEffect(() => {
    const loadResource = async () => {
      i18n.addResourceBundle(
        'en',
        'translation',
        await import('../locales/en/translationAuth.json'),
        true,
        true
      );

      setTranslationsLoaded(true);
    };

    loadResource();
  }, []);

  useIdleTimer({
    timeout: 1000 * 60 * IDLE_TIMEOUT,
    debounce: 500,
    onIdle: () => storeDispatch(accountSlice.actions.logout()),
  });

  return <>{translationsLoaded && children}</>;
};
