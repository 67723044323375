import React, { useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import get from 'lodash/get';
import map from 'lodash/map';
import classNames from 'classnames';
import { FormModal } from 'src/components/FormModal';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/Input';
import { useInfiniteQuery, useMutation } from 'react-query';
import { PostIamRoleDeleteRequest } from 'src/api/client';
import { rolesApi, usersApi } from 'src/api';
import { store } from 'react-notifications-component';
import { Loading } from 'src/components/Loading';

import { ReactComponent as PersonErrorIcon } from 'src/assets/icons/personError.svg';

export interface DeleteRoleFields {
  reason: string;
}

interface Props {
  id: string | null;
  visible: boolean;
  closeModal: () => void;
  onSubmit: () => void;
}

export const DeleteRoleModal = ({
  id,
  visible,
  closeModal,
  onSubmit,
}: Props) => {
  const methods = useForm<DeleteRoleFields>({
    mode: 'onBlur',
  });
  const { handleSubmit, register, errors } = methods;
  const { t } = useTranslation();

  const [error, setError] = useState();

  const {
    mutateAsync: roleDeleteMutateAsync,
    isLoading: roleDeleteIsLoading,
  } = useMutation(
    (args: PostIamRoleDeleteRequest) => rolesApi.postIamRoleDelete(args),
    {
      onError: async (error) => {
        // @ts-ignore
        const err = await error.text();
        setError(err);
      },
    }
  );

  // const time = Date.now();

  const {
    data: assignedUsers,
    // isLoading: assignedUsersLoading,
    // isFetching: assignedUsersFetching,
  } = useInfiniteQuery(
    [id],
    () => {
      return usersApi.postIamUsersList({
        listIamUsersRequest: {
          iamRoleId: id!,
        },
      });
    },
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      retry: false,
      enabled: !!error,
      onError: (err) => console.log(err),
    }
  );

  const assignedUsersData = useMemo(() => {
    return (
      assignedUsers?.pages
        .map((groupItem) =>
          groupItem?.iamUsers?.map((user) => ({
            ...user,
          }))
        )
        .flat() || []
    );
  }, [assignedUsers]);

  const handleFormFinish = useCallback(
    async (values: DeleteRoleFields) => {
      if (id) {
        await roleDeleteMutateAsync({
          deleteIamRoleRequest: {
            id,
          },
        });
      }

      store.addNotification({
        message: 'Success',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });

      onSubmit();
      closeModal();
    },
    [closeModal, id, onSubmit, roleDeleteMutateAsync]
  );

  return (
    <FormProvider {...methods}>
      <div>
        <FormModal
          isOpen={visible}
          closeModal={closeModal}
          maxWidth="auto"
          width="500px"
        >
          <div className="p-6">
            {!(assignedUsersData.length > 0 && error) && (
              <form onSubmit={handleSubmit(handleFormFinish)}>
                <div>
                  <div className={'font-bold mb-2 text-center text-2xl'}>
                    {t('accessManagement.roleDeletion.deleteRole')}
                  </div>

                  <p className="text-center text-sm text-gray-700">
                    {t('accessManagement.roleDeletion.description')}
                  </p>

                  <div className="my-5">
                    <Input
                      type={'textarea'}
                      label={t(
                        'accessManagement.rolesAndPermissions.role.formItems.reason.label'
                      )}
                      error={errors.reason}
                      inputRef={register({
                        maxLength: {
                          value: 200,
                          message: t('validations.maxLength', { limit: 200 }),
                        },
                      })}
                      name="reason"
                      className={'h-16 border-gray-300'}
                    />
                  </div>

                  {error && (
                    <div>
                      <div className="flex items-center py-3 px-5 bg-red-100 text-sm text-gray-900 rounded-sm">
                        <span className="pt-1">{get(JSON.parse(error), 'error[0].details')}</span>

                        <Loading className="ml-2" />
                      </div>
                    </div>
                  )}

                  {!error && (
                    <div className="flex justify-between mt-4">
                      <Button
                        variant="red"
                        size="medium-large"
                        widthClass="w-1/2"
                        loading={roleDeleteIsLoading}
                        className="mr-2"
                        type="submit"
                      >
                        {t('accessManagement.roleDeletion.confirmDeletion')}
                      </Button>

                      <Button
                        variant="secondary"
                        size="medium-large"
                        widthClass="w-1/2"
                        className="ml-2"
                        disabled={roleDeleteIsLoading}
                        onClick={closeModal}
                        type="button"
                      >
                        {t('accessManagement.cancel')}
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            )}

            {assignedUsersData.length > 0 && error && (
              <div>
                <div className="flex border-b border-solid border-gray-300 pb-8 mb-4">
                  <PersonErrorIcon className="mr-4" />

                  <div>
                    <div className="font-bold mb-1 text-2xl">
                      {t('accessManagement.roleDeletion.deleteRole')}
                    </div>
                    <div className="text-xs text-gray-700">
                      {t('accessManagement.roleDeletion.assignedUsers')}
                    </div>
                  </div>
                </div>

                {error && <div>
                  <div className="flex items-center py-3 px-5 bg-red-100 text-sm text-gray-900 rounded-sm mb-4">
                    <span className="pt-1">{get(JSON.parse(error), 'error[0].details')}</span>
                  </div>
                </div>}

                <div>
                  <div className="flex text-gray-700 text-xxs py-3">
                    <div className="w-1/2">Username</div>
                    <div className="w-1/2">Active Roles</div>
                  </div>

                  {map(
                    assignedUsersData,
                    ({ id, username, rolesNameList }, i) => (
                      <div
                        key={id}
                        className={classNames('flex py-3', {
                          'border-t border-solid border-gray-300': i !== 0,
                        })}
                      >
                        <div className="w-1/2 text-gray-900 font-semibold text-xs break-all">
                          {username}
                        </div>
                        <div className="w-1/2 text-gray-700 text-xs">
                          {map(rolesNameList, (roleName, i: number) => (
                            <div
                              key={i}
                              className={classNames({ 'pt-2': i !== 0 })}
                            >
                              {roleName}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </FormModal>
      </div>
    </FormProvider>
  );
};
