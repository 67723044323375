/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Chargeback
 */
export interface Chargeback {
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    idsCaseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    cardType?: string;
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    authorizationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    acquireReferenceNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof Chargeback
     */
    adjustmentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Chargeback
     */
    transactionDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Chargeback
     */
    dueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Chargeback
     */
    disputeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    chargebackCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Chargeback
     */
    currentStatus?: string;
}

export function ChargebackFromJSON(json: any): Chargeback {
    return ChargebackFromJSONTyped(json, false);
}

export function ChargebackFromJSONTyped(json: any, ignoreDiscriminator: boolean): Chargeback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idsCaseNumber': !exists(json, 'idsCaseNumber') ? undefined : json['idsCaseNumber'],
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'authorizationCode': !exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'acquireReferenceNumber': !exists(json, 'acquireReferenceNumber') ? undefined : json['acquireReferenceNumber'],
        'adjustmentDate': !exists(json, 'adjustmentDate') ? undefined : (new Date(json['adjustmentDate'])),
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'dueDate': !exists(json, 'dueDate') ? undefined : (new Date(json['dueDate'])),
        'disputeAmount': !exists(json, 'disputeAmount') ? undefined : json['disputeAmount'],
        'chargebackCurrencyCode': !exists(json, 'chargebackCurrencyCode') ? undefined : json['chargebackCurrencyCode'],
        'currentStatus': !exists(json, 'currentStatus') ? undefined : json['currentStatus'],
    };
}

export function ChargebackToJSON(value?: Chargeback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idsCaseNumber': value.idsCaseNumber,
        'cardType': value.cardType,
        'cardNumber': value.cardNumber,
        'authorizationCode': value.authorizationCode,
        'acquireReferenceNumber': value.acquireReferenceNumber,
        'adjustmentDate': value.adjustmentDate === undefined ? undefined : (value.adjustmentDate.toISOString().substr(0,10)),
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString().substr(0,10)),
        'dueDate': value.dueDate === undefined ? undefined : (value.dueDate.toISOString().substr(0,10)),
        'disputeAmount': value.disputeAmount,
        'chargebackCurrencyCode': value.chargebackCurrencyCode,
        'currentStatus': value.currentStatus,
    };
}


