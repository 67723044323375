/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProgramRequest
 */
export interface GetProgramRequest {
    /**
     * 
     * @type {string}
     * @memberof GetProgramRequest
     */
    programId: string;
}

export function GetProgramRequestFromJSON(json: any): GetProgramRequest {
    return GetProgramRequestFromJSONTyped(json, false);
}

export function GetProgramRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProgramRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programId': json['program_id'],
    };
}

export function GetProgramRequestToJSON(value?: GetProgramRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'program_id': value.programId,
    };
}


