/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateChannelProviderRequest,
    CreateChannelProviderRequestFromJSON,
    CreateChannelProviderRequestToJSON,
    CreateChannelProviderResponse,
    CreateChannelProviderResponseFromJSON,
    CreateChannelProviderResponseToJSON,
    GetChannelProviderRequest,
    GetChannelProviderRequestFromJSON,
    GetChannelProviderRequestToJSON,
    GetChannelProviderResponse,
    GetChannelProviderResponseFromJSON,
    GetChannelProviderResponseToJSON,
    ListChannelProvidersRequest,
    ListChannelProvidersRequestFromJSON,
    ListChannelProvidersRequestToJSON,
    ListChannelProvidersResponse,
    ListChannelProvidersResponseFromJSON,
    ListChannelProvidersResponseToJSON,
} from '../models';

export interface PostChannelProvidersCreateRequest {
    createChannelProviderRequest: CreateChannelProviderRequest;
}

export interface PostChannelProvidersGetRequest {
    getChannelProviderRequest: GetChannelProviderRequest;
}

export interface PostChannelProvidersListRequest {
    listChannelProvidersRequest?: ListChannelProvidersRequest;
}

/**
 * 
 */
export class ChannelProvidersApi extends runtime.BaseAPI {

    /**
     */
    async postChannelProvidersCreateRaw(requestParameters: PostChannelProvidersCreateRequest): Promise<runtime.ApiResponse<CreateChannelProviderResponse>> {
        if (requestParameters.createChannelProviderRequest === null || requestParameters.createChannelProviderRequest === undefined) {
            throw new runtime.RequiredError('createChannelProviderRequest','Required parameter requestParameters.createChannelProviderRequest was null or undefined when calling postChannelProvidersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/channel-providers/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateChannelProviderRequestToJSON(requestParameters.createChannelProviderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateChannelProviderResponseFromJSON(jsonValue));
    }

    /**
     */
    async postChannelProvidersCreate(requestParameters: PostChannelProvidersCreateRequest): Promise<CreateChannelProviderResponse> {
        const response = await this.postChannelProvidersCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postChannelProvidersGetRaw(requestParameters: PostChannelProvidersGetRequest): Promise<runtime.ApiResponse<GetChannelProviderResponse>> {
        if (requestParameters.getChannelProviderRequest === null || requestParameters.getChannelProviderRequest === undefined) {
            throw new runtime.RequiredError('getChannelProviderRequest','Required parameter requestParameters.getChannelProviderRequest was null or undefined when calling postChannelProvidersGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/channel-providers/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetChannelProviderRequestToJSON(requestParameters.getChannelProviderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelProviderResponseFromJSON(jsonValue));
    }

    /**
     */
    async postChannelProvidersGet(requestParameters: PostChannelProvidersGetRequest): Promise<GetChannelProviderResponse> {
        const response = await this.postChannelProvidersGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postChannelProvidersListRaw(requestParameters: PostChannelProvidersListRequest): Promise<runtime.ApiResponse<ListChannelProvidersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/channel-providers/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListChannelProvidersRequestToJSON(requestParameters.listChannelProvidersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListChannelProvidersResponseFromJSON(jsonValue));
    }

    /**
     */
    async postChannelProvidersList(requestParameters: PostChannelProvidersListRequest): Promise<ListChannelProvidersResponse> {
        const response = await this.postChannelProvidersListRaw(requestParameters);
        return await response.value();
    }

}
