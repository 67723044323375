import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TabLink } from './TabLink';

interface Props {
  tabs: { url: string, label: string }[];
}

export const TabsHeader = ({ tabs }: Props) => {
  const routeMatch = useRouteMatch();
  const urls = routeMatch.url.split('/');
  const mainUrl = `/${urls[1]}`;

  return (
    <div className="flex items-center justify-between w-full bg-white h-16">
      <div className="flex px-5 h-full">
        {
          tabs.map(({ label, url }, i) => (
            <TabLink to={mainUrl + url} key={i}>
              {label}
            </TabLink>
          ))
        }
      </div>
    </div>
  );
};
