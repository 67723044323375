import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { InactiveUsers } from './inactive-users/InactiveUsers';

export const Reports = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/inactive-users`}>
        <InactiveUsers />
      </Route>
    </Switch>
  );
};
