import { createContext } from 'react';

export type Chargeback = {
  id: string;
  comments: Comment[];
  documents: Document[];
};

export type Comment = {
  createdAt: string;
  createdBy: string;
  comment: string;
};

export type Document = {
  createdAt: string;
  createdBy: string;
  document: string;
};

export const initialState = {
  chargeback: undefined as Chargeback | undefined,
  chargebackLoading: false,
  chargebackNotFound: false,
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_CHARGEBACK'; chargeback: Chargeback }
  | { type: 'SET_CHARGEBACK_LOADING'; loading: boolean }
  | { type: 'SET_CHARGEBACK_NOT_FOUND'; notFound: boolean };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_CHARGEBACK':
      return { ...state, chargeback: action.chargeback };
    case 'SET_CHARGEBACK_NOT_FOUND':
      return {
        ...state,
        chargebackNotFound: action.notFound,
      };
    case 'SET_CHARGEBACK_LOADING':
      return { ...state, chargebackLoading: action.loading };
    default:
      throw new Error();
  }
};

export const DetailsContext = createContext<typeof initialState>(initialState);
