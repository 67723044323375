import React, { useMemo, useContext, useState } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'react-query';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';

import { PageHeader } from 'src/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { Button } from 'src/components/Button';
import { TabLink } from 'src/components/TabLink';
import { AssignedPermissions } from './AssignedPermissions';
import { AssignedUsers } from './AssignedUsers';
import { AddRoleModal } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/AddRoleModal';
import { iconMap } from 'src/domain/access-management/roles-and-permissions/tabs/rules/useRolesColumns';
import { rolesApi, rolePermissionsApi } from 'src/api';
import { RectLoader } from 'src/components/RectLoader';
import { RolesContext } from 'src/domain/access-management/roles-and-permissions/tabs/rules/RolesContext';
import { DeleteRoleModal } from 'src/domain/access-management/roles-and-permissions/tabs/rules/DeleteRoleModal';

export type ImageItem = {
  avatar?: string;
  icon?: JSX.Element;
  name: string;
  id: string;
};

export const AvatarBadge = ({
  item,
  avatarLength = 2,
}: {
  item: ImageItem | undefined;
  avatarLength?: number;
}) => (
  <span className="box-border flex inline-flex items-center h-8 pl-2 pr-5 bg-gray-200 border-gray-300 rounded-full border-1">
    <div className="flex items-center">
      {item?.icon ? (
        <div className="mr-2">{item.icon}</div>
      ) : item?.avatar ? (
        <div className="w-5 h-5 mr-2 overflow-hidden rounded-full">
          <img src={item?.avatar} alt="logo" />
        </div>
      ) : (
        <span className="flex items-center justify-center w-5 h-5 mr-2 text-xs tracking-wider text-blue-500 uppercase bg-blue-100 rounded-full">
          {item?.name && item?.name.slice(0, avatarLength)}
        </span>
      )}
      <div className="text-xs font-bold leading-tight text-gray-900">
        {item?.name}
      </div>
    </div>
  </span>
);

export const RoleDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeMatch = useRouteMatch<{ id: string }>();

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleAddRuleModal, setVisibleAddRuleModal] = useState(false);

  const { accessChannelList } = useContext(RolesContext);

  const { data, error, isLoading, refetch: refetchRole } = useQuery(
    ['postIamRoleGet', routeMatch.params.id],
    () =>
      rolesApi.postIamRoleGet({
        getIamRoleRequest: {
          id: routeMatch.params.id,
        },
      }),
    {
      retry: false,
    }
  );

  const { data: rolePermissions, refetch } = useQuery(
    ['postIamRolePermissionsList', routeMatch.params.id],
    () =>
      rolePermissionsApi.postIamRolePermissionsList({
        listIamRolePermissionsRequest: {
          iamRoleId: routeMatch.params.id,
        },
      }),
    {
      retry: false,
    }
  );

  const accessChannel = useMemo(() => {
    return accessChannelList.find(
      (item) => data?.iamRole?.accessChannelId === item.id
    );
  }, [accessChannelList, data]);

  const rolePermissionsList = useMemo(() => {
    let permissions = {};

    forEach(rolePermissions?.iamRolePermissions, (item) => {
      // @ts-ignore
      permissions[item.permissionId] = true;
    });

    return permissions;
  }, [rolePermissions]);

  return (
    <div className="flex flex-col w-full bg-gray-200">
      <div className="w-full">
        <PageHeader
          title={t('accessManagement.rolesAndPermissions.roleDetails.title')}
        />

        {data?.iamRole && accessChannel && visibleAddRuleModal && (
          <AddRoleModal
            roleId={data?.iamRole.id}
            visible={visibleAddRuleModal}
            onSubmit={() => {
              setVisibleAddRuleModal(false);
              refetch();
              refetchRole();
            }}
            closeModal={() => setVisibleAddRuleModal(false)}
            defaultValues={{
              name: data?.iamRole?.roleName,
              description: data?.iamRole?.roleDescription,
              reason: data?.iamRole?.reason,
              channel: {
                value: data?.iamRole?.accessChannelId,
                label: (
                  <div className="flex items-center">
                    {iconMap(accessChannel.name!)} {accessChannel.name}
                  </div>
                ),
              },
            }}
          />
        )}

        <div className="h-full p-3">
          <Card className="h-full">
            <div className="flex flex-col w-full h-full">
              <div className="flex justify-between">
                <h2 className="text-xl font-semibold">
                  {data?.iamRole?.roleName}
                </h2>

                {error?.status !== 403 && !isLoading && (
                  <div className="flex">
                    <Button
                      variant="blue-outline"
                      widthClass="auto"
                      className="mr-3"
                      onClick={() => setVisibleAddRuleModal(true)}
                    >
                      {t(
                        'accessManagement.rolesAndPermissions.roleDetails.editRole'
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      widthClass="auto"
                      onClick={() => {
                        setVisibleDeleteModal(true);
                      }}
                    >
                      {t(
                        'accessManagement.rolesAndPermissions.roleDetails.deleteRole'
                      )}
                    </Button>
                  </div>
                )}
              </div>

              <div className="flex h-20 py-5 leading-tight">
                <div className="flex-col mr-10">
                  <div className="mb-2 text-xs font-medium text-gray-900">
                    {t(
                      'accessManagement.rolesAndPermissions.roleDetails.accessChannel'
                    )}
                  </div>
                  {accessChannel ? (
                    <AvatarBadge
                      item={{
                        id: '1',
                        name: accessChannel?.name!,
                        icon: iconMap(accessChannel?.name!),
                      }}
                    />
                  ) : (
                    <RectLoader className={'w-full'} width={100} height={20} />
                  )}
                </div>
                <div className="flex-col mr-10">
                  <div className="mb-2 text-xs font-medium text-gray-900">
                    {t(
                      'accessManagement.rolesAndPermissions.roleDetails.createdBy'
                    )}
                  </div>
                  {data ? (
                    <AvatarBadge
                      item={{
                        id: '2',
                        name: data.iamRole.createdByName,
                        avatar: '',
                      }}
                    />
                  ) : (
                    <RectLoader className={'w-full'} width={100} height={20} />
                  )}
                </div>
                <div className="flex-col mr-10">
                  <div className="mb-2 text-xs font-medium text-gray-900">
                    {t(
                      'accessManagement.rolesAndPermissions.roleDetails.createdOn'
                    )}
                  </div>
                  {data ? (
                    <div className="flex items-center h-8">
                      <span className="font-semibold text-xs text-gray-900">
                        {moment(data.iamRole.createdAt).format(
                          'DD MMMM, YYYY [at] HH:mm'
                        )}
                      </span>
                    </div>
                  ) : (
                    <RectLoader className={'w-full'} width={100} height={20} />
                  )}
                </div>

                {moment(get(data, 'iamRole.updatedAt', null)).format(
                  'YYYYMMDDHHmmss'
                ) !==
                  moment(get(data, 'iamRole.createdAt', null)).format(
                    'YYYYMMDDHHmmss'
                  ) && (
                  <div className='flex'>
                    {data?.iamRole?.updatedByName && (
                      <div className="flex-col mr-10">
                        <div className="mb-2 text-xs font-medium text-gray-900">
                          {t(
                            'accessManagement.rolesAndPermissions.roleDetails.updatedBy'
                          )}
                        </div>
                        {data ? (
                          <AvatarBadge
                            item={{
                              id: '2',
                              name: data.iamRole.updatedByName,
                              avatar: '',
                            }}
                          />
                        ) : (
                          <RectLoader
                            className={'w-full'}
                            width={100}
                            height={20}
                          />
                        )}
                      </div>
                    )}
                    {data?.iamRole?.updatedAt && (
                      <div className="flex-col mr-10">
                        <div className="mb-2 text-xs font-medium text-gray-900">
                          {t(
                            'accessManagement.rolesAndPermissions.roleDetails.updatedOn'
                          )}
                        </div>
                        {data ? (
                          <div className="flex items-center h-8">
                            <span className="font-semibold text-xs text-gray-900">
                              {moment(data.iamRole.updatedAt).format(
                                'DD MMMM, YYYY [at] HH:mm'
                              )}
                            </span>
                          </div>
                        ) : (
                          <RectLoader
                            className={'w-full'}
                            width={100}
                            height={20}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {error?.status === 403 ? (
                <div className="mt-6 py-3 px-5 bg-red-100 text-sm text-gray-900 rounded-sm">
                  {t('permissions.permissionDenied')}
                </div>
              ) : (
                !isLoading && (
                  <div>
                    <div className="flex my-6 -mx-6">
                      <div className="flex h-12">
                        <TabLink
                          to={`${routeMatch.url}`}
                          widthClass="w-44"
                          activeClass="border-r"
                        >
                          {t(
                            'accessManagement.rolesAndPermissions.roleDetails.assignedPermissions'
                          )}
                        </TabLink>
                        <TabLink
                          to={`${routeMatch.url}/assigned-users`}
                          widthClass="w-36"
                          activeClass="border-l border-r"
                        >
                          {t(
                            'accessManagement.rolesAndPermissions.roleDetails.assignedUsers'
                          )}
                        </TabLink>
                      </div>
                      <div className="w-full border-b" />
                    </div>

                    <Switch>
                      <Route path={`${routeMatch.url}/assigned-users`}>
                        <AssignedUsers roleId={routeMatch.params.id} />
                      </Route>
                      <Route path={`${routeMatch.url}`}>
                        <AssignedPermissions
                          channelId={data?.iamRole?.accessChannelId}
                          iamRolePermissions={rolePermissionsList}
                        />
                      </Route>
                    </Switch>
                  </div>
                )
              )}
            </div>
          </Card>
        </div>
      </div>

      {visibleDeleteModal && (
        <DeleteRoleModal
          id={routeMatch.params.id}
          onSubmit={() => {
            history.push('/access-management/roles-and-permissions/roles');
          }}
          visible={!!visibleDeleteModal}
          closeModal={() => setVisibleDeleteModal(false)}
        />
      )}

      {/*<DeleteRoleReassignModal
        visible={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
      />*/}
    </div>
  );
};
