import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container as ListContainer } from './payees-list/Container';
import { AccountholderDetails } from './AccountholderDetails';

export const Payees = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/list`}>
        <ListContainer />
      </Route>
      <Route path={`${match.path}/:id`}>
        <AccountholderDetails />
      </Route>
    </Switch>
  );
};
