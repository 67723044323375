import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Column } from 'src/components/table/Table';
import { LogsItems } from 'src/api/services/access-management/roles-and-permissions/getLogs';

export const useLogsColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    (): Column[] => [
      {
        id: 'roleName',
        value: (item: LogsItems) => item.roleName,
        label: t('accessManagement.rolesAndPermissions.logs.list.roleName'),
        columnFilter: () => null,
      },
      {
        id: 'action',
        value: (item: LogsItems) => item.action,
        label: t('accessManagement.rolesAndPermissions.logs.list.action'),
        columnFilter: () => null,
      },
      {
        id: 'user',
        value: (item: LogsItems) => (
          <div className='flex items-center'>
            <img src="https://admin.payallps.com/uploads/thumbnail_Screenshot_2021_01_26_at_01_39_28_8904ee2ea5.png" alt='avatar' className='rounded-full mr-2' style={{ width: '16px', height: '16px' }}/>
            <span className='font-semibold text-xs text-gray-900'>{item.user.name}</span>
          </div>
        ),
        label: t('accessManagement.rolesAndPermissions.logs.list.user'),
        columnFilter: () => null,
      },
      {
        id: 'date',
        value: (item: LogsItems) => (
          <div>
            <span className='font-semibold text-xs text-gray-900'>{moment(item.date).format('DD MMM, YYYY')}</span>
            <br/>
            <span className='text-xxs text-gray-700'>at {moment(item.date).format('HH:mm')}</span>
          </div>
        ),
        label: t('accessManagement.rolesAndPermissions.logs.list.date'),
        columnFilter: () => null,
      },
    ],
    [t]
  );
};
