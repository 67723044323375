import React, { useReducer, useCallback, useEffect } from 'react';
import { Details } from './Details';
import { reducer, initialState, Actions, DetailsContext } from './context';
import { useRouteMatch, useParams } from 'react-router-dom';
import { NotFound } from './NotFound';
import { useDispatch } from 'react-redux';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import { adjustmentsApi } from 'src/api';

const fetchChargeback = ({ id }: { id: string }) => {
  return adjustmentsApi.postAdjustmentsGet({
    idWrapper: { id },
  });
};

export const Container = () => {
  const [state, dispatchAction] = useReducer(reducer, initialState);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info({ ...action, context: 'ADJUSTMENTS/DETAILS' });
    }

    dispatchAction(action);
  }, []);

  const routeMatch = useRouteMatch();

  const { id } = useParams<{ id: string }>();

  const storeDispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('transactionsManagement.breadcrumb'),
          route: 'transaction-management/adjustments',
        },
        {
          label: t('transactionsManagement.adjustments.breadcrumb'),
          route: 'transaction-management/adjustments',
        },
        {
          label: t('transactionsManagement.adjustments.details.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  useEffect(() => {
    let canceled = false;

    dispatch({
      type: 'SET_ADJUSTMENT_LOADING',
      loading: true,
    });

    fetchChargeback({ id })
      .then((data) => {
        if (!canceled) {
          dispatch({ type: 'SET_ADJUSTMENT', adjustment: data });
        }
      })
      .catch(() => {
        if (!canceled) {
          dispatch({
            type: 'SET_ADJUSTMENT_NOT_FOUND',
            notFound: true,
          });
        }
      })
      .finally(() => {
        if (!canceled) {
          dispatch({
            type: 'SET_ADJUSTMENT_LOADING',
            loading: false,
          });
        }
      });

    return () => {
      canceled = true;
    };
  }, [dispatch, routeMatch.url, routeMatch.isExact, id]);

  return (
    <DetailsContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {state.adjustmentNotFound ? <NotFound /> : <Details />}
    </DetailsContext.Provider>
  );
};
