/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteIamUserRequest
 */
export interface DeleteIamUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteIamUserRequest
     */
    iamUserId: string;
}

export function DeleteIamUserRequestFromJSON(json: any): DeleteIamUserRequest {
    return DeleteIamUserRequestFromJSONTyped(json, false);
}

export function DeleteIamUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteIamUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamUserId': json['iam_user_id'],
    };
}

export function DeleteIamUserRequestToJSON(value?: DeleteIamUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iam_user_id': value.iamUserId,
    };
}


