import React, { useContext } from 'react';
import {
  DropdownFilter,
  InputDropdown,
} from 'src/components/dropdown-filter/DropdownFilter';
import { useTranslation } from 'react-i18next';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { CheckboxList } from 'src/components/dropdown-filter/CheckboxList';
import { ListContext } from '../context';
import { TransactionType } from '../../authorization';

export const FilterAuthorizationType = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const items = [
    TransactionType.AUTHORIZATION,
    TransactionType.REFUND,
    TransactionType.CAPTURE,
    TransactionType.VOID,
    TransactionType.PURCHASE,
    TransactionType.REVERSAL,
    TransactionType.VERIFICATION,
  ].map((item) => ({
    value: item,
    label: t(`transactionsManagement.authorizations.transactionType.${item}`),
  }));

  const itemToggle = (options: { checked: boolean; item: ItemFilter }) =>
    dispatch({
      type: options.checked
        ? 'ADD_TRANSACTION_TYPE_FILTER'
        : 'REMOVE_TRANSACTION_TYPE_FILTER',
      itemFilter: options.item,
    });

  const clearFilter = () => dispatch({ type: 'RESET_TRANSACTION_TYPE_FILTER' });

  return (
    <DropdownFilter
      title={t(
        'transactionsManagement.transactions.list.filters.transactionType'
      )}
      headerSecondaryLabel={t(
        'transactionsManagement.transactions.list.filters.numberSelected',
        { totalItems: filters.transactionTypes.length }
      )}
      onClearFilter={clearFilter}
      trigger={(dropdownProps) => (
        <InputDropdown
          name="filterTransactionTypes"
          label={t(
            'transactionsManagement.transactions.list.filters.transactionType'
          )}
          placeholder={t(
            'transactionsManagement.transactions.list.filters.all'
          )}
          value={
            filters.transactionTypes.length
              ? t(
                  'transactionsManagement.transactions.list.filters.numberSelected',
                  { totalItems: filters.transactionTypes.length }
                )
              : ''
          }
          {...dropdownProps}
        />
      )}
    >
      <CheckboxList
        items={items}
        filters={filters.transactionTypes}
        onItemToggle={itemToggle}
        totalItems={filters.transactionTypes.length}
        heightClassName="h-auto"
      />
    </DropdownFilter>
  );
};
