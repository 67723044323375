/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Program,
    ProgramFromJSON,
    ProgramFromJSONTyped,
    ProgramToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListProgramsResponse
 */
export interface ListProgramsResponse {
    /**
     * 
     * @type {Array<Program>}
     * @memberof ListProgramsResponse
     */
    programs?: Array<Program>;
}

export function ListProgramsResponseFromJSON(json: any): ListProgramsResponse {
    return ListProgramsResponseFromJSONTyped(json, false);
}

export function ListProgramsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProgramsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programs': !exists(json, 'programs') ? undefined : ((json['programs'] as Array<any>).map(ProgramFromJSON)),
    };
}

export function ListProgramsResponseToJSON(value?: ListProgramsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'programs': value.programs === undefined ? undefined : ((value.programs as Array<any>).map(ProgramToJSON)),
    };
}


