import React, { ReactNode } from 'react';
import classNames from 'classnames';

export const Card = ({
  children,
  className,
  horizontalPaddingClassName = 'px-6',
  verticalPaddingClassName = 'py-6',
}: {
  children: ReactNode;
  className?: string;
  horizontalPaddingClassName?: string;
  verticalPaddingClassName?: string;
}) => (
  <div
    className={classNames(
      'bg-white border rounded-sm',
      horizontalPaddingClassName,
      verticalPaddingClassName,
      className
    )}
  >
    {children}
  </div>
);
