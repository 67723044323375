import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { RolesPage } from './tabs/rules/RolesPage';
import { RolesLogsPage } from 'src/domain/access-management/roles-and-permissions/tabs/rules-log/RolesLogsPage';

export const RolesAndPermissions = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path + '/roles'}>
        <RolesPage />
      </Route>
      <Route path={match.path + '/roles-logs'} exact>
        <RolesLogsPage />
      </Route>
    </Switch>
  );
};
