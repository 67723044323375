import React from 'react';

export const IconSortArrow = ({ className }: { className?: string }) => (
  <svg
    width="7"
    height="3"
    viewBox="0 0 7 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.28896 0.208871C3.40587 0.0931682 3.59413 0.0931681 3.71104 0.208871L6.01255 2.48678C6.20304 2.67531 6.06953 3 5.80151 3L1.19849 3C0.93047 3 0.796961 2.67532 0.987452 2.48678L3.28896 0.208871Z"
      fill="currentColor"
    />
  </svg>
);
