import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';

import { IconLogo } from 'src/components/icons/IconLogo';
import { useCurrentUser } from 'src/auth';
import { useForm } from 'react-hook-form';
import { AuthCodeInput } from 'src/components/Input';
import { Button } from 'src/components/Button';

import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/arrowLeft2.svg';
import verified from '../../../assets/images/verified.png';
import { Loading } from 'src/components/Loading';
import { Link } from 'react-router-dom';

type FormSchema = {
  phoneCode?: string;
};

export const VerifyPhone = ({
  onCancel,
  onSubmit,
  onVerified,
  isCheckFinished,
}: {
  onCancel?: () => void;
  onSubmit?: () => void;
  onVerified?: () => void;
  isCheckFinished?: boolean;
}) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();

  const { handleSubmit, errors, setError, control } = useForm<FormSchema>();

  const [loadingChallenge, setLoadingChallenge] = useState(true);
  const [loadingChallengeError, setLoadingChallengeError] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmitHandle = async (data: FormSchema) => {
    setLoading(true);

    if (data.phoneCode) {
      try {
        await Auth.verifyCurrentUserAttributeSubmit(
          'phone_number',
          data.phoneCode
        );
        onSubmit && onSubmit();
        setIsSuccess(true);
      } catch (e) {
        setError('phoneCode', {
          type: 'manual',
          message: get(e, 'message'),
        });
      }
    }

    setLoading(false);
  };

  const dispatchChallenge = useCallback(async () => {
    setLoadingChallenge(true);
    await verifyPhone();
    setLoadingChallenge(false);
  }, []);

  const resendChallenge = useCallback(async () => {
    setResendLoading(true);
    await verifyPhone();
    setResendLoading(false);
  }, []);

  const verifyPhone = useCallback(async () => {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number');
    } catch (e) {
      setLoadingChallengeError(get(e, 'message'));
    }
  }, []);

  useEffect(() => {
    dispatchChallenge();
  }, [dispatchChallenge]);

  return (
    <div className="flex items-center justify-center bg-white fixed top-0 left-0 w-full h-full z-50">
      {loadingChallenge && <Loading />}
      {onCancel && !isSuccess && !loadingChallenge && (
        <button
          onClick={onCancel}
          className="absolute top-0 left-0 flex items-center text-xs bg-white border-none text-blue-500 font-semibold focus:outline-none mt-8 ml-8"
        >
          <ArrowLeftIcon className="mr-2" /> {t('phoneVerification.cancel')}
        </button>
      )}

      {!isSuccess && !loadingChallenge && (
        <div className="flex flex-col items-center w-92">
          <IconLogo className="mb-5" />

          <h3 className="text-2xl font-semibold text-center px-2 leading-8">
            {t('phoneVerification.phoneNumberSent')}
          </h3>

          <p className="mt-4 text-base font-semibold text-center">
            {user?.phoneNumber}
          </p>
          <p className="text-xs text-gray-700 font-semibold mt-1">
            Wrong Number?{' '}
            <a
              href="mailto:support@agilepartners.eu"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs font-semibold text-blue-500 hover:text-blue-500"
            >
              Contact Support
            </a>
          </p>

          <form onSubmit={handleSubmit(onSubmitHandle)} className="w-full mt-4">
            <div className="mb-10">
              <AuthCodeInput
                label={t('phoneVerification.code')}
                name="phoneCode"
                error={errors.phoneCode}
                control={control}
                rules={{ required: true }}
              />
            </div>

            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              size="large"
              widthClass="w-full"
            >
              {t('phoneVerification.verifyButton')}
            </Button>

            <p className="text-center mt-4">
              {resendLoading ? (
                <div className="flex justify-center">
                  <Loading />
                </div>
              ) : (
                <button
                  type="button"
                  className="text-xs bg-white border-none text-blue-500 font-semibold focus:outline-none"
                  onClick={resendChallenge}
                >
                  {t('phoneVerification.resend')}
                </button>
              )}
            </p>
          </form>
        </div>
      )}

      {isSuccess && (
        <div className="flex flex-col items-center w-92">
          <img src={verified} alt="verified" className="mb-8 w-24" />

          <h3 className="text-2xl font-semibold text-center px-8 leading-8 mb-8">
            {t('phoneVerification.successTitle')}
          </h3>

          {onCancel && (
            <Button
              loading={loading}
              disabled={loading}
              type="button"
              size="large"
              widthClass="w-full"
              onClick={onCancel}
            >
              Ok
            </Button>
          )}

          {!onCancel && (
            <Button
              loading={loading}
              disabled={loading}
              type="button"
              size="large"
              widthClass="w-full"
              onClick={() => { onVerified && onVerified(); }}
            >
              Ok
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
