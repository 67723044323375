import React, { ReactNode } from 'react';
import ReactInfiniteScroll from 'react-infinite-scroll-component';

export const InfiniteScroll = ({
  dataLength,
  hasMore,
  children,
  endMessage,
  next,
  scrollableTarget,
}: {
  dataLength: number;
  hasMore: boolean;
  children: ReactNode;
  endMessage?: string;
  next: () => void;
  scrollableTarget: string;
}) => (
  <ReactInfiniteScroll
    dataLength={dataLength}
    next={next}
    hasMore={hasMore}
    loader={
      <div className="my-2 text-xs text-center text-gray-700">Loading...</div>
    }
    endMessage={endMessage}
    scrollableTarget={scrollableTarget}
  >
    {children}
  </ReactInfiniteScroll>
);
