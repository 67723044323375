import { ReactNotificationOptions, store } from 'react-notifications-component';

type Params = Pick<ReactNotificationOptions, 'message'>;

export const notifications = {
  success: (params?: Params) =>
    store.addNotification({
      message: params?.message || 'Success',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    }),
  error: (params?: Params) => {
    store.addNotification({
      message: params?.message || 'Error',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 5000,
        showIcon: true,
        touch: false,
        click: false,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  },
};
