/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetTransactionDetailsResponse,
    GetTransactionDetailsResponseFromJSON,
    GetTransactionDetailsResponseToJSON,
    IdWrapper,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    ListTransactionsFilter,
    ListTransactionsFilterFromJSON,
    ListTransactionsFilterToJSON,
    ListTransactionsPageRequest,
    ListTransactionsPageRequestFromJSON,
    ListTransactionsPageRequestToJSON,
    ListTransactionsPageResponse,
    ListTransactionsPageResponseFromJSON,
    ListTransactionsPageResponseToJSON,
} from '../models';

export interface PostTransactionsCsvRequest {
    listTransactionsFilter?: ListTransactionsFilter;
}

export interface PostTransactionsGetRequest {
    idWrapper?: IdWrapper;
}

export interface PostTransactionsListRequest {
    listTransactionsPageRequest?: ListTransactionsPageRequest;
}

/**
 * 
 */
export class TransactionsApi extends runtime.BaseAPI {

    /**
     */
    async postTransactionsCsvRaw(requestParameters: PostTransactionsCsvRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/transactions/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListTransactionsFilterToJSON(requestParameters.listTransactionsFilter),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async postTransactionsCsv(requestParameters: PostTransactionsCsvRequest): Promise<Blob> {
        const response = await this.postTransactionsCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postTransactionsGetRaw(requestParameters: PostTransactionsGetRequest): Promise<runtime.ApiResponse<GetTransactionDetailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/transactions/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdWrapperToJSON(requestParameters.idWrapper),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTransactionDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postTransactionsGet(requestParameters: PostTransactionsGetRequest): Promise<GetTransactionDetailsResponse> {
        const response = await this.postTransactionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postTransactionsListRaw(requestParameters: PostTransactionsListRequest): Promise<runtime.ApiResponse<ListTransactionsPageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/transactions/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListTransactionsPageRequestToJSON(requestParameters.listTransactionsPageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTransactionsPageResponseFromJSON(jsonValue));
    }

    /**
     */
    async postTransactionsList(requestParameters: PostTransactionsListRequest): Promise<ListTransactionsPageResponse> {
        const response = await this.postTransactionsListRaw(requestParameters);
        return await response.value();
    }

}
