/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateProductCategoryRequest,
    CreateProductCategoryRequestFromJSON,
    CreateProductCategoryRequestToJSON,
    CreateProductCategoryResponse,
    CreateProductCategoryResponseFromJSON,
    CreateProductCategoryResponseToJSON,
    GetProductCategoryRequest,
    GetProductCategoryRequestFromJSON,
    GetProductCategoryRequestToJSON,
    GetProductCategoryResponse,
    GetProductCategoryResponseFromJSON,
    GetProductCategoryResponseToJSON,
    ListProductCategoriesRequest,
    ListProductCategoriesRequestFromJSON,
    ListProductCategoriesRequestToJSON,
    ListProductCategoriesResponse,
    ListProductCategoriesResponseFromJSON,
    ListProductCategoriesResponseToJSON,
} from '../models';

export interface PostProductCategoriesCreateRequest {
    createProductCategoryRequest: CreateProductCategoryRequest;
}

export interface PostProductCategoriesGetRequest {
    getProductCategoryRequest: GetProductCategoryRequest;
}

export interface PostProductCategoriesListRequest {
    listProductCategoriesRequest?: ListProductCategoriesRequest;
}

/**
 * 
 */
export class ProductCategoriesApi extends runtime.BaseAPI {

    /**
     */
    async postProductCategoriesCreateRaw(requestParameters: PostProductCategoriesCreateRequest): Promise<runtime.ApiResponse<CreateProductCategoryResponse>> {
        if (requestParameters.createProductCategoryRequest === null || requestParameters.createProductCategoryRequest === undefined) {
            throw new runtime.RequiredError('createProductCategoryRequest','Required parameter requestParameters.createProductCategoryRequest was null or undefined when calling postProductCategoriesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/product-categories/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductCategoryRequestToJSON(requestParameters.createProductCategoryRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProductCategoryResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProductCategoriesCreate(requestParameters: PostProductCategoriesCreateRequest): Promise<CreateProductCategoryResponse> {
        const response = await this.postProductCategoriesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProductCategoriesGetRaw(requestParameters: PostProductCategoriesGetRequest): Promise<runtime.ApiResponse<GetProductCategoryResponse>> {
        if (requestParameters.getProductCategoryRequest === null || requestParameters.getProductCategoryRequest === undefined) {
            throw new runtime.RequiredError('getProductCategoryRequest','Required parameter requestParameters.getProductCategoryRequest was null or undefined when calling postProductCategoriesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/product-categories/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProductCategoryRequestToJSON(requestParameters.getProductCategoryRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductCategoryResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProductCategoriesGet(requestParameters: PostProductCategoriesGetRequest): Promise<GetProductCategoryResponse> {
        const response = await this.postProductCategoriesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProductCategoriesListRaw(requestParameters: PostProductCategoriesListRequest): Promise<runtime.ApiResponse<ListProductCategoriesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/product-categories/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListProductCategoriesRequestToJSON(requestParameters.listProductCategoriesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProductCategoriesResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProductCategoriesList(requestParameters: PostProductCategoriesListRequest): Promise<ListProductCategoriesResponse> {
        const response = await this.postProductCategoriesListRaw(requestParameters);
        return await response.value();
    }

}
