import React, { useMemo } from 'react';
import moment from 'moment';
import { CellLight, Column } from 'src/components/table/Table';
import { AdjustmentItem } from './context';

export const useAdjustmentsColumns = () => {
  const columns = useMemo(
    (): Column[] => [
      {
        id: 'recordType',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.recordType}</CellLight>
        ),
        label: 'Record Type',
      },
      {
        id: 'externalID',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.externalId}</CellLight>
        ),
        label: 'External ID',
      },
      {
        id: 'fundedDate',
        value: (item: AdjustmentItem) => (
          <CellLight>
            {moment(item.fundedDate).format('DD MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Funded date',
      },
      {
        id: 'processedDate',
        value: (item: AdjustmentItem) => (
          <CellLight>
            {moment(item.processedDate).format('DD MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Processed Date',
      },
      {
        id: 'currencyCode',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.currencyCode}</CellLight>
        ),
        label: 'Currency Code',
      },
      {
        id: 'adjustmentDate',
        value: (item: AdjustmentItem) => (
          <CellLight>
            {moment(item.adjustmentDate).format('DD MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Adjustment Date',
      },
      {
        id: 'adjustmentCode',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.adjustmentCode}</CellLight>
        ),
        label: 'Adjustment Code',
      },
      {
        id: 'adjustmentDescription',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.adjustmentDescription}</CellLight>
        ),
        label: 'Adjustment Description',
      },
      {
        id: 'adjustmentAmount',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.adjustmentaAmount}</CellLight>
        ),
        label: 'Adjustment Amount',
      },
      {
        id: 'adjustmentType',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.adjustmentType}</CellLight>
        ),
        label: 'Adjustment Type',
      },
      {
        id: 'newAdjustmentDescription',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.newAdjustmentDescription}</CellLight>
        ),
        label: 'New Adjustment Description',
      },
      {
        id: 'transactionCount',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.transactionCount}</CellLight>
        ),
        label: 'Transaction Count',
      },
      {
        id: 'rate',
        value: (item: AdjustmentItem) => <CellLight>{item.rate}</CellLight>,
        label: 'Rate',
      },
      {
        id: 'unitAmount',
        value: (item: AdjustmentItem) => (
          <CellLight>{item.unitAmount}</CellLight>
        ),
        label: 'Unit Amount',
      },
      {
        id: 'submitDate',
        value: (item: AdjustmentItem) => (
          <CellLight>
            {moment(item.submitDate).format('DD MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Submit Date',
      },
    ],
    []
  );

  return columns;
};
