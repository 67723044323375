import React from 'react';

export const IconPocket = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.66665 2H13.3333C13.6869 2 14.0261 2.14048 14.2761 2.39052C14.5262 2.64057 14.6666 2.97971 14.6666 3.33333V7.33333C14.6666 9.10144 13.9643 10.7971 12.714 12.0474C11.4638 13.2976 9.76809 14 7.99998 14C7.1245 14 6.25759 13.8276 5.44876 13.4925C4.63992 13.1575 3.90499 12.6664 3.28593 12.0474C2.03569 10.7971 1.33331 9.10144 1.33331 7.33333V3.33333C1.33331 2.97971 1.47379 2.64057 1.72384 2.39052C1.97389 2.14048 2.31302 2 2.66665 2V2Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33331 6.66675L7.99998 9.33341L10.6666 6.66675"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
