import React, { memo } from 'react';
import debounce from 'lodash.debounce';
import BaseAsyncSelect, { AsyncProps } from 'react-select/async';
import { NamedProps } from 'react-select';
import {
  Control,
  Controller,
  FieldErrors,
  UseControllerOptions,
} from 'react-hook-form';
import { Label, LabelProps } from 'src/components/form/FormLabel';
import {
  DropdownIndicator,
  getCustomSelectStyles,
  OptionType,
} from 'src/components/form/Select';

type NativeSelectProps = Pick<
  NamedProps,
  | 'isMulti'
  | 'placeholder'
  | 'isLoading'
  | 'isClearable'
  | 'isSearchable'
  | 'isDisabled'
  | 'name'
  | 'defaultValue'
>;

interface Props extends LabelProps, NativeSelectProps, AsyncProps<OptionType> {
  name: string;
  control: Control;
  rules?: UseControllerOptions['rules'];
  error?: FieldErrors;
  debounceWait?: number;
}

export const AsyncSelect = memo(
  ({
    label,
    labelColorClassName,
    labelFontClassName,
    name,
    control,
    rules,
    error,
    loadOptions,
    placeholder,
    isMulti,
    isDisabled,
    isClearable,
    isSearchable,
    defaultValue,
    debounceWait = 700,
    ...restProps
  }: Props) => {
    const debouncedLoadOptions = debounce(loadOptions, debounceWait);

    return (
      <Label
        name={name}
        label={label}
        labelFontClassName={labelFontClassName}
        labelColorClassName={labelColorClassName}
        error={error}
      >
        <Controller
          placeholder={placeholder}
          defaultValue={defaultValue}
          error={error}
          rules={rules}
          name={name}
          isMulti={isMulti}
          isDisabled={isDisabled}
          control={control}
          isSearchable={isSearchable}
          isClearable={isClearable}
          menuPlacement={'auto'}
          loadOptions={debouncedLoadOptions}
          styles={getCustomSelectStyles(error)}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          as={BaseAsyncSelect}
          {...restProps}
        />
      </Label>
    );
  }
);
