/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyTopId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    dbaName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    licNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    institutionType?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    countryIsoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    addressId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    status?: string;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    statusDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Company
     */
    programsNameList?: Array<string>;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'updatedBy': !exists(json, 'updated_by') ? undefined : json['updated_by'],
        'parentId': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'companyTopId': !exists(json, 'company_top_id') ? undefined : json['company_top_id'],
        'dbaName': !exists(json, 'dba_name') ? undefined : json['dba_name'],
        'shortName': !exists(json, 'short_name') ? undefined : json['short_name'],
        'licNumber': !exists(json, 'lic_number') ? undefined : json['lic_number'],
        'institutionType': !exists(json, 'institution_type') ? undefined : json['institution_type'],
        'countryIsoCode': !exists(json, 'country_iso_code') ? undefined : json['country_iso_code'],
        'addressId': !exists(json, 'address_id') ? undefined : json['address_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDate': !exists(json, 'status_date') ? undefined : (new Date(json['status_date'])),
        'programsNameList': !exists(json, 'programs_name_list') ? undefined : json['programs_name_list'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updated_by': value.updatedBy,
        'parent_id': value.parentId,
        'company_top_id': value.companyTopId,
        'dba_name': value.dbaName,
        'short_name': value.shortName,
        'lic_number': value.licNumber,
        'institution_type': value.institutionType,
        'country_iso_code': value.countryIsoCode,
        'address_id': value.addressId,
        'status': value.status,
        'status_date': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
        'programs_name_list': value.programsNameList,
    };
}


