import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  DropdownFilter,
  InputDropdown,
} from 'src/components/dropdown-filter/DropdownFilter';
import { useTranslation } from 'react-i18next';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { CheckboxList } from 'src/components/dropdown-filter/CheckboxList';
import { useSearchableList } from 'src/hooks/useSearchableList';
import { ListContext } from '../context';
import { merchantsApi } from 'src/api';

const SEARCH_LIMIT = 10;

const resource: Parameters<typeof useSearchableList>['0']['resource'] = async (
  options
) => {
  const data = await merchantsApi.postMerchantsList({
    body: {
      ...options,
    },
  });

  return {
    data: (data?.merchants || [])
      .filter((item) => !!item.id)
      .map((item) => ({
        label: item.name || '',
        value: item.id!,
      })),
    meta: {
      totalItems: 0,
    },
  };
};

export const FilterMerchants = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const [itemsLoaded, setItemsLoaded] = useState<ItemFilter[]>([]);

  const [searchOffset, setSearchOffset] = useState(0);

  const {
    totalItems,
    itemsLoading,
    fetchItems,
    handleHide,
    items,
    setItemsLoading,
  } = useSearchableList({
    resource,
    offset: searchOffset,
    limit: SEARCH_LIMIT,
    setSearchOffset,
    setItemsLoaded,
  });

  const itemsLoadedRef = useRef<typeof itemsLoaded>(itemsLoaded);

  useEffect(() => {
    itemsLoadedRef.current = itemsLoaded;
  }, [itemsLoaded]);

  useEffect(() => {
    setItemsLoaded([...itemsLoadedRef.current, ...items]);
  }, [items]);

  const nameToggle = (options: { checked: boolean; item: ItemFilter }) =>
    dispatch({
      type: options.checked
        ? 'ADD_MERCHANTS_FILTER'
        : 'REMOVE_MERCHANTS_FILTER',
      itemFilter: options.item,
    });

  const [searchQuery, setSearchQuery] = useState('');

  const itemsLoadedFiltered = useMemo(() => {
    const searchQueryLowercased = searchQuery.toLowerCase();

    return searchQuery
      ? itemsLoaded.filter((item) =>
          (item.label as string).toLowerCase().includes(searchQueryLowercased)
        )
      : itemsLoaded;
  }, [itemsLoaded, searchQuery]);

  const clearFilter = () => dispatch({ type: 'RESET_MERCHANTS_FILTER' });

  const onOpen = useCallback(() => {
    fetchItems();
    console.log('testing');
  }, [fetchItems]);

  return (
    <DropdownFilter
      title={t('transactionsManagement.transactions.list.filters.merchant')}
      headerSecondaryLabel={t(
        'transactionsManagement.transactions.list.filters.numberSelected',
        { totalItems: filters.merchants.length }
      )}
      searchPlaceholder={
        itemsLoaded.length
          ? t(
              'transactionsManagement.transactions.list.filters.searchPlaceholders.merchants',
              { totalItems: itemsLoaded.length }
            )
          : undefined
      }
      withSearch
      searchLoading={itemsLoading}
      onSearchQueryChange={(searchQuery) => {
        fetchItems(searchQuery);
        setSearchQuery(searchQuery);
      }}
      setItemsLoaded={setItemsLoaded}
      setItemsLoading={setItemsLoading}
      setSearchOffset={setSearchOffset}
      searchOffset={searchOffset}
      onOpen={onOpen}
      onClose={handleHide}
      onClearFilter={clearFilter}
      trigger={(dropdownProps) => (
        <InputDropdown
          name="filterMerchants"
          label={t('transactionsManagement.transactions.list.filters.merchant')}
          placeholder={t(
            'transactionsManagement.transactions.list.filters.all'
          )}
          value={
            filters.merchants.length
              ? t(
                  'transactionsManagement.transactions.list.filters.numberSelected',
                  { totalItems: filters.merchants.length }
                )
              : ''
          }
          {...dropdownProps}
        />
      )}
    >
      <CheckboxList
        items={itemsLoadedFiltered}
        loading={itemsLoading}
        filters={filters.merchants}
        onItemToggle={nameToggle}
        limit={SEARCH_LIMIT}
        searchOffset={searchOffset}
        setSearchOffset={setSearchOffset}
        totalItems={totalItems}
      />
    </DropdownFilter>
  );
};
