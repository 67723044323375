import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import { OptionType, Select } from 'src/components/form/Select';
import {
  addUserFormType,
  addUserItemType,
  AssignmentsItem,
} from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserModal';
import { nameof, typeProxy } from 'src/utils/nameof';
import { map, differenceBy, filter } from 'lodash';

type DefaultValues = Partial<
  Omit<AssignmentsItem, 'program' | 'autoAssignProgram'>
>;

interface Props {
  defaultValues: DefaultValues;
  fieldIndex: number;
  fieldValues: AssignmentsItem;
  isLoadingRoles: boolean;
  isLoadingCompanies: boolean;
  organizationsOptions: OptionType[];
  rolesOptions: OptionType[];
}

export const AssignmentsFields = memo(
  ({
    defaultValues,
    fieldIndex,
    isLoadingRoles,
    isLoadingCompanies,
    organizationsOptions,
    rolesOptions,
  }: Props) => {
    const { t } = useTranslation();
    const { control, watch, errors } = useFormContext();

    const fieldsValue: AssignmentsItem[] = watch(
      nameof(addUserFormType.companyAssignments)
    );

    const availableOrgnOptions = useMemo(() => {
      const selectedOrgn = filter(
        map(fieldsValue, nameof(addUserItemType.organizations))
      );

      if (!selectedOrgn.length) {
        return organizationsOptions;
      }

      return differenceBy(
        organizationsOptions,
        selectedOrgn,
        nameof(typeProxy<OptionType>().value)
      );
    }, [fieldsValue, organizationsOptions]);

    return (
      <>
        <div className="flex mb-3">
          <div className="w-full mr-1">
            <Select
              label={t(
                'accessManagement.userManagement.form.organization.label'
              )}
              placeholder={t(
                'accessManagement.userManagement.form.organization.placeholder'
              )}
              error={get(
                errors,
                `companyAssignments[${fieldIndex}].organizations`
              )}
              name={`companyAssignments[${fieldIndex}].organizations`}
              control={control}
              defaultValue={defaultValues.organizations}
              options={availableOrgnOptions}
              isLoading={isLoadingCompanies}
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="w-full mr-1">
            <Select
              label={t('accessManagement.userManagement.form.roles')}
              error={get(errors, `companyAssignments[${fieldIndex}].roles`)}
              name={`companyAssignments[${fieldIndex}].roles`}
              control={control}
              defaultValue={defaultValues.roles}
              options={rolesOptions}
              isLoading={isLoadingRoles}
              isMulti
            />
          </div>
        </div>
      </>
    );
  }
);
