/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateIamRoleRequest,
    CreateIamRoleRequestFromJSON,
    CreateIamRoleRequestToJSON,
    CreateIamRoleResponse,
    CreateIamRoleResponseFromJSON,
    CreateIamRoleResponseToJSON,
    DeleteIamRoleRequest,
    DeleteIamRoleRequestFromJSON,
    DeleteIamRoleRequestToJSON,
    GetIamRoleRequest,
    GetIamRoleRequestFromJSON,
    GetIamRoleRequestToJSON,
    GetIamRoleResponse,
    GetIamRoleResponseFromJSON,
    GetIamRoleResponseToJSON,
    IamRoleAssignPermissionRequest,
    IamRoleAssignPermissionRequestFromJSON,
    IamRoleAssignPermissionRequestToJSON,
    ListIamRolesRequest,
    ListIamRolesRequestFromJSON,
    ListIamRolesRequestToJSON,
    ListIamRolesResponse,
    ListIamRolesResponseFromJSON,
    ListIamRolesResponseToJSON,
    UpdateIamRoleRequest,
    UpdateIamRoleRequestFromJSON,
    UpdateIamRoleRequestToJSON,
} from '../models';

export interface PostIamRoleDeleteRequest {
    deleteIamRoleRequest: DeleteIamRoleRequest;
}

export interface PostIamRoleEditRequest {
    updateIamRoleRequest: UpdateIamRoleRequest;
}

export interface PostIamRoleGetRequest {
    getIamRoleRequest: GetIamRoleRequest;
}

export interface PostIamRolesAssignPermissionsRequest {
    iamRoleAssignPermissionRequest?: IamRoleAssignPermissionRequest;
}

export interface PostIamRolesCreateRequest {
    createIamRoleRequest: CreateIamRoleRequest;
}

export interface PostIamRolesListRequest {
    listIamRolesRequest?: ListIamRolesRequest;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     */
    async postIamRoleDeleteRaw(requestParameters: PostIamRoleDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteIamRoleRequest === null || requestParameters.deleteIamRoleRequest === undefined) {
            throw new runtime.RequiredError('deleteIamRoleRequest','Required parameter requestParameters.deleteIamRoleRequest was null or undefined when calling postIamRoleDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-roles/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteIamRoleRequestToJSON(requestParameters.deleteIamRoleRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postIamRoleDelete(requestParameters: PostIamRoleDeleteRequest): Promise<void> {
        await this.postIamRoleDeleteRaw(requestParameters);
    }

    /**
     */
    async postIamRoleEditRaw(requestParameters: PostIamRoleEditRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateIamRoleRequest === null || requestParameters.updateIamRoleRequest === undefined) {
            throw new runtime.RequiredError('updateIamRoleRequest','Required parameter requestParameters.updateIamRoleRequest was null or undefined when calling postIamRoleEdit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-roles/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIamRoleRequestToJSON(requestParameters.updateIamRoleRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postIamRoleEdit(requestParameters: PostIamRoleEditRequest): Promise<void> {
        await this.postIamRoleEditRaw(requestParameters);
    }

    /**
     */
    async postIamRoleGetRaw(requestParameters: PostIamRoleGetRequest): Promise<runtime.ApiResponse<GetIamRoleResponse>> {
        if (requestParameters.getIamRoleRequest === null || requestParameters.getIamRoleRequest === undefined) {
            throw new runtime.RequiredError('getIamRoleRequest','Required parameter requestParameters.getIamRoleRequest was null or undefined when calling postIamRoleGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-roles/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetIamRoleRequestToJSON(requestParameters.getIamRoleRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIamRoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamRoleGet(requestParameters: PostIamRoleGetRequest): Promise<GetIamRoleResponse> {
        const response = await this.postIamRoleGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamRolesAssignPermissionsRaw(requestParameters: PostIamRolesAssignPermissionsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-roles/assign-permission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IamRoleAssignPermissionRequestToJSON(requestParameters.iamRoleAssignPermissionRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postIamRolesAssignPermissions(requestParameters: PostIamRolesAssignPermissionsRequest): Promise<void> {
        await this.postIamRolesAssignPermissionsRaw(requestParameters);
    }

    /**
     */
    async postIamRolesCreateRaw(requestParameters: PostIamRolesCreateRequest): Promise<runtime.ApiResponse<CreateIamRoleResponse>> {
        if (requestParameters.createIamRoleRequest === null || requestParameters.createIamRoleRequest === undefined) {
            throw new runtime.RequiredError('createIamRoleRequest','Required parameter requestParameters.createIamRoleRequest was null or undefined when calling postIamRolesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-roles/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIamRoleRequestToJSON(requestParameters.createIamRoleRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIamRoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamRolesCreate(requestParameters: PostIamRolesCreateRequest): Promise<CreateIamRoleResponse> {
        const response = await this.postIamRolesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamRolesListRaw(requestParameters: PostIamRolesListRequest): Promise<runtime.ApiResponse<ListIamRolesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-roles/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListIamRolesRequestToJSON(requestParameters.listIamRolesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamRolesResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamRolesList(requestParameters: PostIamRolesListRequest): Promise<ListIamRolesResponse> {
        const response = await this.postIamRolesListRaw(requestParameters);
        return await response.value();
    }

}
