import React, { ReactNode, useMemo } from 'react';
import ReactModal from 'react-modal';
import { IconCross } from './icons/IconCross';
import { Loading } from './Loading';

export const FormModal = ({
  isOpen,
  closeModal,
  isLoading,
  children,
  title,
  maxWidth,
  width,
  zIndex = 40,
}: {
  isOpen: boolean;
  closeModal: () => any;
  isLoading?: boolean;
  title?: string | ReactNode;
  maxWidth?: string;
  width?: string;
  zIndex?: number;
  children: ReactNode;
}) => {
  const loading = useMemo(
    () =>
      isLoading && (
        <div
          className={'absolute inset-0 flex justify-center items-center z-20'}
        >
          <div className={'absolute inset-0 bg-white opacity-50'} />
          <Loading width={48} height={48} />
        </div>
      ),
    [isLoading]
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      style={{
        overlay: { zIndex },
        content: {
          border: '0',
          borderRadius: '2px',
          bottom: 'auto',
          left: '50%',
          padding: '1rem',
          position: 'fixed',
          right: 'auto',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          width: width || '80%',
          maxHeight: '95vh',
          overflowY: 'auto',
          maxWidth: maxWidth || '31rem',
        },
      }}
      contentLabel="Example Modal"
    >
      {loading}
      <div className="relative">
        <div className={'relative z-10 flex justify-between item-center '}>
          <span className="flex item-center font-bold tracking-wider text-gray-900 uppercase text-xxxs">
            {title}
          </span>
          <button
            onClick={closeModal}
            className="flex items-center justify-center w-6 h-6  text-gray-700 transition-shadow duration-200 bg-gray-200 rounded-sm focus:shadow-outline focus:outline-none"
          >
            <IconCross />
          </button>
        </div>
        {children}
      </div>
    </ReactModal>
  );
};
