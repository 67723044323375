import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { RolesAndPermissions } from 'src/domain/access-management/roles-and-permissions/RolesAndPermissions';
import { UserManagement } from 'src/domain/access-management/user-management/UserManagement';

export const AccessManagement = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path + '/roles-and-permissions'}>
        <RolesAndPermissions />
      </Route>
      <Route path={match.path + '/user-management'}>
        <UserManagement />
      </Route>
    </Switch>
  );
};
