/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAdjustmentDetailsResponse
 */
export interface GetAdjustmentDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    recordType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    hierarchyId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    hierarchyLevelNo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    locationDBAName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    storeNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    externalId?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAdjustmentDetailsResponse
     */
    fundedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetAdjustmentDetailsResponse
     */
    processedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    ddaNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    abaNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAdjustmentDetailsResponse
     */
    adjustmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    cardType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    adjustmentCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    adjustmentDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAdjustmentDetailsResponse
     */
    adjustmentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    adjustmentType?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAdjustmentDetailsResponse
     */
    invoiceDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    adjustmentInvoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    batchNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    chargebackCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    caseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    specialReference1?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdjustmentDetailsResponse
     */
    newAdjustmentDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAdjustmentDetailsResponse
     */
    transactionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAdjustmentDetailsResponse
     */
    rate?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAdjustmentDetailsResponse
     */
    unitAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetAdjustmentDetailsResponse
     */
    submitDate?: Date;
}

export function GetAdjustmentDetailsResponseFromJSON(json: any): GetAdjustmentDetailsResponse {
    return GetAdjustmentDetailsResponseFromJSONTyped(json, false);
}

export function GetAdjustmentDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAdjustmentDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordType': !exists(json, 'recordType') ? undefined : json['recordType'],
        'hierarchyId': !exists(json, 'hierarchyId') ? undefined : json['hierarchyId'],
        'hierarchyLevelNo': !exists(json, 'hierarchyLevelNo') ? undefined : json['hierarchyLevelNo'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'locationDBAName': !exists(json, 'locationDBAName') ? undefined : json['locationDBAName'],
        'storeNumber': !exists(json, 'storeNumber') ? undefined : json['storeNumber'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'fundedDate': !exists(json, 'fundedDate') ? undefined : (new Date(json['fundedDate'])),
        'processedDate': !exists(json, 'processedDate') ? undefined : (new Date(json['processedDate'])),
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'ddaNumber': !exists(json, 'ddaNumber') ? undefined : json['ddaNumber'],
        'abaNumber': !exists(json, 'abaNumber') ? undefined : json['abaNumber'],
        'adjustmentDate': !exists(json, 'adjustmentDate') ? undefined : (new Date(json['adjustmentDate'])),
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'adjustmentCode': !exists(json, 'adjustmentCode') ? undefined : json['adjustmentCode'],
        'adjustmentDescription': !exists(json, 'adjustmentDescription') ? undefined : json['adjustmentDescription'],
        'adjustmentAmount': !exists(json, 'adjustmentAmount') ? undefined : json['adjustmentAmount'],
        'adjustmentType': !exists(json, 'adjustmentType') ? undefined : json['adjustmentType'],
        'invoiceDate': !exists(json, 'invoiceDate') ? undefined : (new Date(json['invoiceDate'])),
        'adjustmentInvoiceNumber': !exists(json, 'adjustmentInvoiceNumber') ? undefined : json['adjustmentInvoiceNumber'],
        'batchNumber': !exists(json, 'batchNumber') ? undefined : json['batchNumber'],
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'chargebackCode': !exists(json, 'chargebackCode') ? undefined : json['chargebackCode'],
        'caseNumber': !exists(json, 'caseNumber') ? undefined : json['caseNumber'],
        'specialReference1': !exists(json, 'specialReference1') ? undefined : json['specialReference1'],
        'newAdjustmentDescription': !exists(json, 'newAdjustmentDescription') ? undefined : json['newAdjustmentDescription'],
        'transactionCount': !exists(json, 'transactionCount') ? undefined : json['transactionCount'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'unitAmount': !exists(json, 'unitAmount') ? undefined : json['unitAmount'],
        'submitDate': !exists(json, 'submitDate') ? undefined : (new Date(json['submitDate'])),
    };
}

export function GetAdjustmentDetailsResponseToJSON(value?: GetAdjustmentDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordType': value.recordType,
        'hierarchyId': value.hierarchyId,
        'hierarchyLevelNo': value.hierarchyLevelNo,
        'locationId': value.locationId,
        'locationDBAName': value.locationDBAName,
        'storeNumber': value.storeNumber,
        'externalId': value.externalId,
        'fundedDate': value.fundedDate === undefined ? undefined : (value.fundedDate.toISOString().substr(0,10)),
        'processedDate': value.processedDate === undefined ? undefined : (value.processedDate.toISOString().substr(0,10)),
        'currencyCode': value.currencyCode,
        'ddaNumber': value.ddaNumber,
        'abaNumber': value.abaNumber,
        'adjustmentDate': value.adjustmentDate === undefined ? undefined : (value.adjustmentDate.toISOString().substr(0,10)),
        'cardType': value.cardType,
        'adjustmentCode': value.adjustmentCode,
        'adjustmentDescription': value.adjustmentDescription,
        'adjustmentAmount': value.adjustmentAmount,
        'adjustmentType': value.adjustmentType,
        'invoiceDate': value.invoiceDate === undefined ? undefined : (value.invoiceDate.toISOString().substr(0,10)),
        'adjustmentInvoiceNumber': value.adjustmentInvoiceNumber,
        'batchNumber': value.batchNumber,
        'cardNumber': value.cardNumber,
        'chargebackCode': value.chargebackCode,
        'caseNumber': value.caseNumber,
        'specialReference1': value.specialReference1,
        'newAdjustmentDescription': value.newAdjustmentDescription,
        'transactionCount': value.transactionCount,
        'rate': value.rate,
        'unitAmount': value.unitAmount,
        'submitDate': value.submitDate === undefined ? undefined : (value.submitDate.toISOString().substr(0,10)),
    };
}


