/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyAssignment,
    CompanyAssignmentFromJSON,
    CompanyAssignmentFromJSONTyped,
    CompanyAssignmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateIamUserRequest
 */
export interface CreateIamUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    userTypeId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIamUserRequest
     */
    sendInvitation: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    securityProfileId: string;
    /**
     * 
     * @type {Array<CompanyAssignment>}
     * @memberof CreateIamUserRequest
     */
    companyAssignments: Array<CompanyAssignment>;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    orgUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    directSupervisor?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIamUserRequest
     */
    notes?: string;
}

export function CreateIamUserRequestFromJSON(json: any): CreateIamUserRequest {
    return CreateIamUserRequestFromJSONTyped(json, false);
}

export function CreateIamUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIamUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'email': json['email'],
        'phoneNumber': json['phone_number'],
        'userTypeId': json['user_type_id'],
        'sendInvitation': json['send_invitation'],
        'securityProfileId': json['security_profile_id'],
        'companyAssignments': ((json['company_assignments'] as Array<any>).map(CompanyAssignmentFromJSON)),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'orgUnit': !exists(json, 'org_unit') ? undefined : json['org_unit'],
        'directSupervisor': !exists(json, 'direct_supervisor') ? undefined : json['direct_supervisor'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function CreateIamUserRequestToJSON(value?: CreateIamUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'phone_number': value.phoneNumber,
        'user_type_id': value.userTypeId,
        'send_invitation': value.sendInvitation,
        'security_profile_id': value.securityProfileId,
        'company_assignments': ((value.companyAssignments as Array<any>).map(CompanyAssignmentToJSON)),
        'title': value.title,
        'company': value.company,
        'org_unit': value.orgUnit,
        'direct_supervisor': value.directSupervisor,
        'notes': value.notes,
    };
}


