import React, { useContext, useEffect } from 'react';
import { MaskedNumberInput } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm, Controller } from 'react-hook-form';

export const FilterDisputeAmount = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { errors, control, watch, setValue } = useForm<{
    disputeAmount: string;
  }>();

  const { disputeAmount } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_DISPUTE_AMOUNT_FILTER',
      disputeAmount,
    });
  }, [disputeAmount, dispatch]);

  useEffect(() => {
    if (filters.disputeAmount !== undefined) {
      setValue('disputeAmount', filters.disputeAmount);
    }
  }, [filters.disputeAmount, setValue]);

  return (
    <Controller
      as={
        <MaskedNumberInput
          disabled
          label={t('disputeResolution.chargebacks.filters.disputeAmount')}
          name="disputeAmount"
          labelFontClassName="text-xxs font-medium"
          labelColorClassName="text-gray-700"
          className="h-8 text-xs font-bold"
          maxLength={10}
          error={errors.disputeAmount}
        />
      }
      name="disputeAmount"
      control={control}
    />
  );
};
