import React, { FC } from 'react';
import { Controller, Control, FieldValues } from 'react-hook-form';
import Select, {
  OptionTypeBase,
  StylesConfig,
  components as SelectComponents,
} from 'react-select';
import { IconCaretDown } from 'src/components/icons/IconCaretDown';

const DropdownIndicator = (props: any) => (
  <SelectComponents.DropdownIndicator {...props}>
    <IconCaretDown className="mr-1" />
  </SelectComponents.DropdownIndicator>
);

const customStyles: StylesConfig<{ value: string; label: string }, boolean> = {
  container: (base) => ({
    ...base,
    width: '4.5rem',
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: '0.875rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: 'none',
    transition: 'border-color 0.3s',
    borderColor: state.selectProps.menuIsOpen
      ? '#157BFA !important'
      : '#EBEAED !important',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#87949E',
  }),
  menu: (base) => ({
    ...base,
    border: 'none',
    boxShadow: '0px 8px 20px rgba(47, 50, 58, 0.1)',
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '0.75rem',
    color: state.isSelected ? '#2F323A' : '#87949E',
    background: 'transparent',
    fontWeight: 'bold',
    '&:hover': {
      background: '#F7F9FA',
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s',
    transformOrigin: '43%',
    color: state.selectProps.menuIsOpen
      ? '#157BFA !important'
      : '#87949E !important',
  }),
};

export interface CustomSelectProps<
  TFieldValues extends FieldValues = FieldValues
> {
  name: string;
  options: OptionTypeBase;
  control: Control<TFieldValues>;
  defaultFirst?: boolean;
}

export const CustomSelect: FC<CustomSelectProps> = ({
  control,
  name,
  options,
  defaultFirst,
}) => (
  <Controller
    name={name}
    control={control}
    options={options}
    isSearchable={false}
    menuPlacement={'auto'}
    defaultValue={defaultFirst && options[0]}
    styles={customStyles}
    components={{ IndicatorSeparator: () => null, DropdownIndicator }}
    as={Select}
  />
);
