import React, {
  useContext,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components as SelectComponents } from 'react-select';
import { Button } from 'src/components/Button';
import { Table } from 'src/components/table/Table';
import { LoadingSwitch } from 'src/components/Loading';
import { Card } from 'src/components/Card';
import { useColumns } from './useColumns';
import { LogItem, ListContext } from './context';
import { IconCaretDown } from 'src/components/icons/IconCaretDown';
import { RowsPerPage } from './rowsPerPage';
import { PageHeader } from 'src/components/PageHeader';
import { Filters } from './filters/Filters';
import { useHistory } from 'react-router-dom';

const DropdownIndicator = (props: any) => (
  <SelectComponents.DropdownIndicator {...props}>
    <IconCaretDown className="mr-1" />
  </SelectComponents.DropdownIndicator>
);

export const ApiLogsList = () => {
  const { t } = useTranslation();

  const {
    items,
    endReached,
    itemsLoadingComputed,
    paginationLoading,
    page,
    rowsPerPage,
    dispatch,
  } = useContext(ListContext);

  const nextPage = useCallback(
    () => dispatch({ type: 'SET_PAGE', page: page + 1 }),
    [dispatch, page]
  );

  const history = useHistory();

  type RowsPerPageOptionType = {
    value: RowsPerPage;
    label: RowsPerPage;
  };

  const rowsPerPageOptions = useMemo(
    (): RowsPerPageOptionType[] => [
      {
        value: 20,
        label: 20,
      },
      {
        value: 50,
        label: 50,
      },
      {
        value: 100,
        label: 100,
      },
    ],
    []
  );

  const activeRowsPerPage = useMemo(
    () => rowsPerPageOptions.find((item) => item.value === rowsPerPage),
    [rowsPerPage, rowsPerPageOptions]
  );

  const handleRowsPerPageChange = useCallback(
    (option: RowsPerPageOptionType) =>
      dispatch({ type: 'SET_ROWS_PER_PAGE', rowsPerPage: option.value }),
    [dispatch]
  );

  const tableRef = useRef<HTMLDivElement>(null);

  const columns = useColumns();

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [itemsLoadingComputed]);

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="absolute w-full">
        <PageHeader title={t('logs.api.breadcrumb')} />
      </div>
      <div className="h-full p-3 pt-19">
        <Card className="h-full" verticalPaddingClassName="pt-5 pb-6">
          <div className="flex flex-col w-full h-full">
            <div className="mb-5">
              <Filters />
            </div>
            <div
              ref={tableRef}
              className="h-full max-h-full -mx-6 overflow-x-auto overflow-y-scroll"
            >
              <Table
                loading={itemsLoadingComputed}
                columns={columns}
                items={items}
                withFilters={false}
                onRowClick={(row: LogItem) =>
                  window.open(`/logs/api/${row.id}`)
                }
              />
            </div>
            <div className="relative flex items-center justify-between p-5 py-3 -mx-6 -mt-px -mb-5 bg-white border-t border-gray-300">
              <div className="items-center hidden text-xs text-gray-700 md:flex">
                Results:
                <span className="ml-1">
                  <LoadingSwitch
                    loading={itemsLoadingComputed || paginationLoading}
                  >
                    <span className="font-medium">{items.length}</span>
                  </LoadingSwitch>
                </span>
              </div>
              <Button
                disabled={endReached}
                loading={paginationLoading || itemsLoadingComputed}
                variant="link"
                onClick={nextPage}
                className="mx-auto md:mx-0"
              >
                Load More
              </Button>
              <div className="items-center hidden md:flex">
                <span className="text-xs text-gray-700">Rows per page:</span>
                <div className="ml-3">
                  <Select
                    menuPlacement="auto"
                    value={activeRowsPerPage}
                    onChange={(option) =>
                      handleRowsPerPageChange(option as RowsPerPageOptionType)
                    }
                    isSearchable={false}
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: '4.5rem',
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        paddingLeft: '0.875rem',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                      }),
                      control: (base, state) => ({
                        ...base,
                        boxShadow: 'none',
                        transition: 'border-color 0.3s',
                        borderColor: state.selectProps.menuIsOpen
                          ? '#157BFA !important'
                          : '#EBEAED !important',
                      }),
                      singleValue: (base) => ({
                        ...base,
                        color: '#87949E',
                      }),
                      menu: (base) => ({
                        ...base,
                        border: 'none',
                        boxShadow: '0px 8px 20px rgba(47, 50, 58, 0.1)',
                      }),
                      option: (base, state) => ({
                        ...base,
                        fontSize: '0.75rem',
                        color: state.isSelected ? '#2F323A' : '#87949E',
                        background: 'transparent',
                        fontWeight: 'bold',
                        '&:hover': {
                          background: '#F7F9FA',
                        },
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                        transition: 'transform 0.3s',
                        transformOrigin: '43%',
                        color: state.selectProps.menuIsOpen
                          ? '#157BFA !important'
                          : '#87949E !important',
                      }),
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    options={rowsPerPageOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
