/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    settlementDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    cardBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    terminalId?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    authorizationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    referenceNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    transactionDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    transactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    settlementCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionType?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    subMerchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    merchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    matchType?: string;
}

export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'settlementDate': !exists(json, 'settlementDate') ? undefined : (new Date(json['settlementDate'])),
        'cardBrand': !exists(json, 'cardBrand') ? undefined : json['cardBrand'],
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'terminalId': !exists(json, 'terminalId') ? undefined : json['terminalId'],
        'authorizationCode': !exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'referenceNumber': !exists(json, 'referenceNumber') ? undefined : json['referenceNumber'],
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'transactionAmount': !exists(json, 'transactionAmount') ? undefined : json['transactionAmount'],
        'settlementCurrencyCode': !exists(json, 'settlementCurrencyCode') ? undefined : json['settlementCurrencyCode'],
        'transactionStatus': !exists(json, 'transactionStatus') ? undefined : json['transactionStatus'],
        'transactionType': !exists(json, 'transactionType') ? undefined : json['transactionType'],
        'transactionTime': !exists(json, 'transactionTime') ? undefined : json['transactionTime'],
        'subMerchantId': !exists(json, 'subMerchantId') ? undefined : json['subMerchantId'],
        'merchantName': !exists(json, 'merchantName') ? undefined : json['merchantName'],
        'matchType': !exists(json, 'matchType') ? undefined : json['matchType'],
    };
}

export function TransactionToJSON(value?: Transaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'settlementDate': value.settlementDate === undefined ? undefined : (value.settlementDate.toISOString().substr(0,10)),
        'cardBrand': value.cardBrand,
        'cardNumber': value.cardNumber,
        'terminalId': value.terminalId,
        'authorizationCode': value.authorizationCode,
        'referenceNumber': value.referenceNumber,
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString().substr(0,10)),
        'transactionAmount': value.transactionAmount,
        'settlementCurrencyCode': value.settlementCurrencyCode,
        'transactionStatus': value.transactionStatus,
        'transactionType': value.transactionType,
        'transactionTime': value.transactionTime,
        'subMerchantId': value.subMerchantId,
        'merchantName': value.merchantName,
        'matchType': value.matchType,
    };
}


