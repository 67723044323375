import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { FormWrapper } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/FormWrapper';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { iconMap } from 'src/domain/access-management/roles-and-permissions/tabs/rules/useRolesColumns';
import {
  AddRoleFields,
  addRoleFormType,
} from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/AddRoleModal';
import { nameof } from 'src/utils/nameof';
import { Select } from 'src/components/form/Select';
import { RolesContext } from 'src/domain/access-management/roles-and-permissions/tabs/rules/RolesContext';

interface Props {
  roleId?: string | null;
  currentStep: number;
  isDefaultValues: boolean;
  buttonClick?: () => void;
}

export const FormOne = memo(
  ({ roleId, currentStep, isDefaultValues, buttonClick }: Props) => {
    const { t } = useTranslation();
    const {
      control,
      register,
      trigger,
      errors,
    } = useFormContext<AddRoleFields>();


    const { accessChannelList } = useContext(RolesContext);

    const handleSubmit = async () => {
      const results = await trigger();
      return results && buttonClick?.();
    };

    return (
      <FormWrapper
        title={
          roleId
            ? t('accessManagement.rolesAndPermissions.role.editRole')
            : t('accessManagement.rolesAndPermissions.role.addNewRole')
        }
        currentStep={currentStep}
        className={classNames({ hidden: currentStep !== 0 })}
      >
        <div className="mb-5">
          <Input
            label={t(
              'accessManagement.rolesAndPermissions.role.formItems.name.preview'
            )}
            disabled={isDefaultValues}
            name={nameof(addRoleFormType.name)}
            error={errors.name}
            inputRef={register({
              required: true,
              maxLength: {
                value: 100,
                message: t('validations.maxLength', { limit: 100 }),
              },
            })}
            className={'h-10 border-gray-300'}
          />
        </div>
        <div className="mb-5">
          <Input
            label={t(
              'accessManagement.rolesAndPermissions.role.formItems.description.preview'
            )}
            name={nameof(addRoleFormType.description)}
            error={errors.description}
            inputRef={register({
              required: true,
              maxLength: {
                value: 200,
                message: t('validations.maxLength', { limit: 200 }),
              },
            })}
            className={'h-10 border-gray-300'}
          />
        </div>
        <div className="mb-5">
          <Select
            label={t(
              'accessManagement.rolesAndPermissions.role.formItems.channel.label'
            )}
            disabled={isDefaultValues}
            error={errors.channel}
            rules={{ required: true }}
            name={nameof(addRoleFormType.channel)}
            control={control}
            options={accessChannelList.map((accessChannel) => ({
              value: accessChannel.id,
              label: (
                <div className="flex items-center">
                  {iconMap(accessChannel.name!)} {accessChannel.name}
                </div>
              ),
            }))}
          />
        </div>
        <div className="mb-5">
          <Input
            type={'textarea'}
            error={errors.reason}
            label={t(
              'accessManagement.rolesAndPermissions.role.formItems.reason.label'
            )}
            inputRef={register({
              maxLength: {
                value: 200,
                message: t('validations.maxLength', { limit: 200 }),
              },
            })}
            name={nameof(addRoleFormType.reason)}
            className={'h-16 border-gray-300'}
          />
        </div>
        <Button
          onClick={handleSubmit}
          size={'medium-large'}
          className={'w-full'}
        >
          {isDefaultValues
            ? t('accessManagement.rolesAndPermissions.role.editPermissions')
            : t('accessManagement.rolesAndPermissions.role.assignPermissions')}
        </Button>
      </FormWrapper>
    );
  }
);
