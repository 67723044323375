import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { StatusVariants } from 'src/types/statusVariants';

export enum StatusBadgeType {
  WARNING = 'warning',
  DANGER = 'danger',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  PRIMARY = 'primary',
}

const bgClassName = (type: StatusVariants | null) => {
  switch (type) {
    case StatusBadgeType.WARNING:
      return 'border-yellow-500 text-yellow-500';
    case StatusBadgeType.DANGER:
      return 'border-red-500 text-red-500';
    case StatusBadgeType.SECONDARY:
      return 'border-gray-500 text-gray-500';
    case StatusBadgeType.SUCCESS:
      return 'border-green-500 text-green-500';
    default:
      return 'border-blue-500 text-blue-500';
  }
};

export const StatusBadge = ({
  variant,
  children,
  widthClass = 'w-18',
  textClass = '',
}: {
  variant: StatusVariants | null;
  children: ReactNode;
  widthClass?: string;
  textClass?: string;
}) => (
  <div
    className={classNames(
      'py-1 px-2 border rounded-sm flex items-center justify-center text-center',
      bgClassName(variant),
      widthClass
    )}
    style={{ minWidth: '64px' }}
  >
    <span
      className={classNames(
        'font-semibold leading-none uppercase text-xxxs pt-px',
        [textClass]
      )}
    >
      {children}
    </span>
  </div>
);
