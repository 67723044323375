import { UseFormMethods } from 'react-hook-form';

interface Values {
  [x: string]: unknown;
}

export const triggerArrayFields = async (
  getValues: UseFormMethods['getValues'],
  trigger: UseFormMethods['trigger'],
  key: string
) => {
  return new Promise(async (resolve) => {
    const values: Values[] = getValues()[key];
    const validateResults = await values.map((fields, fieldsIndex) =>
      Object.keys(fields).map(async (fieldKey) => {
        const result = await trigger(`${key}[${fieldsIndex}].${fieldKey}`);
        if (!result) {
          resolve(false);
        }
        return result;
      })
    );

    Promise.all(validateResults).then(() => {
      resolve(true);
    });
  });
};
