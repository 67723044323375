import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Chargebacks } from './chargebacks/Chargebacks';

export const DisputeResolution = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/chargebacks`}>
        <Chargebacks />
      </Route>
    </Switch>
  );
};
