import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import get from 'lodash/get';
import { FormTwo } from './FormTwo/FormTwo';
import { FormOne } from './FormOne/FormOne';
import { FormModal } from 'src/components/FormModal';
import { typeProxy } from 'src/utils/nameof';
import {
  AccessChannel,
  CompanyAssignment,
  CreateIamUserRequest,
  IamUser,
  ListIamUserTypesResponse,
} from 'src/api/client';
import { OptionType } from 'src/components/form/Select';
import { StepWrapper } from './StepWrapper';
import { FormReview } from './FromReview';
import { useStepper } from 'src/hooks/useStepper';
import { CopyAssigmentModal } from './CopyAssigmentModal';
import { CloseConfirmModal } from 'src/domain/access-management/user-management/tabs/users/add-user/CloseConfirmModal';
import { UserType } from 'src/domain/access-management/user-management/tabs/users/UsersContext';
import { useQuery } from 'react-query';
import { userTypesApi } from 'src/api';
import { AddUsersContextProvider } from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserContext';
import { EditUserData } from './useGetUserQueries';

const COUNT_STEP = 3;

export interface AssignmentsItem {
  organizations?: OptionType;
  roles: OptionType[];
  program?: OptionType[] | null;
  autoAssignProgram?: boolean;
}

export interface BackOfficeAssignmentsItem
  extends Omit<AssignmentsItem, 'organizations'> {
  firstOrganization?: OptionType;
  secondOrganization?: OptionType;
  autoAssignAffiliated?: boolean;
}

export type AssignmentsItemFields = AssignmentsItem & BackOfficeAssignmentsItem;

type UserFieldsRequest = Pick<
  CreateIamUserRequest,
  | 'email'
  | 'phoneNumber'
  | 'title'
  | 'company'
  | 'orgUnit'
  | 'directSupervisor'
  | 'notes'
>;

export interface AddUserFields
  extends Pick<UserType, 'id' | 'status'>,
    Required<UserFieldsRequest> {
  firstName: string;
  lastName: string;
  companyAssignments: AssignmentsItemFields[];
  securityProfile: OptionType;
  sendInvitation: boolean;
}

const transformToUserData = ({
  firstName,
  lastName,
  companyAssignments,
  securityProfile,
  ...userValues
}: AddUserFields): Omit<CreateIamUserRequest, 'userTypeId'> => {
  return {
    firstName,
    lastName,
    companyAssignments: companyAssignments
      .filter(
        (el) =>
          el.organizations?.value ||
          el.firstOrganization?.value ||
          el.secondOrganization?.value ||
          el.roles?.length ||
          el.program?.length
      )
      .map<CompanyAssignment>((c) => ({
        companyId:
          c.organizations?.value ||
          c.secondOrganization?.value ||
          c.firstOrganization?.value,
        roleIds: c.roles ? c.roles?.map((r) => r.value) : [],
        programIds: c.program ? c.program?.map((p) => p.value) : [],
      })),
    securityProfileId: securityProfile.value,
    ...userValues,
  };
};

const transformToFields = (userData: IamUser): Partial<AddUserFields> => ({
  id: userData.id,
  status: userData.status,
  firstName: userData.firstName,
  lastName: userData.lastName,
  title: userData.title,
  company: userData.company,
  orgUnit: userData.orgUnit,
  directSupervisor: userData.directSupervisor,
  notes: userData.notes,
});

export const addUserFormType = typeProxy<AddUserFields>();
export const addUserItemType = typeProxy<Required<AssignmentsItemFields>>();

export const DEFAULT_BACKOFFICE_ITEM_FIELDS: AssignmentsItemFields = {
  roles: [],
  autoAssignProgram: true,
  autoAssignAffiliated: false,
};

const DEFAULT_BACKOFFICE_FIELDS_VALUES: Partial<AddUserFields> = {
  companyAssignments: [DEFAULT_BACKOFFICE_ITEM_FIELDS],
};

interface Props {
  visible: boolean;
  closeModal: () => void;
  editUserData: EditUserData;
  isEdit?: boolean;
  onSubmit: (user: CreateIamUserRequest) => void;
  selectedChannel?: AccessChannel | null;
}

export const AddUserModal = ({
  visible,
  isEdit,
  closeModal,
  editUserData,
  onSubmit,
  selectedChannel,
}: Props) => {
  const { t } = useTranslation();
  const [isOpenCopyAssignment, setIsOpenCopyAssignment] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [visibleAdditionalDetails, setVisibleAdditionalDetails] = useState(
    false
  );
  const [hiddenAssignmentsFields, setHiddenAssignmentsFields] = useState<
    boolean[]
  >([]);

  const { currentStep, nextStep, previousStep } = useStepper({
    count: COUNT_STEP,
    initialStep: 0,
  });

  const {
    data: userTypesResponse,
    isLoading: isLoadingUserTypes,
  } = useQuery<ListIamUserTypesResponse>(
    [
      'ACCESS_MANAGEMENT/USERS/GET_USER_TYPES',
      { accessChannelId: selectedChannel?.id },
    ],
    ({ queryKey: [, { accessChannelId }] }) =>
      userTypesApi.postIamTypesList({
        listIamUserTypesRequest: {
          accessChannelId,
        },
      }),
    { retry: 1 }
  );

  const isBackoffice = useMemo(
    () => get(userTypesResponse, 'types[0].name') === 'backoffice',
    [userTypesResponse]
  );

  const methods = useForm<AddUserFields>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: isBackoffice ? DEFAULT_BACKOFFICE_FIELDS_VALUES : {},
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    if (!editUserData.user) {
      return;
    }

    reset(transformToFields(editUserData.user));
  }, [reset, editUserData]);

  const toggleHiddenAssignmentsFields = useCallback(
    (value?: boolean[] | number) => {
      if (value !== 0 && !value) {
        return setHiddenAssignmentsFields([]);
      }

      if (Array.isArray(value)) {
        setHiddenAssignmentsFields(value);
      } else {
        setHiddenAssignmentsFields((prevState) => {
          const oldHiddenFields = [...prevState];
          oldHiddenFields[value] = !oldHiddenFields[value];
          return oldHiddenFields;
        });
      }
    },
    []
  );

  const handleFormFinish = useCallback(
    async (userFields: AddUserFields) => {
      const { types } = userTypesResponse || {};
      if (!types?.length || !types[0].id) {
        throw new Error('User type is undefined');
      }
      const userTypeId = types[0].id;

      await onSubmit({
        userTypeId,
        ...transformToUserData(userFields),
      });
    },
    [userTypesResponse, onSubmit]
  );

  const handleCopyAssigment = () => {};

  const steps: { [key: number]: ReactNode } = useMemo(
    () => ({
      0: (
        <StepWrapper
          title={t('accessManagement.userManagement.personalDetails')}
          selectedChannel={selectedChannel}
        >
          <FormOne
            toNextStep={nextStep}
            visibleDetails={visibleAdditionalDetails}
            setVisibleDetails={setVisibleAdditionalDetails}
            isEdit={isEdit}
          />
        </StepWrapper>
      ),
      1: (
        <StepWrapper
          title={t('accessManagement.userManagement.assignments')}
          selectedChannel={selectedChannel}
        >
          <FormTwo
            toNextStep={nextStep}
            toPreviousStep={previousStep}
            copyAssignment={() => setIsOpenCopyAssignment(true)}
            toggleFields={toggleHiddenAssignmentsFields}
            hiddenFields={hiddenAssignmentsFields}
            selectedChannel={selectedChannel}
            userTypesResponse={userTypesResponse}
          />
        </StepWrapper>
      ),
      2: (
        <StepWrapper
          title={t('accessManagement.userManagement.review')}
          selectedChannel={selectedChannel}
        >
          <FormReview toPreviousStep={previousStep} isEdit={isEdit} />
        </StepWrapper>
      ),
    }),
    [
      t,
      isEdit,
      visibleAdditionalDetails,
      toggleHiddenAssignmentsFields,
      hiddenAssignmentsFields,
      previousStep,
      nextStep,
      selectedChannel,
    ]
  );

  return (
    <FormProvider {...methods}>
      <FormModal
        isOpen={visible}
        closeModal={() => {
          setIsOpenCancelModal(true);
        }}
        maxWidth="auto"
        width="500px"
        isLoading={editUserData.isLoading || isLoadingUserTypes}
      >
        <div className={'absolute top-0 left-0 right-0 -m-4'}>
          <div
            className={classNames('bg-blue-500 h-1 ', {
              'w-1/3': currentStep === 0,
              'w-2/3': currentStep === 1,
              'w-full': currentStep === 2,
            })}
          />
        </div>
        <form onSubmit={handleSubmit(handleFormFinish)} className="px-4">
          <AddUsersContextProvider editUserData={editUserData}>
            {steps[currentStep]}
          </AddUsersContextProvider>
        </form>
      </FormModal>
      <CloseConfirmModal
        isOpen={isOpenCancelModal}
        toggleModal={setIsOpenCancelModal}
        closeMainModal={closeModal}
      />
      <CopyAssigmentModal
        isOpen={isOpenCopyAssignment}
        toggleModal={setIsOpenCopyAssignment}
        copyAssigment={handleCopyAssigment}
      />
    </FormProvider>
  );
};
