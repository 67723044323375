/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IamSecurityProfiles
 */
export interface IamSecurityProfiles {
    /**
     * 
     * @type {string}
     * @memberof IamSecurityProfiles
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamSecurityProfiles
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamSecurityProfiles
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamSecurityProfiles
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamSecurityProfiles
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IamSecurityProfiles
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IamSecurityProfiles
     */
    isTotpEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IamSecurityProfiles
     */
    accessChannelId?: string;
}

export function IamSecurityProfilesFromJSON(json: any): IamSecurityProfiles {
    return IamSecurityProfilesFromJSONTyped(json, false);
}

export function IamSecurityProfilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): IamSecurityProfiles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'updatedBy': !exists(json, 'updated_by') ? undefined : json['updated_by'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isTotpEnabled': !exists(json, 'is_totp_enabled') ? undefined : json['is_totp_enabled'],
        'accessChannelId': !exists(json, 'access_channel_id') ? undefined : json['access_channel_id'],
    };
}

export function IamSecurityProfilesToJSON(value?: IamSecurityProfiles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updated_by': value.updatedBy,
        'name': value.name,
        'is_totp_enabled': value.isTotpEnabled,
        'access_channel_id': value.accessChannelId,
    };
}


