/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CcvResultCode,
    CcvResultCodeFromJSON,
    CcvResultCodeFromJSONTyped,
    CcvResultCodeToJSON,
    DateRange,
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
    GPCTransactionType,
    GPCTransactionTypeFromJSON,
    GPCTransactionTypeFromJSONTyped,
    GPCTransactionTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListAuthorizationsPageRequest
 */
export interface ListAuthorizationsPageRequest {
    /**
     * 
     * @type {number}
     * @memberof ListAuthorizationsPageRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof ListAuthorizationsPageRequest
     */
    limit?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAuthorizationsPageRequest
     */
    merchantIds?: Array<string>;
    /**
     * 
     * @type {Array<GPCTransactionType>}
     * @memberof ListAuthorizationsPageRequest
     */
    transactionType?: Array<GPCTransactionType>;
    /**
     * 
     * @type {DateRange}
     * @memberof ListAuthorizationsPageRequest
     */
    transactionDate?: DateRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAuthorizationsPageRequest
     */
    cardType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorizationsPageRequest
     */
    cardLast4?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorizationsPageRequest
     */
    nameOnCard?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorizationsPageRequest
     */
    authorizationCode?: string;
    /**
     * 
     * @type {Array<CcvResultCode>}
     * @memberof ListAuthorizationsPageRequest
     */
    ccvResult?: Array<CcvResultCode>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAuthorizationsPageRequest
     */
    avsResponse?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorizationsPageRequest
     */
    stan?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorizationsPageRequest
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorizationsPageRequest
     */
    responseCode?: string;
}

export function ListAuthorizationsPageRequestFromJSON(json: any): ListAuthorizationsPageRequest {
    return ListAuthorizationsPageRequestFromJSONTyped(json, false);
}

export function ListAuthorizationsPageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAuthorizationsPageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'merchantIds': !exists(json, 'merchantIds') ? undefined : json['merchantIds'],
        'transactionType': !exists(json, 'transactionType') ? undefined : ((json['transactionType'] as Array<any>).map(GPCTransactionTypeFromJSON)),
        'transactionDate': !exists(json, 'transactionDate') ? undefined : DateRangeFromJSON(json['transactionDate']),
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'cardLast4': !exists(json, 'cardLast4') ? undefined : json['cardLast4'],
        'nameOnCard': !exists(json, 'nameOnCard') ? undefined : json['nameOnCard'],
        'authorizationCode': !exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'ccvResult': !exists(json, 'ccvResult') ? undefined : ((json['ccvResult'] as Array<any>).map(CcvResultCodeFromJSON)),
        'avsResponse': !exists(json, 'avsResponse') ? undefined : json['avsResponse'],
        'stan': !exists(json, 'stan') ? undefined : json['stan'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'responseCode': !exists(json, 'responseCode') ? undefined : json['responseCode'],
    };
}

export function ListAuthorizationsPageRequestToJSON(value?: ListAuthorizationsPageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'merchantIds': value.merchantIds,
        'transactionType': value.transactionType === undefined ? undefined : ((value.transactionType as Array<any>).map(GPCTransactionTypeToJSON)),
        'transactionDate': DateRangeToJSON(value.transactionDate),
        'cardType': value.cardType,
        'cardLast4': value.cardLast4,
        'nameOnCard': value.nameOnCard,
        'authorizationCode': value.authorizationCode,
        'ccvResult': value.ccvResult === undefined ? undefined : ((value.ccvResult as Array<any>).map(CcvResultCodeToJSON)),
        'avsResponse': value.avsResponse,
        'stan': value.stan,
        'reference': value.reference,
        'responseCode': value.responseCode,
    };
}


