import React, {
  // useContext,
  // useCallback,
  // useRef,
  // useEffect,
  // useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { CardsTable } from './CardsTable';

export const CardList = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col bg-gray-200">
      <div className="absolute w-full">
        <div className="flex items-center justify-between w-full h-16 bg-white border-b">
          <div className="px-5">
            <p className="mb-0 text-lg font-bold leading-none text-black">
              {t('cards.table.cards')}
            </p>
          </div>
        </div>
      </div>

      <div className="p-2 pt-18">
        <div className="bg-white flex flex-col w-full h-full">
          <CardsTable hasStatusFilter />
        </div>
      </div>
    </div>
  );
};
