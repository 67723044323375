import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { TabLink } from './TabLink';
import { TabsHeaderSearch } from './TabsHeaderSearch';

interface Searching {
  name: string;
  fieldLabels: string[];
}

interface Props {
  searching?: Searching;
  tabs: { url: string, label: string }[];
}

export const TabsHeader = ({ searching, tabs }: Props) => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const urls = routeMatch.url.split('/');
  const mainUrl = `/${urls[1]}/${urls[2]}`;

  return (
    <div className="flex items-center justify-between w-full bg-white h-16">
      <div className="flex px-5 h-full">
        {
          tabs.map(({ label, url }, i) => (
            <TabLink to={mainUrl + url} key={i}>
              {label}
            </TabLink>
          ))
        }
      </div>
      {searching && <TabsHeaderSearch {...searching} placeholder={t('accessManagement.rolesAndPermissions.logs.searchLogs')} />}
    </div>
  );
};
