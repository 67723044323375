import React, { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import get from 'lodash/get';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import { IamUser, UpdateIamUserRequest } from 'src/api/client';
import { useMutation } from 'react-query';
import { profileApi } from 'src/api';
import { notifications } from 'src/utils/notifications';

export const FormEditUser = ({
  userData,
  onCancel,
  onSubmit,
}: {
  userData: IamUser;
  onCancel: () => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup.object().shape({
      title: yup.string().max(200, t('validations.maxLength', { limit: 200 })),
      company: yup.string().max(200, t('validations.maxLength', { limit: 200 })),
      orgUnit: yup.string().max(200, t('validations.maxLength', { limit: 200 })),
      directSupervisor: yup.string().max(200, t('validations.maxLength', { limit: 200 })),
    });
  }, [t]);

  const {
    register,
    handleSubmit,
    errors,
    formState,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      title: userData.title,
      company: userData.company,
      orgUnit: userData.orgUnit,
      directSupervisor: userData.directSupervisor,
    },
  });

  const { mutateAsync: updateUser } = useMutation(
    (user: UpdateIamUserRequest) =>
      profileApi.postProfileUpdate({
        updateProfileRequest: {
          iamUser: user
        }
      }),
    {
      onSuccess: () => {
        notifications.success();
        onCancel();
      },
    }
  );

  const onSubmitCallback = async (data: any) => {
    try {
      await updateUser({
        ...data,
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
      });
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)} className="w-full">
      <div className="pb-2">
        <Input
          error={errors?.title}
          name="title"
          label={t('userProfile.title')}
          inputRef={register({ required: true })}
          className={'h-10 border-gray-300'}
        />
      </div>
      <div className="pb-2">
        <Input
          error={errors?.company}
          name="company"
          label={t('userProfile.company')}
          inputRef={register({ required: true })}
          className={'h-10 border-gray-300'}
        />
      </div>
      <div className="pb-2">
        <Input
          error={errors?.orgUnit}
          name="orgUnit"
          label={t('userProfile.orgUnit')}
          inputRef={register({ required: true })}
          className={'h-10 border-gray-300'}
        />
      </div>
      <div className="pb-2">
        <Input
          error={errors?.directSupervisor}
          name="directSupervisor"
          label={t('userProfile.directSupervisor')}
          inputRef={register({ required: true })}
          className={'h-10 border-gray-300'}
        />
      </div>

      <div className="mt-3 flex justify-between">
        <Button
          type="button"
          size="medium"
          variant="secondary"
          widthClass="w-1/2"
          className="mr-1 h-10"
          disabled={formState.isSubmitting}
          onClick={onCancel}
        >
          {t('userProfile.cancel')}
        </Button>
        <Button
          loading={formState.isSubmitting}
          type="submit"
          size="medium"
          widthClass="w-1/2"
          className="ml-1 h-10"
          disabled={formState.isSubmitting}
        >
          {t('userProfile.update')}
        </Button>
      </div>
    </form>
  );
};
