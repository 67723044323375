/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListProgramsRequest
 */
export interface ListProgramsRequest {
    /**
     * 
     * @type {number}
     * @memberof ListProgramsRequest
     */
    cursor?: number;
    /**
     * 
     * @type {number}
     * @memberof ListProgramsRequest
     */
    limit?: number;
}

export function ListProgramsRequestFromJSON(json: any): ListProgramsRequest {
    return ListProgramsRequestFromJSONTyped(json, false);
}

export function ListProgramsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProgramsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function ListProgramsRequestToJSON(value?: ListProgramsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor': value.cursor,
        'limit': value.limit,
    };
}


