import React, { ChangeEventHandler } from 'react';

const IconUnchecked = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="13"
      height="13"
      rx="6.5"
      fill="white"
      stroke="currentColor"
    />
  </svg>
);

const IconChecked = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="13"
      height="13"
      rx="6.5"
      fill="currentColor"
      stroke="currentColor"
    />
    <rect x="4" y="4" width="6" height="6" rx="3" fill="white" />
  </svg>
);

export const Radio = ({
  disabled = false,
  checked,
  onChange,
}: {
  disabled?: boolean;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}) => (
  <span className="relative inline-block w-4 h-4">
    {checked ? (
      <div className="text-blue-500">
        <IconChecked />
      </div>
    ) : (
      <div className="text-gray-300">
        <IconUnchecked />
      </div>
    )}
    <input
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      type="radio"
      className="absolute top-0 w-full h-full opacity-0 cursor-pointer"
    />
  </span>
);
