import React, { useContext } from 'react';
import {
  DropdownFilter,
  InputDropdown,
} from 'src/components/dropdown-filter/DropdownFilter';
import { useTranslation } from 'react-i18next';
import { ListContext, AvsResponse } from '../context';
import { CheckboxList } from 'src/components/dropdown-filter/CheckboxList';
import { ItemFilter } from 'src/components/dropdown-filter/filter';

export const FilterAvsResponse = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const avsResponseItems = [AvsResponse.Y, AvsResponse.N, AvsResponse.Z].map(
    (item) => ({
      value: item,
      label: t(`transactionsManagement.authorizations.avsResponse.${item}`),
    })
  );

  const itemToggle = (options: { checked: boolean; item: ItemFilter }) =>
    dispatch({
      type: options.checked
        ? 'ADD_AVS_RESPONSE_FILTER'
        : 'REMOVE_AVS_RESPONSE_FILTER',
      itemFilter: options.item,
    });

  const clearFilter = () => dispatch({ type: 'RESET_AVS_RESPONSE_FILTER' });

  return (
    <DropdownFilter
      title={t(
        'transactionsManagement.authorizations.list.filters.avsResponse'
      )}
      headerSecondaryLabel={t(
        'transactionsManagement.transactions.list.filters.numberSelected',
        { totalItems: filters.avsResponse.length }
      )}
      onClearFilter={clearFilter}
      trigger={(dropdownProps) => (
        <InputDropdown
          name="avsResponse"
          label={t(
            'transactionsManagement.authorizations.list.filters.avsResponse'
          )}
          placeholder={t(
            'transactionsManagement.transactions.list.filters.all'
          )}
          value={
            filters.avsResponse.length
              ? t(
                  'transactionsManagement.transactions.list.filters.numberSelected',
                  { totalItems: filters.avsResponse.length }
                )
              : ''
          }
          {...dropdownProps}
        />
      )}
    >
      <CheckboxList
        items={avsResponseItems}
        filters={filters.avsResponse}
        onItemToggle={itemToggle}
        totalItems={filters.avsResponse.length}
        heightClassName="h-auto"
      />
    </DropdownFilter>
  );
};
