import { QueryFunctionContext, useQuery } from 'react-query';
import {
  GetIamUserResponse,
  IamUser,
  IamUserRole,
  Company,
  ListIamUserRolesResponse,
  ListCompaniesResponse,
} from 'src/api/client';
import { companiesApi, userRolesApi, usersApi } from 'src/api';

export const UPDATE_USER_GET_QUERY_KEY =
  'ACCESS_MANAGEMENT/USERS/GET_USER_DATA';
export const UPDATE_USER_ROLES_QUERY_KEY =
  'ACCESS_MANAGEMENT/USERS/GET_USER_ROLES';
export const UPDATE_USER_COMPANIES_QUERY_KEY =
  'ACCESS_MANAGEMENT/USERS/GET_USER_COMPANIES';

export interface EditUserData {
  user?: IamUser;
  roles?: IamUserRole[];
  userCompanies?: Company[];
  isLoading?: boolean;
  isFetching?: boolean;
  error?: any;
  refetch?: () => void;
}

interface Params {
  id?: string;
  userId?: string;
  enabled?: boolean;
}
export const useGetUserQueries = ({
  id = '',
  userId,
  enabled = true,
}: Params): EditUserData => {
  const {
    data: userResponse,
    isLoading: isLoadingUserData,
    isFetching: isFetchingUserData,
    error: userError,
    refetch: userRefetch,
  } = useQuery<GetIamUserResponse | undefined>(
    [UPDATE_USER_GET_QUERY_KEY, { userId, id }],
    ({
      queryKey: [, { userId: _userId }],
    }: QueryFunctionContext<[string, { userId: string }]>) => {
      if (!_userId) {
        return;
      }

      return usersApi.postIamUsersGet({
        getIamUserRequest: {
          iamUserId: _userId,
        },
      });
    },
    { retry: 1, enabled }
  );

  const { data: userRolesResponse, isLoading: isLoadingUserRoles } = useQuery<
    ListIamUserRolesResponse | undefined
  >(
    [UPDATE_USER_ROLES_QUERY_KEY, { userId, id }],
    ({
      queryKey: [, { userId: _userId }],
    }: QueryFunctionContext<[string, { userId: string }]>) => {
      if (!_userId) {
        return;
      }

      return userRolesApi.postIamUserRolesList({
        listIamUserRolesRequest: {
          iamUserId: _userId,
        },
      });
    },
    { retry: 1, enabled }
  );

  const {
    data: userCompaniesResponse,
    isLoading: isLoadingUserCompanies,
  } = useQuery<ListCompaniesResponse | undefined>(
    [UPDATE_USER_COMPANIES_QUERY_KEY, { userId, id }],
    ({
      queryKey: [, { userId: _userId }],
    }: QueryFunctionContext<[string, { userId: string }]>) => {
      if (!_userId) {
        return;
      }

      return companiesApi.postCompaniesList({
        listCompaniesRequest: {
          iamUserId: _userId,
        },
      });
    },
    { retry: 1, enabled }
  );

  return {
    user: userResponse?.iamUser,
    roles: userRolesResponse?.iamUserRoles,
    userCompanies: userCompaniesResponse?.companies,
    error: userError,
    refetch: userRefetch,
    isLoading: isLoadingUserData || isLoadingUserRoles,
    isFetching: isFetchingUserData,
  };
};
