/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Program,
    ProgramFromJSON,
    ProgramFromJSONTyped,
    ProgramToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateProgramResponse
 */
export interface CreateProgramResponse {
    /**
     * 
     * @type {Program}
     * @memberof CreateProgramResponse
     */
    program: Program;
}

export function CreateProgramResponseFromJSON(json: any): CreateProgramResponse {
    return CreateProgramResponseFromJSONTyped(json, false);
}

export function CreateProgramResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProgramResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'program': ProgramFromJSON(json['program']),
    };
}

export function CreateProgramResponseToJSON(value?: CreateProgramResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'program': ProgramToJSON(value.program),
    };
}


