import React, { useContext } from 'react';
import {
  DropdownFilter,
  InputDropdown,
} from 'src/components/dropdown-filter/DropdownFilter';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { ChargebackStatus } from '../context';
import { ListItem } from 'src/components/dropdown-filter/ListItem';
import { Radio } from 'src/components/Radio';

export const FilterStatuses = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const statusItems = [ChargebackStatus.TEST].map((item) => ({
    value: item,
    label: item,
  }));

  const setStatusFilter = (status: ChargebackStatus) =>
    dispatch({
      type: 'SET_STATUS_FILTER',
      status,
    });

  const clearFilter = () => dispatch({ type: 'RESET_STATUSES_FILTER' });

  return (
    <DropdownFilter
      title={t('kyc.frontTableColumns.status')}
      headerSecondaryLabel={t(
        'disputeResolution.chargebacks.filters.numberSelected',
        { totalItems: filters.status ? 1 : 0 }
      )}
      onClearFilter={clearFilter}
      trigger={(dropdownProps) => (
        <InputDropdown
          disabled
          name="statuses"
          label={t('disputeResolution.chargebacks.filters.status')}
          placeholder={t('disputeResolution.chargebacks.filters.all')}
          value={
            filters.status
              ? t('disputeResolution.chargebacks.filters.numberSelected', {
                  totalItems: 1,
                })
              : ''
          }
          {...dropdownProps}
        />
      )}
    >
      {statusItems.map((item, key) => (
        <ListItem key={key} label={item.label}>
          <Radio
            checked={filters.status === item.value}
            onChange={() => setStatusFilter(item.value)}
          />
        </ListItem>
      ))}
    </DropdownFilter>
  );
};
