/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CardBrand,
    CardBrandFromJSON,
    CardBrandFromJSONTyped,
    CardBrandToJSON,
} from './';

/**
 * 
 * @export
 * @interface Authorization
 */
export interface Authorization {
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    transactionType?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    destinationId?: string;
    /**
     * 
     * @type {number}
     * @memberof Authorization
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    currencyCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof Authorization
     */
    localTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Authorization
     */
    transmissionTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    stan?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    terminalId?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    posEntryMode?: string;
    /**
     * 
     * @type {CardBrand}
     * @memberof Authorization
     */
    cardBrand?: CardBrand;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    responseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    authorizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    merchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    cardNumber?: string;
}

export function AuthorizationFromJSON(json: any): Authorization {
    return AuthorizationFromJSONTyped(json, false);
}

export function AuthorizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Authorization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'transactionType': !exists(json, 'transactionType') ? undefined : json['transactionType'],
        'destinationId': !exists(json, 'destinationId') ? undefined : json['destinationId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'localTime': !exists(json, 'localTime') ? undefined : (new Date(json['localTime'])),
        'transmissionTime': !exists(json, 'transmissionTime') ? undefined : (new Date(json['transmissionTime'])),
        'stan': !exists(json, 'stan') ? undefined : json['stan'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'terminalId': !exists(json, 'terminalId') ? undefined : json['terminalId'],
        'posEntryMode': !exists(json, 'posEntryMode') ? undefined : json['posEntryMode'],
        'cardBrand': !exists(json, 'cardBrand') ? undefined : CardBrandFromJSON(json['cardBrand']),
        'responseCode': !exists(json, 'responseCode') ? undefined : json['responseCode'],
        'authorizationId': !exists(json, 'authorizationId') ? undefined : json['authorizationId'],
        'merchantId': !exists(json, 'merchantId') ? undefined : json['merchantId'],
        'merchantName': !exists(json, 'merchantName') ? undefined : json['merchantName'],
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
    };
}

export function AuthorizationToJSON(value?: Authorization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'transactionType': value.transactionType,
        'destinationId': value.destinationId,
        'amount': value.amount,
        'currencyCode': value.currencyCode,
        'localTime': value.localTime === undefined ? undefined : (value.localTime.toISOString()),
        'transmissionTime': value.transmissionTime === undefined ? undefined : (value.transmissionTime.toISOString()),
        'stan': value.stan,
        'reference': value.reference,
        'terminalId': value.terminalId,
        'posEntryMode': value.posEntryMode,
        'cardBrand': CardBrandToJSON(value.cardBrand),
        'responseCode': value.responseCode,
        'authorizationId': value.authorizationId,
        'merchantId': value.merchantId,
        'merchantName': value.merchantName,
        'cardNumber': value.cardNumber,
    };
}


