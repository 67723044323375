import React, { useMemo } from 'react';
import moment from 'moment';
import { CellLight, Column } from 'src/components/table/Table';
import { ChargebackItem } from './context';

export const useCharbacksColumns = () => {
  const columns = useMemo(
    (): Column[] => [
      {
        id: 'id',
        value: (item: ChargebackItem) => <CellLight>{item.id}</CellLight>,
        label: 'Case ID',
      },
      {
        id: 'cardType',
        value: (item: ChargebackItem) => <CellLight>{item.cardType}</CellLight>,
        label: 'Card Type',
      },
      {
        id: 'cardNumber',
        value: (item: ChargebackItem) => (
          <CellLight>{item.cardNumber}</CellLight>
        ),
        label: 'Card Number',
      },
      {
        id: 'authorizationCode',
        value: (item: ChargebackItem) => (
          <CellLight>{item.authorizationCode}</CellLight>
        ),
        label: 'Authorization Code',
      },
      {
        id: 'refNumber',
        value: (item: ChargebackItem) => (
          <CellLight>{item.acquireReferenceNumber}</CellLight>
        ),
        label: 'Acquirer Reference Number',
      },
      {
        id: 'adjustmentDate',
        value: (item: ChargebackItem) => (
          <CellLight>
            {moment(item.adjustmentDate).format('D MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Adjustment Date',
      },
      {
        id: 'transactionDate',
        value: (item: ChargebackItem) => (
          <CellLight>
            {moment(item.transactionDate).format('D MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Transaction Date',
      },
      {
        id: 'dueDate',
        value: (item: ChargebackItem) => (
          <CellLight>
            {moment(item.dueDate).format('D MMM YYYY HH:mm')}
          </CellLight>
        ),
        label: 'Due Date',
      },
      {
        id: 'disputeAmount',
        value: (item: ChargebackItem) => (
          <CellLight>{item.disputeAmount}</CellLight>
        ),
        label: 'Dispute Amount',
      },
      {
        id: 'currencyCode',
        value: (item: ChargebackItem) => (
          <CellLight>{item.chargebackCurrencyCode}</CellLight>
        ),
        label: 'Currency Code',
      },
      {
        id: 'status',
        value: (item: ChargebackItem) => (
          <CellLight>{item.currentStatus}</CellLight>
        ),
        label: 'Current Status',
      },
    ],
    []
  );

  return columns;
};
