import React from 'react';
import classNames from 'classnames';

import { ReactComponent as CheckMarkIcon } from 'src/assets/icons/checkMark.svg';
import { ReactComponent as UncheckMarkIcon } from 'src/assets/icons/uncheckMark.svg';

const Permission = ({ title, isActive }: { title: string, isActive: boolean }) => (
  <span className="inline-flex items-center mr-3 py-1 px-2 bg-gray-150 rounded-sm capitalize font-semibold">
    {isActive ? <CheckMarkIcon className="mr-1" /> : <UncheckMarkIcon className='mr-1' /> }
    <span className={classNames({ 'line-through': !isActive })}>
      {title}
    </span>
  </span>
);

const permissionsData = [
  {
    name: 'Overview',
    items: [
      {
        name: 'Transaction History',
        permissions: { view: true },
      },
    ],
  },
  {
    name: 'Load card',
    items: [
      {
        name: 'Cards List',
        permissions: { view: true },
      },
      {
        name: 'Card Details',
        permissions: { view: true, lock: false, block: true },
      },
    ],
  },
];

export const RolesLogsExpanded = () => {

  return (
    <div className="mx-5 mb-5">
      <table
        className="collapse my-6 w-full text-gray-900 font-medium"
        style={{
          boxShadow:
            '0px -4px 12px rgba(47, 50, 58, 0.1), 0px 4px 20px rgba(47, 50, 58, 0.1)',
        }}
      >
        <thead className="bg-gray-100 text-gray-700 text-xxs">
          <tr>
            <td className="py-3 px-5">Module</td>
            <td className="py-3 px-5 w-1/5">Submodule</td>
            <td className="py-3 px-5">Permission</td>
            <td className="py-3 px-5">Action</td>
          </tr>
        </thead>
        <tbody className="table__tbody">
          {permissionsData.map((permission: any, index) => (
            <React.Fragment key={index}>
              <tr>
                <td
                  className="py-3 px-5 bg-gray-200 uppercase text-xs font-semibold"
                  colSpan={4}
                >
                  {permission.name}
                </td>
              </tr>

              {permission.items.map((item: any, index: number) => (
                <>
                  <tr key={index} className="bg-white">
                    <td></td>
                    <td className="py-2 px-5 text-xs">{item.name}</td>
                    <td className="py-2 px-5 text-xs">
                      {Object.keys(item.permissions).map((permissionName) => (
                        <Permission key={permissionName} title={permissionName} isActive={item.permissions[permissionName]} />
                      ))}
                    </td>
                    <td className="py-2 px-5 text-xs">
                    <span className="text-xs text-gray-900 font-medium">
                      Added
                    </span>
                    </td>
                  </tr>

                  {Object.keys(item.permissions).some(permissionName => !item.permissions[permissionName]) && <tr>
                    <td colSpan={4} className='bg-white p-5'>
                      <span className="text-xs text-gray-900 font-semibold">Reason</span>
                      <p className="text-xs text-gray-900">Edit permissions set based on Operations Department request from FEB32021</p>
                    </td>
                  </tr>}
                </>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
