import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import 'react-virtualized/styles.css';
import './styles/index.css';
import './styles/index.tailwind.css';
import './styles/modal.css';
import { App } from './components/App';
import './i18n';
import { store, persistor } from './store/store';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-dates/lib/css/_datepicker.css';
import 'react-notifications-component/dist/theme.css';
import 'react-dates/initialize';
import 'src/styles/react-dates.scss';
import 'src/styles/animations.scss';
import 'src/styles/auth-code.scss';

import 'typeface-gothic-a1';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactNotification className={'z-50'} />
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
