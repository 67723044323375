import { useTranslation } from 'react-i18next';
import React from 'react';
import { IconArrowRight } from 'src/components/icons/IconArrowRight';

interface Props {
  onClick: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const LinkBack = ({ onClick }: Props) => {
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <a
      href={'!#'}
      onClick={handleClick}
      className={'flex items-center text-blue-700 text-sm leading-none'}
    >
      <IconArrowRight className={'transform rotate-180 mr-1 '} />
      <div>{t('buttons.back')}</div>
    </a>
  );
};
