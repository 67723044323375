import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { PayeesTable } from './PayeesTable';

export const PayeesList = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col bg-gray-200">
      <div className="absolute w-full">
        <div className="flex items-center justify-between w-full h-16 bg-white border-b">
          <div className="px-5">
            <p className="mb-0 text-lg font-bold leading-none text-black">
              {t('cards.table.payees')}
            </p>
          </div>
        </div>
      </div>

      <div className="p-2 pt-18">
        <div className="flex flex-col bg-white w-full h-full">
          <PayeesTable hasStatusFilter />
        </div>
      </div>
    </div>
  );
};
