import Amplify, { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export type User = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  preferredMFA: string;
};

const amplifyConfig = {
  Auth: {
    region: 'us-west-2',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/',
      responseType: 'code',
    },
  },
};

const ROLES_KEY = 'cognito:groups';

export const normalizeUser = (data: any): User => ({
  id: data.attributes.sub,
  email: data.attributes.email,
  emailVerified: data.attributes.email_verified,
  phoneNumber: data.attributes.phone_number,
  phoneNumberVerified: data.attributes.phone_number_verified,
  firstName: data.attributes.name,
  lastName: data.attributes.family_name,
  preferredMFA: data.preferredMFA
});

export class NewPasswordRequired extends Error {
  user: CognitoUser;

  constructor(user: CognitoUser) {
    super();

    this.user = user;

    // Not sure why, but without this crap, you can't compare the instance of errors.
    // TODO: Get rid of this crap.
    Object.setPrototypeOf(this, NewPasswordRequired.prototype);
  }
}

export class MFARequired extends Error {
  user: CognitoUser;

  constructor(user: CognitoUser) {
    super();
    this.user = user;
    Object.setPrototypeOf(this, MFARequired.prototype);
  }
}

export const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
// }): Promise<User> => {
}): Promise<{ user: User; token: string; roles: string[] }> => {
  const user = (await Auth.signIn(username, password)) as any;

  if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    throw new NewPasswordRequired(user);
  }

  if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
    throw new MFARequired(user);
  }

  return user;

  // return {
  //   user: normalizeUser(user),
  //   token: user.signInUserSession.accessToken.jwtToken,
  //   roles: user.signInUserSession.accessToken.payload[ROLES_KEY],
  // };
};

export const getAccountParams = (user: any) => ({
  user: normalizeUser(user),
  token: user.signInUserSession.accessToken.jwtToken,
  roles: user.signInUserSession.accessToken.payload[ROLES_KEY],
});

export const setNewPassword = async ({
  user,
  password,
}: {
  user: CognitoUser;
  password: string;
}) => await Auth.completeNewPassword(user, password);

export const logout = () => Auth.signOut();

export const forgotPassword = (username: string) =>
  Auth.forgotPassword(username);

export const resetPassword = ({
  username,
  code,
  newPassword,
}: {
  username: string;
  code: string;
  newPassword: string;
}) => Auth.forgotPasswordSubmit(username, code, newPassword);

export const configureAuth = (config: {
  userPoolId: string;
  userPoolWebClientId: string;
}) =>
  Amplify.configure({
    ...amplifyConfig,
    Auth: { ...amplifyConfig.Auth, ...config },
  });

configureAuth({ userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID!, userPoolWebClientId: process.env.REACT_APP_AUTH_USER_WEB_CLIENT_ID! });
// configureAuth({ userPoolId: 'us-west-2_oFfIaLAME', userPoolWebClientId: 'co1e47ajurmijgl22umq85q7o' });

export const getUser = async () =>
  normalizeUser(await Auth.currentAuthenticatedUser());

export const getToken = async () =>
  (await Auth.currentSession()).getIdToken().getJwtToken();

export const getRoles = async () =>
  (await Auth.currentSession()).getAccessToken().payload[ROLES_KEY] as string[];
