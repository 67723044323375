/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessChannel,
    AccessChannelFromJSON,
    AccessChannelFromJSONTyped,
    AccessChannelToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListIamAccessChannelsResponse
 */
export interface ListIamAccessChannelsResponse {
    /**
     * 
     * @type {Array<AccessChannel>}
     * @memberof ListIamAccessChannelsResponse
     */
    accessChannels?: Array<AccessChannel>;
}

export function ListIamAccessChannelsResponseFromJSON(json: any): ListIamAccessChannelsResponse {
    return ListIamAccessChannelsResponseFromJSONTyped(json, false);
}

export function ListIamAccessChannelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamAccessChannelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessChannels': !exists(json, 'access_channels') ? undefined : ((json['access_channels'] as Array<any>).map(AccessChannelFromJSON)),
    };
}

export function ListIamAccessChannelsResponseToJSON(value?: ListIamAccessChannelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_channels': value.accessChannels === undefined ? undefined : ((value.accessChannels as Array<any>).map(AccessChannelToJSON)),
    };
}


