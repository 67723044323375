import React, { PropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { VerificationPassed } from './components/verification-passed/VerificationPassed';
import get from 'lodash/get';
import { Loading } from 'src/components/Loading';
import { Auth } from 'aws-amplify';
import { Container as LoginContainer } from 'src/domain/auth/login/Container';
import { useLocation } from 'react-router-dom';
import { InvalidCredentials } from './components/invalid-credentials/InvalidCredentials';
import { SomethingWrong } from './components/something-wrong/Error';

export interface OnboardingProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {}

enum Step {
  ERROR,
  LOADING,
  SET_PASSWORD,
  PHONE_VERIFICATION,
  COMPLETED,
  INVALID_CREDENTIALS,
}

export const useAuthParams = () => {
  const params = new URLSearchParams(useLocation()?.search);

  const [email, password] = [params.get('email'), params.get('p')];

  const hasParams = !!email && !!password;

  return { email, password, hasParams };
};

export const Onboarding = React.forwardRef<HTMLDivElement, OnboardingProps>(
  (props, ref) => {
    const { hasParams, email, password } = useAuthParams();

    const [step, setStep] = useState(Step.LOADING);
    const [user, setUser] = useState();
    const [error, setError] = useState();

    useEffect(() => {
      const login = async () => {
        try {
          if (!hasParams) {
            setStep(Step.INVALID_CREDENTIALS);

            return;
          }

          const temporaryUser = await Auth.signIn(email!, password!);

          if (temporaryUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setUser(temporaryUser);
            setStep(Step.SET_PASSWORD);
            return;
          }

          // if (!temporaryUser?.attributes?.phone_number_verified) {
          //   setStep(Step.PHONE_VERIFICATION);
          //
          //   return;
          // }

          setStep(Step.INVALID_CREDENTIALS);
        } catch (e) {
          setError(get(e, 'message'));
          setStep(Step.INVALID_CREDENTIALS);
          // setStep(
          //   ['NotAuthorizedException', 'UserNotFoundException'].includes(e?.code)
          //     ? Step.INVALID_CREDENTIALS
          //     : Step.ERROR
          // );
        }
      };

      login();
    }, [email, password, hasParams]);

    const stepComponents = useMemo(
      () => ({
        [Step.ERROR]: <SomethingWrong />,
        // [Step.INVALID_CREDENTIALS]: <Redirect to="/login" />,
        [Step.INVALID_CREDENTIALS]: <InvalidCredentials error={error} />,
        [Step.LOADING]: (
          <div className="flex items-center justify-center w-full min-h-screen">
            <Loading />
          </div>
        ),
        [Step.SET_PASSWORD]: <LoginContainer temporaryUser={user} email={email} />,
        [Step.PHONE_VERIFICATION]: (
          <div className="flex items-center justify-center w-full min-h-screen">
            PHONE_VERIFICATION
          </div>
        ),
        [Step.COMPLETED]: (
          <VerificationPassed
            onComplete={() => {
              window.location.href = '/login';
            }}
          />
        ),
      }),
      [user, error]
    );

    return <>{stepComponents[step]}</>;
  }
);
