/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Card,
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListCardsResponse
 */
export interface ListCardsResponse {
    /**
     * 
     * @type {number}
     * @memberof ListCardsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<Card>}
     * @memberof ListCardsResponse
     */
    cards?: Array<Card>;
}

export function ListCardsResponseFromJSON(json: any): ListCardsResponse {
    return ListCardsResponseFromJSONTyped(json, false);
}

export function ListCardsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCardsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'cards': !exists(json, 'cards') ? undefined : ((json['cards'] as Array<any>).map(CardFromJSON)),
    };
}

export function ListCardsResponseToJSON(value?: ListCardsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'cards': value.cards === undefined ? undefined : ((value.cards as Array<any>).map(CardToJSON)),
    };
}


