import React, { useCallback, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { AuthCodeInput, PasswordInput } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Loading } from 'src/components/Loading';
import { forgotPassword, resetPassword } from 'src/auth/auth';

import { oneLowerCase, oneDigit, oneUpperCase } from 'src/validation/patterns';
import classNames from 'classnames';
import { Auth } from 'aws-amplify';
import moment from 'moment';

const PasswordRule = ({
  isValid,
  ruleName,
}: {
  isValid: boolean;
  ruleName: string;
}) => (
  <div
    className={classNames('flex w-1/2 pb-1', {
      'text-gray-900': isValid,
    })}
  >
    <div
      className={classNames('bg-gray-300 rounded-full mr-2', {
        'bg-green-500': isValid,
      })}
      style={{ flex: '0 0 6px', height: '6px', marginTop: '6px' }}
    />
    {ruleName}
  </div>
);

export const FormResetPassword = ({
  email,
  onSubmit = () => {},
  onMaxAttempts = () => {},
}: {
  onSubmit?: () => void;
  onMaxAttempts?: () => void;
  email: string;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup.object().shape({
      password: yup
        .string()
        .min(10, t('validations.minLength', { limit: 10 }))
        .max(40, t('validations.maxLength', { limit: 40 }))
        .matches(oneUpperCase, t('validations.oneUpperCase'))
        .matches(oneLowerCase, t('validations.oneLowerCase'))
        .matches(oneDigit, t('validations.oneDigit'))
        .required(t('validations.required')),
      // passwordConfirmation: yup
      //   .string()
      //   .required(t('validations.required'))
      //   .oneOf([yup.ref('password')], t('validations.passwordConfirmation')),
      code: yup.string().required(t('validations.required')),
    });
  }, [t]);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
      password: '',
    },
  });

  const formValues = watch();

  const [failed, setFailed] = useState(false);

  const [resendFailed, setResendFailed] = useState(false);

  const setChangedTime = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, {
        'custom:pwdLastChange':
          moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss') + ' UTC',
      });
    } catch (e) {}
  }, []);

  const onSubmitCallback = async (data: any) => {
    try {
      await resetPassword({
        username: email,
        code: data.code,
        newPassword: data.password,
      });

      setChangedTime();
      onSubmit();
    } catch (e) {
      if (e?.code === 'LimitExceededException') {
        onMaxAttempts();
      } else {
        setError('code', {
          type: 'error',
          message: 'Invalid or expired validation code',
        });
      }
    }
  };

  const [resendLoading, setResendLoading] = useState(false);

  const resendCode = async () => {
    try {
      setResendLoading(true);

      await forgotPassword(email);

      setResendLoading(false);
    } catch {
      setResendFailed(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)} className="w-full">
      {failed && (
        <p className="mb-2 text-sm font-medium text-center text-red-500">
          {t('auth.resetPasswordFail')}
        </p>
      )}
      <div className="pb-2">
        <AuthCodeInput
          label={t('auth.emailCode')}
          name="code"
          error={errors.code}
          control={control}
          rules={{ required: true }}
        />
      </div>
      <div className="mt-5">
        <input type="text" className="absolute" style={{ left: '-9999px' }} />
        <PasswordInput
          error={errors?.password}
          name="password"
          label={t('auth.newPassword')}
          inputRef={register({ required: true })}
        />
      </div>

      <div className="mt-2 text-gray-700 text-xs">
        <p>Please follow all the requirements for the password</p>

        <div className="flex flex-wrap pt-3">
          <PasswordRule
            isValid={
              formValues.password.length >= 10 &&
              formValues.password.length <= 40
            }
            ruleName="10-40 characters"
          />
          <PasswordRule
            isValid={oneUpperCase.test(formValues.password)}
            ruleName="ONE UPPERCASE letter"
          />
          <PasswordRule
            isValid={oneLowerCase.test(formValues.password)}
            ruleName="one lowercase letter"
          />
          <PasswordRule
            isValid={oneDigit.test(formValues.password)}
            ruleName="One number"
          />
        </div>
      </div>
      <div className="mt-10 text-center">
        <Button
          loading={formState.isSubmitting}
          type="submit"
          size="large"
          widthClass="w-full"
          disabled={formState.isSubmitting}
        >
          {t('auth.resetPassword')}
        </Button>
      </div>
      <div className="mt-6 text-center">
        {resendFailed ? (
          <p className="auth-text-sm">{t('auth.resetPasswordOtpFail')}</p>
        ) : resendLoading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <span
            className="text-blue-500 text-xs font-semibold cursor-pointer"
            onClick={(e) => {
              e.preventDefault();

              resendCode();
            }}
          >
            {t('auth.resendCode')}
          </span>
        )}
      </div>
    </form>
  );
};
