import React from 'react';
import {
  CustomSelect,
  CustomSelectProps,
} from 'src/components/list/CustomSelect';
import { useTranslation } from 'react-i18next';

export const PageSize = (
  props: Pick<CustomSelectProps, 'control' | 'options' | 'name'>
) => {
  const { t } = useTranslation();

  return (
    <div className="items-center hidden md:flex">
      <span className="text-xs text-gray-700">
        {t('list.pagination.rowPerPage')}:
      </span>
      <div className="ml-3">
        <CustomSelect {...props} defaultFirst={true} />
      </div>
    </div>
  );
};
