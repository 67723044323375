import { useCallback, useState } from 'react';

export const useStepper = ({
  count,
  initialStep = 0,
}: {
  count: number;
  initialStep?: number;
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep);

  const nextStep = useCallback(() => {
    if (currentStep < count - 1) {
      setCurrentStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [count, currentStep]);

  const previousStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevActiveStep) => prevActiveStep - 1);
    }
  }, [currentStep]);

  return { currentStep, nextStep, previousStep };
};
