import { useCurrentUser } from './hooks';
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

export const PublicRoute = (props: RouteProps) => {
  const { isAuthenticated } = useCurrentUser();

  return !isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};
