/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressObject
 */
export interface AddressObject {
    /**
     * 
     * @type {string}
     * @memberof AddressObject
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressObject
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressObject
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressObject
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressObject
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressObject
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressObject
     */
    addressLine2?: string;
}

export function AddressObjectFromJSON(json: any): AddressObject {
    return AddressObjectFromJSONTyped(json, false);
}

export function AddressObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
    };
}

export function AddressObjectToJSON(value?: AddressObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_code': value.countryCode,
        'country': value.country,
        'region': value.region,
        'city': value.city,
        'postalCode': value.postalCode,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
    };
}


