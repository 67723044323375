import React, { ReactNode, forwardRef, Ref, MouseEvent } from 'react';
import classNames from 'classnames';
import { LoadingSwitch } from './Loading';

type ButtonVariant =
  | 'primary'
  | 'link'
  | 'secondary'
  | 'success-outline'
  | 'text'
  | 'blue-outline'
  | 'yellow-outline'
  | 'red-pale'
  | 'red';
interface Props {
  type?: 'submit' | 'reset' | 'button';
  size?: 'small' | 'medium' | 'medium-large' | 'large';
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => any;
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
  widthClass?: string;
  paddingClass?: string;
  className?: string;
  prefix?: ReactNode;
}
export const Button = forwardRef(
  (
    {
      type = 'button',
      size = 'medium',
      children,
      widthClass = 'w-32',
      paddingClass = 'px-3',
      variant = 'primary',
      loading = false,
      disabled = false,
      className,
      onClick = () => {},
      prefix,
    }: Props,
    ref: Ref<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      type={type}
      disabled={disabled || loading}
      className={classNames(
        widthClass,
        className,
        paddingClass,
        'transition-all duration-200 rounded-sm focus:outline-none focus:shadow-outline disabled:cursor-not-allowed inline-flex items-center justify-center',
        {
          'bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-700':
            variant === 'primary',
          'bg-red-100 text-red-500 disabled:bg-gray-700 focus:shadow-outline-red':
            variant === 'red-pale',
          'text-gray-700 disabled:text-gray-500': variant === 'text',
          'text-blue-500 disabled:text-gray-500': variant === 'link',
          'text-green-500 border border-green-500 hover:bg-green-500 hover:text-white disabled:text-gray-500':
            variant === 'success-outline',
          'text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white disabled:text-gray-500':
            variant === 'blue-outline',
          'text-yellow-500 border border-yellow-500 hover:bg-yellow-500 hover:text-white disabled:text-gray-500':
            variant === 'yellow-outline',
          'bg-gray-300 text-gray-700 disabled:text-white':
            variant === 'secondary',
          'bg-red-600 text-white disabled:text-red-100':
            variant === 'red',
          'h-6': size === 'small',
          'h-8': size === 'medium',
          'h-10': size === 'medium-large',
          'h-12': size === 'large',
        }
      )}
      onClick={onClick}
    >
      <span
        className={classNames('text-center font-semibold w-full', {
          'text-xs': size === 'medium' || size === 'small',
          'text-sm': size === 'medium-large',
          'text-lg': size === 'large',
        })}
      >
        <LoadingSwitch
          loading={loading}
          animationProps={{
            width: 16,
            height: 16,
            className: 'mx-auto',
          }}
        >
          <div
            className={classNames({
              'flex justify-center': prefix,
            })}
          >
            {prefix && <div className={'mr-2'}>{prefix}</div>}
            <div className="flex items-center justify-center leading-3">{children}</div>
          </div>
        </LoadingSwitch>
      </span>
    </button>
  )
);
