/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateProgramChannelProviderRequest,
    CreateProgramChannelProviderRequestFromJSON,
    CreateProgramChannelProviderRequestToJSON,
    CreateProgramChannelProviderResponse,
    CreateProgramChannelProviderResponseFromJSON,
    CreateProgramChannelProviderResponseToJSON,
    GetProgramChannelProviderRequest,
    GetProgramChannelProviderRequestFromJSON,
    GetProgramChannelProviderRequestToJSON,
    GetProgramChannelProviderResponse,
    GetProgramChannelProviderResponseFromJSON,
    GetProgramChannelProviderResponseToJSON,
    ListProgramChannelProvidersRequest,
    ListProgramChannelProvidersRequestFromJSON,
    ListProgramChannelProvidersRequestToJSON,
    ListProgramChannelProvidersResponse,
    ListProgramChannelProvidersResponseFromJSON,
    ListProgramChannelProvidersResponseToJSON,
} from '../models';

export interface PostProgramChannelProvidersCreateRequest {
    createProgramChannelProviderRequest: CreateProgramChannelProviderRequest;
}

export interface PostProgramChannelProvidersGetRequest {
    getProgramChannelProviderRequest: GetProgramChannelProviderRequest;
}

export interface PostProgramChannelProvidersListRequest {
    listProgramChannelProvidersRequest?: ListProgramChannelProvidersRequest;
}

/**
 * 
 */
export class ProgramChannelProvidersApi extends runtime.BaseAPI {

    /**
     */
    async postProgramChannelProvidersCreateRaw(requestParameters: PostProgramChannelProvidersCreateRequest): Promise<runtime.ApiResponse<CreateProgramChannelProviderResponse>> {
        if (requestParameters.createProgramChannelProviderRequest === null || requestParameters.createProgramChannelProviderRequest === undefined) {
            throw new runtime.RequiredError('createProgramChannelProviderRequest','Required parameter requestParameters.createProgramChannelProviderRequest was null or undefined when calling postProgramChannelProvidersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/program-channel-providers/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProgramChannelProviderRequestToJSON(requestParameters.createProgramChannelProviderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProgramChannelProviderResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProgramChannelProvidersCreate(requestParameters: PostProgramChannelProvidersCreateRequest): Promise<CreateProgramChannelProviderResponse> {
        const response = await this.postProgramChannelProvidersCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProgramChannelProvidersGetRaw(requestParameters: PostProgramChannelProvidersGetRequest): Promise<runtime.ApiResponse<GetProgramChannelProviderResponse>> {
        if (requestParameters.getProgramChannelProviderRequest === null || requestParameters.getProgramChannelProviderRequest === undefined) {
            throw new runtime.RequiredError('getProgramChannelProviderRequest','Required parameter requestParameters.getProgramChannelProviderRequest was null or undefined when calling postProgramChannelProvidersGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/program-channel-providers/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProgramChannelProviderRequestToJSON(requestParameters.getProgramChannelProviderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProgramChannelProviderResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProgramChannelProvidersGet(requestParameters: PostProgramChannelProvidersGetRequest): Promise<GetProgramChannelProviderResponse> {
        const response = await this.postProgramChannelProvidersGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProgramChannelProvidersListRaw(requestParameters: PostProgramChannelProvidersListRequest): Promise<runtime.ApiResponse<ListProgramChannelProvidersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/program-channel-providers/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListProgramChannelProvidersRequestToJSON(requestParameters.listProgramChannelProvidersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProgramChannelProvidersResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProgramChannelProvidersList(requestParameters: PostProgramChannelProvidersListRequest): Promise<ListProgramChannelProvidersResponse> {
        const response = await this.postProgramChannelProvidersListRaw(requestParameters);
        return await response.value();
    }

}
