import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from '../tabs/Tab';
import { TabsHeader } from '../tabs/TabsHeader';
import { FormChangePassword } from './FormChangePassword';
import { ResetMFA } from './ResetMFA';
import { Card } from 'src/components/Card';

export const Security = () => {
  const { t } = useTranslation();

  return (
    <Tab
      header={
        <TabsHeader
          tabs={[
            {
              url: '/overview',
              label: t('userProfile.tabs.overview'),
            },
            {
              url: '/security',
              label: t('userProfile.tabs.security'),
            },
          ]}
        />
      }
      content={<SecurityTab />}
    />
  );
};

const SecurityTab = () => {
  return (
    <div className="relative flex flex-col bg-gray-200 min-h-full">
      <Card className="min-h-full">
        <div className='flex min-h-full'>
          <div className='w-1/2 -my-6 py-8 pr-8 pl-2 border-r border-gray-300'>
            <FormChangePassword />
          </div>

          <div className='w-1/2 px-8 py-2'>
            <ResetMFA />
          </div>
        </div>
      </Card>
    </div>
  );
};
