import React, { ReactNode } from 'react';
import { NavLink, Route } from 'react-router-dom';
import classNames from 'classnames';

export const TabLink = ({
  to,
  activeClass,
  widthClass,
  children,
}: {
  to: string;
  activeClass: string;
  widthClass: string;
  children: ReactNode;
}) => (
  <Route
    path={to}
    exact
    children={({ match }) => (
      <NavLink
        to={to}
        className={classNames(
          'flex items-center justify-center h-full text-xs relative font-bold',
          widthClass,
          {
            [activeClass]: !!match,
            'text-blue-500': !match,
            'border-b text-gray-900': !match,
          }
        )}
      >
        {!!match && <div className="absolute top-0 w-full h-1 bg-blue-500" />}
        {children}
      </NavLink>
    )}
  />
);
