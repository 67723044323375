/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyAssignment
 */
export interface CompanyAssignment {
    /**
     * 
     * @type {string}
     * @memberof CompanyAssignment
     */
    companyId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyAssignment
     */
    roleIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyAssignment
     */
    programIds?: Array<string>;
}

export function CompanyAssignmentFromJSON(json: any): CompanyAssignment {
    return CompanyAssignmentFromJSONTyped(json, false);
}

export function CompanyAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAssignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'roleIds': json['role_ids'],
        'programIds': !exists(json, 'program_ids') ? undefined : json['program_ids'],
    };
}

export function CompanyAssignmentToJSON(value?: CompanyAssignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'role_ids': value.roleIds,
        'program_ids': value.programIds,
    };
}


