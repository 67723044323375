import React, { ReactNode } from 'react';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

export const LayoutApp = ({
  children
}: {
  children: ReactNode;
}) => (
  <div className="flex h-full min-h-screen">
    <Sidebar />
    <div className="flex flex-col w-full h-screen">
      <Topbar />
      <div className="flex-grow w-full h-full pt-16 bg-gray-200 lg:pl-64">
        {children}
      </div>
    </div>
  </div>
);
