import React, {
  ReactNode,
  forwardRef,
  Ref,
  MouseEvent,
  MouseEventHandler,
} from 'react';
import classNames from 'classnames';

type BadgeVariant = 'default' | 'fill' | 'outline';

export const Badge = forwardRef(
  (
    {
      variant = 'default',
      children,
      disabled = false,
      onClick = () => {},
      onMouseEnter = () => {},
      onMouseLeave = () => {},
    }: {
      variant?: BadgeVariant;
      children: ReactNode;
      disabled?: boolean;
      onClick?: (event: MouseEvent | TouchEvent) => any;
      onMouseEnter?: MouseEventHandler;
      onMouseLeave?: MouseEventHandler;
    },
    ref: Ref<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      className={classNames(
        'h-4 px-2 rounded-full border flex items-center justify-center focus:outline-none disabled:cursor-not-allowed transition-colors duration-300',
        {
          'bg-gray-300 border-gray-300 text-gray-700': variant === 'default',
          'bg-white text-gray-700 border-gray-300': variant === 'outline',
          'bg-gray-700 border-gray-700 text-white': variant === 'fill',
        }
      )}
    >
      <span className="text-xxs font-bold leading-none">{children}</span>
    </button>
  )
);
