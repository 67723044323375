/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAuthorizationDetailsResponse,
    GetAuthorizationDetailsResponseFromJSON,
    GetAuthorizationDetailsResponseToJSON,
    IdWrapper,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    ListAuthorizationsPageRequest,
    ListAuthorizationsPageRequestFromJSON,
    ListAuthorizationsPageRequestToJSON,
    ListAuthorizationsPageResponse,
    ListAuthorizationsPageResponseFromJSON,
    ListAuthorizationsPageResponseToJSON,
} from '../models';

export interface PostAuthorizationsCsvRequest {
    listAuthorizationsPageRequest?: ListAuthorizationsPageRequest;
}

export interface PostAuthorizationsGetRequest {
    idWrapper?: IdWrapper;
}

export interface PostAuthorizationsListRequest {
    listAuthorizationsPageRequest?: ListAuthorizationsPageRequest;
}

/**
 * 
 */
export class AuthorizationsApi extends runtime.BaseAPI {

    /**
     */
    async postAuthorizationsCsvRaw(requestParameters: PostAuthorizationsCsvRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/authorizations/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListAuthorizationsPageRequestToJSON(requestParameters.listAuthorizationsPageRequest),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async postAuthorizationsCsv(requestParameters: PostAuthorizationsCsvRequest): Promise<Blob> {
        const response = await this.postAuthorizationsCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postAuthorizationsGetRaw(requestParameters: PostAuthorizationsGetRequest): Promise<runtime.ApiResponse<GetAuthorizationDetailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/authorizations/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdWrapperToJSON(requestParameters.idWrapper),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuthorizationDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postAuthorizationsGet(requestParameters: PostAuthorizationsGetRequest): Promise<GetAuthorizationDetailsResponse> {
        const response = await this.postAuthorizationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postAuthorizationsListRaw(requestParameters: PostAuthorizationsListRequest): Promise<runtime.ApiResponse<ListAuthorizationsPageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/authorizations/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListAuthorizationsPageRequestToJSON(requestParameters.listAuthorizationsPageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAuthorizationsPageResponseFromJSON(jsonValue));
    }

    /**
     */
    async postAuthorizationsList(requestParameters: PostAuthorizationsListRequest): Promise<ListAuthorizationsPageResponse> {
        const response = await this.postAuthorizationsListRaw(requestParameters);
        return await response.value();
    }

}
