import React, { useCallback, useContext, useMemo, useState } from 'react';
import get from 'lodash/get';
import { AddUserModal } from 'src/domain/access-management/user-management/tabs/users/add-user/AddUserModal';
import { CreateIamUserRequest, AccessChannel } from 'src/api/client';
import { notifications } from 'src/utils/notifications';
import { showErrors } from 'src/utils/showErrors';
import { UsersContext } from 'src/domain/access-management/user-management/tabs/users/UsersContext';
import { useGetUserQueries } from 'src/domain/access-management/user-management/tabs/users/add-user/useGetUserQueries';
import { useFormContext } from 'react-hook-form';

export const BACKOFFICE_ID = '152cf754-4810-4add-8c42-dffd3c19f6e9';

interface Params {
  selectedChannel?: null | AccessChannel;
  isEdit?: boolean;
  isOpen?: boolean;
}

export const useUserModal = ({
  selectedChannel,
  isEdit,
  isOpen = false,
}: Params) => {
  const { setValue } = useFormContext();

  const [visible, setVisible] = useState(isOpen);
  const [updatableUserId, setUpdatableUserId] = useState<string>();
  const { createUser, updateUser, accessChannelList } = useContext(
    UsersContext
  );
  const editUserData = useGetUserQueries({
    userId: updatableUserId,
    enabled: isEdit && !!updatableUserId,
  });

  const accessChannel = useMemo(() => {
    if (selectedChannel) {
      return selectedChannel;
    }

    if (!editUserData.user?.accessChannelId) {
      return;
    }

    return accessChannelList.find(
      (item) => item.id === editUserData.user?.accessChannelId
    );
  }, [editUserData.user, accessChannelList, selectedChannel]);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleOpen = useCallback((userId?: string) => {
    userId && setUpdatableUserId(userId);
    setVisible(true);
  }, []);

  const handleSubmit = useCallback(
    async (_user: CreateIamUserRequest) => {
      const update = async () => {
        if (!editUserData.user?.id) {
          throw new Error('User not found');
        }
        try {
          await updateUser({ ..._user, id: editUserData.user.id });
          setValue('cursor', 0);
          handleClose();
          notifications.success();
        } catch (err) {}
      };
      const create = async () => {
        try {
          await createUser(_user);
          setValue('cursor', 0);
          handleClose();
          notifications.success();
        } catch (err) {}
      };

      return isEdit ? update() : create();
    },
    [editUserData, createUser, updateUser, isEdit, handleClose]
  );

  const modal = useMemo(
    () =>
      visible && (
        <AddUserModal
          isEdit={isEdit}
          visible={visible}
          selectedChannel={accessChannel}
          closeModal={handleClose}
          onSubmit={handleSubmit}
          editUserData={editUserData}
        />
      ),
    [isEdit, visible, handleSubmit, handleClose, accessChannel, editUserData]
  );

  return { openModal: handleOpen, closeModal: handleClose, modal };
};
