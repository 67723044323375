import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { COUNT_ROLE_STEPS } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/AddRoleModal';

interface Props {
  title: string;
  currentStep: number;
  className?: string;
}

export const FormWrapper: FC<Props> = ({
  title,
  currentStep,
  className,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('mb-5 mx-5', className)}>
      <div className={'flex flex-col items-center mb-8'}>
        <div className={'font-bold mb-2'}>{title}</div>
        <div className={'text-gray-700 text-xs font-semibold'}>
          {t('accessManagement.rolesAndPermissions.role.step', {
            current: currentStep + 1,
            count: COUNT_ROLE_STEPS,
          })}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
