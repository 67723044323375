/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CardBrand,
    CardBrandFromJSON,
    CardBrandFromJSONTyped,
    CardBrandToJSON,
    CardStatus,
    CardStatusFromJSON,
    CardStatusFromJSONTyped,
    CardStatusToJSON,
    CardType,
    CardTypeFromJSON,
    CardTypeFromJSONTyped,
    CardTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    programId?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    programName?: string;
    /**
     * 
     * @type {CardBrand}
     * @memberof Card
     */
    brand?: CardBrand;
    /**
     * 
     * @type {CardType}
     * @memberof Card
     */
    type?: CardType;
    /**
     * 
     * @type {CardStatus}
     * @memberof Card
     */
    status?: CardStatus;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    cardholderName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Card
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Card
     */
    expiryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    availableBalance?: number;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    proxyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    lastFourDigits?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    maskedPan?: string;
}

export function CardFromJSON(json: any): Card {
    return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Card {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'programId': !exists(json, 'program_id') ? undefined : json['program_id'],
        'programName': !exists(json, 'program_name') ? undefined : json['program_name'],
        'brand': !exists(json, 'brand') ? undefined : CardBrandFromJSON(json['brand']),
        'type': !exists(json, 'type') ? undefined : CardTypeFromJSON(json['type']),
        'status': !exists(json, 'status') ? undefined : CardStatusFromJSON(json['status']),
        'cardholderName': !exists(json, 'cardholder_name') ? undefined : json['cardholder_name'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'expiryDate': !exists(json, 'expiry_date') ? undefined : (new Date(json['expiry_date'])),
        'availableBalance': !exists(json, 'available_balance') ? undefined : json['available_balance'],
        'currencyCode': !exists(json, 'currency_code') ? undefined : json['currency_code'],
        'proxyNumber': !exists(json, 'proxy_number') ? undefined : json['proxy_number'],
        'lastFourDigits': !exists(json, 'last_four_digits') ? undefined : json['last_four_digits'],
        'maskedPan': !exists(json, 'masked_pan') ? undefined : json['masked_pan'],
    };
}

export function CardToJSON(value?: Card | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'program_id': value.programId,
        'program_name': value.programName,
        'brand': CardBrandToJSON(value.brand),
        'type': CardTypeToJSON(value.type),
        'status': CardStatusToJSON(value.status),
        'cardholder_name': value.cardholderName,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'expiry_date': value.expiryDate === undefined ? undefined : (value.expiryDate.toISOString()),
        'available_balance': value.availableBalance,
        'currency_code': value.currencyCode,
        'proxy_number': value.proxyNumber,
        'last_four_digits': value.lastFourDigits,
        'masked_pan': value.maskedPan,
    };
}


