import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { LoadingSwitch } from 'src/components/Loading';
import { Card } from 'src/components/Card';
import { PageSize } from 'src/components/list/PageSize';
import { LoadMoreButton } from 'src/components/list/LoadMoreButton';
import {
  RowsPerPage,
  RulePermission,
} from 'src/api/services/access-management/roles-and-permissions/getRolesAndPermissions';
import { Sorting, Table } from 'src/components/table/Table';
import { useLogsColumns } from 'src/domain/access-management/roles-and-permissions/tabs/rules-log/useLogsColumns';
import { RolesLogsExpanded } from './RolesLogsExpanded';
import { nameof } from 'src/utils/nameof';
import {
  RolesLogsContext,
  rolesLogsFormType,
} from 'src/domain/access-management/roles-and-permissions/tabs/rules-log/RolesLogsContext';

export type RowsPerPageOptionType = {
  value: RowsPerPage;
  label: RowsPerPage;
};

const ROW_PER_PAGE_OPTIONS: RowsPerPageOptionType[] = [
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export interface LogsListParams {
  initLoading: boolean;
  loading: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  hasNextPage?: boolean;
  items: Array<RulePermission>;
}

export const RolesLogsList = () => {
  const [itemExpanded, setItemExpanded] = useState(null);
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { dispatch, columnSorting, listParams } = useContext(RolesLogsContext);
  const tableRef = useRef<HTMLDivElement>(null);
  const columns = useLogsColumns();
  const {
    // initLoading,
    loading,
    fetchNextPage,
    // totalItems,
    hasNextPage,
    items,
  } = listParams;

  const handleColumnSorting = useCallback(
    (sorting?: Sorting) => {
      if (loading) {
        return;
      }

      dispatch({
        type: 'SET_COLUMN_SORTING',
        columnSorting: sorting,
      });
    },
    [dispatch, loading]
  );

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [loading]);

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <Card className="h-full">
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col md:mb-6 lg:flex-row lg:items-center lg:justify-between">
            <div className={'items-center hidden md:flex font-bold'}>
              {t('accessManagement.rolesAndPermissions.logs.logs')}&nbsp;
              <LoadingSwitch loading={loading}>
                {!items?.length && <span className={'text-blue-500'}>0</span>}
              </LoadingSwitch>
            </div>
          </div>
          <div
            ref={tableRef}
            className="h-full max-h-full -mx-6 overflow-x-auto overflow-y-scroll"
          >
            <Table
              loading={loading}
              columns={columns}
              items={items}
              sorting={columnSorting}
              onSort={handleColumnSorting}
              itemExpand={itemExpanded}
              itemExpandContent={<RolesLogsExpanded />}
              onRowClick={(item) => {
                setItemExpanded(item);
              }}
            />
          </div>
          <div className="flex items-center justify-between p-5 py-3 mt-auto -mx-6 -mt-px -mb-5 bg-white border-t border-gray-300">
            <div className="items-center hidden text-xs text-gray-700 md:flex">
              {t('list.pagination.results')}:
              <span className="ml-1">
                <LoadingSwitch loading={loading}>
                  <span className="font-medium">{items?.length}</span>
                  {/*&nbsp;of&nbsp;*/}
                  {/*<span className="font-medium">{totalItems}</span>*/}
                </LoadingSwitch>
              </span>
            </div>
            <LoadMoreButton
              loading={loading}
              onClick={fetchNextPage}
              disabled={!hasNextPage}
            />
            <PageSize
              control={control}
              options={ROW_PER_PAGE_OPTIONS}
              name={nameof(rolesLogsFormType.limit)}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
