import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { IconCross } from './icons/IconCross';

export const Modal = ({
  isOpen,
  isOverlayDisabled = false,
  closeModal,
  hideCross = false,
  children,
  title,
  maxWidth,
  width,
}: {
  isOpen: boolean;
  isOverlayDisabled?: boolean;
  closeModal: () => any;
  hideCross?: boolean;
  title?: string;
  maxWidth?: string;
  width?: string;
  children: ReactNode;
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={isOverlayDisabled ? () => {} : closeModal}
    closeTimeoutMS={200}
    style={{
      content: {
        border: '0',
        borderRadius: '2px',
        bottom: 'auto',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: width || '80%',
        maxWidth: maxWidth || '31rem',
        maxHeight: '100vh',
      },
    }}
    contentLabel="Example Modal"
  >
    <div className="relative">
      {!hideCross && (
        <button
          onClick={closeModal}
          className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mt-4 -mr-4 text-gray-700 transition-shadow duration-200 bg-gray-200 rounded-sm focus:shadow-outline focus:outline-none"
        >
          <IconCross />
        </button>
      )}
    </div>
    <div className="relative">
      {title && (
        <p className="font-bold tracking-wider text-gray-900 uppercase text-xxxs">
          {title}
        </p>
      )}
      {children}
    </div>
  </ReactModal>
);
