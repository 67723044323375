import { createContext } from 'react';
import { Sorting } from 'src/components/table/Table';
import { RowsPerPage } from './rowsPerPage';
import { TransactionStatus, TransactionType, CardType } from '../transaction';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import {
  addFilterToState,
  removeFilterFromState,
  resetFilterInState,
} from 'src/utils/filters';
import { DateRange } from 'src/types/dateRange';

type ItemFilters = {
  merchants: ItemFilter[];
  transactionTypes: ItemFilter[];
  cardTypes: ItemFilter[];
  matchTypes: ItemFilter[];
};

type MiscFilters = {
  cardLastFour: string;
  authorizationCode: string;
  settlementAmount: string;
  arnNumber: string;
  rrnNumber: string;
  stan: string;
  status: TransactionStatus | null;
  settlementDate: DateRange;
  transactionDate: DateRange;
};

export type ListFilters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {
  merchants: [],
  transactionTypes: [],
  cardTypes: [],
  matchTypes: [],
};

const miscFilters: MiscFilters = {
  cardLastFour: '',
  authorizationCode: '',
  settlementAmount: '',
  arnNumber: '',
  rrnNumber: '',
  stan: '',
  status: null,
  settlementDate: {
    from: null,
    to: null,
  },
  transactionDate: {
    from: null,
    to: null,
  },
};

const getItemFilters = (filters: ListFilters): ItemFilters =>
  Object.keys(itemFilters).reduce(
    (result, key) => ({
      ...result,
      [key]: filters[key as keyof ListFilters],
    }),
    {} as ItemFilters
  );

export const defaultFilters: ListFilters = {
  ...itemFilters,
  ...miscFilters,
};

export type TransactionItem = {
  id: string;
  settlementDate: string;
  cardType: CardType;
  cardNumber: string;
  terminalId: string;
  authorizationCode: string;
  referenceNumber: string;
  transactionDate: string;
  transactionAmount: number;
  settlementCurrencyCode: string;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  transactionTime: string;
  subMerchantId: string;
  subMerchantName: string;
  matchType: string;
};

export const initialState = {
  items: [] as TransactionItem[],
  itemsLoading: 0,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  rowsPerPage: 20 as RowsPerPage,
  filters: defaultFilters,
  appliedFilters: defaultFilters,
  downloadLoading: false,
  download: () => {},
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_ITEMS'; items: TransactionItem[] }
  | { type: 'APPEND_ITEMS'; items: TransactionItem[] }
  | { type: 'SET_COLUMN_SORTING'; columnSorting?: Sorting }
  | { type: 'INCREMENT_ITEMS_LOADING' }
  | { type: 'DECREMENT_ITEMS_LOADING' }
  | { type: 'SET_PAGINATION_LOADING'; loading: boolean }
  | { type: 'SET_DOWNLOAD_LOADING'; loading: boolean }
  | { type: 'SET_END_REACHED'; endReached: boolean }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_SEARCH_QUERY'; searchQuery: string }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: RowsPerPage }
  | { type: 'ADD_MERCHANTS_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_MERCHANTS_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_MERCHANTS_FILTER' }
  | { type: 'ADD_TRANSACTION_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_TRANSACTION_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_TRANSACTION_TYPE_FILTER' }
  | { type: 'ADD_CARD_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_CARD_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_CARD_TYPE_FILTER' }
  | { type: 'ADD_MATCH_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_MATCH_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_MATCH_TYPE_FILTER' }
  | { type: 'SET_TRANSACTION_ID_FILTER'; transactionId: string }
  | { type: 'SET_CARD_LAST_FOUR_FILTER'; cardLastFour: string }
  | { type: 'SET_AUTHORIZATION_CODE_FILTER'; authorizationCode: string }
  | { type: 'SET_SETTLEMENT_AMOUNT_FILTER'; settlementAmount: string }
  | { type: 'SET_ARN_NUMBER_FILTER'; arnNumber: string }
  | { type: 'SET_RRN_NUMBER_FILTER'; rrnNumber: string }
  | { type: 'SET_STAN_FILTER'; stan: string }
  | { type: 'SET_STATUS_FILTER'; status: TransactionStatus }
  | { type: 'RESET_STATUSES_FILTER' }
  | { type: 'SET_SETTLEMENT_DATE_FILTER'; dateRange: DateRange }
  | { type: 'RESET_SETTLEMENT_DATE_FILTER' }
  | { type: 'SET_TRANSACTION_DATE_FILTER'; dateRange: DateRange }
  | { type: 'RESET_TRANSACTION_DATE_FILTER' }
  | { type: 'SET_DOWNLOAD_LOADING'; loading: boolean }
  | { type: 'APPLY_FILTERS' }
  | { type: 'RESET_ALL_FILTERS' };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_ITEMS':
      return { ...state, items: action.items };
    case 'APPEND_ITEMS':
      return { ...state, items: [...state.items, ...action.items] };
    case 'INCREMENT_ITEMS_LOADING':
      return { ...state, itemsLoading: state.itemsLoading + 1 };
    case 'DECREMENT_ITEMS_LOADING':
      return {
        ...state,
        itemsLoading: state.itemsLoading > 0 ? state.itemsLoading - 1 : 0,
      };
    case 'SET_PAGINATION_LOADING':
      return { ...state, paginationLoading: action.loading };
    case 'SET_END_REACHED':
      return { ...state, endReached: action.endReached };
    case 'SET_PAGE':
      return { ...state, page: action.page };
    case 'SET_ROWS_PER_PAGE':
      return { ...state, rowsPerPage: action.rowsPerPage };
    case 'ADD_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
          }),
        },
      };
    case 'ADD_TRANSACTION_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'transactionTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_TRANSACTION_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'transactionTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_TRANSACTION_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'transactionTypes',
          }),
        },
      };
    case 'ADD_CARD_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'cardTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_CARD_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'cardTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_CARD_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'cardTypes',
          }),
        },
      };
    case 'ADD_MATCH_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'matchTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_MATCH_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'matchTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_MATCH_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'matchTypes',
          }),
        },
      };
    case 'SET_CARD_LAST_FOUR_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          cardLastFour: action.cardLastFour,
        },
      };
    }
    case 'SET_AUTHORIZATION_CODE_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          authorizationCode: action.authorizationCode,
        },
      };
    }
    case 'SET_SETTLEMENT_AMOUNT_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          settlementAmount: action.settlementAmount,
        },
      };
    }
    case 'SET_ARN_NUMBER_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          arnNumber: action.arnNumber,
        },
      };
    }
    case 'SET_RRN_NUMBER_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          rrnNumber: action.rrnNumber,
        },
      };
    }
    case 'SET_STAN_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          stan: action.stan,
        },
      };
    }
    case 'SET_STATUS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.status,
        },
      };
    case 'RESET_STATUSES_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          status: null,
        },
      };
    case 'SET_SETTLEMENT_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          settlementDate: action.dateRange,
        },
      };
    case 'RESET_SETTLEMENT_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          settlementDate: {
            from: null,
            to: null,
          },
        },
      };
    case 'SET_TRANSACTION_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          transactionDate: action.dateRange,
        },
      };
    case 'RESET_TRANSACTION_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          transactionDate: {
            from: null,
            to: null,
          },
        },
      };
    case 'SET_DOWNLOAD_LOADING':
      return { ...state, downloadLoading: action.loading };
    case 'APPLY_FILTERS':
      return {
        ...state,
        appliedFilters: Object.assign({}, state.filters),
      };
    case 'RESET_ALL_FILTERS':
      return {
        ...state,
        filters: defaultFilters,
        appliedFilters: defaultFilters,
      };
    default:
      throw new Error();
  }
};

export const ListContext = createContext<typeof initialState>(initialState);
