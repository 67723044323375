import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container as ListContainer } from './list/Container';

export const Transactions = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path}>
        <ListContainer />
      </Route>
    </Switch>
  );
};
