import React from 'react';

export const IconRepeat = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.3333 0.666748L14 3.33341L11.3333 6.00008"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 7.33325V5.99992C2 5.29267 2.28095 4.6144 2.78105 4.1143C3.28115 3.6142 3.95942 3.33325 4.66667 3.33325H14"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66667 15.3333L2 12.6667L4.66667 10"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 8.66675V10.0001C14 10.7073 13.719 11.3856 13.219 11.8857C12.7189 12.3858 12.0406 12.6667 11.3333 12.6667H2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
