import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { iconAccessChannelsMap } from 'src/domain/access-management/user-management/tabs/users/useUsersColumns';
import { AccessChannel } from 'src/api/client';

interface Props {
  title: string;
  selectedChannel?: AccessChannel | null;
  className?: string;
}

export const StepWrapper: FC<Props> = ({
  title,
  selectedChannel,
  className,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('mb-5', className)}>
      <div className="pt-8 pb-4 px-8 -mt-10 mb-4 -ml-8 -mr-8 border-b border-gray-300">
        <div className="flex">
          <div className="font-bold text-base text-gray-900 w-1/2">{title}</div>
          {selectedChannel && (
            <div>
              <div className="text-xs text-gray-700">
                {t(
                  'accessManagement.rolesAndPermissions.roleDetails.accessChannel'
                )}
              </div>
              <div className="flex items-center text-gray-900 font-semibold text-xs">
                {selectedChannel.name &&
                  iconAccessChannelsMap(selectedChannel.name)}
                {selectedChannel.name}
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
