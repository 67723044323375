import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import get from 'lodash/get';
import { CellLight, Column } from 'src/components/table/Table';
import { RulePermission } from 'src/api/services/access-management/roles-and-permissions/getRolesAndPermissions';
import { IconVerticalDots } from 'src/components/icons/IconVerticalDots';
import { Tooltip } from 'src/components/Tooltip';
import { HorizontalDivider } from 'src/components/HorizontalDivider';
import { IconPen } from 'src/components/icons/IconPen';
import { IconTrash } from 'src/components/icons/IconTrash';
import { RolesContext } from 'src/domain/access-management/roles-and-permissions/tabs/rules/RolesContext';
import { ReactComponent as IaecspIcon } from 'src/assets/icons/iaecsp.svg';
import { ReactComponent as BooIcon } from 'src/assets/icons/boo.svg';

export const iconMap = (name: string) => {
  const icons = {
    'IAECSP Corporate Portal': <IaecspIcon className="mr-2" />,
    BOO: <BooIcon className="mr-2" />,
  };

  return get(icons, name, null);
};

export const useRolesColumns = ({
  onDelete,
  onEdit,
}: {
  onDelete?: (id: string) => void;
  onEdit?: (role: RulePermission) => void;
}) => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const { accessChannelList } = useContext(RolesContext);

  return useMemo(
    (): Column[] => [
      {
        id: 'roleName',
        value: (item: RulePermission) => get(item, 'roleName'),
        label: t('accessManagement.rolesAndPermissions.list.name'),
        width: 150,
        sortable: true,
        columnFilter: () => null,
      },
      {
        id: 'channelName',
        value: (item: RulePermission) => {
          return (
            <span className="flex items-center text-gray-900 font-semibold">
              {get(item, 'channelName') &&
                iconMap(get(item, 'channelName', ''))}

              {get(item, 'channelName')}
            </span>
          );
        },
        label: t('accessManagement.rolesAndPermissions.list.channel'),
        width: 200,
        sortable: true,
        columnFilter: () => null,
      },
      {
        id: 'roleDescription',
        value: (item: RulePermission) => (
          <CellLight>{get(item, 'roleDescription')}</CellLight>
        ),
        label: t('accessManagement.rolesAndPermissions.list.description'),
        width: 400,
        sortable: true,
        columnFilter: () => null,
      },
      {
        id: 'actions',
        value: (item: RulePermission) => (
          <Tooltip
            minWidth={150}
            placement="bottom-end"
            withPadding={false}
            content={() => (
              <div className="py-1">
                {onEdit && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(item);
                    }}
                    className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100"
                  >
                    <IconPen />
                    <span className="ml-2">{t('buttons.edit')}</span>
                  </div>
                )}
                <div className={'mx-4 py-1'}>
                  <HorizontalDivider />
                </div>
                {onDelete && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(get(item, 'id'));
                    }}
                    className="flex items-center w-full h-5 px-6 py-4 mr-5 font-semibold hover:bg-gray-100 cursor-pointer"
                  >
                    <IconTrash className="text-red-500" />
                    <span className="ml-2">{t('buttons.delete')}</span>
                  </div>
                )}
              </div>
            )}
          >
            {({ ref, setIsOpen, isOpen }) => (
              <div
                ref={ref}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(!isOpen);
                }}
                className="mr-5 cursor-pointer"
              >
                <IconVerticalDots className="text-gray-700" />
              </div>
            )}
          </Tooltip>
        ),
        label: t('list.common.action'),
        width: 50,
        columnFilter: () => null,
      },
    ],
    [t, routeMatch.path, accessChannelList, onDelete]
  );
};
