import React, {
  createContext,
  FC,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { Sorting } from 'src/components/table/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { nameof, typeProxy } from 'src/utils/nameof';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { useInfiniteQuery } from 'react-query';
import {
  getLogs,
  INITIAL_LOGS_OPTIONS,
  LogsQueryOptions,
} from 'src/api/services/access-management/roles-and-permissions/getLogs';
import { LogsListParams } from 'src/domain/access-management/roles-and-permissions/tabs/rules-log/RolesLogsList';

const QUERY_KEY = 'ACCESS_MANAGEMENT/RULES_LOGS';

interface RulesLogsState {
  dispatch: (action: Actions) => void;
}

export const initialState = {
  dispatch: (action: Actions) => {},
  columnSorting: undefined as Sorting | undefined,
  listParams: {} as LogsListParams,
};

export type Actions = {
  type: 'SET_COLUMN_SORTING';
  columnSorting: Sorting | undefined;
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_COLUMN_SORTING':
      return { ...state, columnSorting: action.columnSorting };
    default:
      throw new Error();
  }
};

export const RolesLogsContext = createContext<typeof initialState>(
  initialState
);

export interface RolesLogsListFields extends Pick<LogsQueryOptions, 'limit'> {
  searchQuery: string;
}

export const rolesLogsFormType = typeProxy<RolesLogsListFields>();

export const RolesLogsContextProvider: FC = ({ children }) => {
  const methods = useForm<RolesLogsListFields>();
  const [state, dispatchAction] = useReducer(reducer, initialState);
  const { searchQuery, limit } = methods.watch([
    nameof(rolesLogsFormType.searchQuery),
    nameof(rolesLogsFormType.limit),
  ]);
  const debouncedSearchQuery = useDebouncedValue<string | undefined>(
    searchQuery,
    750
  );

  const {
    data: listData,
    isLoading: initListLoading,
    isFetching: listLoading,
    hasNextPage: listHasNextPage,
    fetchNextPage: listFetchNextPage,
  } = useInfiniteQuery(
    [
      QUERY_KEY,
      {
        sorting: state.columnSorting,
        searchQuery: debouncedSearchQuery,
        limit,
      },
    ],
    getLogs,
    {
      getNextPageParam: (lastPage, allPages) => allPages.length < 5, //TODO TEST LIMIT
      onError: (err) => console.log(err),
    }
  );

  const items = useMemo(() => listData?.pages?.flat() || [], [listData]);

  const handleFetchNextPage = useCallback(async () => {
    if (listData) {
      await listFetchNextPage({
        pageParam: { ...INITIAL_LOGS_OPTIONS },
      });
    }
  }, [listData, listFetchNextPage]);

  const listParams: LogsListParams = useMemo(
    () => ({
      items,
      initLoading: initListLoading,
      loading: listLoading,
      hasNextPage: listHasNextPage,
      fetchNextPage: handleFetchNextPage,
    }),
    [items, listHasNextPage, handleFetchNextPage, initListLoading, listLoading]
  );

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info('Dispatch Action', {
        ...action,
        context: QUERY_KEY,
      });
    }

    dispatchAction(action);
  }, []);

  return (
    <FormProvider {...methods}>
      <RolesLogsContext.Provider
        value={{
          ...state,
          dispatch,
          listParams,
        }}
      >
        {children}
      </RolesLogsContext.Provider>
    </FormProvider>
  );
};
