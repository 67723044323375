import React, { ReactNode } from 'react';
import { Transition } from 'react-transition-group';

export const FadeTransition = ({
  children,
  ...rest
}: {
  children: ReactNode;
}) => (
  <Transition {...rest} timeout={100} unmountOnExit mountOnEnter>
    {state => (
      <div
        style={{
          transition: '0.2s',
          opacity: state === 'entered' ? 1 : 0,
          display: state === 'exited' ? 'none' : 'block',
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);
