import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { IconLogo } from 'src/components/icons/IconLogo';
import { FormLogin } from './FormLogin';
import { SetNewPasswordForm } from './FormSetNewPassword';
import classNames from 'classnames';

enum Mode {
  LOGIN,
  SET_NEW_PASSWORD,
}

export enum LoginStep {
  LOGIN,
  OTP,
}

export const Container = ({ temporaryUser, email }: { temporaryUser?: CognitoUser, email?: string | null }) => {
  const [mode, setMode] = useState(Mode.LOGIN);
  const [loginStep, setLoginStep] = useState(LoginStep.LOGIN);
  const [isCodeRequested, setCodeRequested] = useState(false);
  const [isSetupMFAStep, setIsSetupMFAStep] = useState(false);
  const [user, setUser] = useState<CognitoUser | undefined>();

  useEffect(() => {
    if (temporaryUser) {
      setMode(Mode.SET_NEW_PASSWORD);
      setUser(temporaryUser);
    }
  }, [temporaryUser]);

  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      <div
        className={classNames(
          'flex flex-col items-center justify-center w-full',
          [isSetupMFAStep ? 'max-w-6xl' : 'max-w-sm']
        )}
      >
        <IconLogo className="mb-5" />
        <h3 className="mb-2 text-2xl font-semibold text-gray-900 text-center">
          {isSetupMFAStep
            ? 'Set Up Two Factor Authentication'
            : loginStep === LoginStep.LOGIN && mode === Mode.LOGIN
            ? 'Welcome back!'
            : loginStep === LoginStep.OTP && mode === Mode.LOGIN
            ? 'Enter Authenticator Code'
            : mode === Mode.SET_NEW_PASSWORD
            ? 'Welcome!'
            : ''}
        </h3>
        {loginStep !== LoginStep.OTP && !isSetupMFAStep && (
          <p className="mb-8 text-base font-semibold text-gray-700">
            {mode === Mode.LOGIN && 'Log In to continue'}
            {mode === Mode.SET_NEW_PASSWORD && 'Start by setting your password'}
          </p>
        )}
        {mode === Mode.LOGIN && (
          <FormLogin
            setLoginStep={setLoginStep}
            setIsSetupMFAStep={setIsSetupMFAStep}
            setCodeRequested={setCodeRequested}
            onSetNewPasswordError={({ user }) => {
              setMode(Mode.SET_NEW_PASSWORD);
              setUser(user);
            }}
          />
        )}

        {mode === Mode.SET_NEW_PASSWORD && user && (
          <SetNewPasswordForm
            onSubmit={() => setMode(Mode.LOGIN)}
            user={user}
            email={email}
          />
        )}
        {mode === Mode.LOGIN &&
          loginStep !== LoginStep.OTP &&
          !isCodeRequested && (
            <Link
              to="/forgot-password"
              className="text-sm mt-3 font-semibold text-blue-500"
            >
              Forgot your password?
            </Link>
          )}
      </div>
    </div>
  );
};
