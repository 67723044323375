import React, { useState, useRef, useCallback, useMemo } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PasswordInput } from 'src/components/Input';
import { Button } from 'src/components/Button';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { getToken as getUserToken, login, setNewPassword } from 'src/auth/auth';
import {
  oneLowerCase,
  oneDigit,
  oneUpperCase,
} from 'src/validation/patterns';
import classNames from 'classnames';
import { configureApi, profileApi, usersApi } from 'src/api';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';
import find from 'lodash/find';
import { useMutation } from 'react-query';
import { IamUserStatus } from 'src/api/client';

const PasswordRule = ({
  isValid,
  ruleName,
}: {
  isValid: boolean;
  ruleName: string;
}) => (
  <div
    className={classNames('flex w-1/2 pb-1', {
      'text-gray-900': isValid,
    })}
  >
    <div
      className={classNames('bg-gray-300 rounded-full mr-2', {
        'bg-green-500': isValid,
      })}
      style={{ flex: '0 0 6px', height: '6px', marginTop: '6px' }}
    />
    {ruleName}
  </div>
);

export const SetNewPasswordForm = ({
  onSubmit,
  user,
  email
}: {
  onSubmit: () => void;
  user: CognitoUser;
  email?: string | null;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup.object().shape({
      password: yup
        .string()
        .min(10, t('validations.minLength', { limit: 10 }))
        .max(40, t('validations.maxLength', { limit: 40 }))
        .matches(oneUpperCase, t('validations.oneUpperCase'))
        .matches(oneLowerCase, t('validations.oneLowerCase'))
        .matches(oneDigit, t('validations.oneDigit'))
        .required(t('validations.required')),
    });
  }, [t]);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const formValues = watch();

  const [loading, setLoading] = useState(false);

  const [displayCaptcha] = useState(false);

  const recaptchaRef = useRef<any>(null);

  const onChange = useCallback(
    (token) => {
      setValue('captchaToken', token);
      trigger('captchaToken');
    },
    [trigger, setValue]
  );

  const { mutateAsync: userStatusUpdateMutation } = useMutation(
    () =>
      profileApi.postProfileUpdate({
        updateProfileRequest: { iamUser: { status: IamUserStatus.Active } },
      })
  );


  const onFormSubmit = async (data: any) => {
    setLoading(true);

    try {
      await setNewPassword({
        user,
        password: data.password,
      });

      if (email) {
        try {
          await login({
            username: email,
            password: data.password,
          });

          const token = await getUserToken();

          configureApi({ token });
          userStatusUpdateMutation();
        } catch (e) {}
      }

      setTimeout(() => {
        onSubmit();
      });
    } catch (error) {
      setError('password', {
        type: 'manual',
        message: t('validations.somethingWentWrong'),
      });

      setValue('password', '');
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="w-full">
      <div className="mb-4">
        <PasswordInput
          label={t('loginForm.password')}
          name="password"
          error={errors.password}
          inputRef={register({ required: true })}
        />
      </div>
      <div className="text-gray-700 text-xs">
        <p>Please follow all the requirements for the password</p>

        <div className="flex flex-wrap pt-3">
          <PasswordRule
            isValid={
              formValues.password &&
              formValues.password.length >= 10 &&
              formValues.password.length <= 40
            }
            ruleName="10-40 characters"
          />
          <PasswordRule
            isValid={
              formValues.password && oneUpperCase.test(formValues.password)
            }
            ruleName="ONE UPPERCASE letter"
          />
          <PasswordRule
            isValid={
              formValues.password && oneLowerCase.test(formValues.password)
            }
            ruleName="one lowercase letter"
          />
          <PasswordRule
            isValid={
              formValues.password && oneDigit.test(formValues.password)
            }
            ruleName="One number"
          />
        </div>
      </div>

      <div className="mt-8 text-center">
        <Button
          loading={loading}
          disabled={loading}
          type="submit"
          size="large"
          widthClass="w-full"
        >
          {t('loginForm.setPassword')}
        </Button>
      </div>
      <div className="mt-5">
        {displayCaptcha && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="normal"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
            onChange={onChange}
          />
        )}
        {errors.captchaToken && (
          <p className="ml-5 text-xs font-medium text-red-500">
            {t('validations.required')}
          </p>
        )}
      </div>
    </form>
  );
};
