import { createContext } from 'react';
import { AuditLog } from 'src/api/client';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { Sorting } from 'src/components/table/Table';
import { DateRange } from 'src/types/dateRange';
import {
  addFilterToState,
  removeFilterFromState,
  resetFilterInState,
} from 'src/utils/filters';
import { RowsPerPage } from './rowsPerPage';

export interface LogItem extends AuditLog {}

type ItemFilters = {
  merchants: ItemFilter[];
  api: ItemFilter[];
};

type MiscFilters = {
  cardLastFour: string;
  terminalId: string;
  referenceNumber: string;
  processDate: DateRange;
};

export type Filters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {
  merchants: [],
  api: [],
};

const miscFilters: MiscFilters = {
  cardLastFour: '',
  terminalId: '',
  referenceNumber: '',
  processDate: {
    from: null,
    to: null,
  },
};

const getItemFilters = (filters: Filters): ItemFilters =>
  Object.keys(itemFilters).reduce(
    (result, key) => ({
      ...result,
      [key]: filters[key as keyof Filters],
    }),
    {} as ItemFilters
  );

export const defaultFilters: Filters = {
  ...itemFilters,
  ...miscFilters,
};

export const initialState = {
  items: [] as LogItem[],
  itemsLoading: 0,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  rowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  filters: defaultFilters,
  appliedFilters: defaultFilters,
  download: () => {},
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_ITEMS'; items: LogItem[] }
  | { type: 'APPEND_ITEMS'; items: LogItem[] }
  | { type: 'SET_COLUMN_SORTING'; columnSorting?: Sorting }
  | { type: 'INCREMENT_ITEMS_LOADING' }
  | { type: 'DECREMENT_ITEMS_LOADING' }
  | { type: 'SET_PAGINATION_LOADING'; loading: boolean }
  | { type: 'SET_DOWNLOAD_LOADING'; loading: boolean }
  | { type: 'SET_END_REACHED'; endReached: boolean }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_SEARCH_QUERY'; searchQuery: string }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: RowsPerPage }
  | { type: 'SET_DOWNLOAD_LOADING'; loading: boolean }
  | { type: 'SET_TERMINAL_ID_FILTER'; terminalId: string }
  | { type: 'SET_REFERENCE_NUMBER_FILTER'; referenceNumber: string }
  | { type: 'ADD_MERCHANTS_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_MERCHANTS_FILTER'; itemFilter: ItemFilter }
  | { type: 'SET_PROCESS_DATE_FILTER'; dateRange: DateRange }
  | { type: 'RESET_PROCESS_DATE_FILTER' }
  | { type: 'RESET_MERCHANTS_FILTER' }
  | { type: 'RESET_STATUSES_FILTER' }
  | { type: 'ADD_API_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_API_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_API_FILTER' }
  | { type: 'APPLY_FILTERS' }
  | { type: 'RESET_ALL_FILTERS' };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_ITEMS':
      return { ...state, items: action.items };
    case 'APPEND_ITEMS':
      return { ...state, items: [...state.items, ...action.items] };
    case 'INCREMENT_ITEMS_LOADING':
      return { ...state, itemsLoading: state.itemsLoading + 1 };
    case 'DECREMENT_ITEMS_LOADING':
      return {
        ...state,
        itemsLoading: state.itemsLoading > 0 ? state.itemsLoading - 1 : 0,
      };
    case 'SET_PAGINATION_LOADING':
      return { ...state, paginationLoading: action.loading };
    case 'SET_END_REACHED':
      return { ...state, endReached: action.endReached };
    case 'SET_PAGE':
      return { ...state, page: action.page };
    case 'SET_ROWS_PER_PAGE':
      return { ...state, rowsPerPage: action.rowsPerPage };
    case 'SET_DOWNLOAD_LOADING':
      return { ...state, downloadLoading: action.loading };
    case 'SET_TERMINAL_ID_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          terminalId: action.terminalId,
        },
      };
    }
    case 'SET_REFERENCE_NUMBER_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          referenceNumber: action.referenceNumber,
        },
      };
    }
    case 'ADD_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
          }),
        },
      };
    case 'SET_PROCESS_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          processDate: action.dateRange,
        },
      };
    case 'RESET_PROCESS_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          processDate: {
            from: null,
            to: null,
          },
        },
      };
    case 'ADD_API_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'api',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_API_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'api',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_API_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'api',
          }),
        },
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        appliedFilters: Object.assign({}, state.filters),
      };
    case 'RESET_ALL_FILTERS':
      return {
        ...state,
        filters: defaultFilters,
        appliedFilters: defaultFilters,
      };
    default:
      throw new Error();
  }
};

export const ListContext = createContext<typeof initialState>(initialState);
