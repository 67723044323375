import React, {
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AuthorizationsList } from './AuthorizationsList';
import {
  reducer,
  initialState,
  Actions,
  ListContext,
  ListFilters,
  AuthorizationItem,
} from './context';
import { useDispatch } from 'react-redux';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import {
  Authorization,
  CcvResultCode,
  GPCTransactionType,
} from 'src/api/client';
import { CardType } from '../../transactions/transaction';
import { TransactionType } from '../authorization';
import { authorizationsApi } from 'src/api';

type LoadItemsOptions = {
  limit: number;
  offset?: number;
  page?: number;
  filters: ListFilters;
};

const listRequestPayload = (options: LoadItemsOptions) => ({
  transactionDate:
    options.filters.transactionDate.from && options.filters.transactionDate.to
      ? {
          from: options.filters.transactionDate.from.toString(),
          to: options.filters.transactionDate.to.toString(),
        }
      : undefined,
  cardType: options.filters.cardTypes.length
    ? filterValues(options.filters.cardTypes)
    : undefined,
  cardLast4: options.filters.cardLastFour || undefined,
  nameOnCard: options.filters.nameOnCard || undefined,
  reference: options.filters.merchantExternalReference || undefined,
  authorizationCode: options.filters.authorizationCode || undefined,
  stan: options.filters.stan || undefined,
  merchantIds:
    options.filters.merchants.length > 0
      ? filterValues(options.filters.merchants)
      : undefined,
  transactionType:
    options.filters.transactionTypes.length > 0
      ? (filterValues(options.filters.transactionTypes) as GPCTransactionType[])
      : undefined,
  ccvResult:
    options.filters.ccvResult.length > 0
      ? (filterValues(options.filters.ccvResult) as CcvResultCode[])
      : undefined,
  avsResponse:
    options.filters.avsResponse.length > 0
      ? filterValues(options.filters.avsResponse)
      : undefined,
});

const downloadItems = (options: LoadItemsOptions) => {
  return authorizationsApi.postAuthorizationsCsv({
    listAuthorizationsPageRequest: {
      ...listRequestPayload(options),
    },
  });
};

const filterValues = (items: ItemFilter[]) => items.map((item) => item.value);

const loadItems = (options: LoadItemsOptions) => {
  return authorizationsApi
    .postAuthorizationsList({
      listAuthorizationsPageRequest: {
        offset: options.offset || 0,
        limit: options.limit,
        ...listRequestPayload(options),
      },
    })
};

const normalizeAuthorization = (
  authorization: Authorization
): AuthorizationItem => ({
  ...authorization,
  id: authorization.id || '',
  localDate: authorization.localTime?.toString() || '',
  transmissionDate: authorization.transmissionTime?.toString() || '',
  stan: authorization.stan || '',
  merchantExternalReference: authorization.reference || '',
  terminalId: authorization.terminalId || '',
  merchantId: authorization.merchantId || '',
  merchantName: authorization.merchantName || '',
  posEntryMode: authorization.posEntryMode || '',
  transactionAmount: authorization.amount || 0,
  transactionCurrency: authorization.currencyCode || '',
  cardType: authorization.cardBrand?.toString() as CardType,
  responseCode: authorization.responseCode || '',
  authId: authorization.authorizationId || '',
  transactionType: authorization.transactionType as TransactionType,
  cardNumber: authorization.cardNumber || '', // @TODO ping BE to add this field,
});

export const Container = () => {
  const { t } = useTranslation();

  const [state, dispatchAction] = useReducer(reducer, initialState);
  const [error, setError] = useState<Response | null>(null);

  const storeDispatch = useDispatch();

  const routeMatch = useRouteMatch();

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info({ ...action, context: 'AUTHORIZATIONS/FRONT' });
    }

    dispatchAction(action);
  }, []);

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('transactionsManagement.breadcrumb'),
          route: routeMatch.url,
        },
        {
          label: t('transactionsManagement.authorizations.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  const rowsPerPageRef = useRef(state.rowsPerPage);

  const fetchedItemsRef = useRef(state.items.length);

  useEffect(() => {
    rowsPerPageRef.current = state.rowsPerPage;

    fetchedItemsRef.current = state.items.length;
  }, [state.rowsPerPage, state.items.length]);

  useEffect(() => {
    let canceled = false;

    dispatch({
      type: 'INCREMENT_ITEMS_LOADING',
    });

    dispatch({
      type: 'SET_PAGE',
      page: 1,
    });

    dispatch({
      type: 'SET_END_REACHED',
      endReached: false,
    });

    const requestPayload = {
      limit: rowsPerPageRef.current,
      offset: 0,
      filters: {
        ...state.appliedFilters,
      },
    };

    loadItems(requestPayload)
      .then((response) => {
        if (!canceled) {
          const data = (response.data || []).map(normalizeAuthorization);

          dispatch({ type: 'SET_ITEMS', items: data });

          if (data.length === 0) {
            dispatch({
              type: 'SET_END_REACHED',
              endReached: true,
            });
          }
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        if (!canceled) {
          dispatch({
            type: 'DECREMENT_ITEMS_LOADING',
          });
        }
      });

    return () => {
      dispatch({
        type: 'DECREMENT_ITEMS_LOADING',
      });

      canceled = true;
    };
  }, [dispatch, state.appliedFilters]);

  const appliedFiltersRef = useRef(state.appliedFilters);

  useEffect(() => {
    appliedFiltersRef.current = state.appliedFilters;
  }, [state.appliedFilters]);

  useEffect(() => {
    let canceled = false;

    if (state.page === 1) {
      return;
    }

    const setPaginationLoading = (loading: boolean) =>
      dispatch({
        type: 'SET_PAGINATION_LOADING',
        loading,
      });

    dispatch({
      type: 'SET_END_REACHED',
      endReached: false,
    });

    setPaginationLoading(true);

    loadItems({
      page: state.page,
      limit: rowsPerPageRef.current,
      offset: fetchedItemsRef.current,
      filters: {
        ...appliedFiltersRef.current,
      },
    })
      .then((response) => {
        if (!canceled) {
          const data = (response.data || []).map(normalizeAuthorization);

          dispatch({ type: 'APPEND_ITEMS', items: data });

          if (data.length === 0) {
            dispatch({
              type: 'SET_END_REACHED',
              endReached: true,
            });
          }
        }
      })
      .finally(() => {
        if (!canceled) {
          setPaginationLoading(false);
        }
      });

    return () => {
      canceled = true;
    };
  }, [state.page, dispatch]);

  const itemsLoadingComputed = useMemo(() => state.itemsLoading > 0, [
    state.itemsLoading,
  ]);

  const download = useCallback(async () => {
    dispatch({ type: 'SET_DOWNLOAD_LOADING', loading: true });

    downloadItems({
      limit: 1000,
      filters: state.filters,
    })
      .then(async (csv: any) => {
        // for some reason, the original type doesn't match the real prototype. @TODO
        const downloadLink = document.createElement('a');

        const data = ((await csv.text()) as string).replace(/"/g, '');

        downloadLink.href = 'data:text/csv;charset=utf-8,' + atob(data);

        downloadLink.target = '_blank';

        downloadLink.download = 'authorizations.csv';

        downloadLink.click();

        setTimeout(() => downloadLink.remove());
      })
      .finally(() =>
        dispatch({ type: 'SET_DOWNLOAD_LOADING', loading: false })
      );
  }, [dispatch, state.filters]);

  return (
    <ListContext.Provider
      value={{
        ...state,
        itemsLoadingComputed,
        download,
        dispatch,
      }}
    >
      <AuthorizationsList error={error} />
    </ListContext.Provider>
  );
};
