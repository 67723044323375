import React, { ChangeEventHandler } from 'react';
import classNames from 'classnames';

const IconUnchecked = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="13"
      height="13"
      rx="1.5"
      fill="white"
      stroke="currentColor"
    />
  </svg>
);

const IconChecked = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="13"
      height="13"
      rx="1.5"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M11 4L5.5 9.5L3 7"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Checkbox = ({
  disabled = false,
  checked,
  onChange,
  color= 'text-blue-500',
}: {
  disabled?: boolean;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  color?: string;
}) => (
  <span
    className={classNames('relative inline-block w-4 h-4', {
      'opacity-50': disabled,
    })}
  >
    {checked ? (
      <div className={color}>
        <IconChecked />
      </div>
    ) : (
      <div className="text-gray-300">
        <IconUnchecked />
      </div>
    )}
    <input
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      type="checkbox"
      className={classNames('absolute top-0 w-full h-full opacity-0', {
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
      })}
    />
  </span>
);
