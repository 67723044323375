import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Transactions } from './transactions/Transactions';
import { Authorizations } from './authorizations/Authorizations';
import { Adjustments } from './adjustments/Adjustments';

export const TransactionManagement = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/authorizations`}>
        <Authorizations />
      </Route>
      <Route path={`${match.path}/transactions`}>
        <Transactions />
      </Route>
      <Route path={`${match.path}/adjustments`}>
        <Adjustments />
      </Route>
    </Switch>
  );
};
