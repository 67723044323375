/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateCompanyRequest,
    CreateCompanyRequestFromJSON,
    CreateCompanyRequestToJSON,
    CreateCompanyResponse,
    CreateCompanyResponseFromJSON,
    CreateCompanyResponseToJSON,
    GetCompanyRequest,
    GetCompanyRequestFromJSON,
    GetCompanyRequestToJSON,
    GetCompanyResponse,
    GetCompanyResponseFromJSON,
    GetCompanyResponseToJSON,
    ListCompaniesRequest,
    ListCompaniesRequestFromJSON,
    ListCompaniesRequestToJSON,
    ListCompaniesResponse,
    ListCompaniesResponseFromJSON,
    ListCompaniesResponseToJSON,
} from '../models';

export interface PostCompaniesCreateRequest {
    createCompanyRequest: CreateCompanyRequest;
}

export interface PostCompaniesGetRequest {
    getCompanyRequest: GetCompanyRequest;
}

export interface PostCompaniesListRequest {
    listCompaniesRequest?: ListCompaniesRequest;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     */
    async postCompaniesCreateRaw(requestParameters: PostCompaniesCreateRequest): Promise<runtime.ApiResponse<CreateCompanyResponse>> {
        if (requestParameters.createCompanyRequest === null || requestParameters.createCompanyRequest === undefined) {
            throw new runtime.RequiredError('createCompanyRequest','Required parameter requestParameters.createCompanyRequest was null or undefined when calling postCompaniesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/companies/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyRequestToJSON(requestParameters.createCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCompanyResponseFromJSON(jsonValue));
    }

    /**
     */
    async postCompaniesCreate(requestParameters: PostCompaniesCreateRequest): Promise<CreateCompanyResponse> {
        const response = await this.postCompaniesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postCompaniesGetRaw(requestParameters: PostCompaniesGetRequest): Promise<runtime.ApiResponse<GetCompanyResponse>> {
        if (requestParameters.getCompanyRequest === null || requestParameters.getCompanyRequest === undefined) {
            throw new runtime.RequiredError('getCompanyRequest','Required parameter requestParameters.getCompanyRequest was null or undefined when calling postCompaniesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/companies/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetCompanyRequestToJSON(requestParameters.getCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyResponseFromJSON(jsonValue));
    }

    /**
     */
    async postCompaniesGet(requestParameters: PostCompaniesGetRequest): Promise<GetCompanyResponse> {
        const response = await this.postCompaniesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postCompaniesListRaw(requestParameters: PostCompaniesListRequest): Promise<runtime.ApiResponse<ListCompaniesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/companies/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListCompaniesRequestToJSON(requestParameters.listCompaniesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCompaniesResponseFromJSON(jsonValue));
    }

    /**
     */
    async postCompaniesList(requestParameters: PostCompaniesListRequest): Promise<ListCompaniesResponse> {
        const response = await this.postCompaniesListRaw(requestParameters);
        return await response.value();
    }

}
