import React, { PropsWithoutRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';

import { TotalBalance } from './components/total-balance/TotalBalance';
import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/arrow-left.svg';
import { CreditCard } from './components/credit-card/CreditCard';
import { Loading } from './components/loading/Loading';
import { Status } from './components/status/Status';
import { Card } from 'src/components/Card';
import { statusBadge } from './card-list/CardsTable';

import { cardsApi } from 'src/api';
import { StatusBadge } from 'src/components/StatusBadge';

export interface CardDetailsProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {}

export const CardDetails = React.forwardRef<HTMLDivElement, CardDetailsProps>(
  ({ children, ...props }, ref) => {
    const params: { id?: string } = useParams();
    const { t } = useTranslation();

    const { data, isLoading, error } = useQuery(
      `getCard-${params.id}`,
      () =>
        cardsApi.postGetCard({
          getCardRequest: {
            cardId: params.id!,
          },
        }),
      { retry: 1 }
    );

    return (
      <div ref={ref} {...props}>
        <div className="w-full">
          <div className="flex items-center justify-between w-full h-16 bg-white border-b">
            <div className="px-5 w-full">
              <div className="mb-0 text-lg font-bold leading-none text-black">
                <div className="flex justify-between">
                  {t('headings.cardDetails')}

                  <NavLink
                    to={'/cards/list'}
                    tabIndex={0}
                    className="flex items-center text-sm font-medium text-blue-500 capitalize transition-colors duration-200 ease-in-out outline-none font-din hover:text-orange-500"
                  >
                    <ArrowLeftIcon className="mr-3" />
                    {t('cardList.backToCardList')}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2">
          <Card>
            {error?.status === 403 ? (
              <div className="px-5 py-3 text-sm text-gray-900 bg-red-100 rounded-sm">
                {t('permissions.permissionDenied')}
              </div>
            ) : (
              <div className="flex flex-col w-full h-full">
                {isLoading && <Loading />}

                {!isLoading && (
                  <div>
                    <div className="border-b border-gray-300 py-3 mb-5">
                      <div className="w-full flex items-center">
                        <div
                          className="mr-8 text-base font-bold leading-none text-gray-900"
                          style={{ width: '19rem' }}
                        >
                          {t(`headings.${data?.card?.type}`)}
                        </div>

                        {data?.card?.programName && (
                          <div className="w-1/6">
                            <div className="text-gray-700 text-xs font-medium">
                              {t('headings.program')}
                            </div>
                            <div className="text-gray-900 text-xs font-semibold">
                              {data?.card?.programName}
                            </div>
                          </div>
                        )}

                        {/*<div className="w-1/6">
                        <div className="text-gray-700 text-xs font-medium">
                          {t('headings.balance')}
                        </div>
                        <div className="text-gray-900 text-xs font-semibold">
                          <TotalBalance
                            cardBalance
                            simple
                            balance={+data?.card?.availableBalance || 0}
                          />
                        </div>
                      </div>*/}

                        <div className="w-1/6">
                          <div className="text-gray-700 text-xs font-medium">
                            {t('headings.status')}
                          </div>
                          <div className="text-gray-900 text-xs font-semibold">
                            <StatusBadge
                              variant={statusBadge(data?.card?.status)}
                            >
                              {t(
                                `cards.table.status.${data?.card.status?.toLowerCase()}`
                              )}
                            </StatusBadge>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <CreditCard
                        {...{
                          cardholder: data?.card?.cardholderName,
                          pan: data?.card?.lastFourDigits,
                          issuer: 'SBM',
                          // balance: +data?.card?.availableBalance || 0,
                          type: data?.card?.type,
                        }}
                      />

                      <div className="ml-8 w-1/2">
                        <div>
                          <div className="flex items-start mb-2">
                            <div className="w-1/3">
                              <div className="mt-6">
                                <div className="text-gray-700 text-xs font-medium">
                                  {t('cardDetails.cardNumber')}
                                </div>
                                <div className="text-gray-900 text-xs font-semibold">
                                  •••• {data?.card?.lastFourDigits}
                                </div>
                              </div>
                              <div className="mt-6">
                                <div className="text-gray-700 text-xs font-medium">
                                  {t('cardDetails.cardholder')}
                                </div>
                                <div className="text-gray-900 text-xs font-semibold">
                                  {data?.card?.cardholderName}
                                </div>
                              </div>
                              <div className="mt-6">
                                <div className="text-gray-700 text-xs font-medium">
                                  {t('cardDetails.currency')}
                                </div>
                                <div className="text-gray-900 text-xs font-semibold">
                                  USD
                                </div>
                              </div>
                            </div>
                            <div>
                              {data.card.expiryDate && <div className="mt-6">
                                <div className="text-gray-700 text-xs font-medium">
                                  {t('cardDetails.expiryDate')}
                                </div>
                                <div className="text-gray-900 text-xs font-semibold">
                                  {moment(data.card.expiryDate).format('MM/YY')}
                                </div>
                              </div>}
                              <div className="mt-6">
                                <div className="text-gray-700 text-xs font-medium">
                                  {t('cardDetails.dateAdded')}
                                </div>
                                <div className="text-gray-900 text-xs font-semibold">
                                  {moment(data?.card?.createdAt).utc().format(
                                    'MMM DD, YYYY, HH:mm'
                                  )}
                                </div>
                              </div>
                              <div className="mt-6">
                                <div className="text-gray-700 text-xs font-medium">
                                  {t('cardDetails.proxyNumber')}
                                </div>
                                <div className="text-gray-900 text-xs font-semibold">
                                  {data?.card?.proxyNumber}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  }
);

export default CardDetails;
