import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Comment } from './context';
import { Cell } from './Details';

export const FormComment = ({
  onSubmit,
  comment,
}: {
  onSubmit: () => void;
  comment?: Comment | null;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup.object().shape({
      comment: yup.string().required(t('validations.required')),
    });
  }, [t]);

  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: comment?.comment || '',
    },
  });

  const onFormSubmit = async (data: yup.InferType<typeof schema>) => {
    onSubmit();
  };

  if (comment) {
    return (
      <div className="mt-6">
        <div className="grid grid-cols-2 border divide-x">
          <Cell
            label={t(
              'disputeResolution.chargebacks.details.comments.createdAt'
            )}
          >
            {comment.createdAt}
          </Cell>
          <Cell
            label={t(
              'disputeResolution.chargebacks.details.comments.createdBy'
            )}
          >
            {comment.createdBy}
          </Cell>
        </div>
        <div className="mt-4 border">
          <p className="p-3 text-xs font-medium text-gray-700 bg-gray-100 border-b">
            {t('disputeResolution.chargebacks.details.comments.comment')}:
          </p>
          <p
            className="p-3 overflow-auto text-sm"
            style={{
              maxHeight: '22.5rem',
            }}
          >
            {comment.comment}
          </p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="w-full">
      <div className="mb-8">
        <Input
          label={t('disputeResolution.chargebacks.details.comments.comment')}
          name="comment"
          type="textarea"
          error={errors.comment}
          inputRef={register()}
          className="h-64"
        />
      </div>
      {!comment && (
        <Button
          loading={formState.isSubmitting}
          disabled={formState.isSubmitting}
          type="submit"
          size="large"
          widthClass="w-full"
        >
          {t('disputeResolution.chargebacks.details.comments.addComment')}
        </Button>
      )}
    </form>
  );
};
