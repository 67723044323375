import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import sortBy from 'lodash/sortBy';
import { LoadingSwitch } from 'src/components/Loading';
import { Card } from 'src/components/Card';
import { PageSize } from 'src/components/list/PageSize';
import { Button } from 'src/components/Button';
import { IconPlus } from 'src/components/icons/IconPlus';
import {
  RowsPerPage,
  RulePermission,
} from 'src/api/services/access-management/roles-and-permissions/getRolesAndPermissions';
import { Sorting, Table } from 'src/components/table/Table';
import {
  RolesContext,
  rolesFormType,
} from 'src/domain/access-management/roles-and-permissions/tabs/rules/RolesContext';
import {
  iconMap,
  useRolesColumns,
} from 'src/domain/access-management/roles-and-permissions/tabs/rules/useRolesColumns';
import { nameof } from 'src/utils/nameof';
import { AddRoleModal } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/AddRoleModal';
import { DeleteRoleModal } from './DeleteRoleModal';

export type RowsPerPageOptionType = {
  value: RowsPerPage;
  label: RowsPerPage;
};

const ROW_PER_PAGE_OPTIONS: RowsPerPageOptionType[] = [
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export interface RulesListParams {
  initLoading: boolean;
  loading: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  totalItems?: number;
  hasNextPage?: boolean;
  items: Array<RulePermission>;
  listError: any;
}

export const RolesList = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { dispatch, columnSorting, listParams } = useContext(RolesContext);
  const [visibleAddRuleModal, setVisibleAddRuleModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [roleToEdit, setRoleToEdit] = useState<RulePermission | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const onDelete = (id: string) => {
    setIdToDelete(id);
  };
  const onEdit = (item: RulePermission) => {
    setRoleToEdit(item);
    setVisibleAddRuleModal(true);
  };

  const columns = useRolesColumns({ onDelete, onEdit });

  const {
    loading,
    items,
    refetch,
    listError,
  } = listParams;

  const [sortedItems, setSortedItems] = useState<null | []>(null);

  const handleRowClick = useCallback(
    (item: any) => {
      history.push(`${match.path}/${item.id}`);
    },
    [history, match.path]
  );

  const handleColumnSorting = useCallback(
    (sorting?: Sorting) => {
      // @ts-ignore
      const sorted = sortBy(items, [(o) => o[sorting?.column?.id] ? o[sorting?.column?.id].toLowerCase() : null]);
      // @ts-ignore
      setSortedItems(sorting?.direction === 'ASC' ? sorted : sorted.reverse());

      if (loading) {
        return;
      }

      dispatch({
        type: 'SET_COLUMN_SORTING',
        columnSorting: sorting,
      });
    },
    [dispatch, loading, items]
  );

  const handleCloseAddRoleModal = useCallback(() => {
    setVisibleAddRuleModal(false);
    setRoleToEdit(null);
  }, []);

  const handleOpenAddRoleModal = useCallback(() => {
    setVisibleAddRuleModal(true);
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [loading]);

  return (
    <div className="relative flex flex-col bg-gray-200">
      <Card className="h-full">
        {listError?.status === 403 ? (
          <div className="py-3 px-5 bg-red-100 text-sm text-gray-900 rounded-sm">
            {t('permissions.permissionDenied')}
          </div>
        ) : (
          <div className="flex flex-col w-full h-full">
            <div className="flex flex-col md:mb-6 lg:flex-row lg:items-center lg:justify-between">
              <div className={'items-center hidden md:flex font-bold'}>
                {t('accessManagement.rolesAndPermissions.roles')}&nbsp;
                <LoadingSwitch loading={loading}>
                  {!items?.length && <span className={'text-blue-500'}>0</span>}
                </LoadingSwitch>
              </div>
              <div className="flex flex-col mb-5 lg:flex-row lg:items-center md:mb-0">
                {!loading && (
                  <Button
                    loading={false} //initLoading
                    onClick={handleOpenAddRoleModal}
                    widthClass="w-full"
                    className="pr-4"
                    prefix={<IconPlus />}
                  >
                    {t('buttons.addNew')}
                  </Button>
                )}
                {visibleAddRuleModal && (
                  <AddRoleModal
                    visible={visibleAddRuleModal}
                    closeModal={handleCloseAddRoleModal}
                    roleId={roleToEdit?.id}
                    defaultValues={
                      roleToEdit
                        ? {
                            name: roleToEdit?.roleName,
                            description: roleToEdit?.roleDescription,
                            channel: {
                              value: roleToEdit?.accessChannelId,
                              label: (
                                <div className="flex items-center">
                                  {iconMap(roleToEdit?.channelName!)}{' '}
                                  {roleToEdit?.channelName}
                                </div>
                              ),
                            },
                          }
                        : undefined
                    }
                    onSubmit={() => {
                      refetch();
                      setVisibleAddRuleModal(false);
                      setSortedItems(null);
                    }}
                  />
                )}
              </div>
            </div>

            <div
              ref={tableRef}
              className="h-full max-h-full -mx-6"
            >
              <Table
                loading={loading}
                columns={columns}
                items={sortedItems || items}
                sorting={columnSorting}
                isHeaderFixed
                onSort={handleColumnSorting}
                onRowClick={handleRowClick}
              />
            </div>
            <div className="flex items-center justify-between p-5 py-3 mt-auto -mx-6 -mt-px -mb-5 bg-white border-t border-gray-300">
              <div className="items-center hidden text-xs text-gray-700 md:flex">
                {t('list.pagination.results')}:
                <span className="ml-1">
                  <LoadingSwitch loading={loading}>
                    <span className="font-medium">{items?.length}</span>
                    {/*&nbsp;of&nbsp;*/}
                    {/*<span className="font-medium">{totalItems}</span>*/}
                  </LoadingSwitch>
                </span>
              </div>
              {/*<LoadMoreButton
                loading={loading}
                onClick={fetchNextPage}
                disabled={!hasNextPage}
              />*/}
              <PageSize
                control={control}
                options={ROW_PER_PAGE_OPTIONS}
                name={nameof(rolesFormType.limit)}
              />
            </div>
          </div>
        )}
      </Card>

      {idToDelete && (
        <DeleteRoleModal
          id={idToDelete}
          onSubmit={() => {
            refetch();
          }}
          visible={!!idToDelete}
          closeModal={() => {
            setIdToDelete(null);
          }}
        />
      )}
    </div>
  );
};
