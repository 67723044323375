import React from 'react';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const LoadMoreButton = ({ loading, onClick, disabled }: Props) => {
  const { t } = useTranslation();
  return (
    <Button
      disabled={disabled}
      loading={loading}
      variant="link"
      onClick={onClick}
      className="mx-auto md:mx-0"
    >
      {t('buttons.loadMore')}
    </Button>
  );
};
