import React, { useCallback, useEffect, useState } from 'react';
import { IconLogo } from 'src/components/icons/IconLogo';
import { Button } from 'src/components/Button';
import { Link, useHistory } from 'react-router-dom';
import { useCurrentUser } from 'src/auth';

export const Container = () => {
  const { isAuthenticated, logout: logoutFn } = useCurrentUser();
  const history = useHistory();

  const logout = useCallback(() => {
    logoutFn();
  }, [logoutFn]);

  const [logoutPerformed, setLogoutPerformed] = useState(false);

  useEffect(() => {
    if (logoutPerformed) {
      return;
    }

    if (isAuthenticated) {
      logout();

      setLogoutPerformed(true);
    } else {
      history.push('/login');
    }
  }, [history, logout, isAuthenticated, logoutPerformed, setLogoutPerformed]);

  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      <div className="flex flex-col items-center justify-center w-full max-w-md">

        <div className="flex flex-col items-center justify-center text-center">
          <IconLogo className="mb-5" />

          <h3 className="mb-3 text-2xl font-semibold text-gray-900">
            You Don’t Have Access
          </h3>

          <p className="mb-6 text-base font-semibold text-gray-700">
            Your account has either been suspended or deleted
          </p>

          <div className='flex justify-center'>
            <a
              href="mailto:support@agilepartners.eu"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs font-semibold text-blue-500 hover:text-blue-500"
            >
              Contact Support
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
