import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UsersContextProvider } from './UsersContext';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { UsersTabList } from './UsersTabList';
import { UserDetails } from './user-details/UserDetails';

const UsersTab = () => {
  const { t } = useTranslation();
  const storeDispatch = useDispatch();
  const routeMatch = useRouteMatch();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('accessManagement.breadcrumb'),
          route: routeMatch.url,
        },
        {
          label: t('accessManagement.userManagement.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  return <UsersTabList />;
};

export const UsersPage = () => {
  const routeMatch = useRouteMatch();

  return (
    <UsersContextProvider>
      <Route path={routeMatch.path + '/:id'}>
        <UserDetails />
      </Route>
      <Route path={routeMatch.path} exact>
        <UsersTab />
      </Route>
    </UsersContextProvider>
  );
};
