import React, { useContext, useEffect } from 'react';
import { Input, onKeyPressAllowPattern } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm } from 'react-hook-form';

export const FilterCaseId = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { register, watch, setValue } = useForm<{
    caseId: string;
  }>();

  const { caseId } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_CASE_ID_FILTER',
      caseId,
    });
  }, [caseId, dispatch]);

  useEffect(() => {
    setValue('caseId', filters.caseId);
  }, [filters.caseId, setValue]);

  return (
    <Input
      disabled
      label={t('disputeResolution.chargebacks.filters.caseId')}
      name="caseId"
      labelFontClassName="text-xxs font-medium"
      labelColorClassName="text-gray-700"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressAllowPattern(/[a-zA-Z0-9-_]/g)}
      inputRef={register}
      maxLength={20}
    />
  );
};
