/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetChargebackDetailsResponse,
    GetChargebackDetailsResponseFromJSON,
    GetChargebackDetailsResponseToJSON,
    IdWrapper,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    ListChargebacksRequest,
    ListChargebacksRequestFromJSON,
    ListChargebacksRequestToJSON,
    ListChargebacksResponse,
    ListChargebacksResponseFromJSON,
    ListChargebacksResponseToJSON,
} from '../models';

export interface PostChargebacksGetRequest {
    idWrapper?: IdWrapper;
}

export interface PostChargebacksListRequest {
    listChargebacksRequest?: ListChargebacksRequest;
}

/**
 * 
 */
export class ChargebacksApi extends runtime.BaseAPI {

    /**
     */
    async postChargebacksGetRaw(requestParameters: PostChargebacksGetRequest): Promise<runtime.ApiResponse<GetChargebackDetailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/chargebacks/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdWrapperToJSON(requestParameters.idWrapper),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChargebackDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postChargebacksGet(requestParameters: PostChargebacksGetRequest): Promise<GetChargebackDetailsResponse> {
        const response = await this.postChargebacksGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postChargebacksListRaw(requestParameters: PostChargebacksListRequest): Promise<runtime.ApiResponse<ListChargebacksResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/chargebacks/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListChargebacksRequestToJSON(requestParameters.listChargebacksRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListChargebacksResponseFromJSON(jsonValue));
    }

    /**
     */
    async postChargebacksList(requestParameters: PostChargebacksListRequest): Promise<ListChargebacksResponse> {
        const response = await this.postChargebacksListRaw(requestParameters);
        return await response.value();
    }

}
