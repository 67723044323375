import React, {
  useReducer,
  useCallback,
} from 'react';
import { PayeesList } from './PayeesList';
import {
  reducer,
  initialState,
  Actions,
  FrontContext,
} from './context';

export const Container = () => {
  const [state, dispatchAction] = useReducer(reducer, initialState);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info({ ...action, context: 'KYC/FRONT' });
    }

    dispatchAction(action);
  }, []);

  return (
    <FrontContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <PayeesList />
    </FrontContext.Provider>
  );
};
