/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateProgramRequest,
    CreateProgramRequestFromJSON,
    CreateProgramRequestToJSON,
    CreateProgramResponse,
    CreateProgramResponseFromJSON,
    CreateProgramResponseToJSON,
    GetProgramRequest,
    GetProgramRequestFromJSON,
    GetProgramRequestToJSON,
    GetProgramResponse,
    GetProgramResponseFromJSON,
    GetProgramResponseToJSON,
    ListProgramsRequest,
    ListProgramsRequestFromJSON,
    ListProgramsRequestToJSON,
    ListProgramsResponse,
    ListProgramsResponseFromJSON,
    ListProgramsResponseToJSON,
} from '../models';

export interface PostProgramsCreateRequest {
    createProgramRequest: CreateProgramRequest;
}

export interface PostProgramsGetRequest {
    getProgramRequest: GetProgramRequest;
}

export interface PostProgramsListRequest {
    listProgramsRequest?: ListProgramsRequest;
}

/**
 * 
 */
export class ProgramsApi extends runtime.BaseAPI {

    /**
     */
    async postProgramsCreateRaw(requestParameters: PostProgramsCreateRequest): Promise<runtime.ApiResponse<CreateProgramResponse>> {
        if (requestParameters.createProgramRequest === null || requestParameters.createProgramRequest === undefined) {
            throw new runtime.RequiredError('createProgramRequest','Required parameter requestParameters.createProgramRequest was null or undefined when calling postProgramsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/programs/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProgramRequestToJSON(requestParameters.createProgramRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProgramResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProgramsCreate(requestParameters: PostProgramsCreateRequest): Promise<CreateProgramResponse> {
        const response = await this.postProgramsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProgramsGetRaw(requestParameters: PostProgramsGetRequest): Promise<runtime.ApiResponse<GetProgramResponse>> {
        if (requestParameters.getProgramRequest === null || requestParameters.getProgramRequest === undefined) {
            throw new runtime.RequiredError('getProgramRequest','Required parameter requestParameters.getProgramRequest was null or undefined when calling postProgramsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/programs/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProgramRequestToJSON(requestParameters.getProgramRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProgramResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProgramsGet(requestParameters: PostProgramsGetRequest): Promise<GetProgramResponse> {
        const response = await this.postProgramsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postProgramsListRaw(requestParameters: PostProgramsListRequest): Promise<runtime.ApiResponse<ListProgramsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/programs/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListProgramsRequestToJSON(requestParameters.listProgramsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProgramsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProgramsList(requestParameters: PostProgramsListRequest): Promise<ListProgramsResponse> {
        const response = await this.postProgramsListRaw(requestParameters);
        return await response.value();
    }

}
