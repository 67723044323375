import React, { ReactNode, useCallback, useEffect, useReducer } from 'react';
import { getToken, getUser, getRoles } from './auth';

export type User = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  preferredMFA: string;
};

export const initialState = {
  user: null as User | null,
  token: null as string | null,
  roles: [] as string[],
  loading: true,
  isAuthenticated: false,
  dispatch: (action: Actions) => {},
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.user,
        token: action.token,
        roles: action.roles,
      };
    case 'LOGOUT':
      return { ...state, user: null, token: null, roles: [] };
    case 'MARK_USER_AS_LOADING':
      return { ...state, loading: true };
    case 'MARK_AS_LOADED':
      return { ...state, loading: false };
    case 'SET_MOCK_DATA':
      return { ...state, ...action.state };
    default:
      throw new Error();
  }
};

export type Actions =
  | { type: 'LOGIN'; user: User; token: string; roles: string[] }
  | { type: 'SET_MOCK_DATA'; state: typeof initialState }
  | { type: 'LOGOUT' }
  | { type: 'MARK_USER_AS_LOADING' }
  | { type: 'MARK_AS_LOADED' };

export const AuthContext = React.createContext<typeof initialState>(
  initialState
);

export type MockData = Partial<typeof initialState> | undefined;

export const AuthProvider = ({
  children,
  mockData,
  onAuth,
  onLogout,
}: {
  children: ReactNode;
  mockData?: MockData;
  onAuth?: (auth: { user: User; token: string }) => void;
  onLogout?: () => void;
}) => {
  const [state, dispatchAction] = useReducer(
    reducer,
    mockData ? { ...initialState, ...mockData } : initialState
  );

  const dispatch = useCallback(
    (action: Actions) => {
      switch (action.type) {
        case 'LOGIN': {
          if (onAuth && action.user && action.token) {
            onAuth({ user: action.user, token: action.token });
          }

          break;
        }
        case 'LOGOUT': {
          if (onLogout) {
            onLogout();
          }

          break;
        }
      }

      return dispatchAction(action);
    },
    [dispatchAction, onAuth, onLogout]
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const user = await getUser();

        const token = await getToken();

        const roles = await getRoles();

        dispatch({
          type: 'LOGIN',
          user,
          token,
          roles,
        });
      } catch (e) {
      } finally {
        dispatch({
          type: 'MARK_AS_LOADED',
        });
      }
    };

    loadData();
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{ ...state, isAuthenticated: !!state.user && state.user.preferredMFA !== 'NOMFA', dispatch }}
      // value={{ ...state, isAuthenticated: !!state.user, dispatch }}
    >
      {!state.loading && children}
    </AuthContext.Provider>
  );
};
