/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyAssignment,
    CompanyAssignmentFromJSON,
    CompanyAssignmentFromJSONTyped,
    CompanyAssignmentToJSON,
    IamUserStatus,
    IamUserStatusFromJSON,
    IamUserStatusFromJSONTyped,
    IamUserStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateProfileRequestIamUser
 */
export interface UpdateProfileRequestIamUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    securityProfileId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    orgUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    directSupervisor?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequestIamUser
     */
    notes?: string;
    /**
     * 
     * @type {Array<CompanyAssignment>}
     * @memberof UpdateProfileRequestIamUser
     */
    companyAssignments?: Array<CompanyAssignment>;
    /**
     * 
     * @type {IamUserStatus}
     * @memberof UpdateProfileRequestIamUser
     */
    status?: IamUserStatus;
}

export function UpdateProfileRequestIamUserFromJSON(json: any): UpdateProfileRequestIamUser {
    return UpdateProfileRequestIamUserFromJSONTyped(json, false);
}

export function UpdateProfileRequestIamUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileRequestIamUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'securityProfileId': !exists(json, 'security_profile_id') ? undefined : json['security_profile_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'orgUnit': !exists(json, 'org_unit') ? undefined : json['org_unit'],
        'directSupervisor': !exists(json, 'direct_supervisor') ? undefined : json['direct_supervisor'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'companyAssignments': !exists(json, 'company_assignments') ? undefined : ((json['company_assignments'] as Array<any>).map(CompanyAssignmentFromJSON)),
        'status': !exists(json, 'status') ? undefined : IamUserStatusFromJSON(json['status']),
    };
}

export function UpdateProfileRequestIamUserToJSON(value?: UpdateProfileRequestIamUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'security_profile_id': value.securityProfileId,
        'title': value.title,
        'company': value.company,
        'org_unit': value.orgUnit,
        'direct_supervisor': value.directSupervisor,
        'notes': value.notes,
        'company_assignments': value.companyAssignments === undefined ? undefined : ((value.companyAssignments as Array<any>).map(CompanyAssignmentToJSON)),
        'status': IamUserStatusToJSON(value.status),
    };
}


