/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductCategoryRequest
 */
export interface GetProductCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof GetProductCategoryRequest
     */
    productCategoryId: string;
}

export function GetProductCategoryRequestFromJSON(json: any): GetProductCategoryRequest {
    return GetProductCategoryRequestFromJSONTyped(json, false);
}

export function GetProductCategoryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductCategoryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productCategoryId': json['product_category_id'],
    };
}

export function GetProductCategoryRequestToJSON(value?: GetProductCategoryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_category_id': value.productCategoryId,
    };
}


