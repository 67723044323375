import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
  useCallback,
} from 'react';
import {
  DropdownFilter,
  InputDropdown,
} from 'src/components/dropdown-filter/DropdownFilter';
import { useTranslation } from 'react-i18next';
import { ListContext, ListFilters } from '../context';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import moment, { Moment } from 'moment';
import { IconCalendar } from 'src/components/icons/IconCalendar';
import { IconChevronLeft } from 'src/components/icons/IconChevronLeft';
import { Button } from 'src/components/Button';
import { DateRange } from 'src/types/dateRange';

const DateFilterVariant = ({
  children,
  active,
  onClick,
}: {
  children: ReactNode;
  active: boolean;
  onClick: () => any;
}) => (
  <Button
    size="small"
    className={active ? '' : 'text-gray-700'}
    paddingClass="px-3"
    widthClass="w-auto"
    variant={active ? 'secondary' : 'text'}
    onClick={onClick}
  >
    {children}
  </Button>
);

const Footer = ({
  dateButtons,
  filter,
  onClick,
}: {
  dateButtons: DateButton[];
  filter: DateRange;
  onClick: (item: DateButton) => any;
}) => (
  <div className="flex justify-between leading-none text-gray-700">
    {dateButtons.map((item) => (
      <DateFilterVariant
        key={item.label}
        active={filter.from === item.start && filter.to === item.end}
        onClick={() => onClick(item)}
      >
        {item.label}
      </DateFilterVariant>
    ))}
  </div>
);

type DateButton = { start: Moment | null; end: Moment | null; label: string };

export const FilterGenericDate = ({
  filterKey,
  title,
  setFilterAction,
  resetFilterAction,
}: {
  filterKey: keyof Pick<ListFilters, 'transactionDate'>;
  title: string;
  setFilterAction: (data: { from: Moment; to: Moment }) => void;
  resetFilterAction: () => void;
}) => {
  const { filters } = useContext(ListContext);

  const filter = filters[filterKey];

  const [focusedInput, setInputFocus] = useState<FocusedInputShape>(
    'startDate'
  );

  const { t } = useTranslation();

  const [filterStart, setFilterStart] = useState<Moment | null>(null);

  const [filterEnd, setFilterEnd] = useState<Moment | null>(null);

  const startDayPeriod = useCallback((day: number) => {
    return moment()
      .subtract(day, 'days')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }, []);

  const endDayPeriod = useCallback(() => {
    return moment()
      .subtract(1, 'days')
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
  }, []);

  const dateButtons = useMemo(
    (): DateButton[] => [
      {
        start: moment().subtract(2, 'hours'),
        end: moment(),
        label: t('datePicker.lastTwoHours'),
      },
      {
        start: startDayPeriod(1),
        end: endDayPeriod(),
        label: t('datePicker.lastDay'),
      },
      {
        start: startDayPeriod(7),
        end: endDayPeriod(),
        label: t('datePicker.lastSevenDays'),
      },
      {
        start: startDayPeriod(30),
        end: endDayPeriod(),
        label: t('datePicker.lastThirtyDays'),
      },
      {
        start: null,
        end: null,
        label: t('kyc.filters.allTime'),
      },
    ],
    [t, startDayPeriod, endDayPeriod]
  );

  useEffect(() => {
    if (filterStart && filterEnd) {
      setFilterAction({ from: filterStart, to: filterEnd });
    }
  }, [filterStart, filterEnd, setFilterAction]);

  useEffect(() => {
    setFilterStart(filter?.from);
    setFilterEnd(filter?.to);
  }, [filter]);

  const dateFilterClicked = useCallback(
    (filterButton: DateButton) => {
      setFilterStart(filterButton.start);
      setFilterEnd(filterButton.end);

      if (
        !filterButton.start &&
        !filterButton.end &&
        filter.from &&
        filter.to
      ) {
        resetFilterAction();
      }
    },
    [setFilterStart, setFilterEnd, resetFilterAction, filter]
  );

  return (
    <DropdownFilter
      title={title}
      footer={
        <Footer
          dateButtons={dateButtons}
          filter={filter}
          onClick={dateFilterClicked}
        />
      }
      popoverWidth={480}
      trigger={(dropdownProps) => (
        <InputDropdown
          name={filterKey}
          label={title}
          placeholder={t(
            'transactionsManagement.transactions.list.filters.allTime'
          )}
          value={
            filter.from && filter.to
              ? filter.from.format('D MMM YYYY') +
                ' - ' +
                filter.to.format('D MMM YYYY')
              : ''
          }
          icon={
            <div className="px-2 -mr-3 bg-white">
              <IconCalendar
                className={
                  dropdownProps.popoverIsOpen
                    ? 'text-blue-500'
                    : 'text-gray-700'
                }
              />
            </div>
          }
          {...dropdownProps}
        />
      )}
    >
      <div className="relative overflow-hidden">
        <div className="pb-2 -mx-5">
          <DayPickerRangeController
            minimumNights={0}
            startDate={filterStart}
            endDate={filterEnd}
            transitionDuration={0}
            onDatesChange={({
              startDate,
              endDate,
            }: {
              startDate: Moment | null;
              endDate: Moment | null;
            }) => {
              let startDateTime = startDate;
              let endDateTime = endDate;
              const isSameDate = endDate && startDate?.isSame(endDate);

              if (startDate && endDate && isSameDate) {
                startDateTime = moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                });
                endDateTime = moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 0,
                });
              }

              setFilterStart(startDateTime?.startOf('day') || null);
              setFilterEnd(endDateTime?.endOf('day') || null);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) =>
              setInputFocus(focusedInput || 'startDate')
            }
            numberOfMonths={2}
            daySize={30}
            noBorder
            navPrev={
              <div className="absolute left-0 w-4 h-4 ml-5 text-gray-700">
                <IconChevronLeft />
              </div>
            }
            navNext={
              <div className="absolute right-0 w-4 h-4 mr-4 text-gray-700">
                <IconChevronLeft className="origin-center transform rotate-180" />
              </div>
            }
            hideKeyboardShortcutsPanel
          />
        </div>
      </div>
    </DropdownFilter>
  );
};
