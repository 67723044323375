import React, { FC } from 'react';
import classNames from 'classnames';

export const FormViewItem: FC<{
  label: string;
  className?: string;
}> = ({ label, className, children }) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      <span className={'text-gray-700 mb-1 text-sm'}>{label}</span>
      <span className={'flex flex-col font-semibold text-xs'}>{children}</span>
    </div>
  );
};
