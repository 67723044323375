import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from '../store';
import { ACCESS_TOKEN_HEADER_KEY } from 'src/constants/accessTokenHeaderKey';

export type Profile = { firstName: string; lastName: string; id: string };

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    profile: null,
  } as {
    profile: Profile | null;
  },
  reducers: {
    login(
      state,
      action: { payload: { profile: Profile; accessToken: string } }
    ) {
      state.profile = action.payload.profile;

      Cookies.set(ACCESS_TOKEN_HEADER_KEY, action.payload.accessToken, {
        secure: true,
        domain: '.' + window.location.hostname,
      });
    },
    setProfile(state, action: { payload: Profile }) {
      state.profile = action.payload;
    },
    logout(state) {
      state.profile = null;

      Cookies.remove(ACCESS_TOKEN_HEADER_KEY);
    },
  },
});

export const profileSelector = (state: RootState): Profile | null =>
  state.account.profile;

export const isAuthenticatedSelector = (state: RootState): boolean =>
  state.account.profile !== null;
