import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { IconChevronLeft } from 'src/components/icons/IconChevronLeft';
import { FormResetPassword } from './FormResetPassword';
import { IconLogo } from 'src/components/icons/IconLogo';
import { Modal } from 'src/components/Modal';

import { ReactComponent as IconSuccess } from 'src/assets/icons/iconSuccess.svg';

const SubmittedIcon = () => (
  <svg width="70" height="94" viewBox="0 0 70 94" fill="none">
    <path
      opacity="0.4"
      d="M49.4247 52.7758C53.1 52.7758 56.7339 53.6951 59.934 55.4341C60.5152 55.7474 61.0485 56.1207 61.593 56.48C66.845 46.1989 69.6167 35.3254 69.6167 24.7176C69.6167 24.1215 69.6167 23.5061 69.5975 22.8908C69.559 22.0254 68.9436 21.2754 68.0782 21.0831C56.4629 18.5446 45.9436 12.2561 37.6744 2.90999L36.4436 1.50607C36.0782 1.08305 35.5591 0.852288 35.0013 0.852288C34.4051 0.775407 33.9436 1.0638 33.5782 1.46769L31.6936 3.54464C23.8475 12.1792 13.9628 18.1407 3.11676 20.7946L1.86671 21.1022C1.02055 21.2946 0.424401 22.0446 0.405151 22.9099C-0.287129 49.9484 15.6744 69.3908 22.559 76.5061C24.4231 78.4199 26.5012 80.322 28.4772 81.8943C27.736 79.6588 27.3093 77.3064 27.3093 74.8912C27.3093 62.6964 37.2299 52.7758 49.4247 52.7758Z"
      fill="#157BFA"
    />
    <circle cx="49.1042" cy="75.2708" r="18.2708" fill="#62DA97" />
    <path
      d="M57.0215 69.7896L47.6519 80.143L44.2319 76.4311"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BackToLogin = () => {
  const { t } = useTranslation();

  return (
    <Link to="/login" className="mt-4 text-sm font-semibold">
      <IconChevronLeft className="inline mr-1 text-blue-500" />
      {t('resetPasswordForm.backToLogin')}
    </Link>
  );
};

export const Container = () => {
  const { t } = useTranslation();

  const email = new URLSearchParams(useLocation()?.search).get('email');

  const [step, setStep] = useState<
    'reset-password' | 'success' | 'no-attempts'
  >('reset-password');

  const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false);
  const [submitted] = useState(false);

  const history = useHistory();

  return (
    <div className="flex items-center justify-center w-full min-h-screen">
      {!submitted && (
        <div className="absolute top-0 right-0 p-8">
          <span className="mr-5 text-sm font-medium">
            {t('resetPasswordForm.rememberedPassword')}
          </span>
          <Link
            to="/login"
            className="px-6 py-3 text-sm font-semibold border border-gray-300 rounded"
          >
            {t('loginForm.logIn')}
          </Link>
        </div>
      )}
      <div className="flex flex-col items-center justify-center w-full max-w-sm">
        {step === 'success' && (
          <div className="mb-8">
            <SubmittedIcon />
          </div>
        )}
        {step === 'reset-password' && <IconLogo className="mb-5" />}
        <h3 className="mb-2 text-2xl font-semibold text-gray-900 text-center">
          {step === 'reset-password' && t('auth.resetPasswordHeading')}
          {step === 'success' && t('auth.resetPasswordSuccess')}
          {step === 'no-attempts' && t('auth.noAttempts')}
        </h3>
        {step === 'success' ? (
          <React.Fragment>
            <p className="px-5 mb-8 text-base font-medium text-center text-gray-700">
              {t('resetPasswordForm.submittedDescription')}
            </p>
            <Button
              size="large"
              widthClass="w-full"
              onClick={() => history.push('/login')}
            >
              {t('resetPasswordForm.backToLogin')}
            </Button>
          </React.Fragment>
        ) : (
          <p className="px-5 mb-8 text-base font-semibold text-center text-gray-700">
            {email}

            <Link to="/forgot-password" className="ml-3 text-blue-500 text-xs">
              Change
            </Link>
          </p>
        )}
        {step === 'reset-password' && email && (
          <React.Fragment>
            <FormResetPassword
              email={email}
              onSubmit={() => {
                setIsVisibleSuccessModal(true);
                setTimeout(() => {
                  history.push(`/login`);
                }, 3000);
              }}
              onMaxAttempts={() => setStep('no-attempts')}
            />
            {/*<BackToLogin />*/}
          </React.Fragment>
        )}

        <Modal
          isOpen={isVisibleSuccessModal}
          closeModal={() => {
            setIsVisibleSuccessModal(false);
          }}
          hideCross
          isOverlayDisabled
          maxWidth="auto"
          width="510px"
        >
          <div>
            <div className="my-6 flex justify-center">
              <IconSuccess />
            </div>
            <h3 className="mb-4 text-2xl font-semibold text-gray-900 text-center">
              Successful Password Reset
            </h3>

            <div className="px-5 pb-5">
              <Link to="/login">
                <Button type="button" size="medium-large" widthClass="w-full">
                  {t('auth.ok')}
                </Button>
              </Link>
            </div>
          </div>
        </Modal>

        {step === 'no-attempts' && (
          <div className="auth-max-w-xs auth-mx-auto">
            <p className="auth-text-black text-center">
              {t('auth.noAttemptsDescription')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
