import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useDebouncedValue = <T>(value: T, wait: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>();

  const debouncedSetValue = useCallback(
    debounce(() => setDebouncedValue(value), wait),
    [value]
  );

  useEffect(() => {
    debouncedSetValue();
    return debouncedSetValue.cancel;
  }, [debouncedSetValue]);

  return debouncedValue;
};
