import React, { useState, useEffect, ReactNode } from 'react';
import classNames from 'classnames';
import { TransactionItem } from './context';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { RectLoader } from 'src/components/RectLoader';
import { CardType, TransactionStatus, TransactionType } from '../transaction';
import { transactionHistoryApi } from 'src/api';

export type TransactionDetails = {
  hierarchyId: string;
  dbaName: string;
  terminalId: string;
  locationId: string;
  storeNumber: string;
  merchantId: string;
  cardType: CardType;
  country: string;
  zip: string;
  cardNumber: string;
  state: string;
  city: string;
  addressLineOne: string;
  addressLineTwo: string;
  expiryDate: string;
  settlementDate: string;
  batchNumber: string;
  batchDate: string;
  authorizationCode: string;
  traceId: string;
  arnNumber: string;
  rrnNumber: string;
  transactionDate: string;
  settlementAmount: number;
  settlementCurrencyCode: string;
  billingCurrencyAmount: number;
  billingCurrencyCode: string;
  currencyConversionRate: number;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  transactionMode: string;
  transactionSource: string;
  transactionTime: string;
  matchType: string;
  entryMode: string;
  pcTerminalCapability: string;
  posEntryMode: string;
  partialAuthIndicator: string;
};

const GridItem = ({
  label,
  value,
  withBorderRight = false,
  withBorderBottom = false,
  loading = false,
}: {
  label?: string;
  value?: string;
  withBorderRight?: boolean;
  withBorderBottom?: boolean;
  loading?: boolean;
}) => (
  <div
    className={classNames('px-5 py-2 border-gray-200', {
      'border-b': withBorderBottom,
      'border-r': withBorderRight,
    })}
  >
    <div className="flex items-center">
      <div className="w-1/2">
        <p className="text-xs font-medium text-gray-700">
          {loading ? <RectLoader width={150} height={18} /> : label}
        </p>
      </div>
      <div className="w-1/2">
        <p className="text-xs font-medium text-gray-900 break-all">
          {loading ? <RectLoader width={150} height={18} /> : value}
        </p>
      </div>
    </div>
  </div>
);

const GridGroup = ({
  title,
  children,
  cols = 4,
  loading = false,
}: {
  title: string;
  children: ReactNode;
  cols?: number;
  loading?: boolean;
}) => (
  <div>
    <div className="px-5 py-2 text-xs font-bold text-gray-900 uppercase bg-gray-200">
      {loading ? (
        <RectLoader backgroundColor="#EBEAED" width={100} height={18} />
      ) : (
        title
      )}
    </div>
    <div className={`grid grid-cols-${cols}`}>{children}</div>
  </div>
);

export const TransactionPreview = ({
  transaction,
  closePreview,
}: {
  transaction: TransactionItem;
  closePreview: () => void;
}) => {
  const [
    transactionDetails,
    setTransactionDetails,
  ] = useState<TransactionDetails>();

  const [transactionDetailsLoading, setTransactionDetailsLoading] = useState(
    true
  );

  useEffect(() => {
    setTransactionDetailsLoading(true);

    transactionHistoryApi
      .postTransactionsGet({
        idWrapper: {
          id: transaction.id,
        },
      })
      .then((data) =>
        setTransactionDetails({
          hierarchyId: data.hierarchyId || '',
          dbaName: data.bdaName || '',
          terminalId: data.terminalId || '',
          locationId: data.locationId || '',
          storeNumber: data.storeNumber || '',
          merchantId: data.subMerchantId || '',
          cardType: data.cardBrand as CardType,
          country: 'country',
          zip: 'zip',
          cardNumber: data.maskedCardNumber || '',
          state: 'state',
          city: 'city',
          addressLineOne: 'address 1',
          addressLineTwo: 'address 2',
          expiryDate: '2020',
          settlementDate: data.settlementDate?.toString() || '',
          batchNumber: data.batchNumber || '',
          batchDate: data.batchDate?.toString() || '',
          authorizationCode: data.authorizationCode || '',
          traceId: data.orderNumber || '',
          arnNumber: data.arn || '',
          rrnNumber: data.rrn || '',
          transactionDate: data.transactionDate?.toString() || '',
          settlementAmount: data.settlementAmount || 0,
          settlementCurrencyCode: data.settlementCurrencyCode || '',
          billingCurrencyAmount: data.billingAmount || 0,
          billingCurrencyCode: data.billingCurrencyCode || '',
          currencyConversionRate: data.currencyConversionRate || 0,
          transactionStatus: data.transactionStatus as TransactionStatus,
          transactionType: data.transactionType as TransactionType,
          transactionMode: data.transactionMode || '',
          transactionSource: data.transactionSource || '',
          transactionTime: data.transactionTime || '',
          matchType: data.matchType || '',
          entryMode: data.entryMode || '',
          pcTerminalCapability: data.pcTerminalCapability || '',
          posEntryMode: data.posEntryMode || '',
          partialAuthIndicator: data.partialAuthIndicator || '',
        })
      )
      .finally(() => setTransactionDetailsLoading(false));
  }, [transaction.id]);

  const { t } = useTranslation();

  return (
    <div className="relative">
      <div>
        <div className="bg-white border rounded-sm">
          <div className="flex flex-col">
            <GridGroup
              loading={transactionDetailsLoading}
              title={t('transactionsManagement.transactions.details.merchant')}
            >
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.hierarchyId'
                )}
                value={transactionDetails?.hierarchyId}
                withBorderRight
                withBorderBottom
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t('transactionsManagement.transactions.details.dbaName')}
                value={transactionDetails?.dbaName}
                withBorderRight
                withBorderBottom
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.terminalId'
                )}
                value={transactionDetails?.terminalId}
                withBorderRight
                withBorderBottom
              />
              <GridItem withBorderBottom />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.locationId'
                )}
                value={transactionDetails?.locationId}
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.storeNumber'
                )}
                value={transactionDetails?.storeNumber}
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.merchantId'
                )}
                value={transactionDetails?.merchantId}
                withBorderRight
              />
            </GridGroup>
            <GridGroup
              loading={transactionDetailsLoading}
              title={t(
                'transactionsManagement.transactions.details.cardBillingAddress'
              )}
            >
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.cardType'
                )}
                value={
                  transactionDetails?.cardType &&
                  Object.values(CardType).includes(transactionDetails.cardType)
                    ? t(
                        `transactionsManagement.transactions.cardType.${transactionDetails.cardType}`
                      )
                    : transactionDetails?.cardType
                }
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.cardNumber'
                )}
                value={transactionDetails?.cardNumber}
                withBorderRight
              />
            </GridGroup>
            <GridGroup
              loading={transactionDetailsLoading}
              title={t(
                'transactionsManagement.transactions.details.transaction'
              )}
            >
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.settlementDate'
                )}
                value={moment(transactionDetails?.settlementDate).format(
                  'DD-MM-YYYY'
                )}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.batchNumber'
                )}
                value={transactionDetails?.batchNumber}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.batchDate'
                )}
                value={moment(transactionDetails?.batchDate).format(
                  'DD-MM-YYYY'
                )}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.authorizationCode'
                )}
                value={transactionDetails?.authorizationCode}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t('transactionsManagement.transactions.details.traceId')}
                value={transactionDetails?.traceId}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.arnNumber'
                )}
                value={transactionDetails?.arnNumber}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.rrnNumber'
                )}
                value={transactionDetails?.rrnNumber}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.transactionDate'
                )}
                value={moment(transactionDetails?.transactionDate).format(
                  'DD-MM-YYYY'
                )}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.settlementAmount'
                )}
                value={
                  transactionDetails?.settlementAmount
                    ? numeral(transactionDetails.settlementAmount).format(
                        '0.00'
                      )
                    : undefined
                }
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.settlementCurrencyCode'
                )}
                value={transactionDetails?.settlementCurrencyCode}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.billingCurrencyAmount'
                )}
                value={
                  transactionDetails?.billingCurrencyAmount
                    ? numeral(transactionDetails.billingCurrencyAmount).format(
                        '0.00'
                      )
                    : undefined
                }
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.billingCurrencyCode'
                )}
                value={transactionDetails?.billingCurrencyCode}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.currencyConversionRate'
                )}
                value={
                  transactionDetails?.currencyConversionRate !== null &&
                  transactionDetails?.currencyConversionRate !== undefined
                    ? numeral(transactionDetails.currencyConversionRate).format(
                        '0.00'
                      )
                    : undefined
                }
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.transactionStatus'
                )}
                value={
                  transactionDetails?.transactionStatus &&
                  (Object.values(TransactionStatus).includes(
                    transactionDetails.transactionStatus
                  )
                    ? t(
                        `transactionsManagement.transactions.status.${transactionDetails.transactionStatus}`
                      )
                    : transactionDetails.transactionStatus)
                }
                withBorderRight
                withBorderBottom
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.transactionType'
                )}
                value={
                  transactionDetails?.transactionType &&
                  Object.values(TransactionType).includes(
                    transactionDetails.transactionType
                  )
                    ? t(
                        `transactionsManagement.transactions.transactionType.${transactionDetails.transactionType}`
                      )
                    : transactionDetails?.transactionType
                }
                withBorderRight
                withBorderBottom
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.transactionMode'
                )}
                value={transactionDetails?.transactionMode}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.transactionSource'
                )}
                value={transactionDetails?.transactionSource}
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.transactionTime'
                )}
                value={transactionDetails?.transactionTime}
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.matchType'
                )}
                value={transactionDetails?.matchType}
                withBorderRight
              />
            </GridGroup>
            <GridGroup
              loading={transactionDetailsLoading}
              title={t('transactionsManagement.transactions.details.pos')}
            >
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.entryMode'
                )}
                value={transactionDetails?.entryMode}
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.posEntryMode'
                )}
                value={transactionDetails?.posEntryMode}
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.pcTerminalCapability'
                )}
                value={transactionDetails?.pcTerminalCapability}
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.partialAuthIndicator'
                )}
                value={transactionDetails?.partialAuthIndicator}
              />
            </GridGroup>
          </div>
        </div>
      </div>
    </div>
  );
};
