import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IconEye } from '../../../components/icons/IconEye';
import { ReactComponent as IconCopy } from '../../../assets/icons/iconCopy.svg';

import { AuthCodeInput } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { MfaInfo } from '../../auth/mfa-info/MfaInfo';

import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconSuccess } from 'src/assets/icons/iconSuccess.svg';
import { Modal } from 'src/components/Modal';
import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';

type FormSchema = {
  setupTotpToken?: string;
};

export const ResetMFA = ({
  isCheckFinished,
}: {
  isCheckFinished?: boolean;
}) => {
  const { handleSubmit, errors, setError, control } = useForm<FormSchema>();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isLoadingQR, setLoadingQR] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [qr, setQR] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isShowSecretKey, setIsShowSecretKey] = useState(false);
  const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false);
  const [isVisibleSetupModal, setIsVisibleSetupModal] = useState(false);
  const [userAttributes, setUserAttributes] = useState<{
    mfaLastChange?: string;
  }>({});

  useEffect(() => {
    if (isCheckFinished) {
      checkFinished();
    }
  }, [isCheckFinished]);

  const checkFinished = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(cognitoUser, 'TOTP');
    } catch (e) {
      setIsVisibleSetupModal(true);
      getToken();
    }
  }, []);

  const setChangedTime = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, {
        'custom:mfaLastChange':
          moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss') + ' UTC',
      });
      getAttr();
    } catch (e) {}
  }, []);

  const onSubmit = async (data: FormSchema) => {
    if (data.setupTotpToken) {
      setLoading(true);
      const cognitoUser = await Auth.currentAuthenticatedUser();

      Auth.verifyTotpToken(cognitoUser, data.setupTotpToken)
        .then(() => {
          setIsVisibleSuccessModal(true);
          setLoading(false);
          setQR('');
          setIsVisibleSetupModal(false);
          setChangedTime();
        })
        .catch((e) => {
          setError('setupTotpToken', {
            type: 'manual',
            message: t('validations.invalidCode'),
          });
          setLoading(false);
        });

      return;
    }
  };

  const getToken = useCallback(async function () {
    setLoadingQR(true);
    const user = await Auth.currentAuthenticatedUser();

    const data = await Auth.setupTOTP(user);

    setLoadingQR(false);

    const otpauth = `otpauth://totp/AWSCognito:${user.username}?secret=${data}&issuer=BackOffice`;
    setQR(otpauth);
    setVerificationCode(data);
    setIsVisibleSetupModal(true);
  }, []);

  const getAttr = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const data = await Auth.userAttributes(cognitoUser);
      setUserAttributes({
        mfaLastChange: get(
          find(data, (item) => item.Name === 'custom:mfaLastChange'),
          'Value'
        ),
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getAttr();
  }, []);

  return (
    <div>
      <div>
        {!isCheckFinished && (
          <div>
            <div className="font-semibold text-lg mb-3">
              Two Factor Authentication Enabled
            </div>

            {userAttributes.mfaLastChange && (
              <div className="-mt-2 mb-3 text-gray-700 text-ssm">
                Your Two Factor Authentication has been set on&nbsp;
                {moment(new Date(userAttributes.mfaLastChange)).format(
                  'DD MMMM, YYYY [at] HH:mm'
                )}
              </div>
            )}

            {!qr && (
              <div>
                <Button
                  loading={isLoadingQR}
                  disabled={isLoadingQR}
                  type="button"
                  size="medium-large"
                  widthClass="w-64"
                  onClick={getToken}
                >
                  Reset Two Factor Authentication
                </Button>
              </div>
            )}
          </div>
        )}

        {qr && (
          <Modal
            isOpen={isVisibleSetupModal}
            closeModal={() => {}}
            hideCross
            isOverlayDisabled
            maxWidth="auto"
            width="510px"
          >
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
              <div className="flex flex-col items-center mb-8">
                <MfaInfo />

                <div className="flex flex-col items-center">
                  <div className="flex justify-center w-full py-2">
                    <div className="w-full" style={{ maxWidth: '400px' }}>
                      <div className="text-center mb-4">
                        <div className="text-xxs font-medium text-blue-500 uppercase">
                          STEP 1
                        </div>
                        <div className="text-gray-900 text-lg font-semibold">
                          Scan this QR Code
                        </div>
                      </div>

                      <div className="flex justify-center">
                        <span className="border border-gray-300 rounded p-2">
                          <QRCode value={qr} size={128} />
                        </span>
                      </div>

                      <p className="text-gray-700 text-sm text-center pt-8">
                        Don’t have acces to your mobile right now? Paste the
                        security key in your app.
                      </p>

                      <div className="">
                        {!isShowSecretKey ? (
                          <div
                            className="mt-6 flex justify-center items-center text-blue-500 font-semibold text-xs cursor-pointer"
                            onClick={() => {
                              setIsShowSecretKey(true);
                            }}
                          >
                            Display Shared Secret Key
                            <IconEye className="-mt-px ml-2" />
                          </div>
                        ) : (
                          <div className="mt-6">
                            <div className="text-xs text-gray-900 font-semibold pb-1">
                              Shared Secret Key
                            </div>
                            <div className="relative text-xxs text-gray-900 font-medium border border-gray-300 rounded-sm p-3 w-full">
                              {verificationCode}

                              <div
                                className="absolute"
                                style={{ top: '6px', right: '6px' }}
                              >
                                <CopyToClipboard
                                  text={verificationCode}
                                  onCopy={() => {
                                    setIsCopied(true);
                                    setTimeout(() => {
                                      setIsCopied(false);
                                    }, 3000);
                                  }}
                                >
                                  <div className="text-blue-500 text-xs font-semibold cursor-pointer">
                                    {!isCopied && <IconCopy />}
                                    {isCopied && (
                                      <div
                                        style={{
                                          paddingTop: '3px',
                                          paddingRight: '6px',
                                        }}
                                      >
                                        copied
                                      </div>
                                    )}
                                  </div>
                                </CopyToClipboard>
                              </div>
                            </div>
                            <div className="text-gray-700 text-xs pt-2">
                              Paste this in key your authentificatior app
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center w-full pt-6">
                    <div className="w-full" style={{ maxWidth: '400px' }}>
                      <div className="text-center mb-6">
                        <div className="text-xxs font-medium text-blue-500 uppercase">
                          STEP 2
                        </div>
                        <div className="text-gray-900 text-lg font-semibold">
                          Enter Your Code
                        </div>
                      </div>

                      <div className="mb-10">
                        <AuthCodeInput
                          label={t('loginForm.verificationCode')}
                          name="setupTotpToken"
                          error={errors.setupTotpToken}
                          control={control}
                          rules={{ required: true }}
                        />
                      </div>

                      <Button
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        size="medium-large"
                        widthClass="w-full"
                      >
                        Update Two Factor Authentification
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </div>

      <Modal
        isOpen={isVisibleSuccessModal}
        closeModal={() => {
          setIsVisibleSuccessModal(false);
        }}
        hideCross
        isOverlayDisabled
        maxWidth="auto"
        width="510px"
      >
        <div>
          <div className="my-6 flex justify-center">
            <IconSuccess />
          </div>
          <h3 className="mb-4 text-2xl font-semibold text-gray-900 text-center">
            Successful 2FA Reset
          </h3>

          <div className="px-5 pb-5">
            <Button
              type="button"
              size="medium-large"
              widthClass="w-full"
              onClick={() => {
                setIsVisibleSuccessModal(false);
              }}
            >
              {t('auth.ok')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
