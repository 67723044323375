/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProgramChannelProvider
 */
export interface ProgramChannelProvider {
    /**
     * 
     * @type {string}
     * @memberof ProgramChannelProvider
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramChannelProvider
     */
    programId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramChannelProvider
     */
    providerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProgramChannelProvider
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProgramChannelProvider
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProgramChannelProvider
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProgramChannelProvider
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramChannelProvider
     */
    status?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProgramChannelProvider
     */
    statusDate?: Date;
}

export function ProgramChannelProviderFromJSON(json: any): ProgramChannelProvider {
    return ProgramChannelProviderFromJSONTyped(json, false);
}

export function ProgramChannelProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramChannelProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'programId': !exists(json, 'program_id') ? undefined : json['program_id'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'updatedBy': !exists(json, 'updated_by') ? undefined : json['updated_by'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDate': !exists(json, 'status_date') ? undefined : (new Date(json['status_date'])),
    };
}

export function ProgramChannelProviderToJSON(value?: ProgramChannelProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'program_id': value.programId,
        'provider_id': value.providerId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updated_by': value.updatedBy,
        'status': value.status,
        'status_date': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
    };
}


