import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  map,
  get,
  find,
  forEach,
  groupBy,
  intersectionBy,
  fromPairs,
} from 'lodash';
import { Button } from 'src/components/Button';
import {
  AddUserFields,
  addUserFormType,
  AssignmentsItemFields,
  DEFAULT_BACKOFFICE_ITEM_FIELDS,
} from '../AddUserModal';
import { nameof } from 'src/utils/nameof';
import {
  DynamicFieldsOptions,
  DynamicFields,
} from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/DynamicFileds/DynamicFields';
import { HorizontalDivider } from 'src/components/HorizontalDivider';
import { Select } from 'src/components/form/Select';
import { triggerArrayFields } from 'src/validation/triggerArrayFields';
import { ButtonLink } from 'src/components/ButtonLink';
import {
  AccessChannel,
  ListIamUserTypesResponse,
  ListIamSecurityProfilesResponse,
} from 'src/api/client';
import { useOptionsQueries } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/useOptionsQueries';
import { AddUsersContext } from '../AddUserContext';

export const isEmptyAssign = (companyAssignments?: AssignmentsItemFields) => {
  if (!companyAssignments) {
    return true;
  }

  return !(
    companyAssignments.organizations ||
    companyAssignments.firstOrganization ||
    companyAssignments.secondOrganization ||
    (Array.isArray(companyAssignments.roles) &&
      companyAssignments.roles.length) ||
    (Array.isArray(companyAssignments.program) &&
      !companyAssignments.program.length)
  );
};

export const transformToOptions = <T extends object>(
  data: any[],
  labelKey: keyof T,
  valueKey: keyof T = 'id' as keyof T
): { label: string; value: string }[] =>
  map(data, (el) => ({
    value: el[valueKey] || '',
    label: el[labelKey] || '',
  }));

interface Props {
  toPreviousStep: () => void;
  toNextStep: () => void;
  copyAssignment: () => void;
  toggleFields: (value?: boolean[] | number) => void;
  hiddenFields: boolean[];
  selectedChannel?: AccessChannel | null;
  userTypesResponse?: ListIamUserTypesResponse;
}

export const FormTwo = memo(
  ({
    toPreviousStep,
    toNextStep,
    copyAssignment,
    toggleFields,
    hiddenFields,
    selectedChannel,
    userTypesResponse,
  }: Props) => {
    const { t } = useTranslation();
    const {
      control,
      errors,
      trigger,
      getValues,
      setValue,
      reset,
      watch,
    } = useFormContext<AddUserFields>();
    const { userData, setDefaultValues, setClearSecondEnable } = useContext(
      AddUsersContext
    );
    const [isFilledOrgn, setIsFilledOrgn] = useState(false);
    const fieldsValue = watch<string, AssignmentsItemFields[]>(
      nameof(addUserFormType.companyAssignments)
    );

    const {
      organizationsOptions,
      rolesOptions,
      programsOptions,
      securityProfileOptions,
      isLoadingCompanies,
      isLoadingRoles,
      isLoadingPrograms,
      isLoadingSecurityProfile,
      companiesResponse,
      securityProfileResponse,
    } = useOptionsQueries({ accessChannelId: selectedChannel?.id });

    const isLoadingQueries =
      isLoadingCompanies ||
      isLoadingRoles ||
      isLoadingPrograms ||
      isLoadingSecurityProfile;

    const {
      fields,
      append: appendFields,
      remove: removeFields,
    } = useFieldArray<AssignmentsItemFields>({
      control,
      name: nameof(addUserFormType.companyAssignments),
    });

    const isBackoffice = useMemo(
      () => get(userTypesResponse, 'types[0].name') === 'backoffice',
      [userTypesResponse]
    );

    useEffect(() => {
      if (fields.length === 0) {
        appendFields(isBackoffice ? DEFAULT_BACKOFFICE_ITEM_FIELDS : {});
      }
    }, [isBackoffice, fields, appendFields]);

    useEffect(() => {
      const values: AddUserFields = getValues();
      const companyAssignments: AssignmentsItemFields[] = getValues(
        nameof(addUserFormType.companyAssignments)
      );

      if (
        userData.isLoading ||
        !companiesResponse ||
        !organizationsOptions ||
        !rolesOptions ||
        (companyAssignments && !isEmptyAssign(companyAssignments[0]))
      ) {
        return;
      }

      if (!userData.roles) {
        isBackoffice && setClearSecondEnable(true);
        return;
      }

      const defaultAssigns: AssignmentsItemFields[] = [];
      const groupRoles = groupBy(userData.roles, 'orgId');

      const setAssign = (orgId: string) => {
        const defaultAssign = {} as AssignmentsItemFields;
        defaultAssign.organizations = find(
          organizationsOptions,
          (org) => org.value === orgId
        );
        defaultAssign.roles = intersectionBy(
          rolesOptions,
          map(groupRoles[orgId], (o) => ({ value: o.roleId })),
          'value'
        );

        defaultAssigns.push(defaultAssign);
      };

      const setBackofficeAssign = (orgId: string) => {
        const defaultAssign = {
          ...DEFAULT_BACKOFFICE_ITEM_FIELDS,
        } as AssignmentsItemFields;
        defaultAssign.roles = intersectionBy(
          rolesOptions,
          map(groupRoles[orgId], (o) => ({ value: o.roleId })),
          'value'
        );

        const orgData = find(
          companiesResponse.companies,
          (company) => company.id === orgId
        );
        const orgOption = find(
          organizationsOptions,
          (org) => org.value === orgId
        );

        if (orgData?.companyTopId) {
          defaultAssign.secondOrganization = orgOption;

          defaultAssign.firstOrganization = find(
            organizationsOptions,
            (org) => org.value === orgData.companyTopId
          );
          defaultAssign.autoAssignAffiliated = false;
        } else {
          defaultAssign.firstOrganization = orgOption;
        }

        if (!defaultAssign.secondOrganization) {
          defaultAssign.autoAssignAffiliated = true;
        }

        defaultAssigns.push(defaultAssign);
      };

      if (isBackoffice) {
        forEach(Object.keys(groupRoles), setBackofficeAssign);
      } else {
        forEach(Object.keys(groupRoles), setAssign);
      }

      if (defaultAssigns.length) {
        reset({ ...values, companyAssignments: defaultAssigns });
      }

      if (defaultAssigns.length > 1) {
        const hiddenFields: boolean[] = [];
        forEach(
          defaultAssigns,
          (_, i) => i < defaultAssigns.length - 1 && hiddenFields.push(true)
        );
        toggleFields(hiddenFields);
      }

      if (isBackoffice) {
        setDefaultValues(
          fromPairs(
            map(defaultAssigns, (el, i) => [i, el.firstOrganization?.value])
          ),
          fromPairs(
            map(defaultAssigns, (el, i) => [i, el.autoAssignAffiliated])
          )
        );
        setClearSecondEnable(true);
      }
    }, [
      reset,
      getValues,
      companiesResponse,
      organizationsOptions,
      rolesOptions,
      userData,
      isBackoffice,
      toggleFields,
      setClearSecondEnable,
      setDefaultValues,
    ]);

    useEffect(() => {
      const securityProfile = getValues(
        nameof(addUserFormType.securityProfile)
      );

      if (securityProfile || !securityProfileResponse) {
        return;
      }

      const iamSecurityProfiles = get<
        ListIamSecurityProfilesResponse,
        'iamSecurityProfiles'
      >(securityProfileResponse, 'iamSecurityProfiles');

      if (!iamSecurityProfiles || !iamSecurityProfiles.length) {
        return;
      }

      const defaultSecurityProfile = userData.user?.securityProfileId
        ? find(
            iamSecurityProfiles,
            (securityProfileItem) =>
              securityProfileItem.id === userData.user?.securityProfileId
          )
        : iamSecurityProfiles[0];

      if (!defaultSecurityProfile) {
        return;
      }

      setValue(nameof(addUserFormType.securityProfile), {
        value: defaultSecurityProfile.id,
        label: defaultSecurityProfile.name,
      });
    }, [userData, securityProfileResponse, getValues, setValue]);

    useEffect(() => {
      if (!fieldsValue?.length) {
        return;
      }

      return fieldsValue[fieldsValue.length - 1].organizations ||
        fieldsValue[fieldsValue.length - 1].firstOrganization
        ? setIsFilledOrgn(true)
        : setIsFilledOrgn(false);
    }, [isBackoffice, selectedChannel, fieldsValue]);

    const handleRemoveFields = useCallback(
      (fieldsIndex: number) => {
        removeFields(fieldsIndex);
        const oldHiddenFields = [...hiddenFields];
        oldHiddenFields.splice(fieldsIndex, 1);
        toggleFields(oldHiddenFields);
      },
      [removeFields, hiddenFields, toggleFields]
    );

    const handleClearFields = () => {
      if (isBackoffice) {
        [
          'firstOrganization',
          'secondOrganization',
          'autoAssignAffiliated',
          'program',
          'roles',
        ].map((fieldName) =>
          setValue(
            nameof(addUserFormType.companyAssignments) +
              `[${fields.length - 1}].${fieldName}`,
            null
          )
        );
      } else {
        ['organizations', 'roles', 'program'].map((fieldName) =>
          setValue(
            nameof(addUserFormType.companyAssignments) +
              `[${fields.length - 1}].${fieldName}`,
            null
          )
        );
      }
    };

    const handleAppendFields = async () => {
      const isValidArrayFields = await triggerArrayFields(
        getValues,
        trigger,
        nameof(addUserFormType.companyAssignments)
      );

      if (isValidArrayFields) {
        appendFields(isBackoffice ? DEFAULT_BACKOFFICE_ITEM_FIELDS : {});
        const oldHiddenFields = [...hiddenFields];
        oldHiddenFields.push(true);
        toggleFields(oldHiddenFields);
      }
    };

    const handleNextStep = async () => {
      const isValidArrayFields = await triggerArrayFields(
        getValues,
        trigger,
        nameof(addUserFormType.companyAssignments)
      );

      const isValidFields = await trigger(
        nameof(addUserFormType.securityProfile)
      );

      return isValidArrayFields && isValidFields && toNextStep();
    };

    const dynamicFieldsOptions: DynamicFieldsOptions = useMemo(
      () => ({
        organizationsOptions,
        programsOptions,
        rolesOptions,
      }),
      [organizationsOptions, programsOptions, rolesOptions]
    );

    return (
      <div>
        {fields.map((field, i) => (
          <DynamicFields
            key={field.id}
            fieldIndex={i}
            field={field}
            fieldsLength={fields.length}
            removeFields={handleRemoveFields}
            toggleHiddenFields={toggleFields}
            isHidden={hiddenFields[i]}
            isLoadingCompanies={isLoadingCompanies}
            isLoadingPrograms={isLoadingPrograms}
            isLoadingRoles={isLoadingRoles}
            fieldsOptions={dynamicFieldsOptions}
            companies={companiesResponse?.companies || []}
            userTypesResponse={userTypesResponse}
          />
        ))}
        <div className="flex justify-end mb-3">
          <Button
            widthClass="w-16"
            size={'small'}
            type={'button'}
            onClick={handleClearFields}
            variant={'text'}
            className={'mr-2'}
          >
            {t('buttons.clear')}
          </Button>
          <Button
            widthClass="w-16"
            size={'small'}
            type={'button'}
            onClick={handleAppendFields}
            disabled={!isFilledOrgn}
          >
            {t('buttons.add')}
          </Button>
        </div>
        <HorizontalDivider className={'my-4'} />
        <div className="flex mb-3">
          <div className="w-full mr-1">
            <Select
              label={t('accessManagement.userManagement.form.securityProfile')}
              error={get(errors, nameof(addUserFormType.securityProfile))}
              rules={{ required: true }}
              name={nameof(addUserFormType.securityProfile)}
              control={control}
              isLoading={isLoadingSecurityProfile}
              options={securityProfileOptions}
            />
          </div>
        </div>
        <div className={'flex justify-between items-center mt-6'}>
          <ButtonLink onClick={copyAssignment}>
            {t('accessManagement.userManagement.form.copyAssigment.button')}
          </ButtonLink>
          <div className="flex justify-end  ml-2">
            <Button
              widthClass="w-24"
              size={'medium-large'}
              type={'button'}
              onClick={toPreviousStep}
              variant={'text'}
              className={'mr-2'}
            >
              {t('buttons.back')}
            </Button>
            <Button
              widthClass="w-24"
              size={'medium-large'}
              onClick={handleNextStep}
              disabled={isLoadingQueries}
            >
              {t('buttons.review')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
