import React, { useReducer, useCallback, useEffect } from 'react';
import { Details } from './Details';
import { reducer, initialState, Actions, DetailsContext } from './context';
import { useRouteMatch, useParams } from 'react-router-dom';
import { NotFound } from './NotFound';
import { useDispatch } from 'react-redux';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import { acquiringApi } from 'src/api';

const fetchItem = ({ id }: { id: string }) => {
  return acquiringApi.postLogGet({
    acquiringAuditLogRequest: {
      id,
    },
  });
};

export const Container = () => {
  const [state, dispatchAction] = useReducer(reducer, initialState);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info({ ...action, context: 'LOGS/DETAILS' });
    }

    dispatchAction(action);
  }, []);

  const routeMatch = useRouteMatch();

  const { id } = useParams<{ id: string }>();

  const storeDispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('logs.breadcrumb'),
          route: '/logs/api',
        },
        {
          label: t('logs.api.breadcrumb'),
          route: '/logs/api',
        },
        {
          label: t('logs.api.details.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  useEffect(() => {
    let canceled = false;

    dispatch({
      type: 'SET_ITEM_LOADING',
      loading: true,
    });

    fetchItem({ id })
      .then((data) => {
        if (!canceled) {
          dispatch({ type: 'SET_ITEM', item: data });
        }
      })
      .catch(() => {
        if (!canceled) {
          dispatch({
            type: 'SET_ITEM_NOT_FOUND',
            notFound: true,
          });
        }
      })
      .finally(() => {
        if (!canceled) {
          dispatch({
            type: 'SET_ITEM_LOADING',
            loading: false,
          });
        }
      });

    return () => {
      canceled = true;
    };
  }, [dispatch, routeMatch.url, routeMatch.isExact, id]);

  return (
    <DetailsContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {state.itemNotFound ? <NotFound /> : <Details />}
    </DetailsContext.Provider>
  );
};
