import React, { useEffect, useState } from 'react';
import { useCurrentUser } from './hooks';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { i18n } from '../i18n';

export const PrivateRoute = (props: RouteProps) => {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const { isAuthenticated, user } = useCurrentUser();

  useEffect(() => {
    const loadResource = async () => {
      i18n.addResourceBundle(
        'en',
        'translation',
        await import('../locales/en/translationAuth.json'),
        true,
        true
      );

      setTranslationsLoaded(true);
    };

    if (isAuthenticated) {
      loadResource();

    }
  }, [isAuthenticated, user]);

  return isAuthenticated ? (
    translationsLoaded ? (
      <Route {...props} />
    ) : (
      <></>
    )
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};
