import { createContext } from 'react';
import { Chargeback } from 'src/api/client';
import { Sorting } from 'src/components/table/Table';
import { RowsPerPage } from './rowsPerPage';

export interface ChargebackItem extends Chargeback {}

export enum ChargebackStatus {
  'TEST' = 'TEST',
}

export type Filters = {
  cardLastFour: string;
  caseId: string;
  disputeAmount: string;
  referenceNumber: string;
  status: ChargebackStatus | null;
};

export const defaultFilters: Filters = {
  cardLastFour: '',
  caseId: '',
  disputeAmount: '',
  referenceNumber: '',
  status: null,
};

export const initialState = {
  items: [] as ChargebackItem[],
  itemsLoading: 0,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  rowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  filters: defaultFilters,
  appliedFilters: defaultFilters,
  download: () => {},
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_ITEMS'; items: ChargebackItem[] }
  | { type: 'APPEND_ITEMS'; items: ChargebackItem[] }
  | { type: 'SET_COLUMN_SORTING'; columnSorting?: Sorting }
  | { type: 'INCREMENT_ITEMS_LOADING' }
  | { type: 'DECREMENT_ITEMS_LOADING' }
  | { type: 'SET_PAGINATION_LOADING'; loading: boolean }
  | { type: 'SET_DOWNLOAD_LOADING'; loading: boolean }
  | { type: 'SET_END_REACHED'; endReached: boolean }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_SEARCH_QUERY'; searchQuery: string }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: RowsPerPage }
  | { type: 'SET_DOWNLOAD_LOADING'; loading: boolean }
  | { type: 'SET_CARD_LAST_FOUR_FILTER'; cardLastFour: string }
  | { type: 'SET_CASE_ID_FILTER'; caseId: string }
  | { type: 'SET_DISPUTE_AMOUNT_FILTER'; disputeAmount: string }
  | { type: 'SET_REFERENCE_NUMBER_FILTER'; referenceNumber: string }
  | { type: 'SET_STATUS_FILTER'; status: ChargebackStatus }
  | { type: 'RESET_STATUSES_FILTER' }
  | { type: 'APPLY_FILTERS' }
  | { type: 'RESET_ALL_FILTERS' };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_ITEMS':
      return { ...state, items: action.items };
    case 'APPEND_ITEMS':
      return { ...state, items: [...state.items, ...action.items] };
    case 'INCREMENT_ITEMS_LOADING':
      return { ...state, itemsLoading: state.itemsLoading + 1 };
    case 'DECREMENT_ITEMS_LOADING':
      return {
        ...state,
        itemsLoading: state.itemsLoading > 0 ? state.itemsLoading - 1 : 0,
      };
    case 'SET_PAGINATION_LOADING':
      return { ...state, paginationLoading: action.loading };
    case 'SET_END_REACHED':
      return { ...state, endReached: action.endReached };
    case 'SET_PAGE':
      return { ...state, page: action.page };
    case 'SET_ROWS_PER_PAGE':
      return { ...state, rowsPerPage: action.rowsPerPage };
    case 'SET_DOWNLOAD_LOADING':
      return { ...state, downloadLoading: action.loading };
    case 'SET_CARD_LAST_FOUR_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          cardLastFour: action.cardLastFour,
        },
      };
    }
    case 'SET_CASE_ID_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          caseId: action.caseId,
        },
      };
    }
    case 'SET_DISPUTE_AMOUNT_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          disputeAmount: action.disputeAmount,
        },
      };
    }
    case 'SET_REFERENCE_NUMBER_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          referenceNumber: action.referenceNumber,
        },
      };
    }
    case 'SET_STATUS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.status,
        },
      };
    case 'RESET_STATUSES_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          status: null,
        },
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        appliedFilters: Object.assign({}, state.filters),
      };
    case 'RESET_ALL_FILTERS':
      return {
        ...state,
        filters: defaultFilters,
        appliedFilters: defaultFilters,
      };
    default:
      throw new Error();
  }
};

export const ListContext = createContext<typeof initialState>(initialState);
