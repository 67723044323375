/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProgramChannelProvider,
    ProgramChannelProviderFromJSON,
    ProgramChannelProviderFromJSONTyped,
    ProgramChannelProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateProgramChannelProviderResponse
 */
export interface CreateProgramChannelProviderResponse {
    /**
     * 
     * @type {ProgramChannelProvider}
     * @memberof CreateProgramChannelProviderResponse
     */
    programChannelProvider: ProgramChannelProvider;
}

export function CreateProgramChannelProviderResponseFromJSON(json: any): CreateProgramChannelProviderResponse {
    return CreateProgramChannelProviderResponseFromJSONTyped(json, false);
}

export function CreateProgramChannelProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProgramChannelProviderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programChannelProvider': ProgramChannelProviderFromJSON(json['program_channel_provider']),
    };
}

export function CreateProgramChannelProviderResponseToJSON(value?: CreateProgramChannelProviderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'program_channel_provider': ProgramChannelProviderToJSON(value.programChannelProvider),
    };
}


