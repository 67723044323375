import React from 'react';
import classNames from 'classnames';

export const VerticalColItems = ({ items }: { items?: string[] }) => {
  return (
    <div className={'flex flex-col'}>
      {items?.map((item, index) => (
        <span
          key={item + index}
          className={classNames({ 'mb-2': index !== items?.length - 1 })}
        >
          {item}
        </span>
      ))}
    </div>
  );
};
