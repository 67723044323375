import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import filter from 'lodash/filter';
import get from 'lodash/get';
import find from 'lodash/find';
import { forEach } from 'lodash';
import { Card } from 'src/components/Card';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { StatusBadge } from 'src/components/StatusBadge';
import {
  getUserStatusBadge,
  iconAccessChannelsMap,
} from 'src/domain/access-management/user-management/tabs/users/useUsersColumns';
import { Button } from 'src/components/Button';
import { RectLoader } from 'src/components/RectLoader';
import { IconTrash } from 'src/components/icons/IconTrash';
import { IconVerticalDots } from 'src/components/icons/IconVerticalDots';
import { Tooltip } from 'src/components/Tooltip';

import { useGetUserQueries } from 'src/domain/access-management/user-management/tabs/users/add-user/useGetUserQueries';
import { useOptionsQueries } from 'src/domain/access-management/user-management/tabs/users/add-user/FormTwo/useOptionsQueries';
import { UsersContext } from 'src/domain/access-management/user-management/tabs/users/UsersContext';
import { useUserModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserModal';
import { useUserDeleteModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserDeleteModal';
import { useUserChangeStatusModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserChangeStatusModal';

import { ReactComponent as OrganizationIcon } from '../../../../../../assets/icons/organization.svg';
import { IamUserStatus } from 'src/api/client';
import { ReactComponent as EmailIcon } from 'src/assets/icons/email.svg';
import { Loading } from 'src/components/Loading';
import { notifications } from 'src/utils/notifications';
import { useMutation } from 'react-query';
import { usersApi } from 'src/api';
import checkStatus, { UserActions } from 'src/utils/statuses';

export const UserDetails = () => {
  const { t } = useTranslation();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const history = useHistory();

  const { accessChannelList, listParams } = useContext(UsersContext);

  const {
    user,
    roles,
    userCompanies,
    isFetching,
    error,
    refetch,
  } = useGetUserQueries({
    id,
    userId: id,
    enabled: true,
  });

  const { openModal: openEditUserModal, modal: editUserModal } = useUserModal({
    isEdit: true,
  });

  const {
    organizationsOptions,
    rolesObj,
    securityProfileOptions,
  } = useOptionsQueries({ id, accessChannelId: user?.accessChannelId });

  const onUserDeleted = useCallback(() => {
    closeDeleteUserModal();
    listParams.refetch();
    history.push(`/access-management/user-management/users`);
  }, []);

  const onUserStatusChanged = useCallback(() => {
    closeChangeStatusUserModal();
    refetch && refetch();
  }, []);

  const {
    openDeleteUserModal,
    deleteUserModal,
    closeDeleteUserModal,
  } = useUserDeleteModal({ onSubmit: onUserDeleted });

  const {
    openChangeStatusUserModal,
    changeStatusUserModal,
    closeChangeStatusUserModal,
  } = useUserChangeStatusModal({ onSubmit: onUserStatusChanged, isInvalidateCache: true });

  const [loadingResendUsername, setLoadingResendUsername] = useState('');

  const { mutateAsync: resendWelcomeEmail } = useMutation((iamUserId: string) =>
    usersApi.postIamUsersResendWelcomeEmail({
      resendWelcomeEmailRequest: {
        iamUserId,
      },
    })
  );

  const resendInvitation = useCallback(async (e, iamUserId) => {
    setLoadingResendUsername(iamUserId);

    e.stopPropagation();
    try {
      await resendWelcomeEmail(iamUserId);
      notifications.success();
    } catch (e) {} finally {
      setLoadingResendUsername('');
    }
  }, []);

  return (
    <div className="flex flex-col w-full bg-gray-200">
      {editUserModal}
      {deleteUserModal}
      {!isFetching && changeStatusUserModal}

      <div className="w-full">
        {error?.status === 403 ? (
          <div className="h-full p-3">
            <Card className="mb-3">
              <div className="py-3 px-5 bg-red-100 text-sm text-gray-900 rounded-sm">
                {t('permissions.permissionDenied')}
              </div>
            </Card>
          </div>
        ) : (
          <div className="h-full p-3">
            <Card className="mb-3">
              <div className="flex justify-between">
                <div className="w-1/2 border-r border-gray-300">
                  <div className="text-ssm uppercase text-gray-700">
                    {isFetching ? (
                      <RectLoader
                        className={'w-full'}
                        width={100}
                        height={14}
                      />
                    ) : (
                      <>{user?.identityTokenId}</>
                    )}
                  </div>
                  <h1 className="flex items-center">
                    <span className="text-2xl text-gray-900 font-semibold mr-3 -mb-1">
                      {isFetching ? (
                        <div className={'w-full my-2'}>
                          <RectLoader
                            className={'w-full'}
                            width={200}
                            height={25}
                          />
                        </div>
                      ) : (
                        <>
                          {user?.firstName} {user?.lastName}
                        </>
                      )}
                    </span>

                    {user && user.status && (
                      <StatusBadge
                        variant={getUserStatusBadge(user?.status)}
                        widthClass="w-24"
                        textClass="text-xs capitalize"
                      >
                        {user.status}
                      </StatusBadge>
                    )}
                  </h1>

                  <div className="flex justify-between pt-1">
                    <div className="w-1/2">
                      <div className="pb-3">
                        {/*{user?.status === IamUserStatus.Suspended && (
                          <p className="flex items-center text-xs font-medium text-gray-700">
                            <SuspendIcon className="mr-1 -mt-2px" /> Suspended
                          </p>
                        )}*/}
                        {(user?.title || user?.company) && (
                          <p className="text-xs font-medium text-gray-900">
                            {user?.title}{' '}
                            {user?.title && user?.company && <span>at</span>}{' '}
                            {user?.company && (
                              <span className="text-blue-500">
                                {user?.company}
                              </span>
                            )}
                          </p>
                        )}
                      </div>
                      <div className="pb-3">
                        <p className="text-xs font-medium text-gray-700">
                          Access Channel
                        </p>
                        <p className="flex items-center text-xs font-medium text-gray-900">
                          {iconAccessChannelsMap(
                            get(
                              find(
                                accessChannelList,
                                ({ id }) => id === user?.accessChannelId
                              ),
                              'name'
                            )
                          )}{' '}
                          {accessChannelList &&
                            get(
                              find(
                                accessChannelList,
                                ({ id }) => id === user?.accessChannelId
                              ),
                              'name'
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <div className="pb-3">
                        <p className="text-xs font-medium text-gray-700">
                          Email
                        </p>
                        <p className="text-xs font-medium text-gray-900">
                          {isFetching ? (
                            <RectLoader
                              className={'w-full'}
                              width={100}
                              height={18}
                            />
                          ) : (
                            <>{user?.username}</>
                          )}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs font-medium text-gray-700">
                          Phone Number
                        </p>
                        <p className="text-xs font-medium text-gray-900">
                          {isFetching ? (
                            <RectLoader
                              className={'w-full'}
                              width={100}
                              height={18}
                            />
                          ) : (
                            <>{user?.phoneNumber}</>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-1/2 pl-5 flex justify-between">
                  <div className="flex w-2/3">
                    <div className="w-1/2">
                      <div className="pt-4 pb-3">
                        <p className="text-xs font-medium text-gray-700">
                          Organization Unit
                        </p>
                        <p className="text-xs font-medium text-gray-900">
                          {isFetching ? (
                            <RectLoader
                              className={'w-full'}
                              width={100}
                              height={18}
                            />
                          ) : (
                            <>{user?.orgUnit || '-'}</>
                          )}
                        </p>
                      </div>

                      <div>
                        <p className="text-xs font-medium text-gray-700">
                          Notes
                        </p>
                        <p className="text-xs font-medium text-gray-900">
                          {isFetching ? (
                            <>
                              <RectLoader
                                className={'w-full my-1'}
                                width={200}
                                height={18}
                              />
                              <RectLoader
                                className={'w-full my-1'}
                                width={200}
                                height={18}
                              />
                            </>
                          ) : (
                            <>{user?.notes || '-'}</>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="w-1/2">
                      <div className="pt-4 pb-3">
                        <p className="text-xs font-medium text-gray-700">
                          Direct Supervisor
                        </p>
                        <p className="text-xs font-medium text-gray-900">
                          {isFetching ? (
                            <RectLoader
                              className={'w-full'}
                              width={100}
                              height={18}
                            />
                          ) : (
                            <>{user?.directSupervisor || '-'}</>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-end justify-between w-1/3">
                    {user?.status !== IamUserStatus.Deleted && !isFetching && (
                      <div>
                        <button
                          className="text-blue-500 font-medium text-xs font-semibold focus:outline-none"
                          onClick={() => {
                            openEditUserModal(id);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    )}
                    {user?.status && !isFetching && (
                      <div className="flex items-center">
                        {checkStatus({ status: user.status, action: UserActions.ACTIVATE }) && (
                          <Button
                            variant="primary"
                            size="medium-large"
                            onClick={() => {
                              openChangeStatusUserModal(
                                IamUserStatus.Active,
                                id,
                                `${user?.firstName} ${user?.lastName}`
                              );
                            }}
                          >
                            Activate
                          </Button>
                        )}
                        {checkStatus({ status: user.status, action: UserActions.SUSPEND }) && (
                          <Button
                            variant="yellow-outline"
                            size="medium-large"
                            onClick={() => {
                              openChangeStatusUserModal(
                                IamUserStatus.Suspended,
                                id,
                                `${user?.firstName} ${user?.lastName}`
                              );
                            }}
                          >
                            Suspend
                          </Button>
                        )}

                        {user?.status !== IamUserStatus.Deleted && (
                          <Tooltip
                            minWidth={50}
                            placement="bottom-end"
                            withPadding={false}
                            content={() => (
                              <div className="py-1">
                                {checkStatus({ status: user.status, action: UserActions.RESEND_INVITATION }) && (
                                  <div
                                    onClick={(e) => {
                                      resendInvitation(e, user.id!);
                                    }}
                                    className="flex items-center w-full h-5 px-3 py-4 mr-5 font-semibold hover:bg-gray-100 cursor-pointer"
                                  >
                                    {loadingResendUsername ===
                                      user.id && (
                                      <Loading className="mr-2" />
                                    )}
                                    <EmailIcon />
                                    <span className="ml-2">
                                      {t('buttons.resendInvite')}
                                    </span>
                                  </div>
                                )}

                                {checkStatus({ status: user.status, action: UserActions.DELETE }) && <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDeleteUserModal(
                                      id,
                                      `${user?.firstName} ${user?.lastName}`
                                    );
                                  }}
                                  className="flex items-center w-full h-5 px-3 py-4 mr-5 font-semibold text-red-500 hover:bg-gray-100 cursor-pointer"
                                >
                                  {<IconTrash className="text-red-500" />}
                                  <span className="ml-2">
                                    {t('buttons.delete')}
                                  </span>
                                </div>}
                              </div>
                            )}
                          >
                            {({ ref, setIsOpen, isOpen }) => (
                              <div
                                ref={ref}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsOpen(!isOpen);
                                }}
                                className="ml-5 cursor-pointer"
                              >
                                <IconVerticalDots className="text-gray-700" />
                              </div>
                            )}
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <div className="bg-white border rounded-sm h-full">
              <div className="flex items-center p-6 pb-3 border-b border-gray-300">
                <div className="w-1/2">
                  <span className="text-lg text-gray-900 font-semibold">
                    Assignments and Security
                  </span>
                </div>
                <div className="flex w-1/2 pl-6">
                  <span className="text-xs font-medium text-gray-700 pr-2">
                    Security Profile
                  </span>
                  <span className="text-xs font-medium text-gray-900">
                    {!securityProfileOptions ? (
                      <RectLoader width={80} height={20} />
                    ) : (
                      <span className="text-gray-900">
                        {get(
                          find(
                            securityProfileOptions,
                            (item) => item.value === user?.securityProfileId
                          ),
                          'label'
                        )}
                      </span>
                    )}
                  </span>
                </div>
              </div>

              {isFetching && (
                <div className="flex flex-wrap justify-center">
                  <RectLoader className={'my-3'} width={300} height={24} />
                </div>
              )}

              <div className="flex flex-wrap">
                {!isFetching &&
                  user &&
                  user.companiesList &&
                  map(
                    filter(
                      user.companiesList,
                      ({ companyTopId }) => !companyTopId
                    ),
                    ({ id, companyTopId, shortName }, i) => (
                      <div
                        key={i}
                        className={classNames(
                          'w-1/2 p-6 pb-4 -mb-px border-b border-gray-300',
                          { 'border-r': i % 2 === 0 }
                        )}
                      >
                        <div className="pb-4 mb-4 border-b border-gray-300">
                          <div className="flex items-center">
                            <OrganizationIcon className="mr-3" />

                            <div>
                              <p className="text-xs font-medium text-gray-700">
                                Organization
                              </p>
                              <p className="text-2xl text-gray-900 font-semibold">
                                {shortName}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="flex">
                          <div className="w-1/3 border-r border-gray-300 py-2">
                            <p className="text-xs font-medium text-gray-700">
                              Roles
                            </p>
                            <div>
                              <p className="text-xs font-medium text-gray-900">
                                {!(organizationsOptions && rolesObj) && (
                                  <RectLoader width={80} height={20} />
                                )}
                              </p>
                              {map(
                                filter(roles, (item) => item.orgId === id),
                                (role, i) => (
                                  <p
                                    key={i}
                                    className="text-xs font-medium text-gray-900"
                                  >
                                    {role.roleId &&
                                      rolesObj &&
                                      rolesObj[role.roleId]}
                                  </p>
                                )
                              )}

                              {map(
                                filter(
                                  user.companiesList,
                                  ({ companyTopId }) => companyTopId === id
                                ),
                                ({ id }) =>
                                  map(
                                    filter(roles, (item) => item.orgId === id),
                                    (role, i) => (
                                      <p
                                        key={i}
                                        className="text-xs font-medium text-gray-900"
                                      >
                                        {role.roleId &&
                                          rolesObj &&
                                          rolesObj[role.roleId]}
                                      </p>
                                    )
                                  )
                              )}
                            </div>
                          </div>
                          <div className="w-2/3 pl-4 py-2">
                            <p className="text-xs font-medium text-gray-700">
                              Programs
                            </p>
                            <div className="flex flex-wrap">
                              {map(
                                filter(
                                  userCompanies,
                                  (company) => company.id === id
                                ),
                                ({ programsNameList }, i) =>
                                  map(programsNameList, (program) => (
                                    <p
                                      key={i}
                                      className="w-1/2 text-xs font-medium text-gray-900"
                                    >
                                      {program}
                                    </p>
                                  ))
                              )}
                              {map(
                                filter(
                                  user.companiesList,
                                  ({ companyTopId }) => companyTopId === id
                                ),
                                ({ id }) =>
                                  map(
                                    filter(
                                      userCompanies,
                                      (company) => company.id === id
                                    ),
                                    ({ programsNameList }, i) =>
                                      map(programsNameList, (program) => (
                                        <p
                                          key={i}
                                          className="w-1/2 text-xs font-medium text-gray-900"
                                        >
                                          {program}
                                        </p>
                                      ))
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
