/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressObject,
    AddressObjectFromJSON,
    AddressObjectFromJSONTyped,
    AddressObjectToJSON,
    CcvResultCode,
    CcvResultCodeFromJSON,
    CcvResultCodeFromJSONTyped,
    CcvResultCodeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAuthorizationDetailsResponse
 */
export interface GetAuthorizationDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    terminalId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    subMerchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    merchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    cardType?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    cardHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    cardExpirationMonth?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    cardExpirationYear?: string;
    /**
     * 
     * @type {AddressObject}
     * @memberof GetAuthorizationDetailsResponse
     */
    billingAddress?: AddressObject;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    transactionType?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuthorizationDetailsResponse
     */
    localDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetAuthorizationDetailsResponse
     */
    transmissionDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetAuthorizationDetailsResponse
     */
    transactionAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    transactionCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    stan?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    merchantRefNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    merchantOrderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    merchantExternalReference?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    avsResponse?: string;
    /**
     * 
     * @type {CcvResultCode}
     * @memberof GetAuthorizationDetailsResponse
     */
    ccvResultCode?: CcvResultCode;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    banknetData?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    responseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    authId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    additionalResponseData?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationDetailsResponse
     */
    posEntryMode?: string;
}

export function GetAuthorizationDetailsResponseFromJSON(json: any): GetAuthorizationDetailsResponse {
    return GetAuthorizationDetailsResponseFromJSONTyped(json, false);
}

export function GetAuthorizationDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuthorizationDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'terminalId': !exists(json, 'terminal_id') ? undefined : json['terminal_id'],
        'subMerchantId': !exists(json, 'subMerchant_id') ? undefined : json['subMerchant_id'],
        'merchantName': !exists(json, 'merchant_name') ? undefined : json['merchant_name'],
        'cardType': !exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumber': !exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'cardHolder': !exists(json, 'cardHolder') ? undefined : json['cardHolder'],
        'cardExpirationMonth': !exists(json, 'cardExpirationMonth') ? undefined : json['cardExpirationMonth'],
        'cardExpirationYear': !exists(json, 'cardExpirationYear') ? undefined : json['cardExpirationYear'],
        'billingAddress': !exists(json, 'billingAddress') ? undefined : AddressObjectFromJSON(json['billingAddress']),
        'transactionType': !exists(json, 'transactionType') ? undefined : json['transactionType'],
        'localDate': !exists(json, 'localDate') ? undefined : (new Date(json['localDate'])),
        'transmissionDate': !exists(json, 'transmissionDate') ? undefined : (new Date(json['transmissionDate'])),
        'transactionAmount': !exists(json, 'transactionAmount') ? undefined : json['transactionAmount'],
        'transactionCurrency': !exists(json, 'transactionCurrency') ? undefined : json['transactionCurrency'],
        'stan': !exists(json, 'stan') ? undefined : json['stan'],
        'merchantRefNumber': !exists(json, 'merchantRefNumber') ? undefined : json['merchantRefNumber'],
        'merchantOrderNumber': !exists(json, 'merchantOrderNumber') ? undefined : json['merchantOrderNumber'],
        'merchantExternalReference': !exists(json, 'merchantExternalReference') ? undefined : json['merchantExternalReference'],
        'avsResponse': !exists(json, 'avsResponse') ? undefined : json['avsResponse'],
        'ccvResultCode': !exists(json, 'ccvResultCode') ? undefined : CcvResultCodeFromJSON(json['ccvResultCode']),
        'banknetData': !exists(json, 'banknetData') ? undefined : json['banknetData'],
        'responseCode': !exists(json, 'responseCode') ? undefined : json['responseCode'],
        'authId': !exists(json, 'authId') ? undefined : json['authId'],
        'additionalResponseData': !exists(json, 'additionalResponseData') ? undefined : json['additionalResponseData'],
        'posEntryMode': !exists(json, 'posEntryMode') ? undefined : json['posEntryMode'],
    };
}

export function GetAuthorizationDetailsResponseToJSON(value?: GetAuthorizationDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'terminal_id': value.terminalId,
        'subMerchant_id': value.subMerchantId,
        'merchant_name': value.merchantName,
        'cardType': value.cardType,
        'cardNumber': value.cardNumber,
        'cardHolder': value.cardHolder,
        'cardExpirationMonth': value.cardExpirationMonth,
        'cardExpirationYear': value.cardExpirationYear,
        'billingAddress': AddressObjectToJSON(value.billingAddress),
        'transactionType': value.transactionType,
        'localDate': value.localDate === undefined ? undefined : (value.localDate.toISOString()),
        'transmissionDate': value.transmissionDate === undefined ? undefined : (value.transmissionDate.toISOString()),
        'transactionAmount': value.transactionAmount,
        'transactionCurrency': value.transactionCurrency,
        'stan': value.stan,
        'merchantRefNumber': value.merchantRefNumber,
        'merchantOrderNumber': value.merchantOrderNumber,
        'merchantExternalReference': value.merchantExternalReference,
        'avsResponse': value.avsResponse,
        'ccvResultCode': CcvResultCodeToJSON(value.ccvResultCode),
        'banknetData': value.banknetData,
        'responseCode': value.responseCode,
        'authId': value.authId,
        'additionalResponseData': value.additionalResponseData,
        'posEntryMode': value.posEntryMode,
    };
}


