/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateIamRolePermissionRequest,
    CreateIamRolePermissionRequestFromJSON,
    CreateIamRolePermissionRequestToJSON,
    CreateIamRolePermissionResponse,
    CreateIamRolePermissionResponseFromJSON,
    CreateIamRolePermissionResponseToJSON,
    GetIamRolePermissionRequest,
    GetIamRolePermissionRequestFromJSON,
    GetIamRolePermissionRequestToJSON,
    GetIamRolePermissionResponse,
    GetIamRolePermissionResponseFromJSON,
    GetIamRolePermissionResponseToJSON,
    ListIamRolePermissionsRequest,
    ListIamRolePermissionsRequestFromJSON,
    ListIamRolePermissionsRequestToJSON,
    ListIamRolePermissionsResponse,
    ListIamRolePermissionsResponseFromJSON,
    ListIamRolePermissionsResponseToJSON,
} from '../models';

export interface PostIamRolePermissionGetRequest {
    getIamRolePermissionRequest: GetIamRolePermissionRequest;
}

export interface PostIamRolePermissionsCreateRequest {
    createIamRolePermissionRequest: CreateIamRolePermissionRequest;
}

export interface PostIamRolePermissionsListRequest {
    listIamRolePermissionsRequest?: ListIamRolePermissionsRequest;
}

/**
 * 
 */
export class RolePermissionsApi extends runtime.BaseAPI {

    /**
     */
    async postIamRolePermissionGetRaw(requestParameters: PostIamRolePermissionGetRequest): Promise<runtime.ApiResponse<GetIamRolePermissionResponse>> {
        if (requestParameters.getIamRolePermissionRequest === null || requestParameters.getIamRolePermissionRequest === undefined) {
            throw new runtime.RequiredError('getIamRolePermissionRequest','Required parameter requestParameters.getIamRolePermissionRequest was null or undefined when calling postIamRolePermissionGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-role-permissions/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetIamRolePermissionRequestToJSON(requestParameters.getIamRolePermissionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIamRolePermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamRolePermissionGet(requestParameters: PostIamRolePermissionGetRequest): Promise<GetIamRolePermissionResponse> {
        const response = await this.postIamRolePermissionGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamRolePermissionsCreateRaw(requestParameters: PostIamRolePermissionsCreateRequest): Promise<runtime.ApiResponse<CreateIamRolePermissionResponse>> {
        if (requestParameters.createIamRolePermissionRequest === null || requestParameters.createIamRolePermissionRequest === undefined) {
            throw new runtime.RequiredError('createIamRolePermissionRequest','Required parameter requestParameters.createIamRolePermissionRequest was null or undefined when calling postIamRolePermissionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-role-permissions/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIamRolePermissionRequestToJSON(requestParameters.createIamRolePermissionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIamRolePermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamRolePermissionsCreate(requestParameters: PostIamRolePermissionsCreateRequest): Promise<CreateIamRolePermissionResponse> {
        const response = await this.postIamRolePermissionsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamRolePermissionsListRaw(requestParameters: PostIamRolePermissionsListRequest): Promise<runtime.ApiResponse<ListIamRolePermissionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-role-permissions/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListIamRolePermissionsRequestToJSON(requestParameters.listIamRolePermissionsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamRolePermissionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamRolePermissionsList(requestParameters: PostIamRolePermissionsListRequest): Promise<ListIamRolePermissionsResponse> {
        const response = await this.postIamRolePermissionsListRaw(requestParameters);
        return await response.value();
    }

}
