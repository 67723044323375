/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CardStatus {
    Created = 'CREATED',
    Active = 'ACTIVE',
    Closed = 'CLOSED',
    Lost = 'LOST',
    Stolen = 'STOLEN',
    Fraud = 'FRAUD',
    Inactive = 'INACTIVE',
    Canceled = 'CANCELED',
    Blocked = 'BLOCKED'
}

export function CardStatusFromJSON(json: any): CardStatus {
    return CardStatusFromJSONTyped(json, false);
}

export function CardStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardStatus {
    return json as CardStatus;
}

export function CardStatusToJSON(value?: CardStatus | null): any {
    return value as any;
}

