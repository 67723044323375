import React, { useContext } from 'react';
import {
  DropdownFilter,
  InputDropdown,
} from 'src/components/dropdown-filter/DropdownFilter';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { TransactionStatus } from '../../transaction';
import { TransactionStatusBadge } from '../TransactionStatusBadge';
import { ListItem } from 'src/components/dropdown-filter/ListItem';
import { Radio } from 'src/components/Radio';

export const FilterStatuses = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const statusItems = [
    TransactionStatus.APPROVED,
    TransactionStatus.REJECTED,
  ].map((item) => ({
    value: item,
    label: (
      <TransactionStatusBadge status={item}>
        {t(`transactionsManagement.transactions.status.${item}`)}
      </TransactionStatusBadge>
    ),
  }));

  const setStatusFilter = (status: TransactionStatus) =>
    dispatch({
      type: 'SET_STATUS_FILTER',
      status,
    });

  const clearFilter = () => dispatch({ type: 'RESET_STATUSES_FILTER' });

  return (
    <DropdownFilter
      title={t('kyc.frontTableColumns.status')}
      headerSecondaryLabel={t(
        'transactionsManagement.transactions.list.filters.numberSelected',
        { totalItems: filters.status ? 1 : 0 }
      )}
      onClearFilter={clearFilter}
      trigger={(dropdownProps) => (
        <InputDropdown
          name="statuses"
          label={t(
            'transactionsManagement.transactions.list.filters.transactionStatus'
          )}
          placeholder={t(
            'transactionsManagement.transactions.list.filters.all'
          )}
          value={
            filters.status
              ? t(
                  'transactionsManagement.transactions.list.filters.numberSelected',
                  { totalItems: 1 }
                )
              : ''
          }
          {...dropdownProps}
        />
      )}
    >
      {statusItems.map((item, key) => (
        <ListItem key={key} label={item.label}>
          <Radio
            checked={filters.status === item.value}
            onChange={() => setStatusFilter(item.value)}
          />
        </ListItem>
      ))}
    </DropdownFilter>
  );
};
