import React, { PropsWithoutRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as SBMPattern } from 'src/assets/icons/sbm-pattern-card.svg';
import { ReactComponent as SBMPatternMax } from 'src/assets/icons/sbm-pattern-card-max.svg';
import { TotalBalance } from 'src/domain/cards/components/total-balance/TotalBalance';

export interface CreditCardProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  issuer?: 'SBM' | 'BTC';
  pan?: string;
  cardholder?: string;
  balance?: number;
  type?: string;
  size?: 'max';
}

export const CreditCard = React.forwardRef<HTMLDivElement, CreditCardProps>(
  ({ cardholder, pan, type, balance, issuer = 'SBM', size, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames('relative block')}
        style={{ width: '19rem', height: '13rem' }}
      >
        <div className="absolute">
          {issuer === 'SBM' ? (
            size === 'max' ? (
              <SBMPatternMax />
            ) : (
              <SBMPattern />
            )
          ) : null}
        </div>
        {type && (
          <div
            className={classNames(
              'absolute font-medium text-xs capitalize leading-4 tracking-tight text-white left-5 font-din'
            )}
            style={{ top: '30px', right: '30px' }}
          >
            {type?.toLowerCase()}
          </div>
        )}
        {balance && (
          <div
            className={classNames(
              'absolute font-medium leading-4 text-white left-5 font-din'
            )}
            style={{ bottom: '100px', left: '20px' }}
          >
            <div className="text-xs pb-3 text-white opacity-50">Balance</div>
            <div className="text-2xl text-white font-semibold">
              <TotalBalance cardBalance simple balance={balance} />
            </div>
          </div>
        )}
        {pan && (
          <div
            className={classNames(
              'absolute font-medium leading-4 tracking-tight text-white left-5 font-din',
              [size === 'max' ? 'bottom-12 text-base' : 'bottom-10 text-2sm']
            )}
            style={{ bottom: '50px', left: '20px' }}
          >
            •••• {pan}
          </div>
        )}
        <div
          className={classNames(
            'absolute font-medium leading-3 text-white uppercase bottom-5 left-5 font-din',
            [size === 'max' ? 'text-2sm' : 'text-xs']
          )}
          style={{ bottom: '30px', left: '20px' }}
        >
          {cardholder}
        </div>
      </div>
    );
  }
);
