/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateIamPermissionRequest,
    CreateIamPermissionRequestFromJSON,
    CreateIamPermissionRequestToJSON,
    CreateIamPermissionResponse,
    CreateIamPermissionResponseFromJSON,
    CreateIamPermissionResponseToJSON,
    GetIamPermissionRequest,
    GetIamPermissionRequestFromJSON,
    GetIamPermissionRequestToJSON,
    GetIamPermissionResponse,
    GetIamPermissionResponseFromJSON,
    GetIamPermissionResponseToJSON,
    ListIamPermissionsRequest,
    ListIamPermissionsRequestFromJSON,
    ListIamPermissionsRequestToJSON,
    ListIamPermissionsResponse,
    ListIamPermissionsResponseFromJSON,
    ListIamPermissionsResponseToJSON,
} from '../models';

export interface PostIamPermissionGetRequest {
    getIamPermissionRequest: GetIamPermissionRequest;
}

export interface PostIamPermissionsCreateRequest {
    createIamPermissionRequest: CreateIamPermissionRequest;
}

export interface PostIamPermissionsListRequest {
    listIamPermissionsRequest?: ListIamPermissionsRequest;
}

/**
 * 
 */
export class PermissionsApi extends runtime.BaseAPI {

    /**
     */
    async postIamPermissionGetRaw(requestParameters: PostIamPermissionGetRequest): Promise<runtime.ApiResponse<GetIamPermissionResponse>> {
        if (requestParameters.getIamPermissionRequest === null || requestParameters.getIamPermissionRequest === undefined) {
            throw new runtime.RequiredError('getIamPermissionRequest','Required parameter requestParameters.getIamPermissionRequest was null or undefined when calling postIamPermissionGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-permissions/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetIamPermissionRequestToJSON(requestParameters.getIamPermissionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIamPermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamPermissionGet(requestParameters: PostIamPermissionGetRequest): Promise<GetIamPermissionResponse> {
        const response = await this.postIamPermissionGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamPermissionsCreateRaw(requestParameters: PostIamPermissionsCreateRequest): Promise<runtime.ApiResponse<CreateIamPermissionResponse>> {
        if (requestParameters.createIamPermissionRequest === null || requestParameters.createIamPermissionRequest === undefined) {
            throw new runtime.RequiredError('createIamPermissionRequest','Required parameter requestParameters.createIamPermissionRequest was null or undefined when calling postIamPermissionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-permissions/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIamPermissionRequestToJSON(requestParameters.createIamPermissionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIamPermissionResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamPermissionsCreate(requestParameters: PostIamPermissionsCreateRequest): Promise<CreateIamPermissionResponse> {
        const response = await this.postIamPermissionsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamPermissionsListRaw(requestParameters: PostIamPermissionsListRequest): Promise<runtime.ApiResponse<ListIamPermissionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-permissions/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListIamPermissionsRequestToJSON(requestParameters.listIamPermissionsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamPermissionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamPermissionsList(requestParameters: PostIamPermissionsListRequest): Promise<ListIamPermissionsResponse> {
        const response = await this.postIamPermissionsListRaw(requestParameters);
        return await response.value();
    }

}
