import React, { useMemo } from 'react';
import { CellLight, Column } from 'src/components/table/Table';
import { IamUser } from 'src/api/client';
import moment from 'moment';

export const useColumns = () => {
  const columns = useMemo(
    (): Column[] => [
      {
        id: 'id',
        value: (item: IamUser) => <CellLight>{item.id}</CellLight>,
        label: 'ID',
      },
      {
        id: 'email',
        value: (item: IamUser) => <CellLight>{item.username}</CellLight>,
        label: 'Email',
      },
      {
        id: 'name',
        value: (item: IamUser) => (
          <CellLight>
            {item.firstName} {item.lastName}
          </CellLight>
        ),
        label: 'Name',
      },
      {
        id: 'lastLoginAt',
        value: (item: IamUser) => (
          <CellLight>
            <div>{moment(item.lastLoginAt).format('D MMM YYYY')}</div>
            <div>at {moment(item.lastLoginAt).format('HH:mm')}</div>
          </CellLight>
        ),
        label: 'Last Login Date',
      },
      {
        id: 'updatedAt',
        value: (item: IamUser) => (
          <CellLight>
            <div>{moment(item.updatedAt).format('D MMM YYYY')}</div>
            <div>at {moment(item.updatedAt).format('HH:mm')}</div>
          </CellLight>
        ),
        label: 'Disabled Date',
      },
    ],
    []
  );

  return columns;
};
// export const useColumns = () => {
//   const columns = useMemo(
//     (): Column[] =>
//       [
//         'User ID',
//         'User Email',
//         'Last login',
//         'Disabled date',
//       ].map((item) => ({
//         id: item,
//         value: (item: IamUser) => (
//           <CellLight>{faker.random.words(1)}</CellLight>
//         ),
//         label: item,
//       })),
//     []
//   );

//   return columns;
// };
