/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Page,
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListTransactionsPageResponse
 */
export interface ListTransactionsPageResponse {
    /**
     * 
     * @type {Page}
     * @memberof ListTransactionsPageResponse
     */
    paging?: Page;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof ListTransactionsPageResponse
     */
    data?: Array<Transaction>;
}

export function ListTransactionsPageResponseFromJSON(json: any): ListTransactionsPageResponse {
    return ListTransactionsPageResponseFromJSONTyped(json, false);
}

export function ListTransactionsPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTransactionsPageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': !exists(json, 'paging') ? undefined : PageFromJSON(json['paging']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(TransactionFromJSON)),
    };
}

export function ListTransactionsPageResponseToJSON(value?: ListTransactionsPageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': PageToJSON(value.paging),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(TransactionToJSON)),
    };
}


