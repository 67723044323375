import React from 'react';
import { HorizontalDivider } from 'src/components/HorizontalDivider';
import { useTranslation } from 'react-i18next';
import { AddRoleFields } from 'src/domain/access-management/roles-and-permissions/tabs/rules/add-role/AddRoleModal';

type Props = Partial<Pick<AddRoleFields, 'name' | 'description' | 'channel'>>;

export const FormOnePreview = ({ name, description, channel }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={'mb-6'}>
      <div className="flex mb-4">
        <div
          className="flex flex-grow w-1/3 text-gray-700 text-xs font-medium whitespace-pre-line"
          style={{ wordBreak: 'break-word' }}
        >
          {t(
            'accessManagement.rolesAndPermissions.role.formItems.name.preview'
          )}
        </div>
        <div
          className="flex flex-grow w-1/2 text-xs font-medium whitespace-pre-line"
          style={{ wordBreak: 'break-word' }}
        >
          {name}
        </div>
      </div>
      <div className="flex mb-4">
        <div
          className="flex flex-grow w-1/3 text-gray-700 text-xs font-medium whitespace-pre-line"
          style={{ wordBreak: 'break-word' }}
        >
          {t(
            'accessManagement.rolesAndPermissions.role.formItems.description.preview'
          )}
        </div>
        <div
          className="flex flex-grow w-1/2 text-gray-700 text-xs font-medium whitespace-pre-line"
          style={{ wordBreak: 'break-word' }}
        >
          {description}
        </div>
      </div>
      <div className="flex mb-5">
        <div
          className="flex flex-grow w-1/3 text-gray-700 text-xs font-medium whitespace-pre-line"
          style={{ wordBreak: 'break-word' }}
        >
          {t(
            'accessManagement.rolesAndPermissions.role.formItems.channel.label'
          )}
        </div>
        <div
          className="flex flex-grow w-1/2 text-gray-700 text-xs font-medium  whitespace-pre-line"
          style={{ wordBreak: 'break-word' }}
        >
          {channel?.label}
        </div>
      </div>
      <HorizontalDivider />
    </div>
  );
};
