/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamUserType,
    IamUserTypeFromJSON,
    IamUserTypeFromJSONTyped,
    IamUserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListIamUserTypesResponse
 */
export interface ListIamUserTypesResponse {
    /**
     * 
     * @type {Array<IamUserType>}
     * @memberof ListIamUserTypesResponse
     */
    types?: Array<IamUserType>;
}

export function ListIamUserTypesResponseFromJSON(json: any): ListIamUserTypesResponse {
    return ListIamUserTypesResponseFromJSONTyped(json, false);
}

export function ListIamUserTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamUserTypesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': !exists(json, 'types') ? undefined : ((json['types'] as Array<any>).map(IamUserTypeFromJSON)),
    };
}

export function ListIamUserTypesResponseToJSON(value?: ListIamUserTypesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'types': value.types === undefined ? undefined : ((value.types as Array<any>).map(IamUserTypeToJSON)),
    };
}


