/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductCategory,
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListProductCategoriesResponse
 */
export interface ListProductCategoriesResponse {
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof ListProductCategoriesResponse
     */
    productCategories?: Array<ProductCategory>;
}

export function ListProductCategoriesResponseFromJSON(json: any): ListProductCategoriesResponse {
    return ListProductCategoriesResponseFromJSONTyped(json, false);
}

export function ListProductCategoriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProductCategoriesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productCategories': !exists(json, 'product_categories') ? undefined : ((json['product_categories'] as Array<any>).map(ProductCategoryFromJSON)),
    };
}

export function ListProductCategoriesResponseToJSON(value?: ListProductCategoriesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_categories': value.productCategories === undefined ? undefined : ((value.productCategories as Array<any>).map(ProductCategoryToJSON)),
    };
}


