/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamUserCompaniesList,
    IamUserCompaniesListFromJSON,
    IamUserCompaniesListFromJSONTyped,
    IamUserCompaniesListToJSON,
    IamUserStatus,
    IamUserStatusFromJSON,
    IamUserStatusFromJSONTyped,
    IamUserStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface IamUser
 */
export interface IamUser {
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof IamUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    personId?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    userTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    securityProfileId?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    profileDetailsId?: string;
    /**
     * 
     * @type {IamUserStatus}
     * @memberof IamUser
     */
    status?: IamUserStatus;
    /**
     * 
     * @type {Date}
     * @memberof IamUser
     */
    statusDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    identityTokenId?: string;
    /**
     * 
     * @type {Array<IamUserCompaniesList>}
     * @memberof IamUser
     */
    companiesList?: Array<IamUserCompaniesList>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IamUser
     */
    programsNameList?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IamUser
     */
    rolesNameList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    orgUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    directSupervisor?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUser
     */
    accessChannelId?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamUser
     */
    lastLoginAt?: Date;
}

export function IamUserFromJSON(json: any): IamUser {
    return IamUserFromJSONTyped(json, false);
}

export function IamUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): IamUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'updatedBy': !exists(json, 'updated_by') ? undefined : json['updated_by'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'personId': !exists(json, 'person_id') ? undefined : json['person_id'],
        'userTypeId': !exists(json, 'user_type_id') ? undefined : json['user_type_id'],
        'securityProfileId': !exists(json, 'security_profile_id') ? undefined : json['security_profile_id'],
        'profileDetailsId': !exists(json, 'profile_details_id') ? undefined : json['profile_details_id'],
        'status': !exists(json, 'status') ? undefined : IamUserStatusFromJSON(json['status']),
        'statusDate': !exists(json, 'status_date') ? undefined : (new Date(json['status_date'])),
        'identityTokenId': !exists(json, 'identity_token_id') ? undefined : json['identity_token_id'],
        'companiesList': !exists(json, 'companies_list') ? undefined : ((json['companies_list'] as Array<any>).map(IamUserCompaniesListFromJSON)),
        'programsNameList': !exists(json, 'programs_name_list') ? undefined : json['programs_name_list'],
        'rolesNameList': !exists(json, 'roles_name_list') ? undefined : json['roles_name_list'],
        'typeName': !exists(json, 'type_name') ? undefined : json['type_name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'orgUnit': !exists(json, 'org_unit') ? undefined : json['org_unit'],
        'directSupervisor': !exists(json, 'direct_supervisor') ? undefined : json['direct_supervisor'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'accessChannelId': !exists(json, 'access_channel_id') ? undefined : json['access_channel_id'],
        'lastLoginAt': !exists(json, 'last_login_at') ? undefined : (new Date(json['last_login_at'])),
    };
}

export function IamUserToJSON(value?: IamUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updated_by': value.updatedBy,
        'username': value.username,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'phone_number': value.phoneNumber,
        'person_id': value.personId,
        'user_type_id': value.userTypeId,
        'security_profile_id': value.securityProfileId,
        'profile_details_id': value.profileDetailsId,
        'status': IamUserStatusToJSON(value.status),
        'status_date': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
        'identity_token_id': value.identityTokenId,
        'companies_list': value.companiesList === undefined ? undefined : ((value.companiesList as Array<any>).map(IamUserCompaniesListToJSON)),
        'programs_name_list': value.programsNameList,
        'roles_name_list': value.rolesNameList,
        'type_name': value.typeName,
        'title': value.title,
        'company': value.company,
        'org_unit': value.orgUnit,
        'direct_supervisor': value.directSupervisor,
        'notes': value.notes,
        'access_channel_id': value.accessChannelId,
        'last_login_at': value.lastLoginAt === undefined ? undefined : (value.lastLoginAt.toISOString()),
    };
}


