import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import moment from 'moment';

import { Column, Sorting, Table } from 'src/components/table/Table';
import { StatusBadge, StatusBadgeType } from 'src/components/StatusBadge';
import { usersApi } from 'src/api';
import sortBy from 'lodash/sortBy';
import { toUpper } from 'lodash';

export enum UserStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  DELETED = 'DELETED',
}

export const getUserStatusBadge = (status: UserStatus): StatusBadgeType => {
  switch (status) {
    case UserStatus.ACTIVE:
      return StatusBadgeType.SUCCESS;
    case UserStatus.PENDING:
      return StatusBadgeType.SECONDARY;
    case UserStatus.SUSPENDED:
      return StatusBadgeType.WARNING;
    default:
      return StatusBadgeType.PRIMARY;
  }
};

export const useAssignedUsersColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    (): Column[] => [
      {
        id: 'createdAt',
        value: (item) => (
          <>
            <div className="text-xs font-medium text-gray-900">
              {moment(item.createdAt).format('DD MMM YYYY')}
            </div>
            <div className="text-xxs text-gray-700">
              at {moment(item.createdAt).format('HH:mm')}
            </div>
          </>
        ),
        label: t(
          'accessManagement.rolesAndPermissions.role.assignedUsers.dateAssigned'
        ),
        width: 200,
        columnFilter: () => null,
        sortable: true,
      },
      {
        id: 'firstName',
        value: (item) => (
          <span className="text-xs font-semibold text-gray-900">
            {item.firstName} {item.lastName}
          </span>
        ),
        label: t(
          'accessManagement.rolesAndPermissions.role.assignedUsers.user'
        ),
        columnFilter: () => null,
        sortable: true,
      },
      {
        id: 'status',
        value: (item) => (
          <StatusBadge
            variant={getUserStatusBadge(toUpper(item.status) as UserStatus)}
          >
            {t(`accessManagement.userManagement.status.${item.status}`)}
          </StatusBadge>
        ),
        label: t(
          'accessManagement.rolesAndPermissions.role.assignedUsers.userStatus'
        ),
        width: 200,
        columnFilter: () => null,
        sortable: true,
      },
    ],
    [t]
  );
};

export const AssignedUsers = ({ roleId }: { roleId: string }) => {
  const { t } = useTranslation();

  const [columnSorting, setColumnSorting] = useState<Sorting | undefined>(
    undefined
  );
  const [sortedItems, setSortedItems] = useState<any[]>();

  const tableRef = useRef<HTMLDivElement>(null);
  const columns = useAssignedUsersColumns();


  const {
    data: listData,
    isLoading,
  } = useInfiniteQuery(
    [roleId],
    () => {
      return usersApi.postIamUsersList({
        listIamUsersRequest: {
          iamRoleId: roleId,
        },
      });
    },
    {
      retry: false,
      getNextPageParam: (lastPage, allPages) => allPages.length < 5,
      onError: (err) => console.log(err),
    }
  );

  const items = useMemo(() => {
    return (
      listData?.pages
        .map((groupItem) =>
          groupItem?.iamUsers?.map((user) => ({
            ...user,
          }))
        )
        .flat() || []
    );
  }, [listData]);


  const handleColumnSorting = useCallback(
    (sorting?: Sorting) => {
      if (isLoading) {
        return;
      }

      setColumnSorting(sorting);
    },
    [isLoading]
  );

  useEffect(() => {
    if (!columnSorting) {
      return;
    }

    let sorted = [];

    if (columnSorting.column?.id === 'createdAt') {
      sorted = sortBy(items, [
        (o) => moment(o['createdAt']).format('YYYYMMDDHHmm'),
      ]);
    } else {
      sorted = sortBy(items as any, [
        (o) =>
          o[columnSorting.column?.id || '']
            ? o[columnSorting.column?.id || ''].toString().toLowerCase()
            : null,
      ]);
    }

    setSortedItems(
      columnSorting.direction === 'ASC' ? sorted : sorted.reverse()
    );
  }, [items, columnSorting]);

  return (
    <div>
      <h2 className="text-base font-bold leading-none text-gray-900">
        {t('accessManagement.rolesAndPermissions.roleDetails.assignedUsers')}
      </h2>

      <div
        ref={tableRef}
        className="h-full max-h-full overflow-x-auto my-6 overflow-y-scroll"
        style={{
          boxShadow:
            '0px -4px 12px rgba(47, 50, 58, 0.1), 0px 4px 20px rgba(47, 50, 58, 0.1)',
        }}
      >
         <Table
          loading={isLoading}
          columns={columns}
          items={sortedItems || items}
          sorting={columnSorting}
          onSort={handleColumnSorting}
        />
      </div>
    </div>
  );
};
