import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  onClick: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
}

export const ButtonLink: FC<Props> = ({ onClick, className, children }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <a
      href={'!#'}
      onClick={handleClick}
      className={classNames('font-bold text-blue-500 text-xs leading-none')}
    >
      <span className={classNames(className)}>{children}</span>
    </a>
  );
};
