/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PersonStatus {
    Active = 'ACTIVE',
    Pfraud = 'PFRAUD',
    Unverified = 'UNVERIFIED',
    Sanctioned = 'SANCTIONED',
    Pep = 'PEP',
    Neglist = 'NEGLIST',
    Failed = 'FAILED'
}

export function PersonStatusFromJSON(json: any): PersonStatus {
    return PersonStatusFromJSONTyped(json, false);
}

export function PersonStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonStatus {
    return json as PersonStatus;
}

export function PersonStatusToJSON(value?: PersonStatus | null): any {
    return value as any;
}

