import React, { useContext, useMemo } from 'react';
import { FilterStatuses } from './FilterStatuses';
import { FilterCardLastFour } from './FilterCardLastFour';
import { FilterDisputeAmount } from './FilterDisputeAmount';
import { FilterCaseId } from './FilterCaseId';
import { Button } from 'src/components/Button';
import { ListContext, defaultFilters } from '../context';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { FilterReferenceNumber } from './FilterReferenceNumber';

export const Filters = () => {
  const { dispatch, filters, appliedFilters } = useContext(ListContext);

  const { t } = useTranslation();

  const filtersApplied = useMemo(() => {
    return isEqual(filters, appliedFilters);
  }, [filters, appliedFilters]);

  const filtersInDefaultState = useMemo(() => {
    return isEqual(filters, defaultFilters);
  }, [filters]);

  return (
    <div className="grid grid-cols-6 gap-5 row-gap-2">
      <div>
        <FilterCaseId />
      </div>
      <div>
        <FilterCardLastFour />
      </div>
      <div>
        <FilterDisputeAmount />
      </div>
      <div>
        <FilterReferenceNumber />
      </div>
      <div>
        <FilterStatuses />
      </div>
      <div className="flex items-end">
        <div className="w-px h-8 mr-3 bg-gray-300" />
        <Button
          className="w-1/2 mr-3"
          onClick={() => dispatch({ type: 'APPLY_FILTERS' })}
          disabled
          // disabled={filtersApplied}
          variant={filtersApplied ? 'secondary' : 'primary'}
        >
          {t('transactionsManagement.transactions.list.filters.apply')}
        </Button>
        <Button
          className="w-1/2"
          variant="secondary"
          onClick={() => dispatch({ type: 'RESET_ALL_FILTERS' })}
          disabled
          // disabled={filtersInDefaultState}
        >
          {t('transactionsManagement.transactions.list.filters.clearAll')}
        </Button>
      </div>
    </div>
  );
};
