/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamSecurityProfiles,
    IamSecurityProfilesFromJSON,
    IamSecurityProfilesFromJSONTyped,
    IamSecurityProfilesToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListIamSecurityProfilesResponse
 */
export interface ListIamSecurityProfilesResponse {
    /**
     * 
     * @type {Array<IamSecurityProfiles>}
     * @memberof ListIamSecurityProfilesResponse
     */
    iamSecurityProfiles?: Array<IamSecurityProfiles>;
}

export function ListIamSecurityProfilesResponseFromJSON(json: any): ListIamSecurityProfilesResponse {
    return ListIamSecurityProfilesResponseFromJSONTyped(json, false);
}

export function ListIamSecurityProfilesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamSecurityProfilesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamSecurityProfiles': !exists(json, 'iamSecurityProfiles') ? undefined : ((json['iamSecurityProfiles'] as Array<any>).map(IamSecurityProfilesFromJSON)),
    };
}

export function ListIamSecurityProfilesResponseToJSON(value?: ListIamSecurityProfilesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iamSecurityProfiles': value.iamSecurityProfiles === undefined ? undefined : ((value.iamSecurityProfiles as Array<any>).map(IamSecurityProfilesToJSON)),
    };
}


