/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CcvResultCode {
    ValuesMatch = 'VALUES_MATCH',
    ValuesDoNotMatch = 'VALUES_DO_NOT_MATCH',
    NotProcessed = 'NOT_PROCESSED',
    ValueNotProvided = 'VALUE_NOT_PROVIDED',
    IssuerNotParticipating = 'ISSUER_NOT_PARTICIPATING',
    Unknown = 'UNKNOWN'
}

export function CcvResultCodeFromJSON(json: any): CcvResultCode {
    return CcvResultCodeFromJSONTyped(json, false);
}

export function CcvResultCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CcvResultCode {
    return json as CcvResultCode;
}

export function CcvResultCodeToJSON(value?: CcvResultCode | null): any {
    return value as any;
}

