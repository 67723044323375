import { createContext } from 'react';
import { AcquiringAuditLogResponse } from 'src/api/client';

export interface Log extends AcquiringAuditLogResponse {}

export const initialState = {
  item: undefined as Log | undefined,
  itemLoading: false,
  itemNotFound: false,
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_ITEM'; item: Log }
  | { type: 'SET_ITEM_LOADING'; loading: boolean }
  | { type: 'SET_ITEM_NOT_FOUND'; notFound: boolean };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_ITEM':
      return { ...state, item: action.item };
    case 'SET_ITEM_NOT_FOUND':
      return {
        ...state,
        itemNotFound: action.notFound,
      };
    case 'SET_ITEM_LOADING':
      return { ...state, itemLoading: action.loading };
    default:
      throw new Error();
  }
};

export const DetailsContext = createContext<typeof initialState>(initialState);
