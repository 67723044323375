import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import get from 'lodash/get';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { PasswordInput } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { oneLowerCase, oneDigit, oneUpperCase } from 'src/validation/patterns';
import classNames from 'classnames';
import { ReactComponent as IconSuccess } from 'src/assets/icons/iconSuccess.svg';
import { Modal } from 'src/components/Modal';
import find from 'lodash/find';

const PasswordRule = ({
  isValid,
  ruleName,
}: {
  isValid: boolean;
  ruleName: string;
}) => (
  <div
    className={classNames('flex w-1/2 pb-1', {
      'text-gray-900': isValid,
    })}
  >
    <div
      className={classNames('bg-gray-300 rounded-full mr-2', {
        'bg-green-500': isValid,
      })}
      style={{ flex: '0 0 6px', height: '6px', marginTop: '6px' }}
    />
    {ruleName}
  </div>
);

export const FormChangePassword = () => {
  const { t } = useTranslation();

  const schema = useMemo(() => {
    return yup.object().shape({
      oldPassword: yup
        .string()
        .min(10, t('validations.minLength', { limit: 10 }))
        .max(40, t('validations.maxLength', { limit: 40 }))
        .matches(oneUpperCase, t('validations.oneUpperCase'))
        .matches(oneLowerCase, t('validations.oneLowerCase'))
        .matches(oneDigit, t('validations.oneDigit'))
        .required(t('validations.required')),
      newPassword: yup
        .string()
        .min(10, t('validations.minLength', { limit: 10 }))
        .max(40, t('validations.maxLength', { limit: 40 }))
        .matches(oneUpperCase, t('validations.oneUpperCase'))
        .matches(oneLowerCase, t('validations.oneLowerCase'))
        .matches(oneDigit, t('validations.oneDigit'))
        .required(t('validations.required')),
    });
  }, [t]);

  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  const formValues = watch();

  const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false);
  const [userAttributes, setUserAttributes] = useState<{
    pwdLastChange?: string;
  }>({});

  const setChangedTime = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, {
        'custom:pwdLastChange':
          moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss') + ' UTC',
      });
    } catch (e) {}
  }, []);

  const onSubmitCallback = async (data: any) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, data.oldPassword, data.newPassword);
      setIsVisibleSuccessModal(true);
      reset({ newPassword: '', oldPassword: '' });
      await setChangedTime();
      getAttr();
    } catch (e) {
      setError('oldPassword', {
        type: 'manual',
        message:
          get(e, 'message') === 'Incorrect username or password.'
            ? 'Incorrect password'
            : get(e, 'message'),
      });
    }
  };

  const getAttr = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const data = await Auth.userAttributes(cognitoUser);
      setUserAttributes({
        pwdLastChange: get(
          find(data, (item) => item.Name === 'custom:pwdLastChange'),
          'Value'
        ),
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getAttr();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)} className="w-full">
      <div className="font-semibold text-lg mb-3">Change Password</div>
      {userAttributes.pwdLastChange && (
        <div className="-mt-2 mb-3 text-gray-700 text-ssm">
          Your password has been last changed&nbsp;
          {moment(new Date(userAttributes.pwdLastChange)).fromNow()}
        </div>
      )}
      <div className="pb-2">
        <PasswordInput
          error={errors?.oldPassword}
          name="oldPassword"
          label={t('auth.oldPassword')}
          inputRef={register({ required: true })}
        />
      </div>
      <div className="mt-5">
        <input type="text" className="absolute" style={{ left: '-9999px' }} />
        <PasswordInput
          error={errors?.newPassword}
          name="newPassword"
          label={t('auth.newPassword')}
          inputRef={register({ required: true })}
        />
      </div>

      <div className="mt-2 text-gray-700 text-xs">
        <p>Please follow all the requirements for the password</p>

        <div className="flex flex-wrap pt-3">
          <PasswordRule
            isValid={
              formValues.newPassword.length >= 10 &&
              formValues.newPassword.length <= 40
            }
            ruleName="10-40 characters"
          />
          <PasswordRule
            isValid={oneUpperCase.test(formValues.newPassword)}
            ruleName="ONE UPPERCASE letter"
          />
          <PasswordRule
            isValid={oneLowerCase.test(formValues.newPassword)}
            ruleName="one lowercase letter"
          />
          <PasswordRule
            isValid={oneDigit.test(formValues.newPassword)}
            ruleName="One number"
          />
        </div>
      </div>
      <div className="mt-10">
        <Button
          loading={formState.isSubmitting}
          type="submit"
          size="medium-large"
          widthClass="w-36"
          disabled={formState.isSubmitting}
        >
          {t('auth.changePassword')}
        </Button>
      </div>

      <Modal
        isOpen={isVisibleSuccessModal}
        closeModal={() => {
          setIsVisibleSuccessModal(false);
        }}
        hideCross
        isOverlayDisabled
        maxWidth="auto"
        width="510px"
      >
        <div>
          <div className="my-6 flex justify-center">
            <IconSuccess />
          </div>
          <h3 className="mb-4 text-2xl font-semibold text-gray-900 text-center">
            Successful Password Change
          </h3>

          <div className="px-5 pb-5">
            <Button
              type="button"
              size="medium-large"
              widthClass="w-full"
              onClick={() => {
                setIsVisibleSuccessModal(false);
              }}
            >
              {t('auth.ok')}
            </Button>
          </div>
        </div>
      </Modal>
    </form>
  );
};
