import { createContext } from 'react';
import { Sorting } from 'src/components/table/Table';
import { RowsPerPage } from './rowsPerPage';
import { TransactionType } from '../authorization';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import {
  addFilterToState,
  removeFilterFromState,
  resetFilterInState,
} from 'src/utils/filters';
import { DateRange } from 'src/types/dateRange';
import { CardType } from '../../transactions/transaction';

type ItemFilters = {
  merchants: ItemFilter[];
  transactionTypes: ItemFilter[];
  cardTypes: ItemFilter[];
  ccvResult: ItemFilter[];
  avsResponse: ItemFilter[];
};

type MiscFilters = {
  cardLastFour: string;
  authorizationCode: string;
  stan: string;
  nameOnCard: string;
  merchantExternalReference: string;

  transactionDate: DateRange;
};

export enum AvsResponse {
  'Y' = 'Y',
  'N' = 'N',
  'Z' = 'Z',
}

export type ListFilters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {
  merchants: [],
  transactionTypes: [],
  cardTypes: [],
  ccvResult: [],
  avsResponse: [],
};

const miscFilters: MiscFilters = {
  cardLastFour: '',
  authorizationCode: '',
  stan: '',
  nameOnCard: '',
  merchantExternalReference: '',
  transactionDate: {
    from: null,
    to: null,
  },
};

const getItemFilters = (filters: ListFilters): ItemFilters =>
  Object.keys(itemFilters).reduce(
    (result, key) => ({
      ...result,
      [key]: filters[key as keyof ListFilters],
    }),
    {} as ItemFilters
  );

export const defaultFilters: ListFilters = {
  ...itemFilters,
  ...miscFilters,
};

export type AuthorizationItem = {
  id: string;
  localDate: string;
  transmissionDate: string;
  stan: string;
  merchantExternalReference: string;
  terminalId: string;
  merchantId: string;
  merchantName: string;
  posEntryMode: string;
  transactionAmount: number;
  transactionCurrency: string;
  cardType: CardType;
  responseCode: string;
  authId: string;
  transactionType: TransactionType;
  cardNumber: string;
};

export const initialState = {
  items: [] as AuthorizationItem[],
  itemsLoading: 0,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  rowsPerPage: 20 as RowsPerPage,
  filters: defaultFilters,
  appliedFilters: defaultFilters,
  downloadLoading: false,
  download: () => {},
  dispatch: (action: Actions) => {},
};

export type Actions =
  | { type: 'SET_ITEMS'; items: AuthorizationItem[] }
  | { type: 'APPEND_ITEMS'; items: AuthorizationItem[] }
  | { type: 'SET_COLUMN_SORTING'; columnSorting?: Sorting }
  | { type: 'INCREMENT_ITEMS_LOADING' }
  | { type: 'DECREMENT_ITEMS_LOADING' }
  | { type: 'SET_PAGINATION_LOADING'; loading: boolean }
  | { type: 'SET_DOWNLOAD_LOADING'; loading: boolean }
  | { type: 'SET_END_REACHED'; endReached: boolean }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_SEARCH_QUERY'; searchQuery: string }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: RowsPerPage }
  | { type: 'ADD_MERCHANTS_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_MERCHANTS_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_MERCHANTS_FILTER' }
  | { type: 'ADD_TRANSACTION_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_TRANSACTION_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_TRANSACTION_TYPE_FILTER' }
  | { type: 'ADD_CARD_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_CARD_TYPE_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_CARD_TYPE_FILTER' }
  | { type: 'SET_CARD_LAST_FOUR_FILTER'; cardLastFour: string }
  | { type: 'SET_AUTHORIZATION_CODE_FILTER'; authorizationCode: string }
  | { type: 'SET_STAN_FILTER'; stan: string }
  | {
      type: 'SET_MERCHANT_EXTERNAL_REFERENCE_FILTER';
      merchantExternalReference: string;
    }
  | { type: 'SET_NAME_ON_CARD_FILTER'; nameOnCard: string }
  | { type: 'SET_SETTLEMENT_DATE_FILTER'; dateRange: DateRange }
  | { type: 'RESET_SETTLEMENT_DATE_FILTER' }
  | { type: 'SET_TRANSACTION_DATE_FILTER'; dateRange: DateRange }
  | { type: 'RESET_TRANSACTION_DATE_FILTER' }
  | { type: 'ADD_CCV_RESULT_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_CCV_RESULT_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_CCV_RESULT_FILTER' }
  | { type: 'ADD_AVS_RESPONSE_FILTER'; itemFilter: ItemFilter }
  | { type: 'REMOVE_AVS_RESPONSE_FILTER'; itemFilter: ItemFilter }
  | { type: 'RESET_AVS_RESPONSE_FILTER' }
  | { type: 'APPLY_FILTERS' }
  | { type: 'RESET_ALL_FILTERS' };

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_ITEMS':
      return { ...state, items: action.items };
    case 'APPEND_ITEMS':
      return { ...state, items: [...state.items, ...action.items] };
    case 'INCREMENT_ITEMS_LOADING':
      return { ...state, itemsLoading: state.itemsLoading + 1 };
    case 'DECREMENT_ITEMS_LOADING':
      return {
        ...state,
        itemsLoading: state.itemsLoading > 0 ? state.itemsLoading - 1 : 0,
      };
    case 'SET_PAGINATION_LOADING':
      return { ...state, paginationLoading: action.loading };
    case 'SET_END_REACHED':
      return { ...state, endReached: action.endReached };
    case 'SET_PAGE':
      return { ...state, page: action.page };
    case 'SET_ROWS_PER_PAGE':
      return { ...state, rowsPerPage: action.rowsPerPage };
    case 'ADD_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_MERCHANTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'merchants',
          }),
        },
      };
    case 'ADD_TRANSACTION_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'transactionTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_TRANSACTION_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'transactionTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_TRANSACTION_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'transactionTypes',
          }),
        },
      };
    case 'ADD_CARD_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'cardTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_CARD_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'cardTypes',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_CARD_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'cardTypes',
          }),
        },
      };
    case 'ADD_CCV_RESULT_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'ccvResult',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_CCV_RESULT_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'ccvResult',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_CCV_RESULT_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'ccvResult',
          }),
        },
      };
    case 'ADD_AVS_RESPONSE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...addFilterToState({
            filters: getItemFilters(state.filters),
            filterKey: 'avsResponse',
            payload: action.itemFilter,
          }),
        },
      };
    case 'REMOVE_AVS_RESPONSE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...removeFilterFromState({
            filters: getItemFilters(state.filters),
            filterKey: 'avsResponse',
            payload: action.itemFilter,
          }),
        },
      };
    case 'RESET_AVS_RESPONSE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...resetFilterInState({
            filters: getItemFilters(state.filters),
            filterKey: 'avsResponse',
          }),
        },
      };
    case 'SET_CARD_LAST_FOUR_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          cardLastFour: action.cardLastFour,
        },
      };
    }
    case 'SET_NAME_ON_CARD_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          nameOnCard: action.nameOnCard,
        },
      };
    }
    case 'SET_MERCHANT_EXTERNAL_REFERENCE_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          merchantExternalReference: action.merchantExternalReference,
        },
      };
    }
    case 'SET_AUTHORIZATION_CODE_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          authorizationCode: action.authorizationCode,
        },
      };
    }
    case 'SET_STAN_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          stan: action.stan,
        },
      };
    }
    case 'SET_TRANSACTION_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          transactionDate: action.dateRange,
        },
      };
    case 'RESET_TRANSACTION_DATE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          transactionDate: {
            from: null,
            to: null,
          },
        },
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        appliedFilters: Object.assign({}, state.filters),
      };
    case 'RESET_ALL_FILTERS':
      return {
        ...state,
        filters: defaultFilters,
        appliedFilters: defaultFilters,
      };
    case 'SET_DOWNLOAD_LOADING':
      return { ...state, downloadLoading: action.loading };
    default:
      throw new Error();
  }
};

export const ListContext = createContext<typeof initialState>(initialState);
