import { AppThunk } from '../store';
// import { authResource } from 'src/api/auth';
// import { accountSlice } from './accountSlice';

export const fetchProfile = (): AppThunk => async dispatch => {
  try {
    // const { data } = await authResource.profile();
    //
    // dispatch(accountSlice.actions.setProfile(data));
  } catch (err) {}
};
