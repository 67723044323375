import React from 'react';
import { UsersList } from './UsersList';
import { Tab } from '../../../tabs/Tab';
import { TabsHeader } from '../../../tabs/TabsHeader';

export const UsersTabList = () => {

  return (
    <Tab
      header={
        <TabsHeader
          tabs={[
            {
              url: '/users',
              label: 'Users'
            },
          ]}
        />
      }
      content={<UsersList />}
    />
  );
};
