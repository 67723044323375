/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChannelProvider,
    ChannelProviderFromJSON,
    ChannelProviderFromJSONTyped,
    ChannelProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetChannelProviderResponse
 */
export interface GetChannelProviderResponse {
    /**
     * 
     * @type {ChannelProvider}
     * @memberof GetChannelProviderResponse
     */
    channelProvider?: ChannelProvider;
}

export function GetChannelProviderResponseFromJSON(json: any): GetChannelProviderResponse {
    return GetChannelProviderResponseFromJSONTyped(json, false);
}

export function GetChannelProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelProviderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelProvider': !exists(json, 'channel_provider') ? undefined : ChannelProviderFromJSON(json['channel_provider']),
    };
}

export function GetChannelProviderResponseToJSON(value?: GetChannelProviderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_provider': ChannelProviderToJSON(value.channelProvider),
    };
}


