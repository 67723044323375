/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateIamUserRoleRequest,
    CreateIamUserRoleRequestFromJSON,
    CreateIamUserRoleRequestToJSON,
    CreateIamUserRoleResponse,
    CreateIamUserRoleResponseFromJSON,
    CreateIamUserRoleResponseToJSON,
    GetIamUserRoleRequest,
    GetIamUserRoleRequestFromJSON,
    GetIamUserRoleRequestToJSON,
    GetIamUserRoleResponse,
    GetIamUserRoleResponseFromJSON,
    GetIamUserRoleResponseToJSON,
    ListIamUserRolesRequest,
    ListIamUserRolesRequestFromJSON,
    ListIamUserRolesRequestToJSON,
    ListIamUserRolesResponse,
    ListIamUserRolesResponseFromJSON,
    ListIamUserRolesResponseToJSON,
} from '../models';

export interface PostIamUserRolesCreateRequest {
    createIamUserRoleRequest: CreateIamUserRoleRequest;
}

export interface PostIamUserRolesGetRequest {
    getIamUserRoleRequest: GetIamUserRoleRequest;
}

export interface PostIamUserRolesListRequest {
    listIamUserRolesRequest?: ListIamUserRolesRequest;
}

/**
 * 
 */
export class UserRolesApi extends runtime.BaseAPI {

    /**
     */
    async postIamUserRolesCreateRaw(requestParameters: PostIamUserRolesCreateRequest): Promise<runtime.ApiResponse<CreateIamUserRoleResponse>> {
        if (requestParameters.createIamUserRoleRequest === null || requestParameters.createIamUserRoleRequest === undefined) {
            throw new runtime.RequiredError('createIamUserRoleRequest','Required parameter requestParameters.createIamUserRoleRequest was null or undefined when calling postIamUserRolesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-user-roles/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIamUserRoleRequestToJSON(requestParameters.createIamUserRoleRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIamUserRoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUserRolesCreate(requestParameters: PostIamUserRolesCreateRequest): Promise<CreateIamUserRoleResponse> {
        const response = await this.postIamUserRolesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUserRolesGetRaw(requestParameters: PostIamUserRolesGetRequest): Promise<runtime.ApiResponse<GetIamUserRoleResponse>> {
        if (requestParameters.getIamUserRoleRequest === null || requestParameters.getIamUserRoleRequest === undefined) {
            throw new runtime.RequiredError('getIamUserRoleRequest','Required parameter requestParameters.getIamUserRoleRequest was null or undefined when calling postIamUserRolesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-user-roles/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetIamUserRoleRequestToJSON(requestParameters.getIamUserRoleRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIamUserRoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUserRolesGet(requestParameters: PostIamUserRolesGetRequest): Promise<GetIamUserRoleResponse> {
        const response = await this.postIamUserRolesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postIamUserRolesListRaw(requestParameters: PostIamUserRolesListRequest): Promise<runtime.ApiResponse<ListIamUserRolesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-user-roles/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListIamUserRolesRequestToJSON(requestParameters.listIamUserRolesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamUserRolesResponseFromJSON(jsonValue));
    }

    /**
     */
    async postIamUserRolesList(requestParameters: PostIamUserRolesListRequest): Promise<ListIamUserRolesResponse> {
        const response = await this.postIamUserRolesListRaw(requestParameters);
        return await response.value();
    }

}
