import React from 'react';
import get from 'lodash/get';
import { store } from 'react-notifications-component';

import { assertEnvVar } from 'src/utils/assert';

import {
  AuthorizationsApi,
  ChargebacksApi,
  Configuration,
  MerchantsApi,
  TransactionsApi,
  CardsApi,
  RolesApi,
  RolePermissionsApi,
  PersonApi,
  ProfileApi,
  PermissionsApi,
  AdjustmentsApi,
  AcquiringApi,
  UsersApi,
  CompaniesApi,
  ProgramsApi,
  SecurityProfilesApi,
  UserRolesApi,
  AccessChannelApi,
  UserTypesApi,
} from './client';
import { logout } from '../auth';
import { showErrors } from 'src/utils/showErrors';
import { notifications } from 'src/utils/notifications';

assertEnvVar('REACT_APP_API_URL');

export let cardsApi: CardsApi;
export let rolesApi: RolesApi;
export let usersApi: UsersApi;
export let userTypesApi: UserTypesApi;
export let userRolesApi: UserRolesApi;
export let securityProfilesApi: SecurityProfilesApi;
export let companiesApi: CompaniesApi;
export let programsApi: ProgramsApi;
export let rolePermissionsApi: RolePermissionsApi;
export let transactionHistoryApi: TransactionsApi;
export let merchantsApi: MerchantsApi;
export let authorizationsApi: AuthorizationsApi;
export let chargebacksApi: ChargebacksApi;
export let personApi: PersonApi;
export let accessChannelApi: AccessChannelApi;
export let permissionsApi: PermissionsApi;
export let adjustmentsApi: AdjustmentsApi;
export let acquiringApi: AcquiringApi;
export let profileApi: ProfileApi;

enum UserStatus {
  USER_SUSPENDED = 'USER_SUSPENDED',
  USER_DELETED = 'USER_DELETED',
}

let lastUrlCall = '';

export const configureApi = ({ token }: { token: string }) => {
  if (!transactionHistoryApi) {
    const config = new Configuration({
      basePath: process.env.REACT_APP_API_URL,
      apiKey: () => `Bearer ${token}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      middleware: [
        {
          post: async (context) => {
            if (context.response.status === 401) {
              const response = await context.response.clone().json();

              if ([UserStatus.USER_SUSPENDED, UserStatus.USER_DELETED].includes(get(response, 'error[0].code'))) {
                window.location.replace('/no-access');
              } else {
                logout();
                window.location.replace('/login');
              }
            }

            if (!context.response.ok) {
              const response = await context.response.clone().json();

              if (lastUrlCall !== context.url) {
                if (Array.isArray(response.error)) {
                  showErrors(response.error);
                } else {
                  notifications.error({ message: response });
                }
              }

              lastUrlCall = context.url;
            }

            // if (response.error) {
            //   store.addNotification({
            //     message: <div>
            //       {Array.isArray(response.error) ? response.error.map((e: any, i: number) => (
            //         <div key={i}><b>{e.code}</b><br/> {e.message}, {e.details}</div>
            //       )) : 'Something went wrong'}
            //     </div>,
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animate__animated", "animate__fadeIn"],
            //     animationOut: ["animate__animated", "animate__fadeOut"],
            //     dismiss: {
            //       duration: 5000,
            //       onScreen: true
            //     }
            //   });
            // }

            return new Promise((resolve) => resolve(context.response));
          },
        },
      ],
    });

    transactionHistoryApi = new TransactionsApi(config);
    cardsApi = new CardsApi(config);
    rolesApi = new RolesApi(config);
    companiesApi = new CompaniesApi(config);
    programsApi = new ProgramsApi(config);
    usersApi = new UsersApi(config);
    userTypesApi = new UserTypesApi(config);
    userRolesApi = new UserRolesApi(config);
    securityProfilesApi = new SecurityProfilesApi(config);
    merchantsApi = new MerchantsApi(config);
    authorizationsApi = new AuthorizationsApi(config);
    chargebacksApi = new ChargebacksApi(config);
    personApi = new PersonApi(config);
    accessChannelApi = new AccessChannelApi(config);
    permissionsApi = new PermissionsApi(config);
    rolePermissionsApi = new RolePermissionsApi(config);
    adjustmentsApi = new AdjustmentsApi(config);
    acquiringApi = new AcquiringApi(config);
    profileApi = new ProfileApi(config);

    return {
      usersApi,
      profileApi
    };
  }
};

export const destroyApi = () => {
  transactionHistoryApi = null!;
  merchantsApi = null!;
  authorizationsApi = null!;
  chargebacksApi = null!;
  personApi = null!;
  programsApi = null!;
  usersApi = null!;
  userTypesApi = null!;
  userRolesApi = null!;
  securityProfilesApi = null!;
  companiesApi = null!;
  accessChannelApi = null!;
  permissionsApi = null!;
  rolePermissionsApi = null!;
  adjustmentsApi = null!;
  acquiringApi = null!;
  profileApi = null!;
};
