import React from 'react';
import { useTranslation } from 'react-i18next';

export const SomethingWrong = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <h1 className="mt-10 text-2xl font-semibold">
        {t('onboarding.somethingWentWrong')}
      </h1>
      <p className="max-w-sm mx-auto mt-4 text-sm text-gray-700">
        {t('onboarding.please')}{' '}
        <a
          href="https://help.iaecsp.org"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          {t('onboarding.contactSupport')}
        </a>
        .
      </p>
    </div>
  );
};
