import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { Input, onKeyPressAllowCharacters } from 'src/components/Input';
import { ListContext } from '../context';

export const FilterNameOnCard = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { register, watch, setValue } = useForm<{
    nameOnCard: string;
  }>();

  const { nameOnCard } = watch();

  const setFilter = useRef(
    debounce(
      (filter: string) =>
        dispatch({
          type: 'SET_NAME_ON_CARD_FILTER',
          nameOnCard: filter,
        }),
      300
    )
  );

  useEffect(() => {
    setFilter.current(nameOnCard);
  }, [setFilter, nameOnCard]);

  useEffect(() => {
    setValue('nameOnCard', filters.nameOnCard);
  }, [filters.nameOnCard, setValue]);

  return (
    <Input
      label={t('transactionsManagement.authorizations.list.filters.nameOnCard')}
      name="nameOnCard"
      labelFontClassName="text-xxs font-medium"
      labelColorClassName="text-gray-700"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressAllowCharacters}
      inputRef={register}
    />
  );
};
