/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListIamUserTypesRequest
 */
export interface ListIamUserTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof ListIamUserTypesRequest
     */
    accessChannelId?: string;
}

export function ListIamUserTypesRequestFromJSON(json: any): ListIamUserTypesRequest {
    return ListIamUserTypesRequestFromJSONTyped(json, false);
}

export function ListIamUserTypesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamUserTypesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessChannelId': !exists(json, 'access_channel_id') ? undefined : json['access_channel_id'],
    };
}

export function ListIamUserTypesRequestToJSON(value?: ListIamUserTypesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_channel_id': value.accessChannelId,
    };
}


