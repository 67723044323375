/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateProductRequest
 */
export interface CreateProductRequest {
    /**
     * 
     * @type {Product}
     * @memberof CreateProductRequest
     */
    product: Product;
}

export function CreateProductRequestFromJSON(json: any): CreateProductRequest {
    return CreateProductRequestFromJSONTyped(json, false);
}

export function CreateProductRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProductRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': ProductFromJSON(json['product']),
    };
}

export function CreateProductRequestToJSON(value?: CreateProductRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': ProductToJSON(value.product),
    };
}


