/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuditLogType {
    Request = 'REQUEST',
    Response = 'RESPONSE'
}

export function AuditLogTypeFromJSON(json: any): AuditLogType {
    return AuditLogTypeFromJSONTyped(json, false);
}

export function AuditLogTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogType {
    return json as AuditLogType;
}

export function AuditLogTypeToJSON(value?: AuditLogType | null): any {
    return value as any;
}

