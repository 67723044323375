/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetPersonRequest,
    GetPersonRequestFromJSON,
    GetPersonRequestToJSON,
    GetPersonResponse,
    GetPersonResponseFromJSON,
    GetPersonResponseToJSON,
    ListPersonsRequest,
    ListPersonsRequestFromJSON,
    ListPersonsRequestToJSON,
    ListPersonsResponse,
    ListPersonsResponseFromJSON,
    ListPersonsResponseToJSON,
} from '../models';

export interface PostGetPersonsRequest {
    getPersonRequest?: GetPersonRequest;
}

export interface PostListPersonsRequest {
    listPersonsRequest?: ListPersonsRequest;
}

/**
 * 
 */
export class PersonApi extends runtime.BaseAPI {

    /**
     */
    async postGetPersonsRaw(requestParameters: PostGetPersonsRequest): Promise<runtime.ApiResponse<GetPersonResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/persons/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPersonRequestToJSON(requestParameters.getPersonRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPersonResponseFromJSON(jsonValue));
    }

    /**
     */
    async postGetPersons(requestParameters: PostGetPersonsRequest): Promise<GetPersonResponse> {
        const response = await this.postGetPersonsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postListPersonsRaw(requestParameters: PostListPersonsRequest): Promise<runtime.ApiResponse<ListPersonsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/persons/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListPersonsRequestToJSON(requestParameters.listPersonsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPersonsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postListPersons(requestParameters: PostListPersonsRequest): Promise<ListPersonsResponse> {
        const response = await this.postListPersonsRaw(requestParameters);
        return await response.value();
    }

}
