/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChannelProvider
 */
export interface ChannelProvider {
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChannelProvider
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChannelProvider
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    channelName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    channelGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    channelUsage?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    addressId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelProvider
     */
    status?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChannelProvider
     */
    statusDate?: Date;
}

export function ChannelProviderFromJSON(json: any): ChannelProvider {
    return ChannelProviderFromJSONTyped(json, false);
}

export function ChannelProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'updatedBy': !exists(json, 'updated_by') ? undefined : json['updated_by'],
        'parentId': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'channelName': !exists(json, 'channel_name') ? undefined : json['channel_name'],
        'channelGroup': !exists(json, 'channel_group') ? undefined : json['channel_group'],
        'channelUsage': !exists(json, 'channel_usage') ? undefined : json['channel_usage'],
        'addressId': !exists(json, 'address_id') ? undefined : json['address_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDate': !exists(json, 'status_date') ? undefined : (new Date(json['status_date'])),
    };
}

export function ChannelProviderToJSON(value?: ChannelProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updated_by': value.updatedBy,
        'parent_id': value.parentId,
        'company_id': value.companyId,
        'channel_name': value.channelName,
        'channel_group': value.channelGroup,
        'channel_usage': value.channelUsage,
        'address_id': value.addressId,
        'status': value.status,
        'status_date': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
    };
}


