export enum TransactionType {
  'AUTHORIZATION' = 'AUTHORIZATION',
  'REFUND' = 'REFUND',
  'CAPTURE' = 'CAPTURE',
  'PURCHASE' = 'PURCHASE',
  'VOID' = 'VOID',
  'REVERSAL' = 'REVERSAL',
  'VERIFICATION' = 'VERIFICATION',
}

export enum CcvResult {
  'MATCH' = 'VALUES_MATCH',
  'NO_MATCH' = 'VALUES_DO_NOT_MATCH',
}
