import React, { PropsWithoutRef } from 'react';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';

export interface VerificationPassedProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  onComplete?: () => void;
}

export const VerificationPassed = React.forwardRef<
  HTMLDivElement,
  VerificationPassedProps
>(({ onComplete = () => {}, ...props }, ref) => {
  const { t } = useTranslation();
  return (
    <div ref={ref} className="text-center" {...props}>
      <h1 className="mt-10 text-2xl font-semibold">
        {t('onboarding.verificationPassed')}
      </h1>
      <div className="max-w-sm mx-auto text-center">
        <p className="max-w-sm mx-auto mt-4 text-sm text-gray-700">
          {t('onboarding.profileCreated')}
        </p>
        <div className="mt-9">
          <Button className="w-full max-w-xs mx-auto" onClick={onComplete}>
            {t('actions.logIn')}
          </Button>
        </div>
      </div>
    </div>
  );
});
