import React, { useContext, useEffect } from 'react';
import { Input, onKeyPressAllowPattern } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { ListContext } from '../context';
import { useForm } from 'react-hook-form';

export const FilterTerminalId = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { register, watch, setValue } = useForm<{
    terminalId: string;
  }>();

  const { terminalId } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_TERMINAL_ID_FILTER',
      terminalId,
    });
  }, [terminalId, dispatch]);

  useEffect(() => {
    setValue('terminalId', filters.terminalId);
  }, [filters.terminalId, setValue]);

  return (
    <Input
      disabled
      label={t('logs.api.filters.terminalId')}
      name="terminalId"
      labelFontClassName="text-xxs font-medium"
      labelColorClassName="text-gray-700"
      className="h-8 text-xs font-bold"
      onKeyPress={onKeyPressAllowPattern(/[a-zA-Z0-9-_]/g)}
      inputRef={register}
      maxLength={20}
    />
  );
};
