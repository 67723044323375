import React from 'react';
import { IconPendingWarning } from './icons/IconPendingWarning';

export class AppErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-full text-center text-red-500">
          <IconPendingWarning className="w-16 h-16 mb-2 text-red-500" />
          <p className="text-lg font-medium">
            Something went wrong. <br />
            <a href={window.location.href} className="underline">
              Refresh the page and try again.
            </a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
