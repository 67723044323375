import React from 'react';

export const IconTrash = ({ className }: { className?: string }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.5 4.5H3.83333H14.5"
      stroke="#FF4040"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83331 4.49998V3.16665C5.83331 2.81302 5.97379 2.47389 6.22384 2.22384C6.47389 1.97379 6.81302 1.83331 7.16665 1.83331H9.83331C10.1869 1.83331 10.5261 1.97379 10.7761 2.22384C11.0262 2.47389 11.1666 2.81302 11.1666 3.16665V4.49998M13.1666 4.49998V13.8333C13.1666 14.1869 13.0262 14.5261 12.7761 14.7761C12.5261 15.0262 12.1869 15.1666 11.8333 15.1666H5.16665C4.81302 15.1666 4.47389 15.0262 4.22384 14.7761C3.97379 14.5261 3.83331 14.1869 3.83331 13.8333V4.49998H13.1666Z"
      stroke="#FF4040"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.16669 7.83331V11.8333"
      stroke="#FF4040"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.83331 7.83331V11.8333"
      stroke="#FF4040"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
