import React, { FC } from 'react';
import {
  Controller,
  Control,
  FieldErrors,
  UseControllerOptions,
} from 'react-hook-form';
import classNames from 'classnames';
import { Checkbox } from 'src/components/Checkbox';

export interface CustomCheckboxProps {
  name: string;
  label: string;
  control: Control;
  labelClassName?: string;
  labelTextClassName?: string;
  defaultValue?: unknown;
  rules?: UseControllerOptions['rules'];
  error?: FieldErrors;
  color?: string;
  disabled?: boolean;
}

export const CheckboxForm: FC<CustomCheckboxProps> = ({
  control,
  name,
  label,
  labelClassName,
  labelTextClassName,
  defaultValue,
  rules,
  error,
  color,
  disabled,
}) => {
  return (
    <Controller
      error={error}
      rules={rules}
      name={name}
      control={control}
      defaultValue={defaultValue || false}
      render={({ onChange, value }) => (
        <label
          className={classNames(
            'inline-flex items-center leading-5 text-gray-800 capitalize cursor-pointer w-1/3',
            labelClassName
          )}
        >
          <Checkbox
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            color={color}
            disabled={disabled}
          />

          <span
            className={classNames(
              'ml-1 text-gray-700 text-xs',
              labelTextClassName
            )}
            style={{ marginBottom: '2px' }}
          >
            {label}
          </span>
        </label>
      )}
    />
  );
};
