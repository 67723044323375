import React, {
  createContext,
  FC,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import get from 'lodash/get';
import { Sorting } from 'src/components/table/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { nameof, typeProxy } from 'src/utils/nameof';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { useInfiniteQuery, useQuery } from 'react-query';
import {
  INITIAL_RULES_OPTIONS,
  RulesQueryOptions,
} from 'src/api/services/access-management/roles-and-permissions/getRolesAndPermissions';
import { RulesListParams } from 'src/domain/access-management/roles-and-permissions/tabs/rules/RolesList';
import { accessChannelApi, rolesApi } from 'src/api';
import { AccessChannel } from 'src/api/client';

const QUERY_KEY = 'ACCESS_MANAGEMENT/RULES';

export const initialState = {
  dispatch: (_action: Actions) => {},
  columnSorting: undefined as Sorting | undefined,
  listParams: {} as RulesListParams,
  accessChannelList: [] as AccessChannel[],
};

export type Actions = {
  type: 'SET_COLUMN_SORTING';
  columnSorting: Sorting | undefined;
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case 'SET_COLUMN_SORTING':
      return { ...state, columnSorting: action.columnSorting };
    default:
      throw new Error();
  }
};

export const RolesContext = createContext<typeof initialState>(initialState);

export interface RolesListFields extends Pick<RulesQueryOptions, 'limit'> {
  searchQuery: string;
}

export const rolesFormType = typeProxy<RolesListFields>();

export const RulesContextProvider: FC = ({ children }) => {
  const methods = useForm<RolesListFields>();
  const [state, dispatchAction] = useReducer(reducer, initialState);
  const { searchQuery, limit } = methods.watch([
    nameof(rolesFormType.searchQuery),
    nameof(rolesFormType.limit),
  ]);
  const debouncedSearchQuery = useDebouncedValue<string | undefined>(
    searchQuery,
    750
  );

  const { data: accessChannelList } = useQuery('postAccessChannelList', () =>
    accessChannelApi.postAccessChannelList(),
  );

  const {
    data: listData,
    isLoading: initListLoading,
    isFetching: listLoading,
    hasNextPage: listHasNextPage,
    fetchNextPage: listFetchNextPage,
    refetch,
    error: listError,
  } = useInfiniteQuery(
    [
      QUERY_KEY,
      {
        // sorting: state.columnSorting,
        searchQuery: debouncedSearchQuery,
        limit,
      },
    ],
    () => {
      return rolesApi.postIamRolesList({
        listIamRolesRequest: {
          // cursor: 0,
          // limit: 20,
        },
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => allPages.length < 5, //TODO TEST LIMIT
      onError: (err) => console.log(err),
    }
  );

  const items = useMemo(() => {
    return (
      listData?.pages
        .map((groupItem) =>
          groupItem?.iamRoles?.map((role) => ({
            ...role,
            channelName: accessChannelList
              ? get(
                  accessChannelList.accessChannels.find(
                    (channel: AccessChannel) =>
                      role.accessChannelId === channel.id
                  ),
                  'name',
                  ''
                )
              : '',
          }))
        )
        .flat() || []
    );
  }, [listData, accessChannelList]);

  const handleFetchNextPage = useCallback(async () => {
    if (listData) {
      await listFetchNextPage({
        pageParam: { ...INITIAL_RULES_OPTIONS },
      });
    }
  }, [listData, listFetchNextPage]);

  const listParams: RulesListParams = useMemo(
    () => ({
      items,
      initLoading: initListLoading,
      loading: listLoading,
      hasNextPage: listHasNextPage,
      fetchNextPage: handleFetchNextPage,
      refetch,
      listError,
    }),
    [
      items,
      listHasNextPage,
      handleFetchNextPage,
      initListLoading,
      listLoading,
      refetch,
      listError,
    ]
  );

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === 'development') {
      console.info('Dispatch Action', {
        ...action,
        context: QUERY_KEY,
      });
    }

    dispatchAction(action);
  }, []);

  return (
    <FormProvider {...methods}>
      <RolesContext.Provider
        value={{
          ...state,
          dispatch,
          listParams,
          accessChannelList: accessChannelList?.accessChannels || [],
        }}
      >
        {children}
      </RolesContext.Provider>
    </FormProvider>
  );
};
