import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import { CellLight, Column, CellBase } from 'src/components/table/Table';
import { AuthorizationItem } from './context';
import { CardType } from '../../transactions/transaction';

export const useAuthorizationsListColumns = ({
  transactionPreview,
}: {
  transactionPreview?: AuthorizationItem | null;
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    (): Column[] => [
      {
        id: 'transmissionDate',
        value: (item: AuthorizationItem) => (
          <CellLight>
            {item.transmissionDate &&
              moment(item.transmissionDate).format('DD MMM YYYY')}{' '}
            {item.transmissionDate &&
              moment(item.transmissionDate).format('hh:mm a')}
          </CellLight>
        ),
        label: t('transactionsManagement.authorizations.list.transmissionDate'),
        width: 140,
      },

      {
        id: 'transactionType',
        value: (item: AuthorizationItem) => (
          <CellBase>
            {item.transactionType &&
              t(
                `transactionsManagement.authorizations.transactionType.${item.transactionType}`
              )}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionType'),
        width: 100,
      },
      {
        id: 'transactionAmount',
        value: (item: AuthorizationItem) => (
          <CellBase>{numeral(item.transactionAmount).format('0.00')}</CellBase>
        ),
        label: t(
          'transactionsManagement.authorizations.list.transactionAmount'
        ),
        width: 100,
      },
      {
        id: 'transactionCurrency',
        value: (item: AuthorizationItem) => (
          <CellBase>{item.transactionCurrency}</CellBase>
        ),
        label: t(
          'transactionsManagement.authorizations.list.transactionCurrency'
        ),
        width: 100,
      },
      {
        id: 'maskedPan',
        value: (item: AuthorizationItem) => (
          <CellBase>{item.cardNumber}</CellBase>
        ),
        label: t('transactionsManagement.authorizations.list.maskedPan'),
        width: 125,
      },
      {
        id: 'cardType',
        value: (item: AuthorizationItem) => (
          <CellBase>
            {item.cardType && Object.values(CardType).includes(item.cardType)
              ? t(
                  `transactionsManagement.transactions.cardType.${item.cardType}`
                )
              : item.cardType}
          </CellBase>
        ),
        label: t('transactionsManagement.authorizations.list.cardType'),
        width: 100,
      },
      {
        id: 'responseCode',
        value: (item: AuthorizationItem) => (
          <CellBase>{item.responseCode}</CellBase>
        ),
        label: t('transactionsManagement.authorizations.list.responseCode'),
        width: 100,
      },
      {
        id: 'authId',
        value: (item: AuthorizationItem) => <CellBase>{item.authId}</CellBase>,
        label: t('transactionsManagement.authorizations.list.authId'),
        width: 100,
      },
      {
        id: 'merchantName',
        value: (item: AuthorizationItem) => (
          <CellLight>{item.merchantName}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.subMerchantName'),
        width: 90,
      },
      {
        id: 'stan',
        value: (item: AuthorizationItem) => <CellBase>{item.stan}</CellBase>,
        label: t('transactionsManagement.authorizations.list.stan'),
        width: 100,
      },
    ],
    [t]
  );

  return columns;
};
