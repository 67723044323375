import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { MaskedNumberInput } from 'src/components/Input';
import { ListContext } from '../context';

export const FilterCardLastFour = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const { watch, setValue, errors, control } = useForm<{
    cardLastFour: string;
  }>();

  const { cardLastFour } = watch();

  useEffect(() => {
    dispatch({
      type: 'SET_CARD_LAST_FOUR_FILTER',
      cardLastFour: cardLastFour,
    });
  }, [cardLastFour, dispatch]);

  useEffect(() => {
    if (filters.cardLastFour !== undefined) {
      setValue('cardLastFour', filters.cardLastFour);
    }
  }, [filters.cardLastFour, setValue]);

  return (
    <div>
      <Controller
        as={
          <MaskedNumberInput
            disabled
            format="####"
            label={t(
              'transactionsManagement.transactions.list.filters.cardLastFour'
            )}
            name="cardLastFour"
            labelFontClassName="text-xxs font-medium"
            labelColorClassName="text-gray-700"
            className="h-8 text-xs font-bold"
            maxLength={4}
            error={errors.cardLastFour}
          />
        }
        name="cardLastFour"
        control={control}
      />
    </div>
  );
};
