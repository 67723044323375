/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Card,
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCardResponse
 */
export interface GetCardResponse {
    /**
     * 
     * @type {Card}
     * @memberof GetCardResponse
     */
    card?: Card;
}

export function GetCardResponseFromJSON(json: any): GetCardResponse {
    return GetCardResponseFromJSONTyped(json, false);
}

export function GetCardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCardResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'card': !exists(json, 'card') ? undefined : CardFromJSON(json['card']),
    };
}

export function GetCardResponseToJSON(value?: GetCardResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card': CardToJSON(value.card),
    };
}


