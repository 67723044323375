import React, { useState } from 'react';
import Cookies from 'js-cookie';

const COOKIE_NAME = 'consent';

export const Consent = () => {
  const [consentGiven, setConsentGiven] = useState(!!Cookies.get(COOKIE_NAME));

  const giveConsent: JSX.IntrinsicElements['a']['onClick'] = (e) => {
    e.preventDefault();

    setConsentGiven(true);

    Cookies.set(COOKIE_NAME, 'true', { expires: 365 });
  };

  if (consentGiven) {
    return null;
  }

  return (
    <div
      className="fixed bottom-0 left-0 w-full px-5 py-5 md:px-10 xl:px-24 lg:py-12 z-60"
      style={{ background: 'rgba(0, 0, 0, 0.9)' }}
    >
      <div className="flex flex-wrap items-center">
        <div className="w-full pr-5 mb-5 text-lg text-white lg:pr-0 lg:w-1/2 xl:w-3/5 lg:mb-0">
          <span>
            This site uses cookies to offer you a better browsing experience.
            <br className="hidden xl:inline-block" />
            Find out more on{' '}
            <a href="/privacy" className="text-blue-500">
              how we use cookies and how you can change your settings.
            </a>
          </span>
        </div>
        <div className="flex flex-wrap w-full text-base text-white lg:w-1/2 xl:w-2/5 lg:justify-end">
          <a
            href="/"
            className="w-full max-w-xs px-8 py-3 mb-5 text-center text-white border border-white rounded-full lg:w-auto md:mr-7 md:mb-0"
            onClick={giveConsent}
          >
            I accept cookies
          </a>
        </div>
      </div>
    </div>
  );
};
