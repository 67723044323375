/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetProfileResponse,
    GetProfileResponseFromJSON,
    GetProfileResponseToJSON,
    UpdateProfileRequest,
    UpdateProfileRequestFromJSON,
    UpdateProfileRequestToJSON,
} from '../models';

export interface PostProfileUpdateRequest {
    updateProfileRequest?: UpdateProfileRequest;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     */
    async postProfileGetRaw(): Promise<runtime.ApiResponse<GetProfileResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/profile/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileResponseFromJSON(jsonValue));
    }

    /**
     */
    async postProfileGet(): Promise<GetProfileResponse> {
        const response = await this.postProfileGetRaw();
        return await response.value();
    }

    /**
     */
    async postProfileUpdateRaw(requestParameters: PostProfileUpdateRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/profile/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileRequestToJSON(requestParameters.updateProfileRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postProfileUpdate(requestParameters: PostProfileUpdateRequest): Promise<void> {
        await this.postProfileUpdateRaw(requestParameters);
    }

}
