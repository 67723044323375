import { useContext } from 'react';
import { AuthContext } from '../context';
import { logout } from '../auth';

export const useCurrentUser = () => {
  const { user, token, loading, roles, isAuthenticated, dispatch } = useContext(
    AuthContext
  );

  return {
    user,
    roles,
    token,
    loading,
    isAuthenticated,
    hasRole: (role: string) => roles.includes(role),
    logout: () => {
      logout();

      dispatch({ type: 'LOGOUT' });
    },
  };
};
