/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamUser,
    IamUserFromJSON,
    IamUserFromJSONTyped,
    IamUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListIamUsersResponse
 */
export interface ListIamUsersResponse {
    /**
     * 
     * @type {number}
     * @memberof ListIamUsersResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<IamUser>}
     * @memberof ListIamUsersResponse
     */
    iamUsers?: Array<IamUser>;
}

export function ListIamUsersResponseFromJSON(json: any): ListIamUsersResponse {
    return ListIamUsersResponseFromJSONTyped(json, false);
}

export function ListIamUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'iamUsers': !exists(json, 'iam_users') ? undefined : ((json['iam_users'] as Array<any>).map(IamUserFromJSON)),
    };
}

export function ListIamUsersResponseToJSON(value?: ListIamUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'iam_users': value.iamUsers === undefined ? undefined : ((value.iamUsers as Array<any>).map(IamUserToJSON)),
    };
}


