import React, {
  ReactNode,
  useCallback,
  useState,
  useRef,
  useEffect,
  MouseEvent,
  RefObject,
  Dispatch,
  SetStateAction,
  FocusEvent,
  memo,
} from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { Manager, Reference, Popper } from 'react-popper';
import { animated, useTransition } from 'react-spring';
import { Badge } from 'src/components/Badge';
import { IconCross } from 'src/components/icons/IconCross';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { IconCaretDown } from '../icons/IconCaretDown';
import { PopoverPortal } from '../PopoverPortal';
import { HorizontalDivider } from '../HorizontalDivider';
import { IconSearch } from '../icons/IconSearch';
import { Button } from '../Button';
import { IconCrossCircle } from '../icons/IconCrossCircle';
import { useOnKeyDown, ESC_KEY } from 'src/hooks/useOnKeyDown';
import { LoadingSwitch } from '../Loading';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../Tooltip';
import { IconChevronLeft } from '../icons/IconChevronLeft';

export const TableColumnDropdown = ({
  popoverIsOpen,
  label,
  badge,
  disabled = false,
  togglePopoverIsOpen,
  tooltipContent,
}: {
  label: string | ReactNode;
  disabled?: boolean;
  popoverIsOpen: boolean;
  badge?: string | number;
  tooltipContent?: ReactNode;
  togglePopoverIsOpen: (
    event: MouseEvent | TouchEvent | FocusEvent<HTMLInputElement>
  ) => void;
}) => (
  <div
    className={classNames('flex items-center', {
      'opacity-50': disabled,
    })}
  >
    {badge ? (
      <Tooltip
        minWidth={220}
        placement="bottom-start"
        offsetHorizonal={0}
        offsetVertical={8}
        content={tooltipContent}
        escapeWithReference={false}
      >
        {({ ref, setIsOpen: setTooltipIsOpen }) => (
          <Badge
            ref={ref}
            variant={popoverIsOpen ? 'fill' : 'outline'}
            disabled={disabled}
            onClick={(event) => {
              togglePopoverIsOpen(event);

              setTooltipIsOpen(false);
            }}
            onMouseEnter={() => {
              if (!popoverIsOpen) {
                setTooltipIsOpen(true);
              }
            }}
            onMouseLeave={() => setTooltipIsOpen(false)}
          >
            <span className="text-xxs">{badge}</span>
          </Badge>
        )}
      </Tooltip>
    ) : (
      <button
        className="h-4 text-xs font-bold text-gray-900 focus:outline-none disabled:cursor-not-allowed"
        disabled={disabled}
        onClick={togglePopoverIsOpen}
      >
        {label}
      </button>
    )}
    <button
      className="focus:outline-none disabled:cursor-not-allowed"
      disabled={disabled}
      onClick={togglePopoverIsOpen}
    >
      <IconCaretDown
        className={classNames(
          'w-2 h-2 ml-2 text-gray-700 transform transition-transform duration-300',
          {
            'rotate-180 text-blue-500': popoverIsOpen,
          }
        )}
      />
    </button>
  </div>
);

export const InputDropdown = ({
  name,
  value,
  popoverIsOpen,
  placeholder,
  label,
  disabled = false,
  togglePopoverIsOpen,
  icon,
}: {
  name: string;
  value?: string;
  label: string | ReactNode;
  placeholder: string;
  disabled?: boolean;
  popoverIsOpen: boolean;
  icon?: ReactNode;
  togglePopoverIsOpen: (
    event: MouseEvent | TouchEvent | FocusEvent<HTMLInputElement>
  ) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classNames({
        'opacity-50': disabled,
      })}
    >
      <label htmlFor={name} className="relative block">
        <span className="font-medium tracking-wide text-gray-700 text-xxs">
          {label}
        </span>
      </label>
      <div
        onClick={(e) => !disabled && togglePopoverIsOpen(e)}
        className="relative cursor-pointer"
      >
        <input
          ref={inputRef}
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly
          className="block w-full h-8 mt-1 text-xs font-bold cursor-pointer form-input"
        />
        <button
          onClick={() => inputRef.current?.focus()}
          className="absolute top-0 right-0 flex items-center justify-center w-12 h-full focus:outline-none"
        >
          {icon || (
            <IconChevronLeft
              className={classNames(
                'ml-2 transform transition text-gray-700 duration-200',
                {
                  '-rotate-90': !popoverIsOpen,
                  'rotate-90 text-blue-600': popoverIsOpen,
                }
              )}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export const DropdownFilter = memo(
  ({
    title,
    popoverWidth = 240,
    headerSecondaryLabel,
    searchPlaceholder,
    withSearch = false,
    searchLoading = false,
    children,
    trigger: dropdown,
    footer,
    onSearchQueryChange,
    onOpen,
    onClose,
    onClearFilter,
    scrollRef,
    searchOffset,
    setItemsLoaded,
    setItemsLoading,
    setSearchOffset,
  }: {
    popoverWidth?: number;
    title: string;
    headerSecondaryLabel?: string;
    searchPlaceholder?: string;
    withSearch?: boolean;
    searchLoading?: boolean;
    children: ReactNode;
    trigger: ({
      togglePopoverIsOpen,
      popoverIsOpen,
    }: {
      togglePopoverIsOpen: (
        event: MouseEvent | TouchEvent | FocusEvent<HTMLInputElement>
      ) => void;
      popoverIsOpen: boolean;
    }) => ReactNode;
    footer?: ReactNode;
    onSearchQueryChange?: (searchQuery: string) => any;
    onOpen?: (searchQuery: string) => any;
    onClose?: () => any;
    onClearFilter?: () => any;
    scrollRef?: RefObject<HTMLDivElement>;
    searchOffset?: number;
    setItemsLoaded?: Dispatch<SetStateAction<any[]>>;
    setItemsLoading?: Dispatch<SetStateAction<boolean>>;
    setSearchOffset?: Dispatch<SetStateAction<number>>;
  }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const searchQueryRef = useRef(searchQuery);

    const { t } = useTranslation();

    useEffect(() => {
      if (searchQueryRef.current !== searchQuery) {
        setItemsLoaded && setItemsLoaded([]);
        setItemsLoading && setItemsLoading(true);

        onSearchQueryChange?.(searchQuery);
        searchQueryRef.current = searchQuery;
      }
    }, [onSearchQueryChange, searchQuery, setItemsLoaded, setItemsLoading]);

    const [isSearchFocused, setIsSearchFocused] = useState(false);

    const [popoverIsOpen, setPopoverIsOpen] = useState(false);

    useEffect(() => {
      const close = () => setPopoverIsOpen(false);

      scrollRef?.current?.addEventListener('scroll', close);

      return () => scrollRef?.current?.removeEventListener('scroll', close);
    }, [scrollRef]);

    const isOpenRef = useRef<boolean>();

    useEffect(() => {
      if (popoverIsOpen === isOpenRef.current) {
        return;
      }

      isOpenRef.current = popoverIsOpen;

      if (popoverIsOpen) {
        setItemsLoaded && !searchOffset && setItemsLoaded([]);
        setItemsLoading && setItemsLoading(true);
        onOpen?.(searchQuery);
      } else {
        setSearchOffset && setSearchOffset(0);
        onClose?.();
      }
    }, [
      onOpen,
      onClose,
      popoverIsOpen,
      searchOffset,
      searchQuery,
      setItemsLoaded,
      setItemsLoading,
      setSearchOffset,
    ]);

    const transitions = useTransition(popoverIsOpen, null, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 150 },
    });

    const setPopoverIsOpenDebounced = useCallback(
      debounce((state: boolean) => setPopoverIsOpen(state), 200),
      [popoverIsOpen]
    );

    const togglePopoverIsOpen = useCallback(
      (event: MouseEvent | TouchEvent | FocusEvent) => {
        event.stopPropagation();

        setPopoverIsOpenDebounced(!popoverIsOpen);
      },
      [setPopoverIsOpenDebounced, popoverIsOpen]
    );

    useOnKeyDown({ keyCode: ESC_KEY }, () => setPopoverIsOpenDebounced(false));

    const popoverRef = useRef<HTMLDivElement>(null);

    const searchInputRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(
      {
        ref: popoverRef,
      },
      () => setPopoverIsOpenDebounced(false)
    );

    const resetSearchQuery = () => {
      setSearchQuery('');

      searchInputRef?.current?.focus();
    };

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref}>
              {dropdown({ togglePopoverIsOpen, popoverIsOpen })}
            </div>
          )}
        </Reference>
        <PopoverPortal>
          <Popper
            placement="bottom-start"
            eventsEnabled={false}
            modifiers={{
              offset: {
                offset: '-10,8',
              },
            }}
          >
            {({ ref, style, placement }) =>
              transitions.map(
                ({ item: transitionItem, key, props }) =>
                  transitionItem && (
                    <animated.div
                      ref={ref}
                      key={key}
                      style={{
                        ...style,
                        ...props,
                        width: popoverWidth,
                        zIndex: 50,
                      }}
                      className="relative"
                      data-placement={placement}
                    >
                      <div
                        ref={popoverRef}
                        className="flex flex-col bg-white shadow-popover rounded-px"
                      >
                        <div className="flex items-center justify-between p-3">
                          <span className="text-xs font-semibold text-gray-900">
                            {title}
                          </span>
                          <div className="flex items-center">
                            {headerSecondaryLabel && (
                              <span className="mr-3 text-gray-700 text-xxs">
                                {headerSecondaryLabel}
                              </span>
                            )}
                            <button
                              onClick={() => setPopoverIsOpenDebounced(false)}
                              className="transition-shadow duration-300 rounded focus:outline-none focus:shadow-outline"
                            >
                              <IconCross className="w-4 h-4 text-gray-700" />
                            </button>
                          </div>
                        </div>
                        <HorizontalDivider />
                        {withSearch && (
                          <>
                            <div className="relative h-10">
                              <div className="absolute left-0 flex items-center justify-center h-full ml-3 pointer-events-none">
                                <LoadingSwitch
                                  animationProps={{
                                    className: 'text-gray-500',
                                    width: 14,
                                    height: 14,
                                  }}
                                  loading={searchLoading}
                                >
                                  <IconSearch
                                    className={classNames(
                                      'w-3 h-3 transition-colors',
                                      {
                                        'text-gray-700': !isSearchFocused,
                                        'text-blue-500': isSearchFocused,
                                      }
                                    )}
                                  />
                                </LoadingSwitch>
                              </div>
                              {searchQuery && (
                                <div className="absolute right-0 flex items-center justify-center h-full mr-3">
                                  <button
                                    className="transition-shadow duration-300 rounded focus:outline-none focus:shadow-outline"
                                    onClick={resetSearchQuery}
                                  >
                                    <IconCrossCircle className="w-4 h-4 text-gray-300" />
                                  </button>
                                </div>
                              )}
                              <input
                                ref={searchInputRef}
                                value={searchQuery}
                                className="w-full h-full px-8 text-xs focus:outline-none"
                                placeholder={searchPlaceholder}
                                onFocus={() => setIsSearchFocused(true)}
                                onBlur={() => setIsSearchFocused(false)}
                                onChange={(event) =>
                                  setSearchQuery(event.target.value)
                                }
                              />
                            </div>
                            <HorizontalDivider />
                          </>
                        )}
                        <div className="p-3">{children}</div>
                        <HorizontalDivider />
                        <div className="p-3">
                          {footer || (
                            <Button
                              variant="secondary"
                              widthClass="w-full"
                              className="font-bold"
                              onClick={onClearFilter}
                            >
                              {t('kyc.filters.clearFilter')}
                            </Button>
                          )}
                        </div>
                      </div>
                    </animated.div>
                  )
              )
            }
          </Popper>
        </PopoverPortal>
      </Manager>
    );
  }
);
