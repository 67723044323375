/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ListIamAccessChannelsResponse,
    ListIamAccessChannelsResponseFromJSON,
    ListIamAccessChannelsResponseToJSON,
} from '../models';

/**
 *
 */
export class AccessChannelApi extends runtime.BaseAPI {

    /**
     */
    async postAccessChannelListRaw(): Promise<runtime.ApiResponse<ListIamAccessChannelsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/iam-access-channels/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: {},
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIamAccessChannelsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postAccessChannelList(): Promise<ListIamAccessChannelsResponse> {
        const response = await this.postAccessChannelListRaw();
        return await response.value();
    }

}
