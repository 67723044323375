import React, { useContext, useCallback, useMemo } from 'react';
import { FilterMerchants } from './FilterMerchants';
import { FilterStatuses } from './FilterStatuses';
import { FilterTransactionType } from './FilterTransactionType';
import { FilterCardType } from './FilterCardType';
import { FilterCardLastFour } from './FilterCardLastFour';
import { FilterAuthorizationCode } from './FilterAuthorizationCode';
import { FilterSettlementAmount } from './FilterSettlementAmount';
import { FilterArnNumber } from './FilterArnNumber';
import { FilterRrnNumber } from './FilterRrnNumber';
// import { FilterStan } from './FilterStan';
import { Button } from 'src/components/Button';
import { ListContext, defaultFilters } from '../context';
import { FilterGenericDate } from './FilterGenericDate';
import { DateRange } from 'src/types/dateRange';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { FilterMatchType } from './FilterMatchType';

export const Filters = () => {
  const { dispatch, filters, appliedFilters } = useContext(ListContext);

  const setTransactionDateFilter = useCallback(
    (dateRange: DateRange) =>
      dispatch({ type: 'SET_TRANSACTION_DATE_FILTER', dateRange }),
    [dispatch]
  );

  const resetTransactionDateFilter = useCallback(
    () => dispatch({ type: 'RESET_TRANSACTION_DATE_FILTER' }),
    [dispatch]
  );

  const setSettlementDateFilter = useCallback(
    (dateRange: DateRange) =>
      dispatch({ type: 'SET_SETTLEMENT_DATE_FILTER', dateRange }),
    [dispatch]
  );

  const resetSettlementDateFilter = useCallback(
    () => dispatch({ type: 'RESET_SETTLEMENT_DATE_FILTER' }),
    [dispatch]
  );

  const { t } = useTranslation();

  const filtersApplied = useMemo(() => {
    return isEqual(filters, appliedFilters);
  }, [filters, appliedFilters]);

  const filtersInDefaultState = useMemo(() => {
    return isEqual(filters, defaultFilters);
  }, [filters]);

  return (
    <div className="grid grid-cols-7 gap-5 row-gap-2">
      <div>
        <FilterMerchants />
      </div>
      <div>
        <FilterStatuses />
      </div>
      <div>
        <FilterTransactionType />
      </div>
      <div>
        <FilterGenericDate
          filterKey="transactionDate"
          title={t(
            'transactionsManagement.transactions.list.filters.transactionDate'
          )}
          setFilterAction={setTransactionDateFilter}
          resetFilterAction={resetTransactionDateFilter}
        />
      </div>
      <div>
        <FilterGenericDate
          filterKey="settlementDate"
          title={t(
            'transactionsManagement.transactions.list.filters.settlementDate'
          )}
          setFilterAction={setSettlementDateFilter}
          resetFilterAction={resetSettlementDateFilter}
        />
      </div>
      <div>
        <FilterCardType />
      </div>
      <div>
        <FilterMatchType />
      </div>
      <div>
        <FilterCardLastFour />
      </div>
      <div>
        <FilterAuthorizationCode />
      </div>
      <div>
        <FilterSettlementAmount />
      </div>
      <div>
        <FilterArnNumber />
      </div>
      <div>
        <FilterRrnNumber />
      </div>
      {/* <div>
        <FilterStan />
      </div> */}
      <div className="flex items-end">
        <div className="w-px h-8 mr-3 bg-gray-300" />
        <Button
          className="w-1/2 mr-3"
          onClick={() => dispatch({ type: 'APPLY_FILTERS' })}
          disabled={filtersApplied}
          variant={filtersApplied ? 'secondary' : 'primary'}
        >
          {t('transactionsManagement.transactions.list.filters.apply')}
        </Button>
        <Button
          className="w-1/2"
          variant="secondary"
          onClick={() => dispatch({ type: 'RESET_ALL_FILTERS' })}
          disabled={filtersInDefaultState}
        >
          {t('transactionsManagement.transactions.list.filters.clearAll')}
        </Button>
      </div>
    </div>
  );
};
