import React, { ReactNode } from 'react';

export const PageHeader = ({
  title,
  right,
}: {
  title: string;
  right?: ReactNode;
}) => {
  return (
    <div className="flex items-center justify-between w-full h-16 bg-white border-b">
      <div className="px-4">
        <p className="mb-0 text-base font-bold leading-none text-black">
          {title}
        </p>
      </div>
      {right && <div className="px-4">{right}</div>}
    </div>
  );
};
