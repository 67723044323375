import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { nameof } from 'src/utils/nameof';
import { Tab } from '../../../tabs/Tab';
import { TabsHeader } from '../../../tabs/TabsHeader';
import { rolesLogsFormType } from './RolesLogsContext';
import { RolesLogsList } from './RolesLogsList';

export const RolesLogsTabList = () => {
  const { t } = useTranslation();

  // const searchFieldLabels = useMemo(
  //   () => [
  //     t('accessManagement.rolesAndPermissions.searchColumns.name'),
  //     t('accessManagement.rolesAndPermissions.searchColumns.channel'),
  //     t('accessManagement.rolesAndPermissions.searchColumns.description'),
  //   ],
  //   [t]
  // );

  return (
    <Tab
      header={
        <TabsHeader
          tabs={[
            {
              url: '/roles',
              label: t('accessManagement.rolesAndPermissions.breadcrumb')
            },
            {
              url: '/roles-logs',
              label: t('accessManagement.rolesAndPermissions.logs.breadcrumb')
            },
          ]}

          // searching={{
          //   name: nameof(rolesLogsFormType.searchQuery),
          //   fieldLabels: searchFieldLabels,
          // }}
        />
      }
      content={<RolesLogsList />}
    />
  );
};
