/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IamUserType
 */
export interface IamUserType {
    /**
     * 
     * @type {string}
     * @memberof IamUserType
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamUserType
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamUserType
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamUserType
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IamUserType
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUserType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUserType
     */
    awsPoolId?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUserType
     */
    accessChannelId?: string;
    /**
     * 
     * @type {string}
     * @memberof IamUserType
     */
    status?: string;
    /**
     * 
     * @type {Date}
     * @memberof IamUserType
     */
    statusDate?: Date;
}

export function IamUserTypeFromJSON(json: any): IamUserType {
    return IamUserTypeFromJSONTyped(json, false);
}

export function IamUserTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IamUserType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'updatedBy': !exists(json, 'updated_by') ? undefined : json['updated_by'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'awsPoolId': !exists(json, 'aws_pool_id') ? undefined : json['aws_pool_id'],
        'accessChannelId': !exists(json, 'access_channel_id') ? undefined : json['access_channel_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusDate': !exists(json, 'status_date') ? undefined : (new Date(json['status_date'])),
    };
}

export function IamUserTypeToJSON(value?: IamUserType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'updated_by': value.updatedBy,
        'name': value.name,
        'aws_pool_id': value.awsPoolId,
        'access_channel_id': value.accessChannelId,
        'status': value.status,
        'status_date': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
    };
}


