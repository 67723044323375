import { Status } from '../components/status/Status';
import React, {
  PropsWithoutRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';

import { Table } from 'src/components/table/Table';
// import { FiltersList } from '../filters/FiltersList';
// import { CreatedAt, CardStatus, CardType, CardBrand, PersonStatus } from '../types';

import { personApi } from 'src/api';
import { PersonStatus } from 'src/api/client';
import { StatusBadge, StatusBadgeType } from 'src/components/StatusBadge';
import { LoadingSwitch } from 'src/components/Loading';
import { LoadMoreButton } from 'src/components/list/LoadMoreButton';
import get from 'lodash/get';
import { PageSize } from 'src/components/list/PageSize';
import { nameof } from 'src/utils/nameof';
import { usersFormType } from 'src/domain/access-management/user-management/tabs/users/UsersContext';
import { RowsPerPageOptionType } from 'src/domain/access-management/user-management/tabs/users/UsersList';
import { useForm } from 'react-hook-form';
// import { CreatedAt } from 'api/client';
// import { CardBrand, CardStatus, CardType } from '../../api/client/models';

export const statusBadge = (status: PersonStatus): StatusBadgeType => {
  switch (status) {
    case PersonStatus.Active:
      return StatusBadgeType.SUCCESS;
    case PersonStatus.Pep:
      return StatusBadgeType.SECONDARY;
    case PersonStatus.Pfraud:
    case PersonStatus.Sanctioned:
    case PersonStatus.Failed:
    case PersonStatus.Neglist:
      return StatusBadgeType.DANGER;
    case PersonStatus.Unverified:
      return StatusBadgeType.WARNING;
    default:
      return StatusBadgeType.PRIMARY;
  }
};

export interface CardsTableProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  activeCards?: boolean;
  hasStatusFilter: boolean;
  personId?: string;
}

const ROW_PER_PAGE_OPTIONS: RowsPerPageOptionType[] = [
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];


export const PayeesTable = React.forwardRef<HTMLDivElement, CardsTableProps>(
  ({ children, activeCards, hasStatusFilter, personId, ...props }, ref) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { watch, control } = useForm<{
      limit: { value: number; label: string };
    }>();

    const { limit } = watch();

    const [cursor, setCursor] = useState(0);
    const [id] = useState(Date.now());

    const {
      data,
      error,
      isLoading,
      isFetching,
      fetchNextPage,
      refetch,
      hasNextPage,
      remove,
    } = useInfiniteQuery(
      [id],
      () => {
        const req = {
          listPersonsRequest: {
            cursor,
            limit: limit?.value || 20,
          },
        };

        return personApi.postListPersons(req);
      },
      {
        enabled: !!limit,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchIntervalInBackground: false,
        getNextPageParam: (lastPage) => lastPage?.count === limit?.value,
      }
    );

    const handleLoadMore = () => {
      setCursor(cursor + limit?.value);
    };

    useEffect(() => {
      if (cursor > 0) {
        fetchNextPage();
      }
      if (cursor === 0) {
        remove();
        refetch();
      }
    }, [cursor, fetchNextPage, refetch]); // eslint-disable-line

    useEffect(() => {
      if (cursor === 0) {
        refetch();
      } else {
        setCursor(0);
      }
    }, [refetch, limit, id, setCursor]); // eslint-disable-line

    const tableData = useMemo(() => {
      if (!isLoading && !error && data) {
        return data!.pages.reduce((result, group) => {
          return [
            ...result,
            ...group.persons!.map((row) => [
              {
                value: (
                  <span className="text-ssm text-gray-900 font-semibold">{`${row.firstName} ${row.lastName}`}</span>
                ),
              },
              {
                value: (
                  <span className="text-ssm text-gray-900 font-semibold">
                    {row.email}
                  </span>
                ),
              },
              {
                value: (
                  <span className="text-ssm text-gray-900 font-semibold">{`${row.mobilePhone?.areaCode} ${row.mobilePhone?.number}`}</span>
                ),
              },
              {
                value: row.status && (
                  <StatusBadge variant={statusBadge(row.status)}>
                    {t(`cards.table.status.${row.status?.toLowerCase()}`)}
                  </StatusBadge>
                ),
              },
            ]),
          ];
        }, [] as any[]);
      }

      return [];
    }, [data, t, isLoading, error]);

    const columns = [
      {
        value: (item: any) => (
          <span className="block max-w-xxs">{item[0].value}</span>
        ),
        label: t('cards.table.columns.nameSurname'),
        id: '0',
      },
      {
        value: (item: any) => (
          <span className="block max-w-xxs">{item[1].value}</span>
        ),
        label: t('cards.table.columns.email'),
        id: '1',
      },
      {
        value: (item: any) => (
          <span className="block max-w-xxs">{item[2].value}</span>
        ),
        label: t('cards.table.columns.phoneNumber'),
        id: '2',
      },
      {
        value: (item: any) => item[3].value,
        label: t('cards.table.columns.status'),
        id: '3',
        minWidth: 150,
      },
    ];

    const navigateToCard = useCallback(
      (item, index) => {
        const spreadData = data!.pages.reduce((result, group) => {
          // @ts-ignore
          return [...result, ...group.persons];
        }, [] as any[]);
        history.push(`/payees/${spreadData![index].id}`);
      },
      [data]
    );

    return (
      <div ref={ref} {...props} className="flex flex-col h-full pt-2">
        {error?.status === 403 ? (
          <div className="mt-1 mx-3 mb-3 px-5 py-3 text-sm text-gray-900 bg-red-100 rounded-sm">
            {t('permissions.permissionDenied')}
          </div>
        ) : (
          <div>
            {/*<FiltersList
            filters={filters}
            setFilters={setFilters}
            remove={remove}
            applyFilters={applyFilters}
            filterTypes={{
              cardholders: true,
              payeeStatus: true,
              email: true,
              phoneNumbers: true,
            }}
          />*/}

            <Table
              items={tableData}
              loading={isLoading}
              columns={columns}
              isHeaderFixed
              withFilters={false}
              onRowClick={(item, index) => {
                navigateToCard(item, index);
              }}
            />

            <div className="flex items-center justify-between p-5 py-3 mt-auto -mt-px -mb-5 bg-white border-t border-gray-300">
              <div className="items-center hidden text-xs text-gray-700 md:flex">
                {t('list.pagination.results')}:
                <span className="ml-1">
                  <LoadingSwitch loading={isLoading}>
                    <span className="font-medium">{tableData?.length}</span>
                  </LoadingSwitch>
                </span>
              </div>
              <LoadMoreButton
                loading={isLoading || isFetching}
                onClick={handleLoadMore}
                disabled={!hasNextPage}
              />

              <PageSize
                control={control}
                options={ROW_PER_PAGE_OPTIONS}
                name={'limit'}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);
