/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamUserRole,
    IamUserRoleFromJSON,
    IamUserRoleFromJSONTyped,
    IamUserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateIamUserRoleRequest
 */
export interface CreateIamUserRoleRequest {
    /**
     * 
     * @type {IamUserRole}
     * @memberof CreateIamUserRoleRequest
     */
    iamUserRole: IamUserRole;
}

export function CreateIamUserRoleRequestFromJSON(json: any): CreateIamUserRoleRequest {
    return CreateIamUserRoleRequestFromJSONTyped(json, false);
}

export function CreateIamUserRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIamUserRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamUserRole': IamUserRoleFromJSON(json['iam_user_role']),
    };
}

export function CreateIamUserRoleRequestToJSON(value?: CreateIamUserRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iam_user_role': IamUserRoleToJSON(value.iamUserRole),
    };
}


