/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Person,
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListPersonsResponse
 */
export interface ListPersonsResponse {
    /**
     * 
     * @type {number}
     * @memberof ListPersonsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<Person>}
     * @memberof ListPersonsResponse
     */
    persons?: Array<Person>;
}

export function ListPersonsResponseFromJSON(json: any): ListPersonsResponse {
    return ListPersonsResponseFromJSONTyped(json, false);
}

export function ListPersonsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPersonsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'persons': !exists(json, 'persons') ? undefined : ((json['persons'] as Array<any>).map(PersonFromJSON)),
    };
}

export function ListPersonsResponseToJSON(value?: ListPersonsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'persons': value.persons === undefined ? undefined : ((value.persons as Array<any>).map(PersonToJSON)),
    };
}


