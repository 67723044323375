/* eslint-disable */
import { last } from 'lodash';

export const namesof = <T>(typeProxy: TypeProxy<T>): string[] =>
  (typeProxy as any).__path;
export const nameof = <T>(typeProxy: TypeProxy<T>): string =>
  (typeProxy as any).__name;

type TypeProxy<T> = {
  [P in keyof T]: TypeProxy<T[P]>;
};

export const typeProxy = <T>(): TypeProxy<T> => getProxy([]) as TypeProxy<T>;

const getProxy = <T>(root: string[]) => new Proxy(root, handler) as any;

const handler: ProxyHandler<string[]> = {
  get: (target, key) => {
    if (key === '__path') return target;
    if (key === '__name') return last(target);
    return getProxy([...target, key as string]);
  },
};
