/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditLogType,
    AuditLogTypeFromJSON,
    AuditLogTypeFromJSONTyped,
    AuditLogTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {AuditLogType}
     * @memberof AuditLog
     */
    type?: AuditLogType;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    reference?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuditLog
     */
    createdTime: Date;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    id: string;
}

export function AuditLogFromJSON(json: any): AuditLog {
    return AuditLogFromJSONTyped(json, false);
}

export function AuditLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : AuditLogTypeFromJSON(json['type']),
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'createdTime': (new Date(json['created_time'])),
        'id': json['id'],
    };
}

export function AuditLogToJSON(value?: AuditLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AuditLogTypeToJSON(value.type),
        'reference': value.reference,
        'created_time': (value.createdTime.toISOString()),
        'id': value.id,
    };
}


