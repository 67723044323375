import React, { Dispatch, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { AddUserFields, addUserFormType } from '../AddUserModal';
import { nameof } from 'src/utils/nameof';
import { IconChevronLeft } from 'src/components/icons/IconChevronLeft';
import { emailPattern, phonePattern } from 'src/validation/patterns';

interface Props {
  toNextStep: () => void;
  visibleDetails: boolean;
  setVisibleDetails: Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
}

export const FormOne = memo(
  ({ toNextStep, visibleDetails, setVisibleDetails, isEdit }: Props) => {
    const { t } = useTranslation();
    const { register, trigger, errors } = useFormContext<AddUserFields>();

    const handleNextStep = async () => {
      const contactsFieldsNames = !isEdit
        ? [nameof(addUserFormType.email), nameof(addUserFormType.phoneNumber)]
        : [];

      const detailsFieldsNames = visibleDetails
        ? [
            nameof(addUserFormType.title),
            nameof(addUserFormType.company),
            nameof(addUserFormType.orgUnit),
            nameof(addUserFormType.directSupervisor),
            nameof(addUserFormType.notes),
          ]
        : [];

      const isValidFields = await trigger([
        nameof(addUserFormType.firstName),
        nameof(addUserFormType.lastName),
        ...contactsFieldsNames,
        ...detailsFieldsNames,
      ]);

      return isValidFields && toNextStep();
    };

    return (
      <div>
        <div className="flex mb-3">
          <div className="w-1/2 mr-1">
            <Input
              label={t('accessManagement.userManagement.form.firstName')}
              name={nameof(addUserFormType.firstName)}
              error={errors.firstName}
              inputRef={register({
                required: true,
                maxLength: {
                  value: 50,
                  message: t('validations.maxLength', { limit: 50 }),
                },
              })}
              placeholder="ex. Mike"
              className={'h-10 border-gray-300'}
            />
          </div>
          <div className="w-1/2 ml-1">
            <Input
              label={t('accessManagement.userManagement.form.lastName')}
              name={nameof(addUserFormType.lastName)}
              error={errors.lastName}
              inputRef={register({
                required: true,
                maxLength: {
                  value: 50,
                  message: t('validations.maxLength', { limit: 50 }),
                },
              })}
              placeholder="ex. Smith"
              className={'h-10 border-gray-300'}
            />
          </div>
        </div>
        {!isEdit && (
          <>
            <div className="flex mb-3">
              <div className="w-full">
                <Input
                  label={t('accessManagement.userManagement.form.email')}
                  name={nameof(addUserFormType.email)}
                  error={errors.email}
                  inputRef={register({
                    required: true,
                    validate: {
                      email: (value) => emailPattern.test(value),
                    },
                  })}
                  placeholder="ex. m.smith@gmail.com"
                  className={'h-10 border-gray-300'}
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-full">
                <Input
                  label={t('accessManagement.userManagement.form.phoneNumber')}
                  name={nameof(addUserFormType.phoneNumber)}
                  error={errors.phoneNumber}
                  inputRef={register({
                    required: true,
                    validate: {
                      phone: (value) => phonePattern.test(value),
                    },
                  })}
                  placeholder="ex. +380504789022"
                  className={'h-10 border-gray-300'}
                />
              </div>
            </div>
          </>
        )}
        <div className="flex relative justify-center my-4">
          <span
            onClick={() => {
              setVisibleDetails((prevState) => !prevState);
            }}
            className="inline-flex relative z-10 leading-none	 items-center text-xxs text-gray-700 cursor-pointer py-2 px-4 bg-gray-200 rounded-full"
          >
            Additional Details
            <IconChevronLeft
              className={classNames('transform ml-2', [
                visibleDetails ? 'rotate-90 -mt-px' : '-rotate-90',
              ])}
            />
          </span>

          <div
            className="w-full h-px bg-gray-200 absolute left-0"
            style={{ top: '50%' }}
          />
        </div>

        {visibleDetails && (
          <>
            <div className="flex mb-3">
              <div className="w-full">
                <Input
                  label={t('accessManagement.userManagement.form.title')}
                  optional
                  name={nameof(addUserFormType.title)}
                  error={errors.title}
                  inputRef={register({
                    maxLength: {
                      value: 200,
                      message: t('validations.maxLength', { limit: 200 }),
                    },
                  })}
                  placeholder="ex. Cheif Technical Officer"
                  className={'h-10 border-gray-300'}
                />
              </div>
            </div>

            <div className="flex mb-3">
              <div className="w-full">
                <Input
                  label={t('accessManagement.userManagement.form.company')}
                  optional
                  name={nameof(addUserFormType.company)}
                  error={errors.company}
                  inputRef={register({
                    maxLength: {
                      value: 200,
                      message: t('validations.maxLength', { limit: 200 }),
                    },
                  })}
                  placeholder="Company Name"
                  className={'h-10 border-gray-300'}
                />
              </div>
            </div>

            <div className="flex mb-3">
              <div className="w-full">
                <Input
                  label={t(
                    'accessManagement.userManagement.form.organizationUnit'
                  )}
                  optional
                  name={nameof(addUserFormType.orgUnit)}
                  error={errors.orgUnit}
                  inputRef={register({
                    maxLength: {
                      value: 200,
                      message: t('validations.maxLength', { limit: 200 }),
                    },
                  })}
                  placeholder="ex. Analytics Department"
                  className={'h-10 border-gray-300'}
                />
              </div>
            </div>

            <div className="flex mb-3">
              <div className="w-full">
                <Input
                  label={t(
                    'accessManagement.userManagement.form.directSupervisor'
                  )}
                  optional
                  name={nameof(addUserFormType.directSupervisor)}
                  error={errors.directSupervisor}
                  inputRef={register({
                    maxLength: {
                      value: 200,
                      message: t('validations.maxLength', { limit: 200 }),
                    },
                  })}
                  placeholder="ex. John Doe"
                  className={'h-10 border-gray-300'}
                />
              </div>
            </div>

            <div className="flex mb-3">
              <div className="w-full">
                <Input
                  label={t('accessManagement.userManagement.form.notes')}
                  optional
                  name={nameof(addUserFormType.notes)}
                  error={errors.notes}
                  inputRef={register({
                    maxLength: {
                      value: 200,
                      message: t('validations.maxLength', { limit: 200 }),
                    },
                  })}
                  placeholder="Write your comments"
                  className={'h-10 border-gray-300'}
                />
              </div>
            </div>
          </>
        )}
        <Button
          onClick={handleNextStep}
          size={'medium-large'}
          className={'w-full'}
        >
          {t('accessManagement.userManagement.assignmentsAndSecurityProfiles')}
          <span className={'absolute right-2'}>
            <IconChevronLeft className={'transform ml-2 -rotate-180'} />
          </span>
        </Button>
      </div>
    );
  }
);
