import React, { useContext, useState } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Comment, DetailsContext, Document } from './context';
import { Table } from 'src/components/table/Table';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { FormComment } from './FormComment';
import { FormDocument } from './FormDocument';
import { Loading } from 'src/components/Loading';

enum Tab {
  CASE_INFORMATION = 'caseInformation',
  COMMENTS = 'comments',
  DOCUMENTS = 'documents',
}

const TabButton = ({
  children,
  active,
  activeClassName,
  ...props
}: JSX.IntrinsicElements['button'] & {
  active: boolean;
  activeClassName: string;
}) => (
  <span className="relative">
    {active && (
      <div className="absolute inset-x-0 top-0 w-full h-1 bg-blue-500" />
    )}
    <button
      className={classNames(
        'py-4 px-6 w-full focus:outline-none text-xs font-bold flex-1 bg-white',
        active ? 'text-black' : 'text-blue-500 border-b',
        active && activeClassName
      )}
      {...props}
    >
      {children}
    </button>
  </span>
);

export const Cell = ({
  children,
  label,
}: JSX.IntrinsicElements['div'] & { label: string }) => (
  <div className="flex items-center p-3">
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-700">{label}</p>
    </div>
    <div className="w-1/2">
      <p className="text-xs font-medium text-gray-900 break-all">{children}</p>
    </div>
  </div>
);

export const Details = () => {
  const { t } = useTranslation();

  const { chargeback, chargebackLoading } = useContext(DetailsContext);

  const [tab, setTab] = useState(Tab.CASE_INFORMATION);

  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [commentEdit, setCommentEdit] = useState<Comment | null>();

  const showCommentModal = () => {
    setCommentModalVisible(true);
  };

  const hideCommentModal = () => {
    setCommentModalVisible(false);

    setTimeout(() => {
      setCommentEdit(null);
    }, 150);
  };

  const [documentModalVisible, setDocumentModalVisible] = useState(false);

  return (
    <div className="p-3">
      <Modal
        isOpen={commentModalVisible}
        closeModal={hideCommentModal}
        maxWidth="64rem"
      >
        <FormComment onSubmit={hideCommentModal} comment={commentEdit} />
      </Modal>
      <Modal
        isOpen={documentModalVisible}
        closeModal={() => setDocumentModalVisible(false)}
        maxWidth="64rem"
      >
        <FormDocument onSubmit={() => setDocumentModalVisible(false)} />
      </Modal>
      <div className="mb-4 bg-white border rounded shadow">
        <div className="relative flex overflow-hidden rounded-t">
          <div className="absolute inset-x-0 bottom-0 z-0 w-full h-px mb-px bg-gray-300" />
          {Object.values(Tab).map((item, index) => (
            <TabButton
              key={item}
              active={tab === item}
              activeClassName={index === 0 ? 'border-r' : 'border-r border-l'}
              onClick={() => setTab(item)}
              disabled={item !== Tab.CASE_INFORMATION}
            >
              {t(`disputeResolution.chargebacks.details.tabs.${item}`)}
            </TabButton>
          ))}
        </div>
        <div className="flex items-center justify-between p-3">
          <h3 className="font-bold text-gray-900">
            {t(`disputeResolution.chargebacks.details.tabs.${tab}`)}
          </h3>
          {tab === Tab.COMMENTS && (
            <Button onClick={showCommentModal} disabled>
              {t('disputeResolution.chargebacks.details.comments.addComment')}
            </Button>
          )}
          {tab === Tab.DOCUMENTS && (
            <Button onClick={() => setDocumentModalVisible(true)} disabled>
              {t('disputeResolution.chargebacks.details.documents.addDocument')}
            </Button>
          )}
        </div>
        {tab === Tab.CASE_INFORMATION &&
          (chargebackLoading || !chargeback ? (
            <div className="flex items-center justify-center p-10">
              <Loading className="text-blue-500" width={32} height={32} />
            </div>
          ) : (
            <div className="border-t divide-y">
              {lodash.chunk(Object.keys(chargeback!), 4).map((chunk, index) => (
                <div className="grid grid-cols-4 divide-x" key={index}>
                  {chunk.map((item, itemIndex) => {
                    const itemValue =
                      chargeback[item as keyof typeof chargeback];

                    const getValue = (value: any) => {
                      if (value instanceof Date) {
                        return moment(value).format('D MMM YYYY HH:mm');
                      }

                      if (value instanceof Object) {
                        return JSON.stringify(value);
                      }

                      return value;
                    };

                    return (
                      <Cell key={itemIndex} label={lodash.startCase(item)}>
                        {getValue(itemValue)}
                      </Cell>
                    );
                  })}
                </div>
              ))}
            </div>
          ))}
        {/* {tab === Tab.COMMENTS && (
          <div>
            <Table
              items={chargeback?.comments || []}
              onRowClick={(item: Comment) => {
                setCommentEdit(item);
                showCommentModal();
              }}
              columns={[
                {
                  id: 'createdAt',
                  value: (item: Comment) => item.createdAt,
                  label: t(
                    'disputeResolution.chargebacks.details.comments.createdAt'
                  ),
                  width: 100,
                },
                {
                  id: 'createdBy',
                  value: (item: Comment) => item.createdBy,
                  label: t(
                    'disputeResolution.chargebacks.details.comments.createdBy'
                  ),
                  width: 100,
                },
                {
                  id: 'comment',
                  value: (item: Comment) =>
                    item.comment.substring(0, 450) + '...',
                  label: t(
                    'disputeResolution.chargebacks.details.comments.comment'
                  ),
                },
              ]}
              withFilters={false}
            />
          </div>
        )}
        {tab === Tab.DOCUMENTS && (
          <div>
            <Table
              items={chargeback?.documents || []}
              columns={[
                {
                  id: 'createdAt',
                  value: (item: Document) => item.createdAt,
                  label: t(
                    'disputeResolution.chargebacks.details.comments.createdAt'
                  ),
                  width: 100,
                },
                {
                  id: 'createdBy',
                  value: (item: Document) => item.createdBy,
                  label: t(
                    'disputeResolution.chargebacks.details.comments.createdBy'
                  ),
                  width: 100,
                },
                {
                  id: 'comment',
                  value: (item: Document) => item.document,
                  label: t(
                    'disputeResolution.chargebacks.details.documents.document'
                  ),
                },
              ]}
              withFilters={false}
            />
          </div>
        )} */}
      </div>
      {/* <div className="mb-4 bg-white border rounded shadow">
        <div className="p-3 text-xs font-medium bg-gray-100 border-b rounded-t">
          Merchant Information
        </div>
        <div className="divide-y">
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
        </div>
      </div>
      <div className="mb-4 bg-white border rounded shadow">
        <div className="p-3 text-xs font-medium bg-gray-100 border-b rounded-t">
          Transaction Information
        </div>
        <div className="divide-y">
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
          <div className="grid grid-cols-4 divide-x">
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
            <Cell label={faker.random.words(1)}>{faker.random.words(1)}</Cell>
          </div>
        </div>
      </div> */}
    </div>
  );
};
