import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { breadcrumbsSlice } from './breadcrumbs/breadcrumbsSlice';
import { accountSlice } from './account/accountSlice';

const rootReducer = persistReducer(
  {
    key: 'backoffice',
    storage,
  },
  combineReducers({
    breadcrumbs: breadcrumbsSlice.reducer,
    account: accountSlice.reducer,
  })
);

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    thunk,
  ],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
