/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IamRole,
    IamRoleFromJSON,
    IamRoleFromJSONTyped,
    IamRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListIamRolesResponse
 */
export interface ListIamRolesResponse {
    /**
     * 
     * @type {Array<IamRole>}
     * @memberof ListIamRolesResponse
     */
    iamRoles?: Array<IamRole>;
}

export function ListIamRolesResponseFromJSON(json: any): ListIamRolesResponse {
    return ListIamRolesResponseFromJSONTyped(json, false);
}

export function ListIamRolesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIamRolesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iamRoles': !exists(json, 'iam_roles') ? undefined : ((json['iam_roles'] as Array<any>).map(IamRoleFromJSON)),
    };
}

export function ListIamRolesResponseToJSON(value?: ListIamRolesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iam_roles': value.iamRoles === undefined ? undefined : ((value.iamRoles as Array<any>).map(IamRoleToJSON)),
    };
}


