import React, { useState } from 'react';

import { ReactComponent as IconGA } from '../../../assets/icons/iconGA.svg';
import { ReactComponent as IconMSA } from '../../../assets/icons/iconMSA.svg';
import { ReactComponent as IconQuestion } from 'src/assets/icons/iconQuestion.svg';

import { Button } from 'src/components/Button';

import { useTranslation } from 'react-i18next';

import { Modal } from 'src/components/Modal';

export const MfaInfo = () => {
  const { t } = useTranslation();
  const [isVisibleFaqSetupModal, setIsVisibleFaqSetupModal] = useState(false);

  return (
    <div>
      <button
        onClick={() => {
          setIsVisibleFaqSetupModal(true);
        }}
        type="button"
        className="flex items-center focus:outline-none text-gray-700 text-xs font-semibold"
      >
        How this works
        <IconQuestion className="ml-2" />
      </button>

      <Modal
        isOpen={isVisibleFaqSetupModal}
        closeModal={() => {
          setIsVisibleFaqSetupModal(false);
        }}
        maxWidth="auto"
        width="510px"
      >
        <div className="p-6">
          <h3 className="mb-2 text-2xl font-semibold text-gray-900">
            How to set up
            <br />
            Two Factor Authentication
          </h3>

          <div className="text-gray-900 text-sm">
            <div className="flex border-b border-gray-300 py-6">
              <div
                className="flex items-center justify-center rounded-full border border-gray-300 text-gray-900 text-xs mr-3"
                style={{ flex: '0 0 24px', height: '24px' }}
              >
                1
              </div>
              <div>
                Install an Authenticator App of your choice. Here are the ones
                we recommend:
                <div className="flex mt-4">
                  <IconGA />

                  <div className="ml-3">
                    <div className="text-gray-900 font-semibold text-sm leading-4">
                      Google Authenticator
                    </div>
                    <div className="text-xs text-gray-700">
                      for{' '}
                      <a
                        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        iPhone
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        Android
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex mt-4">
                  <IconMSA />

                  <div className="ml-3">
                    <div className="text-gray-900 font-semibold text-sm leading-4">
                      Microsoft Authenticator
                    </div>
                    <div className="text-xs text-gray-700">
                      for{' '}
                      <a
                        href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        iPhone
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        Android
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex border-b border-gray-300 py-6">
              <div
                className="flex items-center justify-center rounded-full border border-gray-300 text-gray-900 text-xs mr-3"
                style={{ flex: '0 0 24px', height: '24px' }}
              >
                2
              </div>
              <div>
                Scan the QR Code or enter the Shared Secret Key inside the
                Authenticator App
              </div>
            </div>

            <div className="flex py-6">
              <div
                className="flex items-center justify-center rounded-full border border-gray-300 text-gray-900 text-xs mr-3"
                style={{ flex: '0 0 24px', height: '24px' }}
              >
                3
              </div>
              <div>Copy the code from the App</div>
            </div>
          </div>

          <div className="my-8">
            <Button
              onClick={() => {
                setIsVisibleFaqSetupModal(false);
              }}
              type="button"
              size="medium-large"
              widthClass="w-full"
            >
              {t('auth.gotIt')}
            </Button>
          </div>

          <div className='flex justify-center'>
            <a
              href="mailto:support@agilepartners.eu"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs font-semibold text-gray-700 hover:text-blue-500"
            >
              Contact Support
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};
