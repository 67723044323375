import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RulesContextProvider } from './RolesContext';
import { breadcrumbsSlice } from 'src/store/breadcrumbs/breadcrumbsSlice';
import { RolesTabList } from './RolesTabList';
import { RoleDetail } from 'src/domain/access-management/roles-and-permissions/tabs/rules/role-detail/RoleDetail';

const RolesTab = () => {
  const { t } = useTranslation();
  const storeDispatch = useDispatch();
  const routeMatch = useRouteMatch();

  useEffect(() => {
    storeDispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: t('accessManagement.breadcrumb'),
          route: routeMatch.url,
        },
        {
          label: t('accessManagement.rolesAndPermissions.breadcrumb'),
          route: routeMatch.url,
        },
      ])
    );
  }, [storeDispatch, routeMatch.url, t]);

  return <RolesTabList />;
};

export const RolesPage = () => {
  const routeMatch = useRouteMatch();

  return (
    <RulesContextProvider>
      <Route path={routeMatch.path} exact>
        <RolesTab />
      </Route>
      <Route path={routeMatch.path + '/:id'}>
        <RoleDetail />
      </Route>
    </RulesContextProvider>
  );
};
