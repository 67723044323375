import React, { useState, useEffect, ReactNode } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { RectLoader } from 'src/components/RectLoader';
import { TransactionType } from '../authorization';
import { AuthorizationItem, AvsResponse } from './context';
import { CardType } from '../../transactions/transaction';
import { CcvResult } from '../authorization';
import { authorizationsApi } from 'src/api';

export type TransactionDetails = {
  terminalId: string;
  merchantId: string;
  merchantName: string;
  cardType: CardType;
  country?: string;
  zip?: string;
  cardNumber: string;
  nameOnCard: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  billingAddress?: string;
  transactionType: TransactionType;
  localDate: string;
  transmissionDate: string;
  posEntryMode: string;
  transactionAmount: number;
  transactionCurrency: string;
  stan: string;
  merchantRefNumber: string;
  merchantOrderNumber: string;
  merchantExternalReference: string;
  avsResponse: AvsResponse;
  ccvResult: CcvResult;
  banknetData: string;
  responseCode: string;
  authId: string;
  additionalResponseData: string;
};

const GridItem = ({
  label,
  value,
  withBorderRight = false,
  withBorderBottom = false,
  loading = false,
}: {
  label?: string;
  value?: string;
  withBorderRight?: boolean;
  withBorderBottom?: boolean;
  loading?: boolean;
}) => (
  <div
    className={classNames('px-5 py-2 border-gray-200', {
      'border-b': withBorderBottom,
      'border-r': withBorderRight,
    })}
  >
    <div className="flex items-center">
      <div className="w-1/2">
        <p className="text-xs font-medium text-gray-700">
          {loading ? <RectLoader width={150} height={18} /> : label}
        </p>
      </div>
      <div className="w-1/2">
        <p className="text-xs font-medium text-gray-900 break-all">
          {loading ? <RectLoader width={150} height={18} /> : value}
        </p>
      </div>
    </div>
  </div>
);

const GridGroup = ({
  title,
  children,
  cols = 4,
  loading = false,
}: {
  title: string;
  children: ReactNode;
  cols?: number;
  loading?: boolean;
}) => (
  <div>
    <div className="px-5 py-2 text-xs font-bold text-gray-900 uppercase bg-gray-200">
      {loading ? (
        <RectLoader backgroundColor="#EBEAED" width={100} height={18} />
      ) : (
        title
      )}
    </div>
    <div className={`grid grid-cols-${cols}`}>{children}</div>
  </div>
);

export const AuthorizationPreview = ({
  transaction,
  closePreview,
}: {
  transaction: AuthorizationItem;
  closePreview: () => void;
}) => {
  const [
    transactionDetails,
    setTransactionDetails,
  ] = useState<TransactionDetails>();

  const [transactionDetailsLoading, setTransactionDetailsLoading] = useState(
    true
  );

  useEffect(() => {
    setTransactionDetailsLoading(true);

    authorizationsApi
      .postAuthorizationsGet({
        idWrapper: {
          id: transaction.id,
        },
      })
      .then((data) =>
        setTransactionDetails({
          terminalId: data.terminalId || '',
          merchantId: data.subMerchantId || '',
          merchantName: data.merchantName || '',
          cardType: data.cardType as CardType,
          country: data.billingAddress?.country,
          zip: data.billingAddress?.postalCode,
          cardNumber: data.cardNumber || '' || '',
          nameOnCard: data.cardHolder || '',
          cardExpirationMonth: data.cardExpirationMonth || '',
          cardExpirationYear: data.cardExpirationYear || '',
          billingAddress:
            (data.billingAddress?.country
              ? data.billingAddress?.country + ', '
              : '') +
            (data.billingAddress?.region
              ? data.billingAddress?.region + ', '
              : '') +
            (data.billingAddress?.city
              ? data.billingAddress?.city + ', '
              : '') +
            (data.billingAddress?.postalCode
              ? data.billingAddress?.postalCode + ', '
              : '') +
            (data.billingAddress?.addressLine1 || '') +
            ' ' +
            (data.billingAddress?.addressLine2 || ''),
          transactionType: data.transactionType as TransactionType,
          localDate: data.localDate?.toString() || '',
          transmissionDate: data.transmissionDate?.toString() || '',
          posEntryMode: data.posEntryMode || '',
          transactionAmount: data.transactionAmount || 0,
          transactionCurrency: data.transactionCurrency || '',
          stan: data.stan || '',
          merchantRefNumber: data.merchantRefNumber || '',
          merchantOrderNumber: data.merchantOrderNumber || '',
          merchantExternalReference: data.merchantExternalReference || '',
          avsResponse: data.avsResponse as AvsResponse,
          ccvResult: data.ccvResultCode?.toString() as CcvResult,
          banknetData: data.banknetData || '',
          responseCode: data.responseCode || '',
          authId: data.authId || '',
          additionalResponseData: data.additionalResponseData || '',
        })
      )
      .finally(() => setTransactionDetailsLoading(false));
  }, [transaction.id]);

  const { t } = useTranslation();

  return (
    <div className="relative">
      <div>
        <div className="bg-white border rounded-sm">
          <div className="flex flex-col">
            {(transactionDetails?.terminalId ||
              transactionDetails?.merchantId) && (
              <GridGroup
                loading={transactionDetailsLoading}
                title={t(
                  'transactionsManagement.transactions.details.merchant'
                )}
              >
                {transactionDetails?.terminalId && (
                  <GridItem
                    loading={transactionDetailsLoading}
                    label={t(
                      'transactionsManagement.transactions.details.terminalId'
                    )}
                    value={transactionDetails?.terminalId}
                    withBorderRight
                  />
                )}
                {transactionDetails?.merchantId && (
                  <GridItem
                    loading={transactionDetailsLoading}
                    label={t(
                      'transactionsManagement.transactions.details.merchantId'
                    )}
                    value={transactionDetails?.merchantId}
                    withBorderRight
                  />
                )}
              </GridGroup>
            )}
            <GridGroup
              loading={transactionDetailsLoading}
              title={t(
                'transactionsManagement.transactions.details.cardBillingAddress'
              )}
            >
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.cardNumber'
                )}
                value={transactionDetails?.cardNumber}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.cardExpirationMonth'
                )}
                value={transactionDetails?.cardExpirationMonth}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.cardExpirationYear'
                )}
                value={transactionDetails?.cardExpirationYear}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.cardType'
                )}
                value={
                  transactionDetails?.cardType &&
                  Object.values(CardType).includes(transactionDetails.cardType)
                    ? t(
                        `transactionsManagement.transactions.cardType.${transactionDetails?.cardType}`
                      )
                    : transactionDetails?.cardType
                }
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.nameOnCard'
                )}
                value={transactionDetails?.nameOnCard}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.billingAddress'
                )}
                value={transactionDetails?.billingAddress}
                withBorderRight
              />
            </GridGroup>
            <GridGroup
              loading={transactionDetailsLoading}
              title={t(
                'transactionsManagement.transactions.details.transaction'
              )}
            >
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.transactionAmount'
                )}
                value={
                  transactionDetails?.transactionAmount
                    ? numeral(transactionDetails.transactionAmount).format(
                        '0.00'
                      )
                    : undefined
                }
                withBorderRight
                withBorderBottom
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.transactionCurrency'
                )}
                value={transactionDetails?.transactionCurrency}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.transmissionDate'
                )}
                value={
                  transactionDetails?.transmissionDate &&
                  moment(transactionDetails?.transmissionDate).format(
                    'DD-MM-YYYY'
                  )
                }
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.transactionType'
                )}
                value={
                  transactionDetails?.transactionType &&
                  t(
                    `transactionsManagement.authorizations.transactionType.${transactionDetails?.transactionType}`
                  )
                }
                withBorderRight
                withBorderBottom
              />

              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.authId'
                )}
                value={transactionDetails?.authId}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.ccvResult'
                )}
                value={transactionDetails?.ccvResult}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.responseCode'
                )}
                value={transactionDetails?.responseCode}
                withBorderBottom
                withBorderRight
              />

              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.additionalResponseData'
                )}
                value={transactionDetails?.additionalResponseData}
                withBorderBottom
                withBorderRight
              />

              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.merchantExternalReference'
                )}
                value={transactionDetails?.merchantExternalReference}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.merchantRefNumber'
                )}
                value={transactionDetails?.merchantRefNumber}
                withBorderBottom
                withBorderRight
              />

              <GridItem
                loading={transactionDetailsLoading}
                label={t('transactionsManagement.authorizations.details.stan')}
                value={transactionDetails?.stan}
                withBorderBottom
                withBorderRight
              />
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.merchantOrderNumber'
                )}
                value={transactionDetails?.merchantOrderNumber}
                withBorderBottom
              />

              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.authorizations.details.avsResponse'
                )}
                value={transactionDetails?.avsResponse}
                withBorderRight
              />
              {transactionDetails?.banknetData && (
                <GridItem
                  loading={transactionDetailsLoading}
                  label={t(
                    'transactionsManagement.authorizations.details.banknetData'
                  )}
                  value={transactionDetails?.banknetData}
                  withBorderRight
                />
              )}
            </GridGroup>
            <GridGroup
              loading={transactionDetailsLoading}
              title={t('transactionsManagement.transactions.details.pos')}
            >
              <GridItem
                loading={transactionDetailsLoading}
                label={t(
                  'transactionsManagement.transactions.details.posEntryMode'
                )}
                value={transactionDetails?.posEntryMode}
                withBorderRight
              />
            </GridGroup>
          </div>
        </div>
      </div>
    </div>
  );
};
