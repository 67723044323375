/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Adjustment
 */
export interface Adjustment {
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    recordType?: string;
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    externalId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Adjustment
     */
    fundedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Adjustment
     */
    processedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    currencyCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof Adjustment
     */
    adjustmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    adjustmentCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    adjustmentDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Adjustment
     */
    adjustmentaAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    adjustmentType?: string;
    /**
     * 
     * @type {string}
     * @memberof Adjustment
     */
    newAdjustmentDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Adjustment
     */
    transactionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Adjustment
     */
    rate?: number;
    /**
     * 
     * @type {number}
     * @memberof Adjustment
     */
    unitAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Adjustment
     */
    submitDate?: Date;
}

export function AdjustmentFromJSON(json: any): Adjustment {
    return AdjustmentFromJSONTyped(json, false);
}

export function AdjustmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Adjustment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recordType': !exists(json, 'recordType') ? undefined : json['recordType'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'fundedDate': !exists(json, 'fundedDate') ? undefined : (new Date(json['fundedDate'])),
        'processedDate': !exists(json, 'processedDate') ? undefined : (new Date(json['processedDate'])),
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'adjustmentDate': !exists(json, 'adjustmentDate') ? undefined : (new Date(json['adjustmentDate'])),
        'adjustmentCode': !exists(json, 'adjustmentCode') ? undefined : json['adjustmentCode'],
        'adjustmentDescription': !exists(json, 'adjustmentDescription') ? undefined : json['adjustmentDescription'],
        'adjustmentaAmount': !exists(json, 'adjustmentaAmount') ? undefined : json['adjustmentaAmount'],
        'adjustmentType': !exists(json, 'adjustmentType') ? undefined : json['adjustmentType'],
        'newAdjustmentDescription': !exists(json, 'newAdjustmentDescription') ? undefined : json['newAdjustmentDescription'],
        'transactionCount': !exists(json, 'transactionCount') ? undefined : json['transactionCount'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'unitAmount': !exists(json, 'unitAmount') ? undefined : json['unitAmount'],
        'submitDate': !exists(json, 'submitDate') ? undefined : (new Date(json['submitDate'])),
    };
}

export function AdjustmentToJSON(value?: Adjustment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recordType': value.recordType,
        'externalId': value.externalId,
        'fundedDate': value.fundedDate === undefined ? undefined : (value.fundedDate.toISOString().substr(0,10)),
        'processedDate': value.processedDate === undefined ? undefined : (value.processedDate.toISOString().substr(0,10)),
        'currencyCode': value.currencyCode,
        'adjustmentDate': value.adjustmentDate === undefined ? undefined : (value.adjustmentDate.toISOString().substr(0,10)),
        'adjustmentCode': value.adjustmentCode,
        'adjustmentDescription': value.adjustmentDescription,
        'adjustmentaAmount': value.adjustmentaAmount,
        'adjustmentType': value.adjustmentType,
        'newAdjustmentDescription': value.newAdjustmentDescription,
        'transactionCount': value.transactionCount,
        'rate': value.rate,
        'unitAmount': value.unitAmount,
        'submitDate': value.submitDate === undefined ? undefined : (value.submitDate.toISOString().substr(0,10)),
    };
}


