/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAdjustmentDetailsResponse,
    GetAdjustmentDetailsResponseFromJSON,
    GetAdjustmentDetailsResponseToJSON,
    IdWrapper,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    ListAdjustmentsResponse,
    ListAdjustmentsResponseFromJSON,
    ListAdjustmentsResponseToJSON,
    ListChargebacksRequest,
    ListChargebacksRequestFromJSON,
    ListChargebacksRequestToJSON,
} from '../models';

export interface PostAdjustmentsGetRequest {
    idWrapper?: IdWrapper;
}

export interface PostAdjustmentsListRequest {
    listChargebacksRequest?: ListChargebacksRequest;
}

/**
 * 
 */
export class AdjustmentsApi extends runtime.BaseAPI {

    /**
     */
    async postAdjustmentsGetRaw(requestParameters: PostAdjustmentsGetRequest): Promise<runtime.ApiResponse<GetAdjustmentDetailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/adjustments/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdWrapperToJSON(requestParameters.idWrapper),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAdjustmentDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postAdjustmentsGet(requestParameters: PostAdjustmentsGetRequest): Promise<GetAdjustmentDetailsResponse> {
        const response = await this.postAdjustmentsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postAdjustmentsListRaw(requestParameters: PostAdjustmentsListRequest): Promise<runtime.ApiResponse<ListAdjustmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // CognitoAuthorizer authentication
        }

        const response = await this.request({
            path: `/adjustments/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListChargebacksRequestToJSON(requestParameters.listChargebacksRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAdjustmentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async postAdjustmentsList(requestParameters: PostAdjustmentsListRequest): Promise<ListAdjustmentsResponse> {
        const response = await this.postAdjustmentsListRaw(requestParameters);
        return await response.value();
    }

}
