import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import { Tooltip } from 'src/components/Tooltip';
import { LoadingSwitch } from 'src/components/Loading';
import { Card } from 'src/components/Card';
import { PageSize } from 'src/components/list/PageSize';
import { LoadMoreButton } from 'src/components/list/LoadMoreButton';
import {
  RowsPerPage,
  RulePermission,
} from 'src/api/services/access-management/roles-and-permissions/getRolesAndPermissions';
import { Sorting, Table } from 'src/components/table/Table';
import { UsersContext, usersFormType } from './UsersContext';
import { useUsersColumns } from './useUsersColumns';
import { nameof } from 'src/utils/nameof';
import { AccessChannelFilter } from 'src/domain/access-management/user-management/tabs/users/AccessChannelFilter';
import { AddUser } from 'src/domain/access-management/user-management/tabs/users/add-user/AddUser';
import { useUserModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserModal';
import { useUserDeleteModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserDeleteModal';
import { IamUserStatus } from 'src/api/client';
import { useUserChangeStatusModal } from 'src/domain/access-management/user-management/tabs/users/add-user/useUserChangeStatusModal';

export type RowsPerPageOptionType = {
  value: RowsPerPage;
  label: RowsPerPage;
};

const ROW_PER_PAGE_OPTIONS: RowsPerPageOptionType[] = [
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export interface UsersListParams {
  initLoading: boolean;
  loading: boolean;
  refetch: () => void;
  totalItems?: number;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  items: Array<RulePermission>;
  listError: any;
}

export const UsersList = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    watch,
    getValues,
    reset,
    control,
    setValue,
    register,
  } = useFormContext();
  const {
    dispatch,
    accessChannelList,
    isLoadingChannels,
    columnSorting,
    listParams,
    clearListFilters,
  } = useContext(UsersContext);
  const { accessChannelFilter } = watch([
    nameof(usersFormType.accessChannelFilter),
  ]);

  const { openModal: openEditUserModal, modal: editUserModal } = useUserModal({
    isEdit: true,
  });

  const tableRef = useRef<HTMLDivElement>(null);

  const handleClickUserAction = (id: string, status: IamUserStatus) => {
    switch (status) {
      case IamUserStatus.Active:
        return console.log('suspend');
      case IamUserStatus.Disabled:
        return console.log('active');
      default:
        return console.log('sendInvite');
    }
  };

  const {
    loading,
    items,
    listError,
    refetch,
    hasNextPage,
    isFetchingNextPage,
  } = listParams;

  useEffect(() => {
    setValue('cursor', 0);
  }, []);

  const [sortedItems, setSortedItems] = useState<any[]>();

  const onUserDeleted = useCallback(() => {
    closeDeleteUserModal();
    refetch();
  }, []);

  const {
    openDeleteUserModal,
    deleteUserModal,
    closeDeleteUserModal,
  } = useUserDeleteModal({ onSubmit: onUserDeleted });

  const onUserStatusChanged = useCallback(() => {
    closeChangeStatusUserModal();
  }, []);

  const {
    openChangeStatusUserModal,
    changeStatusUserModal,
    closeChangeStatusUserModal,
  } = useUserChangeStatusModal({ onSubmit: onUserStatusChanged });

  const columns = useUsersColumns({
    tableRef,
    onDelete: openDeleteUserModal,
    onEdit: openEditUserModal,
    onClickUserAction: handleClickUserAction,
    openChangeStatusUserModal,
  });

  const handleRowClick = useCallback(
    (item: any) => {
      history.push(`${match.path}/${item.id}`);
    },
    [history, match.path]
  );

  const handleColumnSorting = useCallback(
    (sorting?: Sorting) => {
      if (loading) {
        return;
      }

      dispatch({
        type: 'SET_COLUMN_SORTING',
        columnSorting: sorting,
      });
    },
    [dispatch, loading]
  );

  useEffect(() => {
    if (!columnSorting) {
      return;
    }

    let sorted = [];

    if (columnSorting.column?.id === 'createdAt') {
      sorted = sortBy(items, [
        (o) => moment(o['createdAt']).format('YYYYMMDDHHmm'),
      ]);
    } else {
      sorted = sortBy(items as any, [
        (o) =>
          o[columnSorting.column?.id || '']
            ? o[columnSorting.column?.id || ''].toString().toLowerCase()
            : null,
      ]);
    }

    setSortedItems(
      columnSorting.direction === 'ASC' ? sorted : sorted.reverse()
    );
  }, [items, columnSorting]);

  useEffect(() => {
    setSortedItems(undefined);
  }, [accessChannelFilter]);

  useEffect(() => {
    tableRef?.current?.scrollTo(tableRef?.current?.scrollLeft, 0);
  }, [loading]);

  useEffect(() => clearListFilters(), [
    clearListFilters,
    reset,
    getValues,
    dispatch,
    accessChannelFilter,
  ]);

  return (
    <div className="relative flex flex-col bg-gray-200">
      {changeStatusUserModal}
      <Card className="h-full">
        {listError?.status === 403 ? (
          <div className="py-3 px-5 bg-red-100 text-sm text-gray-900 rounded-sm">
            {t('permissions.permissionDenied')}
          </div>
        ) : (
          <div className="flex flex-col w-full h-full">
            <div className="flex flex-col md:mb-6 lg:flex-row lg:items-center lg:justify-between">
              <div className={'items-center hidden md:flex font-bold'}>
                {t('accessManagement.userManagement.users')}&nbsp;
                <LoadingSwitch loading={loading}>
                  <span className={'text-blue-500'}>{items?.length}</span>
                </LoadingSwitch>
              </div>
              {!isLoadingChannels && (
                <div className="flex flex-col-reverse items-end mb-5 lg:flex-row lg:items-center md:mb-0">
                  <div className={'mt-4 lg:mt-0 lg:mr-12'}>
                    {accessChannelList && accessChannelList.length > 0 && (
                      <AccessChannelFilter />
                    )}
                  </div>
                  <AddUser />
                </div>
              )}
            </div>
            <div ref={tableRef} className="h-full max-h-full -mx-6">
              {!loading && editUserModal}
              {deleteUserModal}
              <Table
                loading={
                  (loading ||
                    !(accessChannelList && accessChannelList.length > 0)) &&
                  !isFetchingNextPage
                }
                isHeaderFixed
                columns={columns}
                items={sortedItems || items}
                sorting={columnSorting}
                onSort={handleColumnSorting}
                onRowClick={handleRowClick}
              />
            </div>
            <div className="flex items-center justify-between p-5 py-3 mt-auto -mx-6 -mt-px -mb-5 bg-white border-t border-gray-300">
              <div className="items-center hidden text-xs text-gray-700 md:flex">
                {t('list.pagination.results')}:
                <span className="ml-1">
                  <LoadingSwitch loading={loading}>
                    <span className="font-medium">{items?.length}</span>
                    {/*&nbsp;of&nbsp;*/}
                    {/*<span className="font-medium">{totalItems}</span>*/}
                  </LoadingSwitch>
                </span>
              </div>
              <LoadMoreButton
                loading={loading}
                onClick={() => {
                  setValue(
                    'cursor',
                    getValues('cursor') + get(getValues('limit'), 'value')
                  );
                }}
                disabled={!hasNextPage}
              />

              <input className="hidden" type="number" {...register('cursor')} />

              <PageSize
                control={control}
                options={ROW_PER_PAGE_OPTIONS}
                name={nameof(usersFormType.limit)}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};
