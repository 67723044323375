import React, {
  ReactNode,
  useState,
  Ref,
  FormEvent,
  KeyboardEventHandler,
} from 'react';
import classNames from 'classnames';
import AuthCode from 'react-auth-code-input';

import { IconEye } from './icons/IconEye';
import { ReactComponent as IconEyeClosed } from 'src/assets/icons/iconEyeClosed.svg';
import {
  Control,
  Controller,
  FieldError,
  UseControllerOptions,
} from 'react-hook-form';
import { IconChevronLeft } from './icons/IconChevronLeft';
import { Tooltip, TooltipPositions } from './Tooltip';
import { IconCheck } from './icons/IconCheck';
import NumberFormat from 'react-number-format';
import ReactInputMask from 'react-input-mask';
import { Label, LabelProps } from 'src/components/form/FormLabel';

interface InputProps extends LabelProps {
  name: string;
  inputRef?: Ref<HTMLInputElement & HTMLTextAreaElement>;
  type?: 'text' | 'password' | 'textarea';
  icon?: ReactNode;
  error?: FieldError;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  optional?: boolean;
  control?: Control;
  rules?: UseControllerOptions['rules'];
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress?: KeyboardEventHandler;
}

export type SelectProps = Omit<InputProps, 'type' | 'icon' | 'name'> & {
  name?: string;
  onChange?: (value: any) => any;
  value?: string;
  disabled?: boolean;
  items: { label: string | ReactNode; value: string }[];
  tooltipPosition?: TooltipPositions;
};

export const PasswordInput = (props: Omit<InputProps, 'type'>) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <Input
      type={passwordVisible ? 'text' : 'password'}
      {...props}
      icon={
        <button
          type="button"
          className="p-1 rounded focus:outline-none focus:shadow-outline"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          {passwordVisible ? (
            <IconEyeClosed className="text-gray-700" />
          ) : (
            <IconEye className="text-gray-700" />
          )}
        </button>
      }
    />
  );
};

const getInputProps = ({
  type,
  error,
  hasError,
  className,
  placeholder,
  name,
  disabled = false,
}: Pick<
  InputProps,
  | 'type'
  | 'error'
  | 'hasError'
  | 'className'
  | 'placeholder'
  | 'name'
  | 'disabled'
>) => ({
  id: name,
  name: name,
  placeholder,
  className: classNames(
    'block w-full mt-1 form-input text-ssm',
    {
      'border-red-500 focus:border-red-500 focus:shadow-outline-red':
        !!error || hasError,
      'h-18 text-xs': type === 'textarea',
      'bg-gray-300 text-gray-700': disabled,
    },
    className
  ),
});

export const MaskedInput = ({
  label,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  error,
  type = 'text',
  placeholder = ' ',
  className,
  maxLength,
  mask,
  ...props
}: {
  mask: string;
} & InputProps) => {
  const inputProps = getInputProps({
    type,
    error,
    className,
    placeholder,
    name,
  });

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      <div className="relative">
        <ReactInputMask
          mask={mask}
          maskChar=""
          onChange={props.onChange}
          {...inputProps}
        />

        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};

export const MaskedNumberInput = ({
  label,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  error,
  type = 'text',
  placeholder = ' ',
  className,
  ...props
}: {
  format?: string;
} & InputProps) => {
  const inputProps = getInputProps({
    type,
    error,
    className,
    placeholder,
    name,
  });

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      <div className="relative">
        <NumberFormat {...props} {...inputProps} isNumericString mask={''} />
        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};

export const onKeyPressAllowCharacters: KeyboardEventHandler = (e) => {
  if (/[0-9]/i.test(e.key)) {
    e.preventDefault();

    e.stopPropagation();
  }
};

export const onKeyPressAllowPattern = (
  pattern: RegExp
): KeyboardEventHandler => (e) => {
  if (!pattern.test(e.key)) {
    e.preventDefault();

    e.stopPropagation();
  }
};

export const onKeyPressDisallowSpecialCharacters: KeyboardEventHandler = (
  e
) => {
  if (/\W|_/g.test(e.key)) {
    e.preventDefault();

    e.stopPropagation();
  }
};

export const Input = ({
  label,
  disabled = false,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  inputRef,
  error,
  hasError,
  type = 'text',
  placeholder = ' ',
  className,
  maxLength,
  optional,
  onKeyPress,
  onChange,
}: InputProps) => {
  const inputProps = {
    ref: inputRef,
    ...getInputProps({
      type,
      error,
      hasError,
      className,
      placeholder,
      name,
      disabled,
    }),
  };

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
      optional={optional}
    >
      <div className="relative">
        {type === 'textarea' ? (
          <textarea
            ref={inputRef}
            style={{ resize: 'none' }}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onKeyPress={onKeyPress}
            {...inputProps}
          />
        ) : (
          <input
            ref={inputRef}
            type={type}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            onKeyPress={onKeyPress}
            {...inputProps}
          />
        )}
        {icon && (
          <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
            {icon}
          </span>
        )}
      </div>
    </Label>
  );
};

export const AuthCodeInput = ({
  label,
  labelColorClassName,
  rules,
  name,
  error,
  control,
}: InputProps) => {
  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={'block text-xs font-bold pb-2'}
      labelColorClassName={labelColorClassName}
      error={error}
      errorPosition='bottom'
      className='auth-code'
    >
      <div className={classNames("auth-code-wrapper relative", { 'mb-4': error })}>
        <Controller
          name={name}
          rules={rules}
          control={control}
          characters={6}
          defaultValue=''
          allowedCharacters={/(?=.*\d)/}
          inputStyle={{
            width: '50px',
            height: '50px',
            boxSizing: 'border-box',
            padding: '8px',
            borderRadius: '2px',
            fontSize: '16px',
            textAlign: 'center',
            textTransform: 'uppercase'
          }}
          as={AuthCode}
        />
      </div>
    </Label>
  );
};

export const Select = ({
  label,
  labelFontClassName = 'text-sm font-bold',
  labelColorClassName = 'text-gray-900',
  className,
  name,
  inputRef,
  error,
  items,
  value,
  tooltipPosition = 'bottom-start',
  onChange = () => {},
}: SelectProps) => {
  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
    >
      <Tooltip
        minWidth={200}
        offsetVertical={10}
        withPadding={false}
        persist
        placement={tooltipPosition}
        content={
          <ul className="flex flex-col py-2">
            {items.map((item, key) => (
              <li
                tabIndex={0}
                key={key}
                className="flex items-center px-5 py-2 text-xs font-bold text-gray-900 transition-colors duration-200 cursor-pointer hover:bg-gray-100"
                onClick={() => onChange(item.value)}
              >
                <span className="w-6">
                  {value === item.value && (
                    <IconCheck className="text-blue-500" />
                  )}
                </span>
                {item.label}
              </li>
            ))}
          </ul>
        }
      >
        {({ ref, setIsOpen, isOpen }) => (
          <div ref={ref} className="relative cursor-pointer">
            <input
              ref={inputRef}
              id={name}
              name={name}
              readOnly
              onFocus={() => setIsOpen(!isOpen)}
              onBlur={() => setIsOpen(false)}
              className={classNames(
                'block w-full mt-1 form-input cursor-pointer',
                {
                  'border-red-500 focus:border-red-500 focus:shadow-outline-red': !!error,
                },
                className
              )}
            />
            <div className="absolute top-0 left-0 w-full h-full px-3 py-1">
              <div className="flex items-center w-full h-full text-xs font-medium text-gray-900 bg-white">
                {items.find((item) => item.value === value)?.label}
              </div>
            </div>
            <span className="absolute top-0 right-0 flex items-center justify-center w-12 h-full">
              <IconChevronLeft
                className={classNames(
                  'ml-2 transform transition text-gray-700 duration-200',
                  {
                    '-rotate-90': !isOpen,
                    'rotate-90 text-blue-600': isOpen,
                  }
                )}
              />
            </span>
          </div>
        )}
      </Tooltip>
    </Label>
  );
};
