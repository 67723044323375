import React, { useContext } from 'react';
import {
  DropdownFilter,
  InputDropdown,
} from 'src/components/dropdown-filter/DropdownFilter';
import { useTranslation } from 'react-i18next';
import { ItemFilter } from 'src/components/dropdown-filter/filter';
import { CheckboxList } from 'src/components/dropdown-filter/CheckboxList';
import { ListContext } from '../context';
import { MatchType } from '../../transaction';

export const FilterMatchType = () => {
  const { dispatch, filters } = useContext(ListContext);

  const { t } = useTranslation();

  const items = [
    MatchType.PENDING,
    MatchType.SUCCESS,
    MatchType.DUP,
    MatchType.NEQSTAN,
    MatchType.NEQAMT,
    MatchType.NEQNWID,
    MatchType.NOAUTH,
    MatchType.NOSTL,
  ].map((item) => ({
    value: item,
    label: item,
  }));

  const itemToggle = (options: { checked: boolean; item: ItemFilter }) =>
    dispatch({
      type: options.checked
        ? 'ADD_MATCH_TYPE_FILTER'
        : 'REMOVE_MATCH_TYPE_FILTER',
      itemFilter: options.item,
    });

  const clearFilter = () => dispatch({ type: 'RESET_MATCH_TYPE_FILTER' });

  return (
    <DropdownFilter
      title={t('transactionsManagement.transactions.list.filters.matchType')}
      headerSecondaryLabel={t(
        'transactionsManagement.transactions.list.filters.numberSelected',
        { totalItems: filters.matchTypes.length }
      )}
      onClearFilter={clearFilter}
      trigger={(dropdownProps) => (
        <InputDropdown
          name="filterMatchTypes"
          label={t(
            'transactionsManagement.transactions.list.filters.matchType'
          )}
          placeholder={t(
            'transactionsManagement.transactions.list.filters.all'
          )}
          value={
            filters.matchTypes.length
              ? t(
                  'transactionsManagement.transactions.list.filters.numberSelected',
                  { totalItems: filters.matchTypes.length }
                )
              : ''
          }
          {...dropdownProps}
        />
      )}
    >
      <CheckboxList
        items={items}
        filters={filters.matchTypes}
        onItemToggle={itemToggle}
        totalItems={filters.matchTypes.length}
        heightClassName="h-auto"
      />
    </DropdownFilter>
  );
};
